import produce from 'immer';
import Konva from 'konva';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { Arrow, Circle } from 'react-konva';
import { Vec2 } from 'three';
import { Colors } from '../../styles/styles';
import { AnnotationCursor, ArrowProps } from './types';

const STAGE_AREA_BUFFER = 10;

interface Props {
  arrow: ArrowProps;
  selectArrow: (evt: Konva.KonvaEventObject<MouseEvent>) => void;
  selected: boolean;
  width: number;
  height: number;
  updatePointsOnArrowDrag: (disp: Vec2) => void;
  updateArrowPoints: (startPoint: Vec2, newPoint: Vec2) => void;
  updateCursorType: (cursor: AnnotationCursor) => void;
}

export function AnnotationArrow({
  arrow,
  selectArrow,
  selected,
  width,
  height,
  updatePointsOnArrowDrag,
  updateArrowPoints,
  updateCursorType,
}: Props) {
  // Transform start and end points to an array of points relative to arrow.startPoint
  const points = useMemo(() => {
    return [
      0,
      0,
      arrow.endPoint.x - arrow.startPoint.x,
      arrow.endPoint.y - arrow.startPoint.y,
    ];
  }, [arrow]);

  const onMouseOver = useCallback(() => {
    const cursorType: AnnotationCursor = selected ? 'move' : 'pointer';
    updateCursorType(cursorType);
  }, [selected, updateCursorType]);

  const onMouseLeave = useCallback(() => updateCursorType('default'), [
    updateCursorType,
  ]);

  return (
    <>
      <Arrow
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        visible={arrow.visible}
        id={arrow.id}
        key={arrow.id}
        type="arrow"
        points={points}
        fill="white"
        stroke="white"
        strokeWidth={2}
        x={arrow.startPoint.x}
        y={arrow.startPoint.y}
        hitStrokeWidth={20}
        onMouseDown={(event) => {
          event.cancelBubble = true;
          selectArrow(event);
          updateCursorType('move');
        }}
        draggable
        onDragMove={(e) => {
          const disp: Vec2 = { x: e.evt.movementX, y: e.evt.movementY };
          updatePointsOnArrowDrag(disp);
        }}
      />
      {/** arrow.visible equalling false is representative of an arrow being deleted so don't even
       * render the handles in this case
       */}
      {arrow.visible && (
        <>
          <ArrowHandle
            point={arrow.startPoint}
            id={`circstart_${arrow.id}`}
            height={height}
            width={width}
            updateArrowPoint={(point: Vec2) =>
              // Update the startPoint only
              updateArrowPoints(point, arrow.endPoint)
            }
            updateCursorType={updateCursorType}
            visible={selected}
            selectArrow={selectArrow}
          />
          <ArrowHandle
            point={arrow.endPoint}
            id={`circend_${arrow.id}`}
            height={height}
            width={width}
            updateArrowPoint={(point: Vec2) =>
              // Update the endPoint only
              updateArrowPoints(arrow.startPoint, point)
            }
            updateCursorType={updateCursorType}
            visible={selected}
            selectArrow={selectArrow}
          />
        </>
      )}
    </>
  );
}

interface ArrowHandleProps {
  point: Vec2;
  id: string;
  height: number;
  width: number;
  updateArrowPoint: (point: Vec2) => void;
  updateCursorType: (cursor: AnnotationCursor) => void;
  visible: boolean;
  selectArrow: (evt: Konva.KonvaEventObject<MouseEvent>) => void;
}

const DEFAULT_HANDLE_OPACITY = 0.2;
const HOVERING_HANDLE_OPACITY = 0.6;

/**
 * The arrow handles will be rendered completely transparent when hidden
 */
function ArrowHandle({
  point,
  id,
  height,
  width,
  updateArrowPoint,
  updateCursorType,
  visible,
  selectArrow,
}: ArrowHandleProps): ReactElement<ArrowHandleProps> {
  const [fill, setFill] = useState(DEFAULT_HANDLE_OPACITY);

  const onMouseOver = useCallback(() => {
    setFill(HOVERING_HANDLE_OPACITY);
    const cursorType: AnnotationCursor = visible ? 'move' : 'pointer';
    updateCursorType(cursorType);
  }, [setFill, updateCursorType, visible]);

  const onMouseLeave = useCallback(() => {
    updateCursorType('default');
    setFill(DEFAULT_HANDLE_OPACITY);
  }, [updateCursorType]);

  // Rather than set visibility of the handles, make them completely transparent when hidden. This
  // allows them to still be hit on mouse down, which will make them reappear as selected and allow
  // you to move them around.
  const fillColour = useMemo(
    () =>
      visible ? `rgba(255, 255, 255, ${fill})` : Colors.completelyTransparent,
    [visible, fill]
  );
  const strokeColour = useMemo(
    () => (visible ? Colors.dodgerBlue : Colors.completelyTransparent),
    [visible]
  );

  return (
    <Circle
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      id={`circend_${id}`}
      fill={fillColour}
      stroke={strokeColour}
      strokeWidth={1}
      draggable
      radius={15}
      x={point.x}
      y={point.y}
      onMouseDown={(event) => {
        event.cancelBubble = true;
        selectArrow(event);
        updateCursorType('move');
      }}
      onDragMove={(e) => {
        const x = e.target.attrs.x;
        const y = e.target.attrs.y;
        const newPoint = produce(point, (draft) => {
          draft.x =
            x >= width - STAGE_AREA_BUFFER
              ? width - STAGE_AREA_BUFFER
              : x > 0
              ? x
              : STAGE_AREA_BUFFER;
          draft.y =
            y >= height - STAGE_AREA_BUFFER
              ? height - STAGE_AREA_BUFFER
              : y > 0
              ? y
              : STAGE_AREA_BUFFER;
        });

        updateArrowPoint(newPoint);
      }}
    />
  );
}
