import cn from 'classnames';
import React, { ReactElement } from 'react';
import { ReactComponent as Close } from '../../assets/icons/close.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

interface Props {
  editMode: boolean;
  type: string;
  title?: string;
  value: string | number | undefined | null;
  measureType?: string | null;
  onChange: (event: any, title?: string, type?: string) => void;
  handleNewMedication?: (event: any, currIndex: number) => void | undefined;
  handleRemoveMedication?: (event: any) => void | undefined;
  routeOfAdministration?: string | null;
  medIndex?: number;
}

const transformToStringOrDash = (value: string | number | undefined) => {
  return value?.toString() && value.toString().length > 0 ? value : '-';
};
const parseKey = (key: string) => {
  return key.replaceAll(' ', '_').toLocaleLowerCase();
};
export default function RowWithTextField({
  editMode,
  type,
  title = '',
  value,
  measureType,
  onChange,
  handleNewMedication,
  handleRemoveMedication,
  routeOfAdministration,
  medIndex = -1,
}: Props): ReactElement<Props> {
  const transformedValue = !editMode
    ? transformToStringOrDash(value ?? undefined)
    : '';
  let valueAUX = '';
  if (value) {
    valueAUX = value.toString();
  }

  if (parseKey(title) === 'heart_rate' || parseKey(title) === 'kvp')
    valueAUX =
      valueAUX.length > 1 && valueAUX[0] === '0' ? valueAUX.slice(1) : valueAUX;

  return (
    <>
      <div
        className={cn('procedure-details__data-cell', {
          'procedure-details__data-cell-edit-mode': editMode,
        })}
      >
        <dt className="procedure_details__data-name">{title}</dt>
      </div>

      <div
        className={cn('procedure-details__data-cell', {
          'procedure-details__data-cell-edit-mode': editMode,
        })}
      >
        {editMode && (
          <input
            type={type}
            min="0"
            className="procedure-details__data__input"
            value={valueAUX ?? undefined}
            onChange={(e) => onChange(e.target.value, title, type)}
          />
        )}
        {!editMode && (
          <dd className="procedure-details__data-value">{transformedValue}</dd>
        )}
        {measureType && transformedValue !== '-' && (
          <dt className="procedure-details__data-measure_type">
            {measureType}
          </dt>
        )}
        {handleRemoveMedication && editMode && (
          <button
            className="procedure-details__data__close-button"
            onClick={handleRemoveMedication}
            title="Remove"
          >
            <span className="close-icon">
              <Close />
            </span>
          </button>
        )}
        {handleNewMedication && editMode && (
          <button
            className="procedure-details__data__plus-button"
            onClick={() => handleNewMedication(routeOfAdministration, medIndex)}
            title="Add"
          >
            <span className="plus-icon">
              <PlusIcon />
            </span>
          </button>
        )}
      </div>
    </>
  );
}
