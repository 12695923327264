import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import showToast from '../components/Toast/showToast';
import { STUDY_LOAD_ERROR_MSG } from '../config';
import { useStoreContext } from '../context/store-context';
import * as api from '../utils/api';

export function useReportData(studyId?: string) {
  const {
    runID,
    setCurrentReport,
    setReportHistory,
    setReviewList,
    setFetchingReport,
  } = useStoreContext();
  const browser_history = useHistory();

  useEffect(() => {
    if (!studyId || !runID) return;

    let isCancelled = false;

    const fetchData = async () => {
      setFetchingReport(true);
      try {
        const promises = Promise.all([
          api.fetchReport(studyId, runID),
          api.fetchReportHistory(studyId, runID),
          api.fetchReviewList(studyId, runID),
        ]);

        const [report, history, reviewList] = await promises;

        if (isCancelled) {
          return;
        }

        setCurrentReport(report);
        setReportHistory(history);
        setReviewList(reviewList);
      } catch (err) {
        console.error('DATALOAD ERROR', err);
        showToast.error(STUDY_LOAD_ERROR_MSG);
        browser_history.push('/');
      } finally {
        setFetchingReport(false);
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [
    studyId,
    runID,
    setCurrentReport,
    setReportHistory,
    setReviewList,
    browser_history,
    setFetchingReport,
  ]);
}
