import {
  CTNonContrastViewerData,
  LongAxisViewerData,
  ShortAxisViewerData,
  SelectedVesselData,
  VesselDataState,
  VesselViewerData,
} from './types';

export const DEFAULT_SELECTED_VESSEL_DATA: SelectedVesselData = {
  highSliceIdx: 0,
  midSliceIdx: 10, // TODO: This may not be a safe value.
  lowSliceIdx: 20, // TODO: This may not be a safe value.
  name: undefined,
  priorityVessel: undefined,
  highestPriorityIdx: 0,
};

export const DEFAULT_VESSEL_DATA_STATE: VesselDataState = {
  vesselData: undefined,
  vesselViewerData: {},
  ctNonContrastViewerData: undefined,
  savingVesselId: undefined,
  patientID: undefined,
  runID: undefined,
  selectedVesselData: { ...DEFAULT_SELECTED_VESSEL_DATA },
};

export const DEFAULT_VESSEL_VIEWER_DATA: VesselViewerData = {
  cprVesselData: undefined,
  longAxisViewerData: undefined,
  shortAxisViewerData: undefined,
  model3dData: undefined,
};

export const DEFAULT_SHORT_AXIS_VIEWER_DATA: ShortAxisViewerData = {
  shape: [],
  imageBufferData: undefined,
  polygonsLumen: {},
  polygonsOuter: {},
};

export const DEFAULT_LONG_AXIS_VIEWER_DATA: LongAxisViewerData = {
  shape: [],
  imageBufferData: undefined,
};

export const DEFAULT_CT_NONCONTRAST_VIEWER_DATA: CTNonContrastViewerData = {
  shape: [],
  imageBufferData: undefined,
};
