import React, {
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import Draggable, { DraggableBounds } from 'react-draggable';
import CoronaryFindingsCard from '../../../components/CoronaryFindingsCard';
import { VesselGroupings } from '../../../config';
import { useContrastContext } from '../../../context/contrast-context';
import {
  useCoronaryFindingIsAiAssessedSelector,
  useCoronaryFindingsSelector,
} from '../../../selectors/reporting';

interface Props {
  selectedVesselGroup: VesselGroupings;
}

export default function ReportSection({
  selectedVesselGroup,
}: Props): ReactElement<Props> {
  const containerRef = useRef<any>();

  const { volumeViewerRef } = useContrastContext();

  const coronaryFindingAiAssessed = useCoronaryFindingIsAiAssessedSelector();

  const coronaryFindings = useCoronaryFindingsSelector();

  const [dragBounds, setDragBounds] = useState<DraggableBounds>({});

  // Calculate the bounds that will restrict dragging the window to within the volume viewer
  const calculateDraggableBounds = useCallback(() => {
    const ancestor = volumeViewerRef.current?.getBoundingClientRect();
    const container = containerRef.current?.getBoundingClientRect();
    if (ancestor && container) {
      setDragBounds({
        top: ancestor.top - container.top,
        left: ancestor.left - container.left,
        right: ancestor.right - container.right,
        bottom: ancestor.bottom - container.bottom,
      });
    }
  }, [volumeViewerRef]);

  useLayoutEffect(calculateDraggableBounds, [calculateDraggableBounds]);

  useEffect(() => {
    window.addEventListener('resize', calculateDraggableBounds);

    return () => window.removeEventListener('resize', calculateDraggableBounds);
  }, [calculateDraggableBounds]);

  return (
    <Draggable bounds={dragBounds}>
      <div className="meta-details__coronary" ref={containerRef}>
        {selectedVesselGroup && (
          <CoronaryFindingsCard
            selectedVesselGroup={selectedVesselGroup}
            headerType="detailed"
            coronaryFindings={coronaryFindings}
            aiAssessed={coronaryFindingAiAssessed[selectedVesselGroup]}
          />
        )}
      </div>
    </Draggable>
  );
}
