import { useCallback } from 'react';
import { EMPTY_VIEW_ARRAY } from '../../context/contrast-constants';
import { useContrastContext } from '../../context/contrast-context';
import {
  ContrastViewContent,
  ContrastViewType,
} from '../../context/contrast-types';
import {
  DraggableItem,
  useDragAndDropContext,
} from '../../context/drag-n-drop';
import { isContrastSeries } from '../../context/drag-n-drop/helpers';
import {
  useViewConfigContext,
  ViewConfigActions,
} from '../../context/viewConfig';
import { useAppSelector } from '../../hooks';
import { currentStudySelector } from '../../selectors/study';
import { getViewContrastType } from './helpers';

/**
 * Respond to an entire contrast series being drag-dropped (ie all 3 or 4 views).
 */
export function useDropParentDraggable() {
  const { onDraggedContrastSeries } = useContrastContext();
  const study = useAppSelector(currentStudySelector);
  const {
    state: { draggableGroup },
  } = useDragAndDropContext();
  const {
    state: { seriesId },
    dispatch,
  } = useViewConfigContext();

  return useCallback(
    (draggableItem: DraggableItem) => {
      if (isContrastSeries(draggableItem.id) && seriesId) {
        if (study) {
          const newConfig = draggableGroup[draggableItem.id].children.map(
            (child): ContrastViewContent => ({
              study,
              seriesName: draggableItem.id,
              viewType:
                getViewContrastType(child.customType) ?? ContrastViewType.Empty,
            })
          );

          // In case there are missing views, make 4 empty views, add after the actual
          // number of views then limit to 4
          const contrastViews = [...newConfig, ...EMPTY_VIEW_ARRAY].slice(0, 4);
          onDraggedContrastSeries(contrastViews);
          dispatch(ViewConfigActions.pushRecentlySelectedViews(contrastViews));
        }
      } else {
        dispatch(ViewConfigActions.clearRecentlySelectedViews());
      }
    },
    [study, draggableGroup, seriesId, onDraggedContrastSeries, dispatch]
  );
}

/**
 * Respond to a single view being dragged and dropped.
 */
export function useDropChildDraggable() {
  const { onDraggedContrastView } = useContrastContext();
  const { dispatch } = useViewConfigContext();
  const study = useAppSelector(currentStudySelector);

  return useCallback(
    (draggableItem: DraggableItem, viewIndex: number) => {
      const contrastType = getViewContrastType(draggableItem.customType);
      // Check we are dragging in a valid contrast view type.
      if (contrastType !== undefined && study) {
        const contrastView: ContrastViewContent = {
          study,
          seriesName: draggableItem.id,
          viewType: contrastType,
        };
        onDraggedContrastView(contrastView, viewIndex);
        // Add the dragged view to the recent views list.
        dispatch(ViewConfigActions.pushRecentlySelectedViews([contrastView]));
      }
    },
    [study, onDraggedContrastView, dispatch]
  );
}
