import * as PIXI from 'pixi.js-legacy';
import { throttle } from 'lodash';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import mergeImages from 'merge-images';
import { Loader } from '../../../components/Loader/Loader';
import {
  MOUSE_BUTTONS,
  NAV_TABS,
  WINDOW_LEVEL_MAX,
  WINDOW_LEVEL_MIN,
  WINDOW_WIDTH_MAX,
  WINDOW_WIDTH_MIN,
} from '../../../config';
import {
  useContrastContext,
  useContrastViewTypeSelector,
  useContrastVolumeSelector,
} from '../../../context/contrast-context';
import {
  ContrastViewType,
  ContrastVolume,
  ContrastVolumeActions,
  ContrastVolumeOverlayMode,
  ContrastVolumeStatus,
  getStudySeriesId,
} from '../../../context/contrast-types';
import { useStoreContext } from '../../../context/store-context';
import { useWindowContext } from '../../../context/window-context';
import { WindowLevels } from '../../../context/window-types';
import { useVesselStateSelector } from '../../../selectors/vessels';
import { useMeasurementToolContext } from '../../../context/measurement-tools-context';
import {
  createEllipseSprite,
  createRulerSprite,
  onMouseDownRuler,
  onMouseMoveRuler,
  onMouseUpRuler,
  onMouseMoveEllipse,
  onMouseUpEllipse,
  onZoomMeasurementTools,
  MEASUREMENT_SETTINGS,
  isRulerHandled,
  onMouseDownEllipse,
} from '../../../utils/measurementTools';
import {
  HandlePosition,
  MeasurementGraphics,
  MM_TO_PX,
  HuData,
} from '../../../utils/measurementTools/types';
import { PointObject } from '../../../types';
import { View2D } from '../ReactVTKJS';
import { ContrastViewOverlay } from './ContrastViewOverlay';
import './initCornerstone.js';
import { vec2 } from 'gl-matrix';
import { getMeanValue, getStandardDeviation } from '../../../utils/math-utils';

/**
 * A simple function to round the number and clamp it to the min-max range.
 */
const roundAndClamp = (num: number, min: number, max: number) => {
  return num <= min ? min : num >= max ? max : Math.round(num);
};

interface ContrastViewProps {
  // The index of the view being shown (0 to 3).
  viewIndex: number;
}

export const ContrastView: React.FC<ContrastViewProps> = ({ viewIndex }) => {
  const { visibleTab } = useStoreContext();
  const { contrastWindowLevels, setContrastWindowLevels } = useWindowContext();
  const {
    pendingScreenshot,
    visibleViews,
    dispatchContrastVolumeAction,
    onDoubleClickView,
  } = useContrastContext();
  const { selectedVesselName } = useVesselStateSelector();

  //measurement tool context
  const {
    isEllipseActive,
    isRulerActive,
    measurementToolStartPoint,
    setMeasurementToolStartPoint,
    isDraggingMeasurementToolRef,
    isClickDownMeasurementToolRef,
    measurementTargetRef,
    isMeasurementMode,
    isOverMeasurementToolRef,
    hitAreaEllipseToolRef,
    clearMeasurements,
  } = useMeasurementToolContext();

  // The PIXI container that the images, annotations etc are rendered on.
  const containerRef = useRef<PIXI.Container | null>(null);
  const appRef = useRef<PIXI.Application | null>(null);
  // The outer WebGLViewer div element.
  const holderRef = useRef<HTMLDivElement | null>(null);
  // We can use this to let View2D force a render on the ContrastView, in particular we want to do
  // this after a context lost event where we might want to show an error.
  const [_, setForceRender] = useState<number>(0);

  // measurement tools
  const msmToolsContainerRef = useRef<PIXI.Container | null>(null);
  const measurementSpriteRef = useRef<MeasurementGraphics | null>(null);
  const handleTargetNameRef = useRef<HandlePosition | null>(null);
  const defaultScaleRef = useRef<number>(1);
  const mousePosRef = useRef<PointObject>({ x: 0, y: 0 });
  const huDataRef = useRef<HuData>({});
  const pixelPerMillimeterRef = useRef({ mmPerPixelX: 0, mmPerPixelY: 0 });
  const isEllipseActiveRef = useRef(isEllipseActive);
  const isRulerActiveRef = useRef(isRulerActive);

  const contrastVolume = useContrastVolumeSelector(viewIndex);
  // TODO: If this fails to get a viewType then really we shouldn't render anything...
  const viewType =
    useContrastViewTypeSelector(viewIndex) || ContrastViewType.Axial;
  const volumeLoadFailed =
    contrastVolume?.status === ContrastVolumeStatus.LOAD_FAILED;
  const volumeLoaded = contrastVolume?.status === ContrastVolumeStatus.LOADED;
  const studySeriesId = contrastVolume
    ? getStudySeriesId(contrastVolume.study, contrastVolume.seriesName)
    : 'undefined';
  const isVisibleTab = visibleTab === NAV_TABS.ctVolumeTab;

  const getPixelPerMillimeter = useCallback((): number => {
    // If the pixel per millimeter has yet to be calculated, then return a
    // rought estimate. This should only happen for a max of one frame.
    if (pixelPerMillimeterRef.current === null) {
      return MM_TO_PX;
    }

    // Get the current scale (read: zoom) of thing rendering the measurement lines
    // (currently pixi.)
    const measurementRendererScale = getMeasurementRendererScale();

    return pixelPerMillimeterRef.current.mmPerPixelY * measurementRendererScale;
  }, []);

  useEffect(() => {
    if (visibleTab !== NAV_TABS.ctVolumeTab) return;

    isEllipseActiveRef.current = isEllipseActive;
    isRulerActiveRef.current = isRulerActive;
    const scale = getMeasurementRendererScale();
    // if there is a measurement tool active, reset the state
    if (
      measurementTargetRef.current &&
      measurementTargetRef.current.lineName ===
        MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse
    ) {
      createEllipseSprite({
        parent: MEASUREMENT_SETTINGS.PARENT.Contrast,
        sprite: measurementTargetRef.current,
        points: measurementTargetRef.current.points,
        lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse,
        state: MEASUREMENT_SETTINGS.STATES.finish,
        scale,
        callback: onMouseDownEllipseNode,
        hitAreaEllipseToolRef: hitAreaEllipseToolRef.current,
        pixelsPerMillimeter: getPixelPerMillimeter(),
      });
    }
    if (
      measurementTargetRef.current &&
      measurementTargetRef.current.lineName ===
        MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler
    ) {
      createRulerSprite({
        parent: MEASUREMENT_SETTINGS.PARENT.Contrast,
        sprite: measurementTargetRef.current,
        start: measurementTargetRef.current.startPoint,
        end: measurementTargetRef.current.endPoint,
        lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
        state: MEASUREMENT_SETTINGS.STATES.finish,
        scale,
        pixelsPerMillimeter: getPixelPerMillimeter(),
      });
    }
    // disable click event for ellipse
    if (isEllipseActiveRef.current && msmToolsContainerRef.current) {
      msmToolsContainerRef.current.children.forEach((child: any) => {
        if (child.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse)
          child.cursor = 'move';
        if (child.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler) {
          child.cursor = 'default';
          child._fillStyle.alpha = 1;
        }
      });
    }
    // disable click event for ruler
    if (isRulerActiveRef.current && msmToolsContainerRef.current) {
      msmToolsContainerRef.current.children.forEach((child: any) => {
        if (child.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler)
          child.cursor = 'move';
        if (child.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse) {
          child.cursor = 'default';
          child._fillStyle.alpha = 0;
        }
      });
    }

    isDraggingMeasurementToolRef.current = undefined;
    measurementTargetRef.current = null;
    handleTargetNameRef.current = null;
    isOverMeasurementToolRef.current = '';
  }, [isRulerActive, isEllipseActive]);

  /**
   * cleanup all measurement tools
   */
  const cleanUpMeasurementTools = useCallback(() => {
    if (msmToolsContainerRef.current) {
      msmToolsContainerRef.current.destroy({
        children: true,
      });
      msmToolsContainerRef.current = null;
      measurementSpriteRef.current = null;
      handleTargetNameRef.current = null;
      isOverMeasurementToolRef.current = '';
      hitAreaEllipseToolRef.current = {};
      restoreCursor();
      holderRef.current?.classList.add('contrast-view-default');
    }
  }, [hitAreaEllipseToolRef, isOverMeasurementToolRef]);

  useEffect(() => {
    if (!isMeasurementMode) {
      cleanUpMeasurementTools();
    } else {
      if (containerRef.current) {
        msmToolsContainerRef.current = new PIXI.Container();
        containerRef.current.addChild(msmToolsContainerRef.current);
      }
    }
    return () => {
      isDraggingMeasurementToolRef.current = undefined;
      measurementTargetRef.current = null;
    };
  }, [isMeasurementMode]);

  useEffect(() => {
    cleanUpMeasurementTools();
  }, [clearMeasurements]);

  /**
   * If the user has just switched back to this tab then resize the vtk buffers and render vtk view.
   * If the number of visible views has changed we need to resize the vtk buffers and render vtk view.
   */
  useLayoutEffect(() => {
    const api = contrastVolume?.getApi(viewIndex);
    // We don't need to redraw if this isn't the visible tab.
    if (api && isVisibleTab) {
      api.refreshViewImmediately(true, true);
    }
  }, [isVisibleTab, visibleViews.length]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * It takes a frame for the layout to be applied so we need to flag that the view wasEnlarged so we can
   * resize buffers etc once the new layout has been applied.
   */
  useEffect(() => {
    // If there is a pending screenshot then we can now take it.
    if (
      pendingScreenshot &&
      pendingScreenshot.viewIndex === viewIndex &&
      pendingScreenshot.onTakeScreenshotCallback
    ) {
      const api = contrastVolume?.getApi(viewIndex);
      if (api) {
        const views = api.genericRenderWindow.getRenderWindow().getViews();
        if (views && views.length >= 1) {
          let screenshot: any = null;
          //divide the size of the view by window.devicePixelRatio to avoid the offset with the measurement tools
          let viewWidth = 0,
            viewHeight = 0;
          if (isMeasurementMode && window.devicePixelRatio > 1) {
            viewWidth = views[0].getSize()[0];
            viewHeight = views[0].getSize()[1];
            views[0].setSize(
              viewWidth / window.devicePixelRatio,
              viewHeight / window.devicePixelRatio
            );
          }
          // Flag that we want to capture an image when we next render one.
          views[0].captureNextImage().then((imageData: string) => {
            if (isMeasurementMode && appRef.current) {
              const canvas = appRef.current.renderer.view;

              screenshot = new Image();
              screenshot.src = canvas.toDataURL();

              screenshot.onload = () => {
                mergeImages([imageData, canvas.toDataURL()]).then(
                  (mergeImageData: string) => {
                    pendingScreenshot.onTakeScreenshotCallback(mergeImageData);
                    //restore the size of the view
                    window.devicePixelRatio > 1 &&
                      views[0].setSize(viewWidth, viewHeight);
                  }
                );
              };
            } else {
              pendingScreenshot.onTakeScreenshotCallback(imageData);
            }
          });
          // Force a render so that captureNextImage has an image to capture.
          api.refreshViewImmediately(true, false);
          return () => (screenshot = null);
        }
      }
    }
    return () => {};
  }, [pendingScreenshot]); // eslint-disable-line react-hooks/exhaustive-deps

  // Get the centerline points for the currently selected vessel.
  const centerline: number[][] | undefined = selectedVesselName
    ? contrastVolume?.centerlineMap.get(selectedVesselName)?.points
    : undefined;

  // Try to get the scan thickness of the study from the series details.
  // NOTE: thickness is saved as a string.
  let scanThickness = 0.1;
  if (contrastVolume) {
    const series = contrastVolume.study.series[contrastVolume.seriesName];
    if (series?.thickness) {
      scanThickness = parseFloat(series?.thickness);
    }
  }

  /**
   * Update the huValue and crosshairs position in the store context after the volume has loaded.
   */
  useEffect(() => {
    if (volumeLoaded) {
      dispatchContrastVolumeAction({
        type: ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES,
        viewIndex,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [volumeLoaded]);

  /**
   * A hook safe throttled way to update the huValue and crosshairs position in the store context.
   */
  const throttledOnCrosshairsMovedRef = useRef(
    throttle(
      (viewIndex) => {
        dispatchContrastVolumeAction({
          type: ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES,
          viewIndex,
        });
      },
      200,
      { leading: false, trailing: true }
    )
  );

  /**
   * Respond to the crosshairs position changing.
   */
  const onCrosshairsMoved = useCallback(() => {
    throttledOnCrosshairsMovedRef.current(viewIndex);
  }, [viewIndex]);

  /**
   * A hook safe throttled way to respond to the window levels changing.
   */
  const throttledOnWindowLevelsChangedRef = useRef(
    throttle(
      (
        contrastVolume: ContrastVolume | undefined,
        windowLevels: WindowLevels
      ) => {
        if (contrastVolume) {
          // Limit the windowing ranges.
          windowLevels.windowWidth = Math.min(
            Math.max(windowLevels.windowWidth, WINDOW_WIDTH_MIN),
            WINDOW_WIDTH_MAX
          );
          windowLevels.windowCenter = Math.min(
            Math.max(windowLevels.windowCenter, WINDOW_LEVEL_MIN),
            WINDOW_LEVEL_MAX
          );
          // Update the window levels for the CPR, MPR, short-axis, long-axis etc.
          setContrastWindowLevels(windowLevels);
        }
      },
      10,
      { leading: false, trailing: true }
    )
  );

  /**
   * Respond to the window levels changing.
   */
  const onWindowLevelsChanged = useCallback(
    (windowLevels: WindowLevels) => {
      throttledOnWindowLevelsChangedRef.current(contrastVolume, windowLevels);
    },
    [contrastVolume]
  );

  /**
   * Cancel any throttled functions on unmount.
   */
  useEffect(() => {
    // This looks daft but it makes lint happy.
    const throttledOnCrosshairsMoved = throttledOnCrosshairsMovedRef.current;
    const throttledOnWindowLevelsChanged =
      throttledOnWindowLevelsChangedRef.current;
    return () => {
      throttledOnCrosshairsMoved.cancel();
      throttledOnWindowLevelsChanged.cancel();
    };
  }, []);

  /**
   * Respond to a double click event on the window.
   * @param button The index of the button that was double clicked.
   */
  const onDoubleClick = useCallback(
    (button: number) => {
      // Switch to / from fullscreen for the double clicked view on the left button.
      if (button === 1) {
        onDoubleClickView(viewIndex);
      }
    },
    [viewIndex, onDoubleClickView]
  );
  const viewProps = contrastVolume?.viewProps?.[viewType]!;

  // onReady to be called when the view is ready to be used measurement tools with Pixijs.
  const onReady = ({ container, holder, app, defaultScale }: any) => {
    appRef.current = app;
    containerRef.current = container;
    holderRef.current = holder;
    defaultScaleRef.current = defaultScale;
    // Setup the measurement tools if in measurement mode (if we got here via a reset the old tools will
    // have been destroyed and the rotations would be incorrect even if they weren't destroyed).
    if (containerRef.current && isMeasurementMode) {
      // Clear the old measurement tools.
      cleanUpMeasurementTools();
      msmToolsContainerRef.current = new PIXI.Container();
      containerRef.current.addChild(msmToolsContainerRef.current);
    }
  };

  /**
   * Get the position of the mouse over the container from the global page position in the event.
   */
  const getMousePos = (event: React.MouseEvent): any => {
    const pos = { x: 0, y: 0 };
    if (holderRef.current && containerRef.current) {
      // Get the position and size of the component on the page.
      const holderOffset = holderRef.current.getBoundingClientRect();
      pos.x =
        (event.pageX - holderOffset.x - containerRef.current.x) /
        containerRef.current.scale.x;
      pos.y =
        (event.pageY - holderOffset.y - containerRef.current.y) /
        containerRef.current.scale.y;
    }
    return pos;
  };

  /**
   * Convert from a PIXI container position to a view position.
   * ie the top left of the view is [0, 0] and the bottom right is [width - 1, height - 1].
   */
  const containerPosToViewPos = (pos: { x: number; y: number }): vec2 => {
    if (containerRef.current && pos) {
      return [
        pos.x * containerRef.current.scale.x + containerRef.current.x,
        pos.y * containerRef.current.scale.y + containerRef.current.y,
      ];
    }
    return [-1, -1];
  };

  const getMeasurementRendererScale = useCallback(() => {
    return containerRef.current?.scale?.y ?? 1;
  }, []);

  const onZoom = () => {
    try {
      // Set the measurement tools to the correct position for the current zoom.
      if (
        msmToolsContainerRef.current &&
        msmToolsContainerRef.current?.children.length > 0
      ) {
        const scale = getMeasurementRendererScale();

        onZoomMeasurementTools(
          MEASUREMENT_SETTINGS.PARENT.Contrast,
          msmToolsContainerRef.current?.children,
          scale,
          onMouseDownEllipseNode,
          () => getPixelPerMillimeter(),
          hitAreaEllipseToolRef.current
        );
      }
    } catch (error) {
      // This could potentially get here from View2D::ResetView on a context lost re-initialize.
    }
  };

  const resetStateMeasurementTool = (resetState: any) => {
    if (!resetState) return;
    'measurementToolStartPoint' in resetState &&
      setMeasurementToolStartPoint(resetState.measurementToolStartPoint);
    'isDraggingMeasurementToolRef' in resetState &&
      (isDraggingMeasurementToolRef.current =
        resetState.isDraggingMeasurementToolRef);
    'measurementTargetRef' in resetState &&
      (measurementTargetRef.current = resetState.measurementTargetRef);
    'handleTargetNameRef' in resetState &&
      (handleTargetNameRef.current = resetState.handleTargetNameRef);
    'isClickDownMeasurementToolRef' in resetState &&
      (isClickDownMeasurementToolRef.current =
        resetState.isClickDownMeasurementToolRef);
    'isOverMeasurementToolRef' in resetState &&
      (isOverMeasurementToolRef.current = resetState.isOverMeasurementToolRef);
    'hitAreaEllipseToolRef' in resetState &&
      (hitAreaEllipseToolRef.current = resetState.hitAreaEllipseToolRef);
  };

  const onMouseDown = (event: React.MouseEvent) => {
    if (isMeasurementMode) {
      if (
        appRef.current &&
        appRef.current.renderer &&
        event.target !== appRef.current.renderer.view
      ) {
        return;
      }
      const scale = getMeasurementRendererScale();

      let resetState = null;
      if (isRulerActiveRef.current) {
        resetState = onMouseDownRuler({
          isClickDownMeasurementToolRef: isClickDownMeasurementToolRef.current,
          isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
          measurementTargetRef: measurementTargetRef.current,
          scale: scale,
          btnClicked: event.buttons,
          pixelsPerMillimeter: getPixelPerMillimeter(),
        });
      }

      if (isEllipseActiveRef.current && msmToolsContainerRef.current) {
        resetState = onMouseDownEllipse({
          isClickDownMeasurementToolRef: isClickDownMeasurementToolRef.current,
          isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
          measurementSpriteRef: measurementSpriteRef.current,
          measurementTargetRef: measurementTargetRef.current,
          scale,
          onMouseDownEllipseNode,
          hitAreaEllipseToolRef: hitAreaEllipseToolRef.current,
          pixelsPerMillimeter: getPixelPerMillimeter(),
          isOverMeasurementToolRef: isOverMeasurementToolRef.current,
          msmToolsContainerRef: msmToolsContainerRef.current,
          btnClicked: event.buttons,
          huData: huDataRef.current,
        });
      }

      resetStateMeasurementTool(resetState);

      setMeasurementToolStartPoint(mousePosRef.current);

      measurementSpriteRef.current = new PIXI.Graphics() as MeasurementGraphics;
      msmToolsContainerRef.current &&
        msmToolsContainerRef.current.addChild(measurementSpriteRef.current);
    }
  };

  const onMouseDownEllipseNode = (event: any) => {
    if (isMeasurementMode && isEllipseActiveRef.current && event.target) {
      isDraggingMeasurementToolRef.current =
        MEASUREMENT_SETTINGS.ELLIPSE_STATE.Handle;
      isClickDownMeasurementToolRef.current = true;

      measurementTargetRef.current = event.target;
    }
  };

  const onMouseDownStraightLine = (event: any) => {
    if (isMeasurementMode && isRulerActiveRef.current && event.target) {
      const scale = getMeasurementRendererScale();

      //active ruler clickin on a handle or line
      let target = event.target;
      if (isRulerHandled(target.lineName)) {
        target = target.parent;
      }
      if (target && target.startPoint) {
        createRulerSprite({
          parent: MEASUREMENT_SETTINGS.PARENT.MPR,
          sprite: target,
          start: target.startPoint,
          end: target.endPoint,
          lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
          state: MEASUREMENT_SETTINGS.STATES.moving,
          scale,
          pixelsPerMillimeter: getPixelPerMillimeter(),
        });
      }
      //if there is a ruler active and the user clicks on the ruler, then we need to change the state to inative
      if (
        (isDraggingMeasurementToolRef.current ===
          MEASUREMENT_SETTINGS.RULER_STATE.Active ||
          isDraggingMeasurementToolRef.current ===
            MEASUREMENT_SETTINGS.RULER_STATE.Handle) &&
        measurementTargetRef.current
      ) {
        createRulerSprite({
          parent: MEASUREMENT_SETTINGS.PARENT.MPR,
          sprite: measurementTargetRef.current,
          start: measurementTargetRef.current.startPoint,
          end: measurementTargetRef.current.endPoint,
          lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
          state: MEASUREMENT_SETTINGS.STATES.finish,
          scale,
          pixelsPerMillimeter: getPixelPerMillimeter(),
        });
      }
      if (isRulerHandled(event.target.lineName)) {
        isDraggingMeasurementToolRef.current =
          MEASUREMENT_SETTINGS.RULER_STATE.Handle;
        handleTargetNameRef.current = event.target.lineName;
        isClickDownMeasurementToolRef.current = true;
      }

      measurementTargetRef.current = target;
      isDraggingMeasurementToolRef.current =
        isDraggingMeasurementToolRef.current ||
        MEASUREMENT_SETTINGS.RULER_STATE.Active;
      isClickDownMeasurementToolRef.current = true;
    }
  };

  const onMouseUp = () => {
    if (isMeasurementMode) {
      //measurement tools mouseUp
      const scale = getMeasurementRendererScale();

      let resetState = null;
      if (isRulerActiveRef.current) {
        resetState = onMouseUpRuler({
          isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
          measurementSpriteRef: measurementSpriteRef.current,
          measurementTargetRef: measurementTargetRef.current,
          start: measurementToolStartPoint,
          end: mousePosRef.current,
          scale: scale,
          callbackMouseDownStraightLine: onMouseDownStraightLine,
          pixelsPerMillimeter: getPixelPerMillimeter(),
        });
      }
      if (isEllipseActiveRef.current) {
        resetState = onMouseUpEllipse({
          isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
          measurementSpriteRef: measurementSpriteRef.current,
          measurementTargetRef: measurementTargetRef.current,
          scale: scale,
          callbackMouseDownEllipseNode: onMouseDownEllipseNode,
          hitAreaEllipseToolRef: hitAreaEllipseToolRef.current,
          huData: huDataRef.current,
          pixelsPerMillimeter: getPixelPerMillimeter(),
        });
      }
      resetStateMeasurementTool(resetState);
    }

    //end measurement tools mouseUp
  };

  const calculateHuData = (posA: vec2, posB: vec2) => {
    const api = contrastVolume?.getApi(viewIndex);
    const genericRenderWindow = api?.genericRenderWindow;
    // @ts-ignore
    const openGLRenderWindow = genericRenderWindow?.getOpenGLRenderWindow();
    if (openGLRenderWindow) {
      // Get the size of the view in pixels.
      const viewSize: vec2 = openGLRenderWindow.getSize();

      // Get the start and end position that we want to read back from the view; these should be clipped to the view extents.
      const startPos = [
        roundAndClamp(Math.min(posA[0], posB[0]), 0, viewSize[0] - 1),
        roundAndClamp(Math.min(posA[1], posB[1]), 0, viewSize[1] - 1),
      ];
      const endPos = [
        roundAndClamp(Math.max(posA[0], posB[0]), 0, viewSize[0] - 1),
        roundAndClamp(Math.max(posA[1], posB[1]), 0, viewSize[1] - 1),
      ];

      // Force a render so that getPixelData captures something.
      api?.refreshViewImmediately(true, false);

      // Read back the pixel data.
      // NOTE: It is essential to have re-rendered the view immediately prior.
      // NOTE: The Y coordinates are flipped thanks to WebGL logic.
      const pixelData = openGLRenderWindow.getPixelData(
        startPos[0],
        viewSize[1] - 1 - endPos[1],
        endPos[0],
        viewSize[1] - 1 - startPos[1]
      );
      // Get the dimensions to the area we read back.
      const w = endPos[0] - startPos[0] + 1;
      const h = endPos[1] - startPos[1] + 1;

      // Calculate HU Mean
      let huMean: number | undefined;
      // Calculate HU Standard deviation
      let huStdDeviation: number | undefined;

      if (pixelData) {
        // Get the hu values that black and white map to.
        // As mentioned previously reading back values in the 0-255 range will lose a lot of precision vs reading from the model itself.
        const lowValue =
          contrastWindowLevels.windowCenter -
          0.5 * contrastWindowLevels.windowWidth;
        const highValue =
          contrastWindowLevels.windowCenter +
          0.5 * contrastWindowLevels.windowWidth;

        // Get the center of the ellipse and the radius per dimension.
        const centerX = 0.5 * (posA[0] + posB[0]);
        const centerY = 0.5 * (posA[1] + posB[1]);
        const radiusX = 0.5 * Math.abs(posA[0] - posB[0]);
        const radiusY = 0.5 * Math.abs(posA[1] - posB[1]);

        // Build up a list of all HU values that fall inside the ellipse.
        const huValues: number[] = [];
        for (let y = 0; y < h; y++) {
          for (let x = 0; x < w; x++) {
            // Calculate if the point is inside the ellipse.
            const dx = (startPos[0] + x - centerX) / radiusX;
            const dy = (startPos[1] + y - centerY) / radiusY;
            if (dx * dx + dy * dy <= 1.0) {
              // Use the green channel (although red and blue should have the same value).
              const windowedValue = pixelData[4 * (x + w * y) + 1];
              // Convert from 0-255 back to a un-windowed value.
              const unwindowedValue =
                (highValue - lowValue) * (windowedValue / 255.0) + lowValue;
              // Add the value to the list.
              huValues.push(unwindowedValue);
            }
          }
        }
        if (huValues.length > 0) {
          // Calculate HU Mean
          huMean = getMeanValue(huValues);
          // Calculate HU Standard deviation
          huStdDeviation = getStandardDeviation(huValues);
        }
      }

      // Update the saved hu data values.
      huDataRef.current = { mean: huMean, stdDev: huStdDeviation };
    }
  };

  const restoreCursor = () => {
    if (holderRef.current) {
      holderRef.current.classList.remove('contrast-view-none');
      holderRef.current.classList.remove('contrast-view-dragging');
      holderRef.current.classList.remove('contrast-view-default');
      holderRef.current.classList.remove('contrast-view-crosshair');
    }
  };

  const onMouseMove = (event: React.MouseEvent) => {
    if (isMeasurementMode) {
      // Get the mouse position over the image in the [0, 0] - [renderWidth, renderHeight] range.
      mousePosRef.current = getMousePos(event);
      const scale = getMeasurementRendererScale();
      let resetState: any = null;
      // if measurement tools are active, don't zoom
      if (
        isDraggingMeasurementToolRef.current &&
        event.buttons === MOUSE_BUTTONS.BOTH
      ) {
        measurementTargetRef.current = null;
        handleTargetNameRef.current = null;
        isClickDownMeasurementToolRef.current = true;
        isDraggingMeasurementToolRef.current = undefined;
        return;
      }
      if (isRulerActiveRef.current && isClickDownMeasurementToolRef.current) {
        resetState = onMouseMoveRuler({
          isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
          measurementSpriteRef: measurementSpriteRef.current,
          measurementTargetRef: measurementTargetRef.current,
          handleTargetNameRef: handleTargetNameRef.current,
          start: measurementToolStartPoint,
          mousePosition: mousePosRef.current,
          scale: scale,
          offsetX: event.movementX,
          offsetY: event.movementY,
          recalculatePixelsToMm: () => getPixelPerMillimeter(),
        });
      }

      if (isEllipseActiveRef.current) {
        // Update the huData value?
        switch (isDraggingMeasurementToolRef.current) {
          // A new ellipse is being drawn on the view, it has a fixed start point with the end point being positioned right now.
          case MEASUREMENT_SETTINGS.ELLIPSE_STATE.New:
            calculateHuData(
              containerPosToViewPos(measurementToolStartPoint),
              containerPosToViewPos(mousePosRef.current)
            );
            break;

          // Update the HU value of a previously placed ellipse if it is being resized.
          case MEASUREMENT_SETTINGS.ELLIPSE_STATE.Handle:
          // Update the HU value of a previously placed ellipse if it is being moved about the view.
          case MEASUREMENT_SETTINGS.ELLIPSE_STATE.Moving:
            if (measurementTargetRef.current) {
              const targetPoints = measurementTargetRef.current.points;
              calculateHuData(
                containerPosToViewPos(targetPoints[0]),
                containerPosToViewPos(targetPoints[2])
              );
            }
            break;

          default:
            break;
        }

        resetState = onMouseMoveEllipse({
          isDraggingMeasurementToolRef: isDraggingMeasurementToolRef.current,
          measurementSpriteRef: measurementSpriteRef.current,
          measurementTargetRef: measurementTargetRef.current,
          start: measurementToolStartPoint,
          mousePosition: mousePosRef.current,
          scale: scale,
          offsetX: event.movementX,
          offsetY: event.movementY,
          isOverMeasurementToolRef: isOverMeasurementToolRef.current,
          hitAreaEllipseToolRef: hitAreaEllipseToolRef.current,
          recalculatePixelsToMm: () => getPixelPerMillimeter(),
          huData: huDataRef.current,
        });
      }

      resetStateMeasurementTool(resetState);
      switch (isDraggingMeasurementToolRef.current) {
        case MEASUREMENT_SETTINGS.RULER_STATE.New:
        case MEASUREMENT_SETTINGS.RULER_STATE.Handle:
        case MEASUREMENT_SETTINGS.RULER_STATE.HandleEnd:
        case MEASUREMENT_SETTINGS.RULER_STATE.HandleStart:
        case MEASUREMENT_SETTINGS.ELLIPSE_STATE.New:
        case MEASUREMENT_SETTINGS.ELLIPSE_STATE.Handle:
          restoreCursor();
          holderRef.current?.classList.add('contrast-view-none');
          return;
        case MEASUREMENT_SETTINGS.RULER_STATE.Moving:
        case MEASUREMENT_SETTINGS.RULER_STATE.Active:
          restoreCursor();
          holderRef.current?.classList.add('contrast-view-dragging');
          return;
        case 'moving-ellipse':
          if (measurementTargetRef.current) {
            // Moving / dragging an existing ellipse measurement graphic
            const targetPoints = measurementTargetRef.current.points;
            calculateHuData(targetPoints[0], targetPoints[2]);
          }
          return;
        default:
          restoreCursor();
          holderRef.current?.classList.add('contrast-view-crosshair');
          if (isOverMeasurementToolRef.current) {
            restoreCursor();
            holderRef.current?.classList.add('contrast-view-dragging');
          }
      }
    }
  };

  // Check that vtk is able to render, if not we will show a warning instead of the 3D volume.
  // If AP-1200 / AP-2630 / AP-2634 / AP-2929 low performance GPU bug has caused the shader compile to fail and/or a lost context for every WebGL renderer;
  // We show a warning instead.
  let vtkError: string | undefined;
  if (!volumeLoadFailed && contrastVolume?.volume) {
    if (contrastVolume?.getApi(viewIndex)?.getError) {
      vtkError = contrastVolume?.getApi(viewIndex)?.getError();
    } else {
      // NOTE: We can get here is the view loses its WebGL context and then fails to re-initialize.
      // The most likely cause is a catastrophic overuse of memory.
      vtkError = 'vtkError: volume api not found';
    }
  }

  return (
    <>
      {volumeLoadFailed && (
        // It's possible we failed to load all the required slices, in which case we show an alert and the following error message.
        <div className="contrast-viewer__loading">
          <div className="contrast-viewer__text">
            {'Unable to display series'}
          </div>
        </div>
      )}

      {!volumeLoadFailed && contrastVolume?.volume === undefined && (
        // Render the loading spinner if the CT volume has not yet loaded. It will show "Preparing slices" briefly before
        // the images are sequentially loaded and also when they are already loaded in the cache for the selected study + seriesName.
        <div className="contrast-viewer__loading">
          <Loader
            large={false}
            text={
              contrastVolume && contrastVolume.imageCountLoaded > 0
                ? `Loading slice ${contrastVolume.imageCountLoaded} of ${contrastVolume.imageCount}`
                : 'Preparing slices'
            }
          />
        </div>
      )}

      {/* Render the volume as soon as the images have loaded, this will set the volume status to loaded once the values have been initialized */}
      {!volumeLoadFailed && contrastVolume?.volume && (
        // Render the view of the CT volume with crosshair overlay.
        <View2D
          key={`ContrastView-${studySeriesId}-${viewIndex}-${viewType}`} // We use the key to force a remount when the viewIndex, viewType, study or series changes.
          viewIndex={viewIndex}
          viewType={viewType}
          apis={contrastVolume.apis}
          volumes={contrastVolume.volumes}
          crosshairWorldPosition={contrastVolume.crosshairWorldPosition}
          crosshairWorldAxes={contrastVolume.crosshairWorldAxes}
          scanThickness={scanThickness}
          renderThickness={viewProps.renderThickness}
          blendMode={viewProps.blendMode}
          windowLevels={contrastWindowLevels}
          centerline={centerline}
          onCrosshairsMoved={onCrosshairsMoved}
          onWindowLevelsChanged={onWindowLevelsChanged}
          onDoubleClick={onDoubleClick}
          rotateHandleDistance={7 / 16}
          showCenterline={
            viewProps.overlayMode ===
            ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE
          }
          showCrosshairs={
            viewProps.overlayMode ===
            ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE
          }
          onReady={onReady}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onZoom={onZoom}
          onForceRender={() => setForceRender((forceRender) => forceRender + 1)}
          isMeasurementMode={isMeasurementMode}
          onMmPerPixelChanged={(newValue: {
            mmPerPixelX: number;
            mmPerPixelY: number;
          }) => (pixelPerMillimeterRef.current = newValue)}
        />
      )}

      {/* Only render the overlay once the volume has fully loaded and been initialized */}
      {!volumeLoadFailed && volumeLoaded && contrastVolume?.volume && (
        // Render the MetaDetails overlay.
        <ContrastViewOverlay
          contrastVolume={contrastVolume}
          viewIndex={viewIndex}
        />
      )}

      {/* If vtk is currently unable to render the view then show a warning over the top of the entire view. */}
      {!volumeLoadFailed && vtkError && (
        <div className="contrast-viewer__vtk_error_overlay">
          <div className="contrast-viewer__text">
            {'Insufficient resources to display'}
          </div>
        </div>
      )}
    </>
  );
};
