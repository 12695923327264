import { unwrapResult } from '@reduxjs/toolkit';
import Moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { IDLE_CONFIG, PERMISSION_CHECK_INTERVAL } from '../../config';
import { useStoreContext } from '../../context/store-context';
import { useUserContext } from '../../context/user-context';
import { useAppDispatch } from '../../hooks';
import { fetchStudyById } from '../../reducers/study';
import { performPermissionCheck } from '../../utils/auth';
import { lockStudy, unlockStudy } from '../../utils/studyLocking';
import { useAbortController } from '../../utils/use-abort-controller';
import { ActionModal } from '../ActionModal/ActionModal';

const IdleTimer: React.FC = ({ children }) => {
  const {
    setPatientID,
    setInactivity,
    setLastStudyId,
    selectedStudyClient,
    setStudyLocked,
    setStudyLockedBy,
  } = useStoreContext();

  // Interval ID for locking/relocking the study
  const lockInterval = useRef<number | null>(null);
  // Interval ID for updating the countdown in the idle warning modal
  const inactiveInterval = useRef<number>(0);
  // Interval ID for checking user permissions
  const permissionInterval = useRef<number>(0);

  const { id } = useParams<{ id?: string }>();

  const { user } = useUserContext();
  const history = useHistory();
  const location = useLocation();

  // Seconds to display on the countdown in the idle warning modal
  const [idleCountdown, setIdleCountdown] = useState<string | undefined>(
    IDLE_CONFIG.idle_countdown
  );
  // Flag that sets whether or not the idle warning modal is visible
  const [showWarning, setShowWarning] = useState<boolean>(false);
  // Timestamp set when the study is successfully relocked (not set on the initial /lock call)
  const [relockTimestamp, setRelockTimestamp] = useState<Moment.Moment | null>(
    null
  );
  // The currently locked study ID
  const [lockedStudyId, setLockedStudyId] = useState<string | null>(null);
  const signal = useAbortController();

  const isArtryaUser = useCallback(() => {
    if (user.groups?.includes('artrya') && selectedStudyClient !== 'artrya') {
      return true;
    }
    return false;
  }, [selectedStudyClient, user.groups]);

  // Lock/relock the current study
  const _lockStudy = useCallback(async () => {
    if (!id || signal().aborted) {
      return;
    }

    const result = await lockStudy(id, history);
    if (result.success) {
      setLockedStudyId(result.studyId);
      if (result.didRelock) {
        setRelockTimestamp(Moment());
      }

      // Always set the patient ID to ensure that it is set if the user reloads the page (which triggers a relock on the
      // existing lock ID in session storage).
      setPatientID(result.studyId);
    }
  }, [history, id, setPatientID, signal]);

  // Unlock the current study. Is a no-op if the study is already unlocked
  const _unlockStudy = useCallback(async () => {
    if (lockedStudyId) {
      await unlockStudy(lockedStudyId);
    }
  }, [lockedStudyId]);

  const stopRelockInterval = useCallback(() => {
    if (lockInterval.current !== null) {
      window.clearInterval(lockInterval.current);
      lockInterval.current = null;
    }
  }, []);

  const startRelockInterval = useCallback(() => {
    stopRelockInterval();

    lockInterval.current = window.setInterval(() => {
      _lockStudy();
    }, 10000);
  }, [_lockStudy, stopRelockInterval]);

  const dispatch = useAppDispatch();

  const checkStudyStatus = useCallback(async () => {
    const response = await dispatch(fetchStudyById(id ?? ''));
    const { workflow_status } = unwrapResult(response);
    if (
      workflow_status.state === 'Locked' &&
      workflow_status.user !== user.name
    ) {
      // Someone else has locked out this study redirect to dashboard and display warning
      setStudyLockedBy(workflow_status.user);
      setStudyLocked(true);
      history.push('/');
    }
  }, [history, id, setStudyLocked, setStudyLockedBy, user.name, dispatch]);

  const closeWarning = useCallback(
    (redirect = true) => {
      if (inactiveInterval.current) {
        window.clearInterval(inactiveInterval.current);
      }
      setIdleCountdown(IDLE_CONFIG.idle_countdown);
      setShowWarning(false);
      if (redirect) {
        history.push('/');
      }
    },
    [history]
  );

  const handleOnIdle = useCallback(() => {
    if (showWarning) return;

    stopRelockInterval();

    if (inactiveInterval.current) {
      setIdleCountdown(IDLE_CONFIG.idle_countdown);
      window.clearInterval(inactiveInterval.current);
    }

    setShowWarning(true);
    let timer = parseInt(IDLE_CONFIG.idle_countdown ?? '');
    inactiveInterval.current = window.setInterval(() => {
      const timeSinceLastRelock = Moment().diff(relockTimestamp, 'seconds');
      if (
        relockTimestamp === null ||
        timeSinceLastRelock > parseInt(IDLE_CONFIG.lock_timeout ?? '') * 60
      ) {
        // lock could have expired, so check study status to work out what to do
        checkStudyStatus();
      }

      if (timer && timer <= 0) {
        window.clearInterval(inactiveInterval.current);
        setInactivity(true);
        setLastStudyId(id);
        closeWarning();
      } else {
        setIdleCountdown((timer--).toString());
      }
    }, 1000);
  }, [
    checkStudyStatus,
    closeWarning,
    id,
    relockTimestamp,
    setInactivity,
    setLastStudyId,
    showWarning,
    stopRelockInterval,
  ]);

  const { reset } = useIdleTimer({
    timeout: 1000 * 60 * parseInt(IDLE_CONFIG.idle_timeout ?? ''),
    onIdle: handleOnIdle,
    startOnMount: false,
  });

  const continueReview = useCallback(() => {
    closeWarning(false);

    // Start locking again
    if (!isArtryaUser()) {
      _lockStudy();
      startRelockInterval();
    }
  }, [_lockStudy, closeWarning, startRelockInterval, isArtryaUser]);

  // Reset the idle timer when we close the warning modal
  useEffect(() => {
    if (!showWarning) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showWarning]); // reset is mutated, so leave it out of the dependency array

  // Check user permissions on mount
  useEffect(() => {
    let isCancelled = false;

    async function checkUserPermission() {
      const hasPermissions = await performPermissionCheck(user.groups);
      if (!hasPermissions && !isCancelled) {
        history.push('/error', { state: 'permissionError' });
      }
    }

    checkUserPermission();

    return () => {
      isCancelled = true;
      // Try to unlock the currently locked study on unmount
      _unlockStudy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Start the permissions check interval on mount
  useEffect(() => {
    permissionInterval.current = window.setInterval(() => {
      performPermissionCheck(user.groups);
    }, PERMISSION_CHECK_INTERVAL);

    return () => window.clearInterval(permissionInterval.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Start the locking interval whenever we navigate to a study page
  useEffect(() => {
    if (location.pathname.includes('study')) {
      if (isArtryaUser()) {
        setPatientID(id);
        return;
      }

      startRelockInterval();

      _lockStudy();
    }

    return () => {
      stopRelockInterval();
    };
  }, [
    location.pathname,
    startRelockInterval,
    stopRelockInterval,
    id,
    isArtryaUser,
    setPatientID,
    _lockStudy,
  ]);

  // Unlock currently locked studies when navigating to any non-study page
  useEffect(() => {
    if (!location.pathname.includes('study')) {
      _unlockStudy();
      setRelockTimestamp(null);
    }
  }, [_unlockStudy, location.pathname]);

  return (
    <>
      {children}
      <ActionModal
        confirmText={'Continue reviewing'}
        onClose={closeWarning}
        onConfirm={continueReview}
        visible={showWarning}
        headerContent={<>TIMEOUT ALERT</>}
      >
        <p>
          Are you still reviewing this patient study? If no further activity is
          detected, the file will automatically close in&nbsp;
          <span className="patient__idle-countdown">{idleCountdown}</span>
          &nbsp;seconds
        </p>
      </ActionModal>
    </>
  );
};
export default IdleTimer;
