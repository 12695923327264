import { useEffect, useRef } from 'react';

export default function usePreviousValue(value: any) {
  const valueRef = useRef<any>();

  useEffect(() => {
    valueRef.current = value;
  });
  return valueRef.current;
}
