import { useCallback } from 'react';
import { showToast } from '../components/Toast/showToast';
import { useReportContext } from '../context/report-context';
import { useStoreContext } from '../context/store-context';
import { ReviewUser } from '../context/types';
import { useUserContext } from '../context/user-context';
import { useAppDispatch, useAppSelector } from '../hooks';
import { GlobalLoaderActions } from '../reducers/global-loader';
import { fetchStudyById } from '../reducers/study';
import { currentStudySelector } from '../selectors/study';
import { useIsAuditorUserSelector } from '../selectors/user';
import * as api from '../utils/api';

export function useFetchReviewUsers() {
  const { user: loggedInUser } = useUserContext();
  const { setReviewUsers } = useReportContext();
  const isAuditorUser = useIsAuditorUserSelector();

  return useCallback(async () => {
    // Don't fetch reviewers for auditor
    if (isAuditorUser) return;

    // Use undefined to mean that the request is in progress
    setReviewUsers(undefined);
    try {
      const users = await api.fetchReviewUsers();
      setReviewUsers(users.filter((u) => u.email !== loggedInUser.email));
    } catch (err) {
      console.warn('Failed to fetch workflow users:', err);
      setReviewUsers([]);
      showToast.error('Failed to get list of users');
    }
  }, [setReviewUsers, loggedInUser.email, isAuditorUser]);
}

export function useRequestReview() {
  const { setReviewList } = useStoreContext();

  const selectedStudy = useAppSelector(currentStudySelector);

  const dispatch = useAppDispatch();

  return useCallback(
    async (assignee: ReviewUser, reviewType: string, message: string) => {
      if (!selectedStudy) {
        return;
      }

      const { active_run: activeRun, study_id: studyId } = selectedStudy;

      if (!activeRun || !studyId) {
        showToast.error('Failed to get study information');
        return;
      }

      dispatch(GlobalLoaderActions.show('Sending for review'));

      try {
        await api.requestReview(
          studyId,
          activeRun,
          assignee,
          reviewType,
          message
        );
        showToast.success(`Report sent to ${assignee.name} for review`);

        const promises = Promise.all([
          dispatch(fetchStudyById(selectedStudy.study_id)),
          api.fetchReviewList(studyId, activeRun),
        ]);

        const [, reviewList] = await promises;
        setReviewList(reviewList);
        dispatch(GlobalLoaderActions.hide());
      } catch (err) {
        console.warn('Failed to request review:', err);
        if (err instanceof api.APIError && err.response.status !== 500) {
          showToast.error(`Failed to request review: ${err.message}`);
        } else {
          showToast.error('Failed to request review');
        }
      } finally {
        dispatch(GlobalLoaderActions.hide());
      }
    },
    [dispatch, selectedStudy, setReviewList]
  );
}

export function useCompleteReview() {
  const { setReviewList } = useStoreContext();

  const selectedStudy = useAppSelector(currentStudySelector);

  const dispatch = useAppDispatch();

  return useCallback(
    async (reviewId: string, reviewType: 'review' | 'approve') => {
      if (!selectedStudy) {
        return;
      }

      const { active_run: activeRun, study_id: studyId } = selectedStudy;

      if (!activeRun || !studyId) {
        showToast.error('Failed to get study information');
        return;
      }

      try {
        await api.completeReview(studyId, activeRun, reviewId);
        showToast.success(
          reviewType === 'review' ? 'Review completed' : 'Analysis approved'
        );

        const promises = Promise.all([
          dispatch(fetchStudyById(selectedStudy.study_id)),
          api.fetchReviewList(studyId, activeRun),
        ]);

        const [, reviewList] = await promises;
        setReviewList(reviewList);
      } catch (err) {
        // Fetch and update current report so the 'complete review' button no longer shows.
        // Most likely the review was cancelled if we get here.
        await dispatch(fetchStudyById(studyId));

        console.warn('Failed to complete review:', err);
        if (err instanceof api.APIError && err.response.status !== 500) {
          showToast.error(`Failed to complete review: ${err.message}`);
        } else {
          showToast.error('Failed to complete review');
        }
      }
    },
    [selectedStudy, dispatch, setReviewList]
  );
}
