import { ActionType, createAction } from 'typesafe-actions';
import { VesselGroupings } from '../../config';
import { UnsavedChangesToInlineReport } from './types';

export const InlineReportingActions = {
  updateCoronaryFinding: createAction(
    'inlineReporting/UPDATE_CORONARY_FINDING',
    (vessel: VesselGroupings, finding?: string) => ({
      vessel,
      finding,
    })
  )(),
  updateImpressions: createAction(
    'inlineReporting/UPDATE_IMPRESSIONS',
    (impressions: string[] | undefined) => impressions
  )(),
  setUnsavedChangesWarning: createAction(
    'inlineReporting/SET_UNSAVED_CHANGES_WARNING',
    (showWarningType?: {
      type: UnsavedChangesToInlineReport;
      passthroughAction: () => void;
    }) => showWarningType
  )(),
  clearInlineReportingDraftState: createAction(
    'inlineReporting/CLEAR_INLINE_REPORTING_DRAFT_STATE'
  )(),
};

export type InlineReportingAction = ActionType<typeof InlineReportingActions>;
