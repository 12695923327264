import React, { useMemo } from 'react';
import { CTVolumeOverlay } from '../../../context/contrast-types';
import { useStoreContext } from '../../../context/store-context';
import { WindowLevels } from '../../../context/window-types';
import { useVesselStateSelector } from '../../../selectors/vessels';
import { getMajorVesselGrouping } from '../../../utils/vessel';
import InfoSection from './InfoSection';
import ReportSection from './ReportSection';

interface MetaDetailsProps {
  studyDetails?: any;
  calciumScoreData?: any;
  HURef?: any;
  huValue?: number;
  sliceRef?: any;
  slice?: number;
  slabThickness?: number;
  windowLevelRef?: any;
  windowLevels: WindowLevels;
  windowLabel: string | null;
  windowWidthRef?: any;
  isCprView?: any;
  seriesDate?: any;
  overlayToShow?: CTVolumeOverlay;
  vesselCalciumVisible?: { [key: string]: boolean };
  onClearCalciumScore?: (key: string) => void;
  onToggleVesselCalcium?: (toggle: { [key: string]: boolean }) => void;
}

export const MetaDetails: React.FC<MetaDetailsProps> = ({
  studyDetails,
  calciumScoreData,
  HURef,
  huValue,
  sliceRef,
  slice,
  slabThickness,
  windowLevelRef,
  windowLevels,
  windowLabel,
  windowWidthRef,
  isCprView = false,
  seriesDate,
  overlayToShow,
  vesselCalciumVisible,
  onClearCalciumScore,
  onToggleVesselCalcium,
}) => {
  const { contrastLesionData } = useStoreContext();

  const { selectedVesselName } = useVesselStateSelector();

  const selectedVesselGroup = useMemo(
    () => getMajorVesselGrouping(selectedVesselName),
    [selectedVesselName]
  );

  const selectedLesionData = useMemo(() => {
    if (!contrastLesionData || !selectedVesselName || !isCprView)
      return undefined;
    return { ...contrastLesionData[selectedVesselName] };
  }, [contrastLesionData, selectedVesselName, isCprView]);

  return (
    <div className="meta-details">
      <div className="meta-details__container">
        {overlayToShow === CTVolumeOverlay.INFO && (
          <InfoSection
            windowLevels={windowLevels}
            windowLabel={windowLabel}
            isCprView={isCprView}
            studyDetails={studyDetails}
            windowLevelRef={windowLevelRef}
            windowWidthRef={windowWidthRef}
            HURef={HURef}
            sliceRef={sliceRef}
            slice={slice}
            seriesDate={seriesDate}
            slabThickness={slabThickness}
            calciumScoreData={calciumScoreData}
            huValue={huValue}
            lesionData={isCprView && selectedLesionData}
            vesselCalciumVisible={vesselCalciumVisible}
            onClearCalciumScore={onClearCalciumScore}
            onToggleVesselCalcium={onToggleVesselCalcium}
          />
        )}
        {overlayToShow === CTVolumeOverlay.REPORT && selectedVesselGroup && (
          <ReportSection selectedVesselGroup={selectedVesselGroup} />
        )}
      </div>
    </div>
  );
};
export default MetaDetails;
