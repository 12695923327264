import React, {
  ReactElement,
  useCallback,
  useMemo,
  ChangeEventHandler,
} from 'react';
import Button from '../../components/Button/Button';
import { CAD_RADS } from '../../config';
import { StatsDataType } from '../../context/types';

interface Props {
  stats: StatsDataType;
  editingReport: boolean;
  handleUpdateReportStats: (stat: string, newValue: string) => void;
  // If true, user will be given a free text field when editing instead of only being able to pick modifiers
  editable?: boolean;
}

interface CadRadModifiers {
  N: boolean;
  S: boolean;
  G: boolean;
  V: boolean;
}

function createModifiers(modifiers: CadRadModifiers) {
  const N = modifiers.N ? ['N'] : [];
  const S = modifiers.S ? ['S'] : [];
  const G = modifiers.G ? ['G'] : [];
  const V = modifiers.V ? ['V'] : [];

  const allModifiers = [...N, ...S, ...G, ...V];

  if (allModifiers.length === 0) {
    return '';
  }

  return allModifiers.reduce((a, b) => a + '/' + b);
}

export default function CadRadsStat({
  stats,
  editingReport,
  handleUpdateReportStats,
  editable = false,
}: Props): ReactElement<Props> {
  const [cadrad, ...modifiers]: (string | undefined)[] = useMemo(
    () => `${stats.value}`.replace(' ', '/').split('/'),
    [stats.value]
  );

  const updateValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      handleUpdateReportStats('cad_rads', event.target.value);
    },
    [handleUpdateReportStats]
  );

  const modifierStates: CadRadModifiers = useMemo(() => {
    return {
      N: modifiers.includes('N'),
      S: modifiers.includes('S'),
      G: modifiers.includes('G'),
      V: modifiers.includes('V'),
    };
  }, [modifiers]);

  const updateModifiers = useCallback(
    (value: 'N' | 'S' | 'G' | 'V') => {
      const newState: CadRadModifiers = {
        ...modifierStates,
        [value]: !modifierStates[value],
      };

      handleUpdateReportStats(
        'cad_rads',
        `${cadrad}/${createModifiers(newState)}`
      );
    },
    [cadrad, modifierStates, handleUpdateReportStats]
  );

  const renderToggleables = useCallback(
    (mod: 'N' | 'S' | 'G' | 'V') => {
      return (
        <Button
          onClick={() => updateModifiers(mod)}
          selected={modifierStates[mod]}
          theme="tertiary-square"
          title={CAD_RADS[mod]}
        >
          {mod}
        </Button>
      );
    },
    [modifierStates, updateModifiers]
  );

  return (
    <div className={'stats__stat cad-rads-stat'}>
      {editingReport && !editable && (
        <div className="inline_stats">
          <div
            className="stats__stat-value"
            style={{
              marginRight: '4px',
              alignSelf: 'center',
            }}
          >
            <span>{cadrad.length > 0 ? cadrad : '-'}</span>
          </div>
          <div className="inline_stats__cell">
            <div className="inline_stats">
              {Object.keys(modifierStates)
                .filter((_x): _x is 'N' | 'S' | 'G' | 'V' => true)
                .map((x: 'N' | 'S' | 'G' | 'V', index: number) => (
                  <div className="inline_stats__cell" key={`${x}-${index}`}>
                    {renderToggleables(x)}
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      {editingReport && editable && (
        <div className="stats__stat-value">
          <input
            type="text"
            className="stats__stat-value__input"
            value={stats.value}
            onChange={updateValue}
          />
        </div>
      )}
      {!editingReport && (
        <div className="stats__stat-value">
          {stats.value ? stats.value : '-'}
        </div>
      )}
      <div className="stats__stat-label">{stats.label}</div>
    </div>
  );
}
