import React from 'react';
import cn from 'classnames';
import { Loader } from '../Loader/Loader';

export interface LoadingOverlayProps {
  text?: string;
  open?: boolean;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  text = 'Loading...',
  open = false,
}) => {
  const classNames = cn('loadingOverlay', {
    'loadingOverlay--open': open,
  });

  return (
    <div className={classNames}>
      <Loader text={text} />
    </div>
  );
};

export default LoadingOverlay;
