import cn from 'classnames';
import React, { ReactElement, useCallback } from 'react';
import { DraggableItem } from '../../context/drag-n-drop';
import {
  useViewConfigContext,
  ViewConfigActions,
} from '../../context/viewConfig';
import {
  useAnyGroupItemDragging,
  useAnySingleItemDragging,
} from '../../selectors/dragAndDrop';
import {
  useDropChildDraggable,
  useDropParentDraggable,
} from './use-dropitem-callbacks';

// The non-contrast dropzone has a special view index.
export const NON_CONTRAST_DROP_ZONE = -1;

interface Props {
  viewIndex: number;
}

export default function DropZone({
  viewIndex,
}: Props): ReactElement<Props> | null {
  const groupItemBeingDragged = useAnyGroupItemDragging();
  const singleItemBeingDragged = useAnySingleItemDragging();
  const anyDragging = groupItemBeingDragged || singleItemBeingDragged;
  const {
    dispatch: dispatchViewConfigAction,
    state: { viewportsToShowBorder },
  } = useViewConfigContext();
  const dropParentDraggable = useDropParentDraggable();
  const dropChildDraggable = useDropChildDraggable();
  const showBorder = viewportsToShowBorder.includes(viewIndex);

  /**
   * A series or single view has been dragged over this DropZone.
   */
  const onDragEnter = useCallback(() => {
    // Actually handle the drag enter event.
    const resolveDragEnter = () => {
      // Highlight the four views if dragging a full series over one of the quad views.
      if (groupItemBeingDragged && viewIndex !== NON_CONTRAST_DROP_ZONE) {
        // NOTE: This assumes a fixed max of four views for the contrast series, this needs tweaking if that expectation changes.
        dispatchViewConfigAction(
          ViewConfigActions.setViewportsToShowBorder([0, 1, 2, 3])
        );
      }
      // Otherwise highlight a single view if dragging a single view or dragging over the noncontrast view.
      else if (
        singleItemBeingDragged ||
        (viewIndex === NON_CONTRAST_DROP_ZONE && groupItemBeingDragged)
      ) {
        dispatchViewConfigAction(
          ViewConfigActions.setViewportsToShowBorder([viewIndex])
        );
      }
    };

    // Not the nicest way to fix a problem but unfortunately, if you're dragging really fast between,
    // viewports, `onDragLeave` will fire after `onDragEnter`, meaning the drag action added in
    // `onDragEnter` will be removed and the drop action will not fire. This means the `onDragEnter`
    // action will be fired on the following render.
    //
    // It's likely that the reducer state could be refactored to handle this case, but with the time
    // given, this keeps most of the code simple, and hopefully this comment will make it really obvious
    // that we shouldn't remove the `setTimeout` here will-nilly.
    if (viewportsToShowBorder.length > 0) {
      // onDragLeave hasn't fired so we need to wait for it to clear the highlighted views.
      setTimeout(resolveDragEnter);
    } else {
      // onDragLeave has fired so we can set the highlighted views right away.
      resolveDragEnter();
    }
  }, [
    viewIndex,
    viewportsToShowBorder,
    singleItemBeingDragged,
    groupItemBeingDragged,
    dispatchViewConfigAction,
  ]);

  /**
   * A series or single view has been dragged off this DropZone.
   */
  const onDragLeave = useCallback(() => {
    dispatchViewConfigAction(ViewConfigActions.setViewportsToShowBorder([]));
  }, [viewIndex, dispatchViewConfigAction]);

  /**
   * A series or single view has been dropped on this DropZone.
   */
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const draggableItem: DraggableItem | undefined =
        singleItemBeingDragged ?? groupItemBeingDragged;
      if (draggableItem) {
        // Switch between 'contrast type' views and 'noncontrast' type views
        dispatchViewConfigAction(
          ViewConfigActions.changeSeriesId(draggableItem.id)
        );

        if (draggableItem.type === 'parent') {
          dropParentDraggable(draggableItem);
        } else {
          dropChildDraggable(draggableItem, viewIndex);
        }

        dispatchViewConfigAction(
          ViewConfigActions.setViewportsToShowBorder([])
        );
      }
    },
    [
      viewIndex,
      singleItemBeingDragged,
      groupItemBeingDragged,
      dropParentDraggable,
      dropChildDraggable,
      dispatchViewConfigAction,
    ]
  );

  return (
    <div
      key={`DropZone_${viewIndex}`}
      className={cn('drop-zone', {
        'drop-zone__active': anyDragging,
        'drop-zone__over': showBorder,
      })}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onDragOver={(e) => {
        // This makes the whole thing work ¯\_(ツ)_/¯
        // Without this, `onDragLeave` is called when dropping a draggable item.
        // Which removes the drop action from the item and means nothing happens.
        e.stopPropagation();
        e.preventDefault();
      }}
    />
  );
}
