import React, {
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { DEFAULT_INLINE_REPORTING_STATE } from './constants';
import { inlineReportingReducer } from './reducer';
import { InlineReportingContextValue } from './types';

const InlineReportingContext = React.createContext<
  InlineReportingContextValue | undefined
>(undefined);

InlineReportingContext.displayName = 'InlineReportingContext';

interface Props {
  children: ReactNode;
}

export function InlineReportingProvider({
  children,
}: Props): ReactElement<Props> {
  const [inlineReportingState, dispatchInlineReportingAction] = useReducer(
    inlineReportingReducer,
    DEFAULT_INLINE_REPORTING_STATE
  );

  const inlineReportingContextValue: InlineReportingContextValue = useMemo(
    () => ({
      state: inlineReportingState,
      dispatch: dispatchInlineReportingAction,
    }),
    [inlineReportingState, dispatchInlineReportingAction]
  );

  return (
    <InlineReportingContext.Provider value={inlineReportingContextValue}>
      {children}
    </InlineReportingContext.Provider>
  );
}

/**
 * Hook to access the InlineReportingContext
 * @returns {InlineReportingContextValue} InlineReportingContextValue
 */
export function useInlineReportingContext(): InlineReportingContextValue {
  const context = useContext(InlineReportingContext);
  if (context === undefined) {
    throw new Error(
      'useInlineReportingContext must be used within a InlineReportingProvider.'
    );
  }
  return context;
}
