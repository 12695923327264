import React, { useState, useEffect, useRef, useCallback } from 'react';
import cn from 'classnames';
import { ReactComponent as EditReportIcon } from '../../assets/icons/edit.svg';
import { useStoreContext } from '../../context/store-context';
import Button from '../Button/Button';
import { PatientData } from '../../context/types';
import { calculateLVMassIndex, getLatestVersion } from '../../../src/utils/api';
import showToast from '../Toast/showToast';
import { Loader } from '../Loader/Loader';
import {
  fetchPatientData,
  fetchLVMassIndex,
  fetchLVMass,
} from '../../../src/utils/api';

export const LVMassIndex: React.FC = () => {
  const {
    runID,
    patientID,
    versionHead,
    fetchingOverallMeasurements,
    updateVersionHead,
  } = useStoreContext();
  const [editModeLV, setEditModeLV] = useState(false);
  const [height, setHeight] = useState<number | null>(null);
  const [weight, setWeight] = useState<number | null>(null);
  const [patientHeight, setPatientHeight] = useState<number | null>(null);
  const [patientWeight, setPatientWeight] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [invalidWeight, setInvalidWeight] = useState(false);
  const [invalidHeight, setInvalidHeight] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [heightHasChanges, setHeightHasChanges] = useState(true);
  const [weightHasChanges, setWeightHasChanges] = useState(true);
  const DEFAULT_PATIENT_DATA = { height: undefined, weight: undefined };
  const [lvMassIndexMeasurement, setLvMassIndexMeasurement] = useState<
    string | undefined
  >();
  const [patientData, setPatientData] = useState<PatientData>(
    DEFAULT_PATIENT_DATA
  );
  const [lvMassMeasurement, setLvMassMeasurement] = useState<
    string | undefined
  >();
  const debounceEvent = useRef<any>(0);

  useEffect(() => {
    if (!patientID || !runID) {
      return;
    }

    getLatestVersion(patientID, runID, versionHead)
      .then((res) => {
        updateVersionHead(res.head_version);
      })
      .catch((err) => {
        updateVersionHead(versionHead);
      });

    fetchLVMassIndex(patientID, runID, versionHead)
      .then((res) => {
        let lvMassIndexValue = Number(res.lv_mass_index).toFixed(2);
        res
          ? setLvMassIndexMeasurement(lvMassIndexValue)
          : setLvMassIndexMeasurement(undefined);
      })
      .catch((err) => {
        setLvMassIndexMeasurement(undefined);
      });

    fetchPatientData(patientID, runID, versionHead)
      .then((res) => {
        console.log(res);
        res ? setPatientData(res) : setPatientData(DEFAULT_PATIENT_DATA);
        setHeight(Number(res.height) / 100);
        setWeight(Number(res.weight));
        setPatientHeight(Number(res.height) / 100);
        setPatientWeight(Number(res.weight));
      })
      .catch(() => {
        setPatientData(DEFAULT_PATIENT_DATA);
      });

    fetchLVMass(patientID, runID, versionHead)
      .then((res) => {
        let lvMassValue = Number(res.lv_mass).toFixed(2);
        res
          ? setLvMassMeasurement(lvMassValue)
          : setLvMassMeasurement(undefined);
      })
      .catch(() => {
        setLvMassMeasurement(undefined);
      });
  }, [
    patientID,
    runID,
    lvMassMeasurement,
    lvMassIndexMeasurement,
    patientHeight,
    patientWeight,
  ]);

  const savePatientHeight = (event: any) => {
    setHeight(event.target.value);
    isHeightValid(event.target.value);
    if (event.target.value == patientHeight) {
      setHeightHasChanges(true);
    } else {
      setHeightHasChanges(false);
    }
  };

  const savePatientWeight = (event: any) => {
    setWeight(event.target.value);
    isWeightValid(event.target.value);
    if (event.target.value == patientWeight) {
      setWeightHasChanges(true);
    } else {
      setWeightHasChanges(false);
    }
  };

  const onCancelEdit = () => {
    setEditModeLV(false);
  };

  const onSaveEdit = () => {
    setLoading(true);
    setIsDisable(true);
    if (!patientID || !runID || !versionHead || !height || !weight) {
      return;
    }
    calculateLVMassIndex(
      patientID,
      runID,
      versionHead,
      //converting m to cm
      Number(height * 100),
      Number(weight),
      Number(lvMassMeasurement)
    )
      .then((res) => {
        updateVersionHead(res.version_id);
        setLoading(false);
        setEditModeLV(false);
        setHeightHasChanges(true);
        setWeightHasChanges(true);
        if (res.success) {
          setIsDisable(false);
          showToast.success('LV Mass Index saved');
          let lvMassIndexValue = Number(res.lv_mass_index).toFixed(2);
          setLvMassIndexMeasurement(lvMassIndexValue);
        }
      })
      .catch((err) => {
        // Revert to original version
        setLoading(false);
        setIsDisable(false);
        showToast.error('Error Saving LV Mass Index');
      });
  };
  // validating that the height is in 1 m and 3 m
  const isHeightValid = useCallback(
    (heightValue) => {
      clearTimeout(debounceEvent.current);
      debounceEvent.current = setTimeout(() => {
        if (
          (heightValue && heightValue < 1) ||
          (heightValue && heightValue > 3) ||
          !heightValue
        ) {
          setInvalidHeight(true);
        } else {
          setInvalidHeight(false);
        }
      }, 500);
    },
    [weight]
  );
  // validating that the weight is more than 30kg
  const isWeightValid = useCallback(
    (weightValue) => {
      clearTimeout(debounceEvent.current);
      debounceEvent.current = setTimeout(() => {
        if ((weightValue && weightValue < 30) || !weightValue) {
          setInvalidWeight(true);
        } else {
          setInvalidWeight(false);
        }
      }, 500);
    },
    [weight]
  );

  return (
    <div className={'lv-mass-index'}>
      {!fetchingOverallMeasurements && (
        <>
          <div className="lv-mass-index__stat">
            <div className="lv-mass-index__title">
              Left Ventricular Mass Index
            </div>
            <button
              className="card__button button-lv-mass"
              onClick={() => {
                setEditModeLV(!editModeLV);
              }}
              disabled={editModeLV || !lvMassMeasurement}
            >
              <EditReportIcon />
              {loading && <Loader inline />}
              {!lvMassMeasurement && (
                <div className="button-lv-mass__tooltip">
                  LV Mass value is missing
                </div>
              )}
            </button>
          </div>
          {editModeLV ? (
            <>
              <div className="lv-mass-index__stat-burden">
                <div className="lv-mass-index__stat-burden-value">
                  {lvMassIndexMeasurement ? (
                    <span>{lvMassIndexMeasurement} g/m²</span>
                  ) : (
                    <span>-</span>
                  )}
                </div>
                <div className="lv-mass-index__stat-burden-label">
                  LV Mass Index
                </div>
              </div>
              <div className="lv-mass-index__stat-burden">
                <div className="lv__section">
                  <input
                    type="number"
                    value={height ? height : '-'}
                    className={cn('lv__section-input', {
                      'lv__section-input_invalid-input': invalidHeight,
                    })}
                    onChange={savePatientHeight}
                    disabled={isDisable}
                  />
                  <label className="lv__section-label">m</label>
                </div>
                <div className="lv-mass-index__stat-burden-label">
                  Patient height
                </div>
                {invalidHeight && (
                  <div className="lv__section-input_invalid">
                    {height && height < 1
                      ? 'Height must be at least 1 m'
                      : null}
                    {height && height > 3 ? 'Height must be 3 m or less' : null}
                  </div>
                )}
              </div>
              <div className="lv-mass-index__stat-burden">
                <div className="lv__section">
                  <input
                    type="number"
                    value={weight ? weight : '-'}
                    className={cn('lv__section-input', {
                      'lv__section-input_invalid-input': invalidWeight,
                    })}
                    onChange={savePatientWeight}
                    disabled={isDisable}
                  />
                  <label className="lv__section-label">kg</label>
                </div>
                <div className="lv-mass-index__stat-burden-label">
                  Patient weight
                </div>
                {invalidWeight && (
                  <div className="lv__section-input_invalid">
                    Weight must be at least 30 kg
                  </div>
                )}
              </div>
              <div className="lv-mass-index__stat-burden">
                <div className="lv-mass-index__stat-burden-value">
                  {lvMassMeasurement ? (
                    <span>{lvMassMeasurement} g</span>
                  ) : (
                    <span>-</span>
                  )}
                </div>
                <div className="lv-mass-index__stat-burden-label">LV Mass</div>
              </div>
              <div className="lv-mass-index__stat-edit">
                <Button
                  inline
                  theme="secondary"
                  onClick={onCancelEdit}
                  disabled={isDisable}
                >
                  <span className="card__cancel">Cancel</span>
                </Button>
                <Button
                  inline
                  theme="primary"
                  disabled={
                    invalidHeight ||
                    invalidWeight ||
                    isDisable ||
                    (heightHasChanges && weightHasChanges)
                  }
                  onClick={onSaveEdit}
                >
                  <span className="card__save">Save</span>
                </Button>
              </div>
            </>
          ) : (
            <>
              <div className="lv-mass-index__stat-burden">
                <div className="lv-mass-index__stat-burden-value">
                  {lvMassIndexMeasurement ? (
                    <span>{lvMassIndexMeasurement} g/m²</span>
                  ) : (
                    <span>-</span>
                  )}
                </div>
                <div className="lv-mass-index__stat-burden-label">
                  LV Mass Index
                </div>
              </div>
              <div className="lv-mass-index__stat-burden">
                <div className="lv-mass-index__stat-burden-value">
                  {patientData?.height ? patientData?.height / 100 + ' m' : '-'}
                </div>
                <div className="lv-mass-index__stat-burden-label">
                  Patient height
                </div>
              </div>
              <div className="lv-mass-index__stat-burden">
                <div className="lv-mass-index__stat-burden-value">
                  {patientData?.weight ? patientData?.weight + ' kg' : '-'}
                </div>
                <div className="lv-mass-index__stat-burden-label">
                  Patient weight
                </div>
              </div>
              <div className="lv-mass-index__stat-burden">
                <div className="lv-mass-index__stat-burden-value">
                  {lvMassMeasurement ? (
                    <span>{lvMassMeasurement} g</span>
                  ) : (
                    <span>-</span>
                  )}
                </div>
                <div className="lv-mass-index__stat-burden-label">LV Mass</div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default LVMassIndex;
