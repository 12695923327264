import cn from 'classnames';
import React, { ReactElement, ReactNode, CSSProperties } from 'react';
import { FindingsStatus } from '../../components/FindingsStatus/FindingsStatus';

interface Props {
  style?: CSSProperties;
  cardHighlited?: boolean;
  title?: string;
  children: ReactNode;
  statusVersion?: boolean;
  onRevertFindings?: () => void;
}

export default function Card({
  style,
  cardHighlited,
  title,
  statusVersion,
  onRevertFindings,
  children,
}: Props): ReactElement<Props> {
  return (
    <div
      className={cn('card', {
        'card--highlighted': cardHighlited,
      })}
      style={style}
    >
      {(title || statusVersion !== undefined) && (
        <div className="card__header">
          <div className="card__title">{title ?? ''}</div>
          {statusVersion !== undefined && (
            <FindingsStatus
              versions={statusVersion}
              onRevertFindings={onRevertFindings}
            />
          )}
        </div>
      )}
      {children}
    </div>
  );
}
