import * as PIXI from 'pixi.js-legacy';
import {
  COLOR_CPR_CENTRELINE,
  COLOR_CPR_LOW_FFR,
  COLOR_CPR_MARKER_COLOR_INDICATOR,
  COLOR_CPR_SLICE_INDICATOR,
  THEME,
} from '../../config';
import { CenterlineMapping, Marker } from '../../context/types';
import {
  AuxAnnos,
  CPRSliceData,
  CPRVesselData,
  Shape,
  ShapeV1,
  ShapeV2,
  TRange,
  XYCoords,
} from '../../reducers/vessel-data';
import { LineArray, ObjectArray, PointArray } from '../../types';
import * as api from '../../utils/api';
import { PIXIPointerEvent } from '../../utils/captureMouse';
import { clampNumber } from '../../utils/shared';
import { EditCenterlineV2_result_result } from '../../views/VesselViewer/types';
import { createImageBufferFromImageData } from '../WebGLViewer/Utils';
import { AdjacentPoints } from './types';

export const INDICATOR_SETTINGS = {
  MARKER_COLOR: COLOR_CPR_MARKER_COLOR_INDICATOR,
  INDICATOR_COLOR_MAIN: COLOR_CPR_CENTRELINE,
  INDICATOR_COLOR: COLOR_CPR_SLICE_INDICATOR,
  INDICATOR_WIDTH: 20,
  INDICATOR_LENGTH: 12,
  SHADOW_OPACITY: 0.8,
  SHADOW_SIZE: 1,
  SHADOW_COLOR: '#000',
  HIT_AREA_BUFFER_PX: 5,
  FFR_MARKER_OFFSET: 15,
  FFR_LOW_COLOR: COLOR_CPR_LOW_FFR,
};

export const MEASUREMENT_MARKER_SETTINGS = {
  OFFSET: 65,
  Z_INDEX: 30,
};

/**
 * Use the tArray to create a mapping from the slice index to the centerline index and vice versa.
 * @param sliceCount The number of slices the centerline is divided into.
 * @param tArray An array the same length as the centerline points for this image.
 *               Each entry has the 't' value for that point which is just how far along the centerline the point is (in the t range).
 * @param tRange The slice index is remapped to a 't' value for some reason.
 * @param centerlinePointCount The total number of points along the centerline for this image.
 */
export const getCenterlineMapping = (
  sliceCount: number,
  tArray: number[],
  tRange: TRange,
  centerlinePointCount: number
): CenterlineMapping | undefined => {
  if (sliceCount && tArray && tRange) {
    let lowIndex = 0;
    const sliceToCenterline: number[] = [];
    for (let index = 0; index < sliceCount; index++) {
      // Linearly convert from a slice index to a 't' value in the 'tRange'.
      const t =
        tRange[0] + (index / (sliceCount - 1)) * (tRange[1] - tRange[0]);
      // Loop through the tArray to find the slice with the closest matching 't' value.
      let bestSlice = 0;
      let bestDiff = 1000000;
      for (let i = 0; i < tArray.length; i++) {
        const diff = Math.abs(tArray[i] - t);
        if (bestDiff > diff) {
          bestDiff = diff;
          bestSlice = i;
        }
      }
      // TODO: If we tracked the best two points we could use triangulation to get an interpolated value; would that be better?

      // For some reason the mapping needs to be offset again, so that the first slice mapping is always zero.
      if (index === 0) {
        lowIndex = bestSlice;
      }
      // Set the mapping for the slice to the centerline point index it belongs to for this image.
      sliceToCenterline[index] = clampNumber(
        bestSlice - lowIndex,
        0,
        centerlinePointCount - 1
      );
    }

    // Get the centerline to slice mapping.
    const centerlineToSlice: number[] = [];
    for (let index = 0; index < centerlinePointCount; index++) {
      // Get the 't' value of the centerline point.
      const t = tArray[index + lowIndex];
      // Linearly convert from a 't' value in the 'tRange' to a slice index.
      // Note the index must be integer and fully inside the (0 to sliceCount - 1) range.
      centerlineToSlice[index] = clampNumber(
        Math.round(
          ((sliceCount - 1) * (t - tRange[0])) / (tRange[1] - tRange[0])
        ),
        0,
        sliceCount - 1
      );
    }
    return { sliceToCenterline, centerlineToSlice };
  }
  return undefined;
};

/**
 * Get the index of the nearest point in the array of centerline points to the specified pos.
 */
export const getNearestCenterlinePoint = (
  pos: XYCoords,
  centerline: XYCoords[]
): number => {
  let bestIndex = 0;
  let bestDistance = 10000000;
  centerline.forEach((point, index) => {
    // Calculate the distance to the point (yes this is the square of it but we don't need the actual value so this is faster).
    const distance =
      (pos.x - point.x) * (pos.x - point.x) +
      (pos.y - point.y) * (pos.y - point.y);
    if (bestDistance > distance) {
      bestDistance = distance;
      bestIndex = index;
    }
  });
  return bestIndex;
};

/**
 * Convert a ShapeV1 into a Shape.
 */
export const shapeV1ToShape = (shape: ShapeV1 | undefined): Shape => {
  const result: Shape = [];
  if (shape) {
    for (let i = 0; i < shape[0]; i++) {
      result[i] = { x: shape[1], y: shape[2] };
    }
  }
  return result;
};

/**
 * Convert a ShapeV2 into a Shape.
 */
export const shapeV2ToShape = (shape: ShapeV2 | undefined): Shape => {
  const result: Shape = [];
  if (shape && shape.view_idx) {
    Object.keys(shape.view_idx).forEach((key) => {
      result[Number(key)] = {
        x: shape.view_idx[key][0],
        y: shape.view_idx[key][1],
      };
    });
  }
  return result;
};

/**
 * A tiny helper function to get the correct endpoint for the CPR V1 or CPR V2.
 */
export const getCPRVersion = (cprVersion: number): string => {
  if (cprVersion === 2) return '/cpr/v2';
  return '/cpr';
};

/**
 * Fetch the shape of the image data: an array of [width, height] per image.
 */
export const fetchShape = (
  endPoint: string,
  shapeVersion: number,
  versionHead: string | undefined,
  viewType: string
): Promise<Shape> => {
  return new Promise<Shape>((accept, reject) => {
    // Fetch the array style shape (ShapeV2)?
    if (shapeVersion === 2) {
      api
        .getJSON(`${endPoint}/shape/all?version=${versionHead}`, false, {
          group_type: viewType,
        })
        .then((shape: ShapeV2) => {
          accept(shapeV2ToShape(shape));
        })
        .catch((e) => {
          console.error('Failed to fetch data shape v2', e);
          reject(e);
        });
    }
    // Otherwise fetch the standard shape (ShapeV1).
    else {
      api
        .getJSON(`${endPoint}/shape?version=${versionHead}`, false, {
          group_type: viewType,
        })
        .then((shape: ShapeV1) => {
          accept(shapeV1ToShape(shape));
        })
        .catch((e) => {
          console.error('Failed to fetch data shape v1', e);
          reject(e);
        });
    }
  });
};

/**
 * Convert from a LineArray to an array of XYCoords.
 * @param transpose Flip the X and Y value?
 */
export const lineArrayToXYCoords = (
  lineArray: LineArray,
  transpose: boolean = true
): XYCoords[] => {
  // Safety check.
  if (lineArray && lineArray.map) {
    return lineArray.map((value: PointArray) => {
      return { x: value[transpose ? 1 : 0], y: value[transpose ? 0 : 1] };
    });
  }
  return [];
};

/**
 * Convert the AuxAnno to an XYCoordAuxAnnos.
 */
export const auxAnnosToXYCoordsAuxAnnos = (
  auxAnnos: AuxAnnos
): ObjectArray<XYCoords[]> => {
  const result: ObjectArray<XYCoords[]> = {};
  Object.keys(auxAnnos).forEach((key) => {
    // Note the data from the endpoint actually stuffs the LineArray into an array of length 1 for some reason ... and
    // vessels that do not appear on this slice 'may' exist as an array of length 0 (we can ignore these).
    if (auxAnnos[key].length > 0) {
      result[key.toUpperCase()] = lineArrayToXYCoords(auxAnnos[key][0]);
    }
  });
  return result;
};

/**
 * Remove the auxAnnos for the specified vessel id (because the CPR V2 endpoints return auxAnnos data for
 * the vessel it belongs to and shouldn't).
 */
export const fiterAuxAnnos = (
  auxAnnos: ObjectArray<XYCoords[]>,
  vesselId: string
): ObjectArray<XYCoords[]> => {
  const result = { ...auxAnnos };
  if (vesselId) {
    delete result[vesselId.toUpperCase()];
  }
  return result;
};

/**
 * Get the new image data from the specified base endpoint and slice.
 */
export const fetchImage = (
  basePath: string,
  sliceIndex: number
): Promise<Buffer> => {
  const path = `task-results/${basePath.replace(
    `{view_num}`,
    String(sliceIndex)
  )}`;
  return api.getRawH5(path).catch((error) => {
    console.error('Failed to fetch image:', error);
    throw error;
  });
};

/**
 * Get the aux annos for the specified slice (ie the centerlines for each other vessel).
 */
export const fetchAuxAnnos = (
  basePath: string,
  sliceIndex: number
): Promise<AuxAnnos> => {
  const path = `task-results/${basePath.replace(
    `{view_num}`,
    String(sliceIndex)
  )}`;
  return api.getJSON(path, false).catch((error) => {
    console.error('fetchAuxAnnos Error:', error);
    throw error;
  });
};

/**
 * Get the aux annos for every slice (ie the centerlines for each other vessel).
 */
export const fetchAllAuxAnnos = (
  basePath: string,
  sliceCount: number
): Promise<AuxAnnos[]> => {
  // Fetch the aux annos data for each slice.
  const auxAnnosPromises: Promise<AuxAnnos>[] = [];
  for (let sliceIndex = 0; sliceIndex < sliceCount; sliceIndex++) {
    auxAnnosPromises.push(fetchAuxAnnos(basePath, sliceIndex));
  }
  return Promise.all(auxAnnosPromises);
};

/**
 * Load the remaining data required before we can present the first image slice after we have a CPR V2 edit result.
 * @param vesselId The name of the vessel we are loading (eg "LAD"). This is required because CPR V2
 *                 sends us the vessel we are editing in its own auxAnnos.
 * @param sliceCount The number of slices the centerline is divided into for the moving of indicator handles etc.
 */
export const onEditCenterlineV2Result = (
  patientID: string,
  vesselName: string,
  result: EditCenterlineV2_result_result,
  initialSliceIndex: number,
  sliceCount: number
): Promise<CPRVesselData> => {
  // Get the shape of the data.
  const shape = shapeV2ToShape(result.shape);

  // Fetch the aux annos data for each slice.
  return fetchAllAuxAnnos(result.aux_annos_keys.path, shape.length).then(
    (auxAnnos) => {
      // Fetch the first new image (the view being edited)
      return fetchImage(result.image_keys.path, initialSliceIndex).then(
        (image) => {
          // Create the data for each slice from the individual arrays of data.
          const sliceData: CPRSliceData[] = [];
          for (let sliceIndex = 0; sliceIndex < shape.length; sliceIndex++) {
            sliceData[sliceIndex] = {
              anno: lineArrayToXYCoords(result.cl2d.view_idx[sliceIndex]),
              auxAnno: fiterAuxAnnos(
                auxAnnosToXYCoordsAuxAnnos(auxAnnos[sliceIndex]),
                vesselName
              ),
              tArray: result.cl_t_vals?.view_idx?.[sliceIndex],
              // Create a usable centerline mapping from the tArray.
              centerlineMapping: getCenterlineMapping(
                sliceCount,
                result.cl_t_vals?.view_idx?.[sliceIndex],
                result.cl_t_range_vessel,
                result.cl2d.view_idx[sliceIndex].length
              ),
            };
          }

          // Convert the image into an image buffer.
          const imageBuffer = createImageBufferFromImageData(image, true);
          const imageBufferData = [];
          imageBufferData[initialSliceIndex] = imageBuffer;

          // Return the CPRVesselData.
          return {
            patientID,
            vesselName,
            tRange: result.cl_t_range_vessel,
            shape,
            sliceData,
            imageBufferData,
          };
        }
      );
    }
  );
};

/**
 * Fetch the remaining centerline images after an edit centerline call has returned a sucessful result.
 */
export const onEditCenterlineFetchImages = (
  basePath: string,
  sliceCount: number,
  initialSliceIndex: number,
  onImageLoaded: (sliceIndex: number, image: Buffer) => void
): Promise<void[]> => {
  // Queue loading of remaining images.
  const promises: Promise<void>[] = [];
  for (let sliceIndex = 0; sliceIndex < sliceCount; sliceIndex++) {
    // Skip the slice we have already loaded.
    if (sliceIndex === initialSliceIndex) {
      continue;
    }
    promises.push(
      // Fetch the image.
      fetchImage(basePath, sliceIndex).then((image) => {
        // Do something with the loaded image?
        if (onImageLoaded) {
          onImageLoaded(sliceIndex, image);
        }
      })
    );
  }

  // We need all promises to settle.
  return Promise.all(promises);
};

export const getLineAngle = (point1: XYCoords, point2: XYCoords) => {
  return (Math.atan2(point2.y - point1.y, point2.x - point1.x) * 180) / Math.PI;
};

export const getDistanceBetweenPoints = (
  point1: XYCoords,
  point2: XYCoords
) => {
  return Math.hypot(point2.x - point1.x, point2.y - point1.y);
};

export const getTrianglePoint = (
  start: XYCoords,
  angle: number,
  length = 50
) => {
  const point = {
    x: start.x + length * Math.cos((angle * Math.PI) / 180),
    y: start.y + length * Math.sin((angle * Math.PI) / 180),
  };
  return point;
};

export const getPerpendicularLine = (line: XYCoords[], width = 50) => {
  const angle = getLineAngle(line[0], line[line.length - 1]) + 90;
  // If the line has three points we want the center to be the second point.
  const center = line[Math.round((line.length - 1) / 2)];
  const distance = width;
  const p1 = {
    x: center.x + distance * Math.cos((angle * Math.PI) / 180),
    y: center.y + distance * Math.sin((angle * Math.PI) / 180),
  };
  const p2 = {
    x: center.x - distance * Math.cos((angle * Math.PI) / 180),
    y: center.y - distance * Math.sin((angle * Math.PI) / 180),
  };
  return [p1, center, p2];
};

/**
 * Draw an indicator triangle on the sprite with the triangle starting at the start point and aligned along the start to end line angle.
 */
export const drawIndicatorTriangle = (
  sprite: PIXI.Graphics,
  start: XYCoords,
  end: XYCoords,
  fillColour: string,
  defaultScale: number,
  zoom: number
) => {
  // Triangle's size; dynamic size depending of the scale.
  const scalingFactor = 0.75; // 0 to 1: 0 = scale the triangles with the image. 1 = fixed size triangles.
  const scale = defaultScale * (scalingFactor * zoom + 1.0 - scalingFactor);

  // Getting points to draw the triangle
  const angle = getLineAngle(end, start);
  const PointA = start;
  const PointB = getTrianglePoint(
    start,
    angle + 35,
    INDICATOR_SETTINGS.INDICATOR_LENGTH / scale
  );
  const PointC = getTrianglePoint(
    start,
    angle - 35,
    INDICATOR_SETTINGS.INDICATOR_LENGTH / scale
  );

  sprite.beginFill(Number(fillColour.replace('#', '0x')), 1);
  sprite.lineStyle(
    INDICATOR_SETTINGS.SHADOW_SIZE / (zoom * defaultScale),
    Number(INDICATOR_SETTINGS.SHADOW_COLOR.replace('#', '0x')),
    INDICATOR_SETTINGS.SHADOW_OPACITY
  );
  sprite.drawPolygon([
    PointA.x,
    PointA.y,
    PointB.x,
    PointB.y,
    PointC.x,
    PointC.y,
    PointA.x,
    PointA.y,
  ]);
  sprite.endFill();
};

export const createSliceIndicatorSprite = (params: {
  centerline: XYCoords[];
  lineIndex: number;
  fillColour: string;
  visible: boolean;
  zIndex: number;
  defaultScale: number;
  zoom: number;
  onPointerDown: (event: PIXIPointerEvent) => void;
}) => {
  // We want the indicator width to be fixed in terms of screen pixels so we need to divide it by the defaultScale.
  const indicatorWidth =
    INDICATOR_SETTINGS.INDICATOR_WIDTH / params.defaultScale;

  const sprite = new PIXI.Graphics();
  if (!params.centerline || !params.centerline[params.lineIndex]) {
    return sprite;
  }
  const slicePoint = params.centerline[params.lineIndex];
  const nextPoint =
    params.centerline[
      Math.min(params.lineIndex + 1, params.centerline.length - 1)
    ];
  const prevPoint = params.centerline[Math.max(params.lineIndex - 1, 0)];
  const indicatorLine = getPerpendicularLine(
    [prevPoint, slicePoint, nextPoint],
    indicatorWidth
  );

  // Draw the triangle on the left side.
  drawIndicatorTriangle(
    sprite,
    indicatorLine[2],
    indicatorLine[1],
    params.fillColour,
    params.defaultScale,
    params.zoom
  );
  // Draw the triangle on the right side.
  drawIndicatorTriangle(
    sprite,
    indicatorLine[0],
    indicatorLine[1],
    params.fillColour,
    params.defaultScale,
    params.zoom
  );

  // make interactive
  const buff = INDICATOR_SETTINGS.HIT_AREA_BUFFER_PX;
  sprite.interactive = true;
  const lowX = Math.min(indicatorLine[0].x, indicatorLine[2].x);
  const lowY = Math.min(indicatorLine[0].y, indicatorLine[2].y);
  sprite.hitArea = new PIXI.Rectangle(
    lowX - buff,
    lowY - buff,
    Math.abs(indicatorLine[2].x - indicatorLine[0].x) + buff * 2,
    Math.abs(indicatorLine[2].y - indicatorLine[0].y) + buff * 2
  );
  // We use pointerdown so we can capture the mouse pointer.
  if (params.onPointerDown) {
    sprite.on('pointerdown', params.onPointerDown);
  }
  sprite.cursor = 'ns-resize';
  sprite.zIndex = params.zIndex;
  // The sprite is hidden if we are in edit or add centerline mode.
  sprite.visible = params.visible;
  return sprite;
};

export function calculateAdjacentPoints(
  centerline: XYCoords[],
  lineIndex: number
): AdjacentPoints {
  const slicePoint = centerline[lineIndex];
  const nextPoint = centerline[lineIndex + 1]
    ? centerline[lineIndex + 1]
    : centerline[lineIndex];
  const prevPoint = centerline[lineIndex - 1]
    ? centerline[lineIndex - 1]
    : centerline[lineIndex];

  return {
    prevPoint: prevPoint,
    slicePoint: slicePoint,
    nextPoint: nextPoint,
  };
}

export const createMeasurementMarkerSprite = (measurement: string): Marker => {
  const style = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 12,
    fill: THEME.colors.base.white,
    stroke: THEME.colors.base.black,
    strokeThickness: 2,
  });
  const text = new PIXI.Text(measurement, style);

  const wrapper = new PIXI.Container() as Marker;
  wrapper.addChild(text);
  return wrapper;
};
