import React, { useCallback, useMemo } from 'react';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron.svg';
import { useContrastContext } from '../../../context/contrast-context';
import { ContrastViewContent } from '../../../context/contrast-types';
import {
  useViewConfigContext,
  ViewConfigActions,
} from '../../../context/viewConfig';
import { MAX_RECENTLY_SELECTED_VIEWS } from '../../../context/viewConfig/constants';
import RecentlySelectedContrastSeries from './RecentlySelectedContrastSeries';

interface EmptyViewProps {
  viewport: number;
}

/**
 * A view waiting for some content to be dragged into it.
 */
export const EmptyView: React.FC<EmptyViewProps> = ({ viewport }) => {
  const {
    state: { recentlySelected },
    dispatch,
  } = useViewConfigContext();
  const { contrastViews, onDraggedContrastView } = useContrastContext();

  const recentlySelectedMinusCurrentlyShown = useMemo(
    () => recentlySelected.filter((x) => !contrastViews.includes(x)),
    [recentlySelected, contrastViews]
  );

  /**
   * Respond to the user selecting a recent view from the list of recent views shown on the empty view.
   * This assumes the list is accurate and will not contain any view which is currently visible.
   */
  const onClick = useCallback(
    (contrastView: ContrastViewContent) => {
      onDraggedContrastView(contrastView, viewport);
      dispatch(ViewConfigActions.removeRecentlySelectedView(contrastView));
    },
    [viewport, onDraggedContrastView, dispatch]
  );

  return (
    <div className="empty-view">
      <div className="empty-view-arrow">
        <ChevronIcon />
      </div>
      {recentlySelectedMinusCurrentlyShown.length <= 0 && (
        <>
          <div className="empty-view-text">Please drag and</div>
          <div className="empty-view-text">drop a series here</div>
        </>
      )}
      {recentlySelectedMinusCurrentlyShown.length > 0 && (
        <>
          <div className="empty-view-text">Select the view you</div>
          <div className="empty-view-text empty-view-with-bottom-margin">
            want for this window
          </div>
          {recentlySelectedMinusCurrentlyShown
            .slice(0, MAX_RECENTLY_SELECTED_VIEWS)
            .map((recent, index) => {
              return (
                <RecentlySelectedContrastSeries
                  key={`${recent.viewType}-${index}`}
                  onClick={() => onClick(recent)}
                  recent={recent}
                />
              );
            })}
        </>
      )}
    </div>
  );
};
