import produce from 'immer';
import { getType } from 'typesafe-actions';
import { VesselDataAction, VesselDataActions } from './actions';
import {
  DEFAULT_CT_NONCONTRAST_VIEWER_DATA,
  DEFAULT_LONG_AXIS_VIEWER_DATA,
  DEFAULT_SHORT_AXIS_VIEWER_DATA,
  DEFAULT_VESSEL_DATA_STATE,
  DEFAULT_VESSEL_VIEWER_DATA,
  DEFAULT_SELECTED_VESSEL_DATA,
} from './constants';
import { VesselDataState } from './types';
import { createImageBufferFromImageData } from '../../components/WebGLViewer/Utils';

export function vesselDataReducer(
  state: VesselDataState = DEFAULT_VESSEL_DATA_STATE,
  action: VesselDataAction
): VesselDataState {
  return produce(state, (draft) => {
    switch (action.type) {
      case getType(VesselDataActions.addVesselData): {
        draft.vesselData = action.payload;
        break;
      }

      case getType(VesselDataActions.selectVessel): {
        draft.selectedVesselData.name = action.payload;
        break;
      }

      case getType(VesselDataActions.updateSliceIndices): {
        const { high, mid, low } = action.payload;

        if (high !== undefined) {
          draft.selectedVesselData.highSliceIdx = high;
        }
        if (mid !== undefined) {
          draft.selectedVesselData.midSliceIdx = mid;
        }
        if (low !== undefined) {
          draft.selectedVesselData.lowSliceIdx = low;
        }
        break;
      }

      case getType(VesselDataActions.updateCalciumScores): {
        Object.entries(action.payload).forEach(
          ([majorVessel, calciumScore]) => {
            if (draft.vesselData?.[majorVessel]) {
              draft.vesselData[majorVessel].calcium_score = calciumScore;
            }
          }
        );
        break;
      }

      case getType(VesselDataActions.updatePriorityVessel): {
        draft.selectedVesselData.priorityVessel = action.payload;
        break;
      }

      case getType(VesselDataActions.updateHighestPriorityIdx): {
        draft.selectedVesselData.highestPriorityIdx = action.payload;
        break;
      }

      case getType(VesselDataActions.initialisePatient): {
        const { patientID, runID } = action.payload;
        // Initialize the patient id and run id.
        if (draft.patientID === undefined && draft.runID === undefined) {
          draft.patientID = patientID;
          draft.runID = runID;
        } else {
          console.warn(
            `Initialising patient when patient is already initialised`
          );
        }
        break;
      }

      case getType(VesselDataActions.addVesselViewerDataCPRViewerData): {
        const { patientID, runID, vesselID, cprVesselData } = action.payload;
        // Check we are setting data for this patient and run, if not we should abort saving the changes.
        if (draft.patientID === patientID && draft.runID === runID) {
          if (!draft.vesselViewerData[vesselID]) {
            draft.vesselViewerData[vesselID] = {};
          }

          draft.vesselViewerData[vesselID].cprVesselData = cprVesselData;
        }
        break;
      }

      case getType(VesselDataActions.addVesselViewerDataCPRViewerDataImage): {
        const {
          patientID,
          runID,
          vesselID,
          sliceIndex,
          image,
        } = action.payload;

        // To update we MUST have existing cprVesselData and the studyId and vesselID must match.
        if (
          draft.patientID === patientID &&
          draft.runID === runID &&
          draft.selectedVesselData.name === vesselID &&
          draft.vesselViewerData[vesselID]?.cprVesselData
        ) {
          const imageBuffer = createImageBufferFromImageData(image, true);
          if (imageBuffer) {
            const cprVesselData = draft.vesselViewerData[vesselID]
              .cprVesselData!;
            if (!cprVesselData.imageBufferData) {
              cprVesselData.imageBufferData = [];
            }
            cprVesselData.imageBufferData[sliceIndex] = imageBuffer;
          }
        }
        break;
      }

      case getType(VesselDataActions.updateCPRVesselDataForVessel): {
        const { patientID, runID, vesselID, data } = action.payload;

        // Check we are setting data for this patient and run, if not we should abort saving the changes.
        if (draft.patientID === patientID && draft.runID === runID) {
          if (!draft.vesselViewerData) {
            draft.vesselViewerData = {};
          }

          if (!draft.vesselViewerData[vesselID]) {
            draft.vesselViewerData[vesselID] = {
              ...DEFAULT_VESSEL_VIEWER_DATA,
            };
          }

          draft.vesselViewerData[vesselID].cprVesselData = data;
        }
        break;
      }

      case getType(VesselDataActions.updateCPRSliceData): {
        const { patientID, runID, vesselID, sliceIndex, data } = action.payload;

        // Check we are setting data for this patient and run, if not we should abort saving the changes.
        if (draft.patientID === patientID && draft.runID === runID) {
          if (!draft.vesselViewerData) {
            draft.vesselViewerData = {};
          }

          if (!draft.vesselViewerData[vesselID]) {
            draft.vesselViewerData[vesselID] = {};
          }

          if (!draft.vesselViewerData[vesselID].cprVesselData) {
            draft.vesselViewerData[vesselID].cprVesselData = {
              sliceData: [],
              tRange: [0, 1],
              shape: [],
              imageBufferData: undefined,
            };
          }

          draft.vesselViewerData[vesselID].cprVesselData!.sliceData[
            sliceIndex
          ] = data;
        }
        break;
      }

      case getType(VesselDataActions.clearSavingVessel): {
        draft.savingVesselId = undefined;
        break;
      }

      case getType(VesselDataActions.savingVessel): {
        draft.savingVesselId = action.payload;
        break;
      }

      case getType(VesselDataActions.clear): {
        // NOTE: We essentially want to do:
        // draft = { ...DEFAULT_VESSEL_DATA_STATE };
        // except that would break immer so we need to mutate draft instead.
        draft.vesselData = undefined;
        draft.vesselViewerData = {};
        draft.ctNonContrastViewerData = undefined;
        draft.savingVesselId = undefined;
        draft.patientID = undefined;
        draft.runID = undefined;
        draft.selectedVesselData = { ...DEFAULT_SELECTED_VESSEL_DATA };
        break;
      }

      case getType(VesselDataActions.updateShortAxisVesselData): {
        const {
          patientID,
          runID,
          vesselID,
          shape,
          lumen,
          outer,
        } = action.payload;
        // Check we are setting data for this patient and run, if not we should abort saving the changes.
        if (draft.patientID === patientID && draft.runID === runID) {
          if (!draft.vesselViewerData[vesselID]) {
            draft.vesselViewerData[vesselID] = {
              ...DEFAULT_VESSEL_VIEWER_DATA,
            };
          }

          if (!draft.vesselViewerData[vesselID].shortAxisViewerData) {
            draft.vesselViewerData[vesselID].shortAxisViewerData = {
              ...DEFAULT_SHORT_AXIS_VIEWER_DATA,
            };
          }

          draft.vesselViewerData[vesselID].shortAxisViewerData!.shape = shape;
          draft.vesselViewerData[
            vesselID
          ].shortAxisViewerData!.polygonsLumen = lumen;
          draft.vesselViewerData[
            vesselID
          ].shortAxisViewerData!.polygonsOuter = outer;
        }
        break;
      }

      case getType(VesselDataActions.updateLongAxisShapeData): {
        const { patientID, runID, vesselID, shape } = action.payload;

        // Check we are setting data for this patient and run, if not we should abort saving the changes.
        if (draft.patientID === patientID && draft.runID === runID) {
          if (!draft.vesselViewerData[vesselID]) {
            draft.vesselViewerData[vesselID] = {
              ...DEFAULT_VESSEL_VIEWER_DATA,
            };
          }

          if (!draft.vesselViewerData[vesselID].longAxisViewerData) {
            draft.vesselViewerData[vesselID].longAxisViewerData = {
              ...DEFAULT_LONG_AXIS_VIEWER_DATA,
            };
          }

          draft.vesselViewerData[vesselID].longAxisViewerData!.shape = shape;
        }
        break;
      }

      case getType(VesselDataActions.updateCTNonContrastShapeData): {
        const { patientID, runID, shape } = action.payload;

        // Check we are setting data for this patient and run, if not we should abort saving the changes.
        if (draft.patientID === patientID && draft.runID === runID) {
          if (!draft.ctNonContrastViewerData) {
            draft.ctNonContrastViewerData = {
              ...DEFAULT_CT_NONCONTRAST_VIEWER_DATA,
            };
          }
          draft.ctNonContrastViewerData!.shape = shape;
        }
        break;
      }

      case getType(VesselDataActions.updateCPRVesselSliceImageData): {
        const {
          patientID,
          runID,
          vesselID,
          sliceIndex,
          image,
        } = action.payload;

        // Check we are setting data for this patient and run, if not we should abort saving the changes.
        if (draft.patientID === patientID && draft.runID === runID) {
          const cprVesselData = draft.vesselViewerData[vesselID]?.cprVesselData;
          if (cprVesselData) {
            if (!cprVesselData.imageBufferData) {
              cprVesselData.imageBufferData = [];
            }
            cprVesselData.imageBufferData[sliceIndex] = image;
          }
        }
        break;
      }

      case getType(VesselDataActions.updateShortAxisVesselSliceImageData): {
        const {
          patientID,
          runID,
          vesselID,
          sliceIndex,
          image,
        } = action.payload;

        // Check we are setting data for this patient and run, if not we should abort saving the changes.
        if (draft.patientID === patientID && draft.runID === runID) {
          const shortAxisData =
            draft.vesselViewerData[vesselID]?.shortAxisViewerData;

          if (shortAxisData) {
            if (!shortAxisData.imageBufferData) {
              shortAxisData.imageBufferData = [];
            }
            shortAxisData.imageBufferData[sliceIndex] = image;
          }
        }
        break;
      }

      case getType(VesselDataActions.updateLongAxisVesselSliceImageData): {
        const {
          patientID,
          runID,
          vesselID,
          sliceIndex,
          image,
        } = action.payload;

        // Check we are setting data for this patient and run, if not we should abort saving the changes.
        if (draft.patientID === patientID && draft.runID === runID) {
          const longAxisData =
            draft.vesselViewerData[vesselID]?.longAxisViewerData;

          if (longAxisData) {
            if (!longAxisData.imageBufferData) {
              longAxisData.imageBufferData = [];
            }
            longAxisData.imageBufferData[sliceIndex] = image;
          }
        }
        break;
      }

      case getType(VesselDataActions.updateCTNonContrastVesselSliceImageData): {
        const { patientID, runID, sliceIndex, image } = action.payload;

        // Check we are setting data for this patient and run, if not we should abort saving the changes.
        if (draft.patientID === patientID && draft.runID === runID) {
          const ctNonContrastViewerData = draft.ctNonContrastViewerData;

          if (ctNonContrastViewerData) {
            if (!ctNonContrastViewerData.imageBufferData) {
              ctNonContrastViewerData.imageBufferData = [];
            }
            ctNonContrastViewerData.imageBufferData[sliceIndex] = image;
          }
        }
        break;
      }

      default:
        break;
    }
  });
}
