import { useCallback, useMemo } from 'react';
import { showToast } from '../components/Toast/showToast';
import { VesselGroupings } from '../config';
import { useInlineReportingContext } from '../context/inlineReporting/context';
import { useReportContext } from '../context/report-context';
import { useStoreContext } from '../context/store-context';
import { VesselToReportNameMapping } from '../context/types';
import { useAppDispatch, useAppSelector } from '../hooks';
import { fetchStudyById } from '../reducers/study';
import { useShouldShowAmendmentModal } from '../selectors/reporting';
import { currentStudySelector } from '../selectors/study';
import * as api from '../utils/api';

export function useSaveCallBack(isPartialUpdate: boolean = false) {
  const {
    setPostingReport,
    setPostingPartialReport,
    currentReport,
    draftReport,
    runID,
    setCurrentReport,
    setReportHistory,
    setReviewList,
    setEditingReport,
    setEditingImpressions,
    setEditingCoronaryFindings,
    clearDraftReport,
  } = useStoreContext();

  const selectedStudy = useAppSelector(currentStudySelector);
  const dispatch = useAppDispatch();

  const {
    amendmentModalPassthrough,
    setAmendmentModalPassthrough,
  } = useReportContext();

  const showAmendmentModal = useShouldShowAmendmentModal();

  const setLoadingSpinner = useMemo(
    () => (isPartialUpdate ? setPostingPartialReport : setPostingReport),
    [isPartialUpdate, setPostingPartialReport, setPostingReport]
  );

  const actualEndpoint = useCallback(
    async (studyId: string, runID: string, draftReport, reason?: string) => {
      let success = false;
      try {
        // Save the report
        const saveResponse = await api.saveReport(studyId, runID, {
          ...draftReport,
          update_message: reason,
        });
        if (saveResponse.detail === 'success' && saveResponse.changes) {
          success = true;
          setCurrentReport(saveResponse.content);
        }

        // Reload the other report data
        const promises = Promise.all([
          api.fetchReport(studyId, runID),
          api.fetchReportHistory(studyId, runID),
          api.fetchReviewList(studyId, runID),
          selectedStudy?.is_report_approved
            ? dispatch(fetchStudyById(studyId))
            : Promise.resolve(null),
        ]);

        const [report, history, reviewList] = await promises;
        setCurrentReport(report);
        setReportHistory(history);
        setReviewList(reviewList);
      } catch (err) {
        // Revert to original version
        setCurrentReport(currentReport);
        console.error(err);
        showToast.error('Error Saving Report');
      } finally {
        setLoadingSpinner(false);
        setEditingReport(false);
        setEditingImpressions(false);
        setEditingCoronaryFindings(false);
        clearDraftReport();
        if (success) {
          showToast.success('Report saved');
        }
      }
    },
    [
      setReportHistory,
      setReviewList,
      setCurrentReport,
      currentReport,
      setLoadingSpinner,
      selectedStudy?.is_report_approved,
      dispatch,
      setEditingImpressions,
      setEditingCoronaryFindings,
      setEditingReport,
      clearDraftReport,
    ]
  );

  const hitEndPoint = useCallback(
    (reason?: string) => {
      const studyId = selectedStudy?.study_id;
      if (studyId && currentReport && draftReport && runID) {
        setAmendmentModalPassthrough({});
        setLoadingSpinner(true);
        setCurrentReport({ ...currentReport, ...draftReport });

        actualEndpoint(studyId, runID, draftReport, reason);
      }
    },
    [
      setAmendmentModalPassthrough,
      runID,
      actualEndpoint,
      draftReport,
      setCurrentReport,
      currentReport,
      setLoadingSpinner,
      selectedStudy?.study_id,
    ]
  );

  return useCallback(async () => {
    // If the report has been approved, show the amendment modal first
    if (showAmendmentModal && !amendmentModalPassthrough?.passthrough) {
      setAmendmentModalPassthrough({ passthrough: hitEndPoint });
    } else {
      hitEndPoint();
    }
  }, [
    hitEndPoint,
    showAmendmentModal,
    setAmendmentModalPassthrough,
    amendmentModalPassthrough,
  ]);
}

export function useSaveImpressionsCallback(
  studyId: string | undefined,
  impressions: string[] | undefined
) {
  const {
    setPostingPartialReport,
    currentReport,
    runID,
    setCurrentReport,
    setReportHistory,
  } = useStoreContext();

  const selectedStudy = useAppSelector(currentStudySelector);
  const dispatch = useAppDispatch();

  const {
    amendmentModalPassthrough,
    setAmendmentModalPassthrough,
  } = useReportContext();

  const setLoadingSpinner = useMemo(() => setPostingPartialReport, [
    setPostingPartialReport,
  ]);

  const showAmendmentModal = useShouldShowAmendmentModal();

  const hitEndPoint = useCallback(
    (reason?: string) => {
      if (
        currentReport &&
        runID !== undefined &&
        impressions !== undefined &&
        studyId !== undefined
      ) {
        let success = false;
        setAmendmentModalPassthrough({});

        setLoadingSpinner(true);
        setCurrentReport({ ...currentReport, impression: impressions });
        api
          .saveFieldInReport(studyId, runID, {
            impression: impressions,
            update_message: reason,
          })
          .then((response) => {
            if (response.detail === 'success' && response.changes) {
              success = true;
              return setCurrentReport(response.content);
            }
          })
          .then(() => api.fetchReportHistory(studyId, runID))
          .then((response) => {
            setLoadingSpinner(false);
            setReportHistory(response);
            if (success) {
              showToast.success('Report saved');
            }
          })
          .then(() => {
            // TODO: Find a better way to reload study data
            // Re-fetch study data if updating an approved report
            if (selectedStudy?.is_report_approved) {
              dispatch(fetchStudyById(studyId));
            }

            return;
          })
          .catch((err) => {
            setLoadingSpinner(false);
            // Revert to original version
            setCurrentReport(currentReport);
            console.error(err);
            showToast.error('Error Saving Report');
          });
      }
    },
    [
      setAmendmentModalPassthrough,
      impressions,
      setReportHistory,
      studyId,
      runID,
      setCurrentReport,
      currentReport,
      setLoadingSpinner,
      selectedStudy?.is_report_approved,
      dispatch,
    ]
  );

  return useCallback(async () => {
    if (showAmendmentModal && !amendmentModalPassthrough?.passthrough) {
      setAmendmentModalPassthrough({ passthrough: hitEndPoint });
    } else {
      hitEndPoint();
    }
  }, [
    hitEndPoint,
    setAmendmentModalPassthrough,
    showAmendmentModal,
    amendmentModalPassthrough,
  ]);
}

export function useSaveCoronaryFindingCallback(
  studyId: string | undefined,
  selectedVessel: VesselGroupings
) {
  const {
    setPostingPartialReport,
    currentReport,
    runID,
    setCurrentReport,
    setReportHistory,
  } = useStoreContext();

  const selectedStudy = useAppSelector(currentStudySelector);
  const dispatch = useAppDispatch();

  const showAmendmentModal = useShouldShowAmendmentModal();

  const {
    amendmentModalPassthrough,
    setAmendmentModalPassthrough,
  } = useReportContext();

  const { state } = useInlineReportingContext();

  const setLoadingSpinner = useMemo(() => setPostingPartialReport, [
    setPostingPartialReport,
  ]);

  const hitEndPoint = useCallback(
    (reason?: string) => {
      const mapped = VesselToReportNameMapping[selectedVessel];

      const findings = state[mapped];

      if (currentReport && findings && runID && studyId) {
        let success = false;
        setAmendmentModalPassthrough({});
        setLoadingSpinner(true);
        setCurrentReport({ ...currentReport, [mapped]: findings });
        api
          .saveFieldInReport(studyId, runID, {
            [mapped]: findings,
            update_message: reason,
          })
          .then((response) => {
            if (response.detail === 'success' && response.changes) {
              success = true;
              return setCurrentReport(response.content);
            }
          })
          .then(() => api.fetchReportHistory(studyId, runID))
          .then((response) => {
            setLoadingSpinner(false);
            setReportHistory(response);
            if (success) {
              showToast.success('Report saved');
            }
          })
          .then(() => {
            // TODO: Find a better way to reload study data
            // Re-fetch study data if updating an approved report
            if (selectedStudy?.is_report_approved) {
              dispatch(fetchStudyById(studyId));
            }

            return;
          })
          .catch((err) => {
            setLoadingSpinner(false);
            // Revert to original version
            setCurrentReport(currentReport);
            console.error(err);
            showToast.error('Error Saving Report');
          });
      }
    },
    [
      setAmendmentModalPassthrough,
      selectedVessel,
      state,
      setReportHistory,
      studyId,
      runID,
      setCurrentReport,
      currentReport,
      setLoadingSpinner,
      selectedStudy?.is_report_approved,
      dispatch,
    ]
  );

  return useCallback(async () => {
    if (showAmendmentModal && !amendmentModalPassthrough?.passthrough) {
      setAmendmentModalPassthrough({ passthrough: hitEndPoint });
    } else {
      hitEndPoint();
    }
  }, [
    hitEndPoint,
    setAmendmentModalPassthrough,
    showAmendmentModal,
    amendmentModalPassthrough,
  ]);
}

export function useRevertVersionCallBack(studyId: string | undefined) {
  const {
    runID,
    setCurrentReport,
    setReportHistory,
    setPostingReport,
  } = useStoreContext();

  const dispatch = useAppDispatch();

  return useCallback(
    (version: 'initial' | 'previous') => {
      if (runID && studyId) {
        setPostingReport(true);
        api
          .revertReportVersion(studyId, runID, version)
          .then((response) => setCurrentReport(response))
          .then(() => api.fetchReportHistory(studyId, runID))
          .then((response) => {
            setReportHistory(response);
            setPostingReport(false);
            showToast.success('Report reverted');
          })
          .then(() => dispatch(fetchStudyById(studyId)))
          .catch((error) => {
            setPostingReport(false);
            console.error('Error reverting version', error);
            showToast.error('Error reverting version');
          });
      }
    },
    [
      studyId,
      runID,
      setReportHistory,
      setCurrentReport,
      setPostingReport,
      dispatch,
    ]
  );
}
