import cn from 'classnames';
import Moment from 'moment';
import { rgba } from 'polished';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { ReactComponent as SuccessIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import {
  PLAQUE,
  PRIMARY_VESSEL_COLOR_MAPPING,
  RISK_LEVELS,
  STENOSIS_RISK_LEVEL_MAPPING,
  THEME,
  VESSEL_DEFAULT_COLOR,
  DASHBOARD_FORMAT_DOB,
} from '../../../config';
import { useStoreContext } from '../../../context/store-context';
import { ContrastLesionData, Study } from '../../../context/types';
import { WindowLevels } from '../../../context/window-types';
import { useAppSelector } from '../../../hooks';
import { currentStudySelector } from '../../../selectors/study';
import { useVesselStateSelector } from '../../../selectors/vessels';
import { sortedVesselList } from '../../../utils/vessel';

interface Props {
  windowLevels: WindowLevels;
  windowLabel: string | null;
  isCprView: boolean;
  studyDetails: Study;
  windowLevelRef?: any;
  windowWidthRef?: any;
  HURef?: any;
  sliceRef?: any;
  slice?: any;
  seriesDate?: any;
  slabThickness?: number;
  calciumScoreData?: any;
  huValue?: any;
  lesionData?: { [key: string]: ContrastLesionData } | undefined;
  vesselCalciumVisible?: { [key: string]: boolean };
  onClearCalciumScore?: (key: string) => void;
  onToggleVesselCalcium?: (toggle: { [key: string]: boolean }) => void;
}

function verifyStat(statValue: string | number) {
  if (!isNaN(Number(statValue)) || String(statValue).length) {
    return true;
  }
  return true;
}

export default function InfoSection({
  windowLevels,
  windowLabel,
  isCprView,
  studyDetails,
  HURef,
  sliceRef,
  slice,
  seriesDate,
  slabThickness,
  calciumScoreData,
  huValue,
  lesionData,
  vesselCalciumVisible,
  onClearCalciumScore,
  onToggleVesselCalcium,
}: Props): ReactElement<Props> {
  const { studyData } = useStoreContext();

  const selectedStudy = useAppSelector(currentStudySelector);

  const { selectedVesselData, selectedVesselName } = useVesselStateSelector();

  const [sliceValue, setSliceValue] = useState<number | null>(null);
  const [HUValue, setHUValue] = useState(null);

  useImperativeHandle(HURef, () => ({ setValue: setHUValue }));
  useImperativeHandle(sliceRef, () => ({ setValue: setSliceValue }));

  const [stats, setStats] = useState([]) as any;

  function formatDOB() {
    // Checks for a valid DOB string, formats accordingly or else renders '-'
    const studyDOB = studyDetails.date_of_birth;
    if (!studyDOB || !Moment(studyDOB).isValid()) return '-';
    return Moment(studyDOB).format(DASHBOARD_FORMAT_DOB);
  }

  useEffect(() => {
    if (selectedVesselData) {
      const { calcium_score: calciumScore } = selectedVesselData;

      if (!lesionData || (!slice && slice !== 0)) return;

      const filteredData = Object.keys(lesionData).filter((key) => {
        const lesion = lesionData[key];
        return lesion.slices.indexOf(slice) >= 0;
      });
      // NOTE: There could be muliple lesions across a slice
      //       For now, retrieve the first lesion
      const slicelesionData = lesionData[filteredData[0]];

      // Lesion Id
      const lesionId = slicelesionData ? filteredData[0] : '-';

      // Lesion stenosis
      const lesionStenosis = slicelesionData
        ? slicelesionData.stenosis_max
        : '-';
      let riskLevel = null;
      if (lesionStenosis) {
        riskLevel = STENOSIS_RISK_LEVEL_MAPPING[lesionStenosis.split(' ')[0]];
      }

      // Plaque composition
      const plaqueComp = slicelesionData
        ? slicelesionData.plaque_composition
        : 'none';

      // Vulnerable plaque markers
      const vpMarkers: string[] = [];
      if (slicelesionData) {
        Object.entries(slicelesionData.vp_biomarker_counts).forEach(
          ([key, value]) => {
            if (value > 0) {
              vpMarkers.push(key);
            }
          }
        );
      }

      const calScore = parseFloat(`${calciumScore}`);

      setStats([
        {
          label: selectedVesselName
            ? `${selectedVesselName.toUpperCase()} Calcium Score`
            : 'Calcium Score',
          value: isNaN(calScore) ? '-' : Math.round(calScore),
        },
        {
          label: 'Stenosis',
          value: lesionStenosis,
          riskLevel,
        },
        {
          label: 'VP Markers',
          value: vpMarkers.length ? vpMarkers.join(', ').toUpperCase() : '-',
        },
        {
          label: 'Plaque',
          value: PLAQUE[plaqueComp],
        },
        {
          label: 'Lesion #',
          value: lesionId,
        },
      ]);
    }
  }, [lesionData, selectedVesselData, slice, selectedVesselName]);

  const retrieveCalciumColor = useCallback((vessel: string) => {
    const col: string | undefined =
      PRIMARY_VESSEL_COLOR_MAPPING[
        vessel as keyof typeof PRIMARY_VESSEL_COLOR_MAPPING
      ];
    return col ?? VESSEL_DEFAULT_COLOR;
  }, []);

  const patientNameDisplay = useMemo(
    () =>
      selectedStudy?.patient_plaintext
        ? selectedStudy.patient_name
        : selectedStudy?.study_id,
    [
      selectedStudy?.patient_plaintext,
      selectedStudy?.patient_name,
      selectedStudy?.study_id,
    ]
  );

  const fallbackNameDisplay = useMemo(
    () => studyData?.patient_data.name ?? '-',
    [studyData?.patient_data.name]
  );

  return (
    <div
      className="meta-details__info_section"
      style={{ backgroundColor: rgba(THEME.colors.greyscale.mineshaft, 0.8) }}
    >
      {!isCprView && (
        <div className="meta-details__info">
          <div className="meta-details__info-item">
            {studyDetails && (
              <>
                <label>Name</label>
                <span className="meta-details__info_value">
                  <span>{patientNameDisplay ?? fallbackNameDisplay}</span>
                </span>
                <label>Patient ID</label>
                <span className="meta-details__info_value">
                  {studyDetails.study_id || '-'}
                </span>
                {seriesDate && (
                  <>
                    <label>Scanned</label>
                    <span className="meta-details__info_value">
                      {Moment(seriesDate).format('DD MMM yyyy LT') || '-'}
                    </span>
                  </>
                )}
                <label>Thickness</label>
                <span className="meta-details__info_value">
                  {slabThickness ? slabThickness.toFixed(1) + 'mm' : '-'}
                </span>
                <label>DOB</label>
                <span className="meta-details__info_value">{formatDOB()}</span>
              </>
            )}
            <label>Slice</label>
            <span className="meta-details__info_value">
              {sliceValue !== null && !isNaN(sliceValue)
                ? Number(sliceValue) + 1
                : !isNaN(slice) && slice !== null
                ? Number(slice) + 1
                : '-'}
            </span>
            {seriesDate && (
              <>
                <label>Scanned</label>
                <span className="meta-details__info_value">
                  {Moment(seriesDate).format('DD MMM yyyy LT') || '-'}
                </span>
                <label>Thickness</label>
                {/* TODO: Get default slab thickness for non-contrast view */}
                <span className="meta-details__info_value">
                  {slabThickness ? slabThickness : '-'}
                </span>
              </>
            )}
            <label>WW/WL</label>
            {/* TODO: Get default value from endpoint when available */}
            <span className="meta-details__info_value">
              {windowLevels ? (
                <span>
                  {windowLevels.windowWidth.toFixed(0)}/{' '}
                  {windowLevels.windowCenter.toFixed(0)}
                </span>
              ) : (
                <span>-/-</span>
              )}
              {windowLabel && <span>({windowLabel})</span>}
            </span>
            <label>HU</label>
            <span className="meta-details__info_value">
              {HUValue
                ? HUValue
                : !isNaN(huValue) && huValue !== null
                ? huValue
                : '-'}
            </span>
          </div>
        </div>
      )}
      {calciumScoreData && !calciumScoreData.detail && (
        <div className="calcium-scores">
          <label className="calcium-scores__title">Calcium scores</label>
          <div className="interactive">
            {sortedVesselList(Object.keys(calciumScoreData)).map((key) => {
              return (
                <div className="calcium-scores__item" key={`score__${key}`}>
                  {key !== 'total' && (
                    <>
                      <label className="calcium-scores__vessel">
                        <input
                          type="checkbox"
                          checked={
                            vesselCalciumVisible && vesselCalciumVisible[key]
                          }
                          id={`check_${key}`}
                          className="calcium-check"
                          onChange={(e) =>
                            onToggleVesselCalcium &&
                            onToggleVesselCalcium({ [key]: e.target.checked })
                          }
                        />
                        <span
                          className={cn(
                            'calcium-block',
                            vesselCalciumVisible && vesselCalciumVisible[key]
                              ? 'checked'
                              : ''
                          )}
                          style={{
                            backgroundColor:
                              vesselCalciumVisible && vesselCalciumVisible[key]
                                ? retrieveCalciumColor(key)
                                : rgba(retrieveCalciumColor(key), 0.5),
                            borderColor: retrieveCalciumColor(key),
                          }}
                        ></span>
                        {key.toUpperCase()}
                      </label>
                      <div>
                        <span
                          className={cn(
                            'calcium-scores__score',
                            calciumScoreData['total'] < 10000 && 'inline'
                          )}
                        >
                          {Math.round(calciumScoreData[key])}
                          <button
                            className="calcium-scores__clear"
                            disabled={calciumScoreData[key] <= 0}
                            onClick={() =>
                              onClearCalciumScore && onClearCalciumScore(key)
                            }
                          >
                            <CloseIcon />
                          </button>
                        </span>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
            <div className="calcium-scores__item">
              <label>
                <span className="calcium-block"></span>
                <span className="strong">TOTAL</span>
              </label>
              <span className="calcium-scores__score clear">
                <span className="strong">
                  {Math.round(calciumScoreData['total'])}
                </span>
                <span className="calcium-block-total"></span>
              </span>
            </div>
          </div>
          <span className="meta-details__divider" />
          <div className="interactive">
            <div className="calcium-scores__item">
              <label className="calcium-scores__vessel">
                <input
                  type="checkbox"
                  id={`check_other`}
                  checked={
                    vesselCalciumVisible && vesselCalciumVisible['other']
                  }
                  className="calcium-check"
                  onChange={(e) =>
                    onToggleVesselCalcium &&
                    onToggleVesselCalcium({ other: e.target.checked })
                  }
                />
                <span
                  className={cn(
                    'calcium-block',
                    vesselCalciumVisible && vesselCalciumVisible['other']
                      ? 'checked'
                      : ''
                  )}
                  style={{
                    backgroundColor:
                      vesselCalciumVisible && vesselCalciumVisible['other']
                        ? retrieveCalciumColor('other')
                        : rgba(retrieveCalciumColor('other'), 0.5),
                    borderColor: retrieveCalciumColor('other'),
                  }}
                ></span>
                OTHER
              </label>
              <span>&nbsp;</span>
            </div>
            <div className="calcium-scores__item">
              <label className="calcium-scores__vessel">
                <input
                  type="checkbox"
                  id={`check-all`}
                  className="calcium-check"
                  checked={vesselCalciumVisible && vesselCalciumVisible['all']}
                  onChange={(e) =>
                    onToggleVesselCalcium &&
                    onToggleVesselCalcium({ all: e.target.checked })
                  }
                />
                <span
                  className={cn(
                    'calcium-block show-all',
                    vesselCalciumVisible && vesselCalciumVisible['all']
                      ? 'checked'
                      : ''
                  )}
                >
                  {vesselCalciumVisible && vesselCalciumVisible['all'] && (
                    <SuccessIcon />
                  )}
                </span>
                Show All
              </label>
              <span>&nbsp;</span>
            </div>
          </div>
        </div>
      )}
      {isCprView && (
        <div className="meta-details__info">
          {stats.map((s: any, i: any) => {
            return (
              verifyStat(s.value) && (
                <div
                  className="meta-details__info-item meta-details--large"
                  key={`vessel-stat_${i}`}
                >
                  <label>{s.label}</label>
                  <span
                    className={cn(
                      'meta-details__stat',
                      RISK_LEVELS.includes(s.riskLevel)
                        ? `meta-details__stat--risk-${s.riskLevel}`
                        : ''
                    )}
                  >
                    {s.value}
                  </span>
                </div>
              )
            );
          })}
        </div>
      )}
    </div>
  );
}
