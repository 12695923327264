import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useStoreContext } from '../../../context/store-context';
import { PLAQUE } from '../../../config';
import {
  ContrastLesionDataResponse,
  PlaqueCategories,
  PlaqueCategoriesRatio,
  PlaqueMeasurementsPerLesion,
} from '../../../context/types';
import { VesselDataResponse } from '../../../reducers/vessel-data';
import { getStenosisRiskLevel } from './utils';
import { forEach } from 'lodash';
import {
  fetchPlaqueMeasurementsLesionLength,
  fetchPlaqueMeasurementsPerLesion,
} from '../../../utils/api';
import { Loader } from '../../../components/Loader/Loader';

interface SliceDetailsProps {
  hoverVessel: string | null;
  hoverSliceIdx: number | null;
  showHoverData: boolean;
  lesionData: ContrastLesionDataResponse | undefined;
  vesselData: VesselDataResponse | undefined;
}

export const SliceDetails: React.FC<SliceDetailsProps> = ({
  hoverVessel,
  hoverSliceIdx,
  showHoverData,
  vesselData,
  lesionData,
}) => {
  const [lesionStenosis, setLesionStenosis] = useState('0');
  const [riskLevel, setRiskLevel] = useState('none');
  const [vpType, setVpType] = useState<string[] | null>(null);
  const [hoverLesionId, setHoverLesionId] = useState<number | null>(null);
  const [plaqueComp, setPlaqueComp] = useState<string | null>();
  const [loading, setLoading] = useState(false);

  const vessel = hoverVessel ? hoverVessel.toUpperCase() : '';
  const adjustedSliceIdx = hoverSliceIdx ? hoverSliceIdx + 1 : '';
  const {
    displayMeasurements,
    runID,
    patientID,
    versionHead,
    fetchingReport,
  } = useStoreContext();

  const DEFAULT_PLAQUE_VALUES: PlaqueCategories = {
    total: undefined,
    lap: undefined,
    ca: undefined,
    nca: undefined,
  };
  const DEFAULT_PLAQUE_VALUES_RATIO: PlaqueCategoriesRatio = {
    lap: undefined,
    ca: undefined,
    nca: undefined,
  };

  const DEFAULT_PLAQUE_MEASUREMENT_VALUES_PER_LESION: PlaqueMeasurementsPerLesion = {
    volume: DEFAULT_PLAQUE_VALUES,
    ratio: DEFAULT_PLAQUE_VALUES_RATIO,
  };

  const DEFAULT_MEASUREMENT_LESION_LENGTH = undefined;

  const [
    plaqueMeasurementsPerLesion,
    setPlaqueMeasurementsPerLesion,
  ] = useState<PlaqueMeasurementsPerLesion>(
    DEFAULT_PLAQUE_MEASUREMENT_VALUES_PER_LESION
  );

  const [measurementsLesionLength, setMeasurementsLesionLength] = useState(
    DEFAULT_MEASUREMENT_LESION_LENGTH
  );

  useEffect(() => {
    if (!patientID || !runID || !hoverVessel || !hoverLesionId) {
      return;
    }
    setMeasurementsLesionLength(DEFAULT_MEASUREMENT_LESION_LENGTH);
    setPlaqueMeasurementsPerLesion(
      DEFAULT_PLAQUE_MEASUREMENT_VALUES_PER_LESION
    );
    setLoading(true);
    fetchPlaqueMeasurementsLesionLength(
      patientID,
      runID,
      hoverVessel,
      hoverLesionId,
      versionHead
    )
      .then((res) => {
        res && setMeasurementsLesionLength(res);
      })
      .catch(() => {
        setMeasurementsLesionLength(DEFAULT_MEASUREMENT_LESION_LENGTH);
      });

    fetchPlaqueMeasurementsPerLesion(
      patientID,
      runID,
      hoverVessel,
      hoverLesionId,
      versionHead
    )
      .then((res: PlaqueMeasurementsPerLesion) => {
        let plaqueVolume: PlaqueCategories = DEFAULT_PLAQUE_VALUES;
        let plaqueRatio: PlaqueCategoriesRatio = DEFAULT_PLAQUE_VALUES_RATIO;
        forEach(res.volume, (value, key) => {
          plaqueVolume[key as keyof PlaqueCategories] = Number(value).toFixed(
            2
          );
        });
        forEach(res.ratio, (value, key) => {
          plaqueRatio[key as keyof PlaqueCategoriesRatio] = (
            Number(value) * 100
          ).toFixed(2);
        });
        res &&
          setPlaqueMeasurementsPerLesion({
            volume: plaqueVolume,
            ratio: plaqueRatio,
          });
        setLoading(false);
      })
      .catch(() => {
        setPlaqueMeasurementsPerLesion(
          DEFAULT_PLAQUE_MEASUREMENT_VALUES_PER_LESION
        );
        setLoading(false);
      });
  }, [
    hoverLesionId,
    hoverVessel,
    patientID,
    runID,
    //fetchingReport is used as a flag for editing disease
    //adding it to dependencies because measurements should be updated when disease is edited
    fetchingReport,
  ]);

  useEffect(() => {
    if (!hoverVessel || !lesionData || !hoverSliceIdx || !vesselData) return;
    const sliceLesionMapping = vesselData[hoverVessel].slice_to_lesion_mapping;
    const vesselLesionData = lesionData[hoverVessel];
    const vesselLesions = Object.entries(vesselLesionData || {});
    if (Object.keys(vesselLesions).length === 0) {
      setLesionStenosis('0%');
      setRiskLevel('minimal');
      setVpType(null);
      setHoverLesionId(null);
      setPlaqueComp('None');
      return;
    }

    const sliceLesionIds = sliceLesionMapping[hoverSliceIdx];

    if (sliceLesionIds && sliceLesionIds.length > 0) {
      const priorityLesionId = sliceLesionMapping[hoverSliceIdx][0];
      const priorityLesionData = vesselLesionData[priorityLesionId];
      setLesionStenosis(priorityLesionData.stenosis_max);
      setHoverLesionId(priorityLesionId);
      // Calculate stenosis risk level
      const rangeMin = parseInt(priorityLesionData.stenosis_max.split('-')[0]);
      const lesionRisk = getStenosisRiskLevel(isNaN(rangeMin) ? 0 : rangeMin);
      setRiskLevel(lesionRisk);
      // Display vulnerable plaque markers
      const vpMarkers: React.SetStateAction<string[] | null> = [];
      Object.entries(priorityLesionData.vp_biomarker_counts).forEach(
        ([key, value]) => {
          if (value > 0) {
            vpMarkers.push(key);
          }
        }
      );
      setVpType(vpMarkers);
      // Display Plaque Composition
      const thisComp = Object.entries(PLAQUE).find(
        ([key, value]) => key === priorityLesionData.plaque_composition
      );
      if (thisComp && thisComp[0] !== 'none') {
        setPlaqueComp(thisComp && thisComp[1]);
      }
    } else {
      setLesionStenosis('0%');
      setRiskLevel('minimal');
      setVpType(null);
      setHoverLesionId(null);
      setPlaqueComp('None');
    }
  }, [hoverSliceIdx, hoverVessel, lesionData, vesselData]);

  return (
    <div
      className={cn('slice-details', {
        'slice-details--large': plaqueComp,
      })}
    >
      {showHoverData && (
        <div className="slice-details__wrapper">
          <div className="slice-detail__section">
            <div>
              {' '}
              <div className="slice-details__vessel-slice">
                {`${vessel} #${adjustedSliceIdx}`}
              </div>
              {hoverLesionId && (
                <div className="slice-details__vessel-slice">{`LESION #${hoverLesionId}`}</div>
              )}
            </div>
            {loading && displayMeasurements && <Loader inline />}
          </div>
          <div className="slice-details__grid">
            <div
              className={cn(
                `slice-details__stenosis--risk-${riskLevel}`,
                !plaqueComp || (vpType && vpType.length == 0)
                  ? 'slice-details__measurementPerLesion'
                  : 'slice-details__stenosis'
              )}
            >
              <div className="slice-details__stenosis-value">
                {lesionStenosis}
              </div>
              <div className="slice-details__stenosis-text">
                Maximum Stenosis
              </div>
            </div>
            {vpType && vpType.length > 0 && (
              <div
                className={cn(
                  'slice-details__stenosis--vp-marker',
                  !plaqueComp && vpType
                    ? 'slice-details__measurementPerLesion'
                    : 'slice-details__stenosis'
                )}
              >
                <div className="slice-details__stenosis-value">
                  {vpType.join(', ').toUpperCase()}
                </div>
                <div className="slice-details__stenosis-text">
                  Vulnerable Plaque
                </div>
              </div>
            )}
            {plaqueComp && (
              <div
                className={cn(
                  'slice-details__stenosis--vp-marker',
                  plaqueComp && !vpType
                    ? 'slice-details__measurementPerLesion'
                    : 'slice-details__stenosis'
                )}
              >
                <div className="slice-details__stenosis-value">
                  {plaqueComp}
                </div>
                <div className="slice-details__stenosis-text">
                  Plaque composition
                </div>
              </div>
            )}
            {displayMeasurements && (
              <>
                <div className="slice-details__measurementPerLesion slice-details__stenosis--vp-marker">
                  <div className="slice-details__stenosis-value">
                    {plaqueMeasurementsPerLesion.volume.total
                      ? `${plaqueMeasurementsPerLesion.volume.total + ' mm²'}`
                      : '-'}
                  </div>
                  <div className="slice-details__stenosis-text">
                    Total Plaque Volume
                  </div>
                </div>
                <div className="slice-details__measurementPerLesion slice-details__stenosis--vp-marker">
                  <div className="slice-details__stenosis-value">
                    {plaqueMeasurementsPerLesion.volume.ca &&
                      !plaqueMeasurementsPerLesion.ratio.ca && (
                        <span>
                          {plaqueMeasurementsPerLesion.volume.ca} mm² (-%)
                        </span>
                      )}

                    {!plaqueMeasurementsPerLesion.volume.ca &&
                      plaqueMeasurementsPerLesion.ratio.ca && (
                        <span>
                          -mm² ({plaqueMeasurementsPerLesion.ratio.ca}%)
                        </span>
                      )}

                    {plaqueMeasurementsPerLesion.volume.ca &&
                    plaqueMeasurementsPerLesion.ratio.ca ? (
                      <span>
                        {plaqueMeasurementsPerLesion.volume.ca} mm² (
                        {plaqueMeasurementsPerLesion.ratio.ca}%)
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                  <div className="slice-details__stenosis-text">
                    Calcified Plaque Volume (Ratio)
                  </div>
                </div>
                <div className="slice-details__measurementPerLesion slice-details__stenosis--vp-marker">
                  <div className="slice-details__stenosis-value">
                    {plaqueMeasurementsPerLesion.volume.nca &&
                      !plaqueMeasurementsPerLesion.ratio.nca && (
                        <span>
                          {plaqueMeasurementsPerLesion.volume.nca} mm² (-%)
                        </span>
                      )}

                    {!plaqueMeasurementsPerLesion.volume.nca &&
                      plaqueMeasurementsPerLesion.ratio.nca && (
                        <span>
                          -mm² ({plaqueMeasurementsPerLesion.ratio.nca}%)
                        </span>
                      )}

                    {plaqueMeasurementsPerLesion.volume.nca &&
                    plaqueMeasurementsPerLesion.ratio.nca ? (
                      <span>
                        {plaqueMeasurementsPerLesion.volume.nca} mm² (
                        {plaqueMeasurementsPerLesion.ratio.nca}%)
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                  <div className="slice-details__stenosis-text">
                    Non-calcified Plaque Volume (Ratio)
                  </div>
                </div>
                <div className="slice-details__measurementPerLesion slice-details__stenosis--vp-marker">
                  <div className="slice-details__stenosis-value">
                    {plaqueMeasurementsPerLesion.volume.lap &&
                      !plaqueMeasurementsPerLesion.ratio.lap && (
                        <span>
                          {plaqueMeasurementsPerLesion.volume.lap} mm² (-%)
                        </span>
                      )}

                    {!plaqueMeasurementsPerLesion.volume.lap &&
                      plaqueMeasurementsPerLesion.ratio.lap && (
                        <span>
                          -mm² ({plaqueMeasurementsPerLesion.ratio.lap}%)
                        </span>
                      )}

                    {plaqueMeasurementsPerLesion.volume.lap &&
                    plaqueMeasurementsPerLesion.ratio.lap ? (
                      <span>
                        {plaqueMeasurementsPerLesion.volume.lap} mm² (
                        {plaqueMeasurementsPerLesion.ratio.lap}%)
                      </span>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                  <div className="slice-details__stenosis-text">
                    LP Volume (Ratio)
                  </div>
                </div>
                <div className="slice-details__measurementPerLesion slice-details__stenosis--vp-marker">
                  <div className="slice-details__stenosis-value">
                    {measurementsLesionLength
                      ? `${measurementsLesionLength + ' mm²'}`
                      : '-'}
                  </div>
                  <div className="slice-details__stenosis-text">
                    Lesion length
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SliceDetails;
