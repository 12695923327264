import React, {
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { DEFAULT_DRAG_AND_DROP_STATE } from './constants';
import { dragAndDropReducer } from './reducer';
import { DragAndDropContextValue } from './types';

const DragAndDropContext = React.createContext<
  DragAndDropContextValue | undefined
>(undefined);

DragAndDropContext.displayName = 'DragAndDropContext';

interface Props {
  children: ReactNode;
}

export function DragAndDropProvider({ children }: Props): ReactElement<Props> {
  const [dragAndDropState, dispatchDragAndDropAction] = useReducer(
    dragAndDropReducer,
    DEFAULT_DRAG_AND_DROP_STATE
  );

  const dragAndDropContextValue: DragAndDropContextValue = useMemo(
    () => ({
      state: dragAndDropState,
      dispatch: dispatchDragAndDropAction,
    }),
    [dragAndDropState, dispatchDragAndDropAction]
  );

  return (
    <DragAndDropContext.Provider value={dragAndDropContextValue}>
      {children}
    </DragAndDropContext.Provider>
  );
}

/**
 * Hook to access the DragAndDropContext
 * @returns {DragAndDropContextValue} DragAndDropContextValue
 */
export function useDragAndDropContext(): DragAndDropContextValue {
  const context = useContext(DragAndDropContext);
  if (context === undefined) {
    throw new Error(
      'useDragAndDropContext must be used within a DragAndDropProvider.'
    );
  }
  return context;
}
