import { throttle } from 'lodash';
import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import * as PIXI from 'pixi.js-legacy';
import PropTypes from 'prop-types';
import vtkGenericRenderWindow from 'vtk.js/Sources/Rendering/Misc/GenericRenderWindow';
import vtkWidgetManager from 'vtk.js/Sources/Widgets/Core/WidgetManager';
import { uuidv4 } from '../../../../utils/shared';
import { Centerline } from './Centerline';
import { Crosshairs } from './Crosshairs';
import { InteractionOperations } from './Manipulators/customBase';
// Load the rendering pieces we want to use (for both WebGL and WebGPU)
import 'vtk.js/Sources/Rendering/Profiles/Volume';
import { vec2, vec3 } from 'gl-matrix';
import {
  getSliceFromPoint,
  moveCameraToSlice,
  setWindowLevels,
  worldToDisplay,
  displayToWorld,
} from './Utils';
import vtkInteractorStyleRotatableMPRCrosshairs, {
  operations,
} from './vtkInteractorStyleRotatableMPRCrosshairs';

/**
 * Axial = 0
 * Sagittal = 1
 * Coronal = 2
 */
function getDefaultCrosshairWorldAxes() {
  return [
    [0, 0, 1],
    [1, 0, 0],
    [0, 1, 0],
  ];
}

/**
 * Given the viewType get the index of the axis cooresponding to it's default viewUp vector.
 * If the value is negative it means the axis vector must be inverted.
 */
function getViewUpAxis(viewType) {
  switch (viewType) {
    // Axial View
    case 0:
      return -2;
    // Sagittal View
    case 1:
      return 0;
    // Coronal View.
    case 2:
      return 0;
    // Range error.
    default:
      break;
  }
}

export default class View2D extends Component {
  static propTypes = {
    // The positional index of this view in the layout of views.
    viewIndex: PropTypes.number.isRequired,
    // The default orientation of this view: 0 = Axial, 1 = Sagittal, 2 = Coronal.
    viewType: PropTypes.number.isRequired,
    // The array of view apis.
    apis: PropTypes.array.isRequired,
    // An array with a single volume.
    volumes: PropTypes.array.isRequired,
    // The world position of the crosshairs center [X, Y, Z].
    crosshairWorldPosition: PropTypes.array.isRequired,
    // The world orientation of the three axes as unit vectors [][X, Y, Z].
    crosshairWorldAxes: PropTypes.array.isRequired,
    // Link the crosshairs position across series?
    linkCrosshairs: PropTypes.bool,
    // The last world position of the crosshairs center [X, Y, Z] (as a useRef reference) (used to propagate the position across crosshairs).
    lastCrosshairWorldPositionRef: PropTypes.any,
    // The width of each scan slice (in mm), this is only used when rendering the centerline.
    scanThickness: PropTypes.number,
    // The distance through which the ray should be cast when rendering (in mm).
    // This is in addition to the thickness of the actual scan.
    renderThickness: PropTypes.number,
    // The type of projection to use when rendering the volume:
    // 0 Composite
    // 1 MIP
    // 2 MinIP
    // 3 AvgIP
    blendMode: PropTypes.number,
    // The current { windowWidth: number, windowCenter: number }
    windowLevels: PropTypes.any,
    // The array centerline points [][X, Y, Z].
    centerline: PropTypes.array,
    // The (optional) centerline color.
    centerlineColor: PropTypes.string,
    // The (optional) centerline width.
    centerlineWidth: PropTypes.number,
    // Callback to update the values for the conversion from pixels to millimetres to be used by the measurement tool.
    // ({ mmPerPixelX: number, mmPerPixelY: number}) = > void
    onMmPerPixelChanged: PropTypes.func,
    // Optional override for the default [Axial Colour, Sagittal Colour, Coronal Colour] colours.
    axisColours: PropTypes.array,
    // Optional distance away from the crosshairs center to place the rotation handles
    // (as a proportion of the minimum view dimension) - this defaults to 3/8.
    rotateHandleDistance: PropTypes.number,
    // Show the crosshairs on the view? (NOTE: This is required for many interactions to occur)
    showCrosshairs: PropTypes.bool,
    // Show the centerline on the view?
    showCenterline: PropTypes.bool,
    // Callback for when the crosshairs have been moved on this view.
    // (viewIndex: number) => void
    onCrosshairsMoved: PropTypes.func,
    // Callback for when the crosshairs have been adjusted on this view (moved or rotated).
    // (viewIndex: number) => void
    onCrosshairsAdjusted: PropTypes.func,
    // Callback for when the window levels have been changed on this view:
    // (viewIndex: number, windowLevels: { windowWidth: number, windowCenter: number }) => void
    onWindowLevelsChanged: PropTypes.func,
    // Callback for when the button was double clicked on this view:
    // (button: number, viewIndex: number) => void
    onDoubleClick: PropTypes.func,
    // Callback to get the position initial to start dragging the measurement tool.
    onMouseDown: PropTypes.func,
    // Callback to draw the measurement tool.
    onMouseMove: PropTypes.func,
    // Callback to end the measurement tool.
    onMouseUp: PropTypes.func,
    // Callback to force the ContrastView to render: () => void.
    onForceRender: PropTypes.func,
    // Callback to init pixi renderer for measurement tool.
    onReady: PropTypes.func,
    onZoom: PropTypes.func,
    isMeasurementMode: PropTypes.bool,
  };

  static defaultProps = {
    // Default scan thickness.
    scanThickness: 0.0,
    // Default render thickness.
    renderThickness: 0.0,
    // Default to MIP (maximum intensity projection).
    blendMode: 1,
    // Default the windowLevels to 'let vtk decide'.
    windowLevels: undefined,
    // Default to showing the crosshairs.
    showCrosshairs: true,
    // Default to not showing the centerline.
    showCenterline: true,
  };

  constructor(props) {
    super(props);

    this.genericRenderWindow = null;
    this.widgetManager = vtkWidgetManager.newInstance();
    this.widgetManager.disablePicking();
    this.container = React.createRef();
    // We remember the width and height of the last render so we know if we need to resize the buffer when we render next.
    this.containerDims = { width: undefined, height: undefined };
    this.centerlineRef = React.createRef();
    this.crosshairsRef = React.createRef();

    this.interactorStyleSubs = [];
    this.apiProperties = {};
    // This is used to ensure the view and crosshairs render is synced in the throttle function.
    this.pendingRefreshView = false;
    this.pendingRefreshCrosshairs = false;
    this.state = {
      containerClassName: 'contrast-view-default',
    };
    this.appRef = null;
    this.containerPIXI = null;
    this.defaultScale = 1.0;
    this.size = { width: 0, height: 0 };
    // A string defining the current error preventing vtk from drawing a vaild view, or undefined if there is no error.
    this.vtkError = undefined;
    // True if the WebGL context is currently lost, false otherwise.
    this.lostContext = false;
  }

  setHolderSize = () => {
    if (this.container.current) {
      const parent = this.container.current.parentNode;
      if (!parent) return;
      this.size.width = parent.clientWidth;
      this.size.height = parent.clientHeight || this.size.width;
    }
  };
  /**
   * Reset the crosshair position and orientation.
   */
  resetCrosshairs = () => {
    if (this.props.volumes?.length > 0) {
      const volume = this.props.volumes[0];
      const {
        crosshairWorldPosition,
        crosshairWorldAxes,
        lastCrosshairWorldPositionRef,
        linkCrosshairs,
      } = this.props;

      // Move the crosshairs to the current linked position?
      if (linkCrosshairs && lastCrosshairWorldPositionRef?.current) {
        crosshairWorldPosition[0] = lastCrosshairWorldPositionRef.current[0];
        crosshairWorldPosition[1] = lastCrosshairWorldPositionRef.current[1];
        crosshairWorldPosition[2] = lastCrosshairWorldPositionRef.current[2];
      }
      // Otherwise move them to the center of the volume.
      else {
        // Get the volume extends along each axis.
        const volumeXRange = volume.getXRange();
        const volumeYRange = volume.getYRange();
        const volumeZRange = volume.getZRange();

        // Manually reset the camera position and orientation.
        const volumeCenter = [
          (volumeXRange[0] + volumeXRange[1]) / 2,
          (volumeYRange[0] + volumeYRange[1]) / 2,
          (volumeZRange[0] + volumeZRange[1]) / 2,
        ];

        // Update the crosshair position (by mutation).
        crosshairWorldPosition[0] = volumeCenter[0];
        crosshairWorldPosition[1] = volumeCenter[1];
        crosshairWorldPosition[2] = volumeCenter[2];
      }

      // Update the current linked position.
      if (lastCrosshairWorldPositionRef) {
        lastCrosshairWorldPositionRef.current = [...crosshairWorldPosition];
      }

      // Update the crosshair axes (by mutation).
      crosshairWorldAxes[0] = getDefaultCrosshairWorldAxes()[0];
      crosshairWorldAxes[1] = getDefaultCrosshairWorldAxes()[1];
      crosshairWorldAxes[2] = getDefaultCrosshairWorldAxes()[2];
    }
  };

  /**
   * The crosshairWorldPosition or crosshairWorldAxis values have changed and we need to update the camera etc.
   */
  repositionToCrosshairs = (linkRotation) => {
    if (this.props.volumes?.length > 0) {
      const { crosshairWorldPosition, crosshairWorldAxes } = this.props;
      const camera = this.genericRenderWindow
        .getRenderWindow()
        .getInteractor()
        .getCurrentRenderer()
        .getActiveCamera();
      const slice = getSliceFromPoint(camera, crosshairWorldPosition);

      moveCameraToSlice(camera, slice);

      if (linkRotation) {
        const offset = -camera.getDistance();
        const viewType = this.props.viewType;

        // Calculate the correct camera direction and orientation based on the current crosshairs alignment.
        const viewDirection = crosshairWorldAxes[viewType];
        const viewUpIndex = getViewUpAxis(viewType);
        // Make sure we make a copy of the axis before modifying it.
        const viewUp = [...crosshairWorldAxes[Math.abs(viewUpIndex)]];
        if (viewUpIndex < 0) {
          viewUp[0] *= -1;
          viewUp[1] *= -1;
          viewUp[2] *= -1;
        }

        const cameraPos = [
          crosshairWorldPosition[0] + offset * viewDirection[0],
          crosshairWorldPosition[1] + offset * viewDirection[1],
          crosshairWorldPosition[2] + offset * viewDirection[2],
        ];
        camera.setPosition(cameraPos[0], cameraPos[1], cameraPos[2]);
        camera.setFocalPoint(
          crosshairWorldPosition[0],
          crosshairWorldPosition[1],
          crosshairWorldPosition[2]
        );
        camera.setViewUp(viewUp[0], viewUp[1], viewUp[2]);
      }

      this.refreshView(true, true);
    }
  };

  /**
   * Reset the camera position, focal point, viewUp, parallelScale and the istyle renderThickness.
   *
   * The camera parallel projection scale is set so that the volume fits snugly inside (or around) the view.
   * @param cropToFit If true the 'left and right' or 'top and bottom' of the volume will be cropped as required
   * to fit it perfectly over the view with no empty space showing. If false the 'left and right' or 'top and bottom'
   * of the view will have empty space so that the volume fits perfectly inside the view.
   */
  resetView = (cropToFit = false) => {
    const { crosshairWorldPosition, crosshairWorldAxes } = this.props;
    // Get the volume, camera, openGLRenderWindow, and interactor style (istyle).
    const volume = this.props.volumes[0];
    const camera = this.genericRenderWindow
      .getRenderWindow()
      .getInteractor()
      .getCurrentRenderer()
      .getActiveCamera();
    const openGLRenderWindow = this.genericRenderWindow.getOpenGLRenderWindow();
    const istyle = this.genericRenderWindow
      .getInteractor()
      .getInteractorStyle();
    const viewType = this.props.viewType;

    // Calculate the correct camera direction and orientation based on the current crosshairs alignment.
    const viewDirection = crosshairWorldAxes[viewType];
    const viewUpIndex = getViewUpAxis(viewType);
    // Make sure we make a copy of the axis before modifying it.
    const viewUp = [...crosshairWorldAxes[Math.abs(viewUpIndex)]];
    if (viewUpIndex < 0) {
      viewUp[0] *= -1;
      viewUp[1] *= -1;
      viewUp[2] *= -1;
    }

    const offset = -500;
    const cameraPos = [
      crosshairWorldPosition[0] + offset * viewDirection[0],
      crosshairWorldPosition[1] + offset * viewDirection[1],
      crosshairWorldPosition[2] + offset * viewDirection[2],
    ];
    camera.setPosition(cameraPos[0], cameraPos[1], cameraPos[2]);
    camera.setFocalPoint(
      crosshairWorldPosition[0],
      crosshairWorldPosition[1],
      crosshairWorldPosition[2]
    );
    camera.setViewUp(viewUp[0], viewUp[1], viewUp[2]);

    // Get the volume extends along each axis.
    const volumeXRange = volume.getXRange();
    const volumeYRange = volume.getYRange();
    const volumeZRange = volume.getZRange();

    // Get the volume size that will be aligned with the view's X and Y and it's aspect ratio.
    var volumeSize = [1, 1];
    switch (viewType) {
      case 0:
        volumeSize = [
          Math.abs(volumeXRange[1] - volumeXRange[0]),
          Math.abs(volumeYRange[1] - volumeYRange[0]),
        ];
        break;
      case 1:
        volumeSize = [
          Math.abs(volumeXRange[1] - volumeXRange[0]),
          Math.abs(volumeZRange[1] - volumeZRange[0]),
        ];
        break;
      case 2:
        volumeSize = [
          Math.abs(volumeYRange[1] - volumeYRange[0]),
          Math.abs(volumeZRange[1] - volumeZRange[0]),
        ];
        break;
      default:
        break;
    }
    const volumeAspectRatio = volumeSize[0] / volumeSize[1];

    // Get the openGL render size (this is also the view size) and aspect ratio.
    const openGLRenderSize = openGLRenderWindow.getSize();
    const openGLAspectRatio = openGLRenderSize[0] / openGLRenderSize[1];
    let renderHeight = 1;
    // Crop the 'top and bottom' or 'left and right' of the volume to fit it perfectly over the entire view?
    if (cropToFit) {
      // Crop 'left and right' because the volume's aspect ratio is wider than the window's.
      if (volumeAspectRatio >= openGLAspectRatio) {
        renderHeight = volumeSize[1];
      }
      // Crop 'top and bottom' because the volume's aspect ratio is taller than the window's.
      else {
        renderHeight = volumeSize[0] / openGLAspectRatio;
      }
    }
    // Pad around the 'top and bottom' or 'left and right' of the volume to fit it perfectly inside the entire view?
    else {
      // Pad the 'top and bottom' because the volume's aspect ratio is wider than the window's.
      if (volumeAspectRatio >= openGLAspectRatio) {
        renderHeight = volumeSize[0] / openGLAspectRatio;
      }
      // Pad the 'left and right' because the volume's aspect ratio is taller than the window's.
      else {
        renderHeight = volumeSize[1];
      }
    }
    if (this.containerPIXI && this.appRef.screen) {
      const openGLRenderWindow = this.genericRenderWindow.getOpenGLRenderWindow();
      const openGLRenderSize = openGLRenderWindow.getSize();
      this.containerPIXI.width = openGLRenderSize[0];
      this.containerPIXI.height = openGLRenderSize[1];
      const scale = this.getScale();
      this.containerPIXI.scale.set(scale, scale);
    }
    // Set the parallel projection scale.
    camera.setParallelScale(renderHeight / 2);

    // Set the model.renderThickness and call camera.setThicknessFromFocalPoint(renderThickness).
    istyle.setRenderThickness(this.props.renderThickness);

    // Refresh the view and the crosshairs.
    this.refreshViewImmediately(true, true);

    // Update the mm per pixel measurement.
    this.updateMmPerPixel();

    // Call onZoom to update the measurment layer sizing etc.
    if (this.props.onZoom) {
      this.props.onZoom();
    }
  };

  webGLContextLost = (event) => {
    event.preventDefault();
    this.vtkError = 'vtk error: context lost';
    this.lostContext = true;
    // Cleanup and try to reinitialize.
    this.cleanUp();
    this.initialise();
    // Force the ContrastView to render so we can show the error state if required.
    if (this.props.onForceRender) this.props.onForceRender();
  };

  webGLContextRestored = (event) => {
    this.vtkError = undefined;
    this.lostContext = false;
  };

  initialise = () => {
    // Tracking ID to tie emitted events to this component
    const uid = uuidv4();

    try {
      this.genericRenderWindow = vtkGenericRenderWindow.newInstance({
        background: [0, 0, 0],
        // Disable the default window resize listener as we need to handle this ourselves to:
        // A) Ensure the SVG crosshairs are resized with the view buffer.
        // B) Ensure the correct BlendMode is set before the resize() function calls render().
        listenWindowResize: false,
      });
    } catch (error) {
      this.vtkError = 'vtk error: failed to create render window';
      return;
    }

    // Abort immediately if the genericRenderWindow creation failed.
    if (!this.genericRenderWindow) {
      this.vtkError = 'vtk error: failed to create render window';
      return;
    }

    this.genericRenderWindow.setContainer(this.container.current);
    this.renderer = this.genericRenderWindow.getRenderer();
    this.renderWindow = this.genericRenderWindow.getRenderWindow();
    const openGLRenderWindow = this.genericRenderWindow.getOpenGLRenderWindow();

    // update view node tree so that vtkOpenGLHardwareSelector can access
    // the vtkOpenGLRenderer instance.
    openGLRenderWindow.buildPass(true);

    // Create the interactor style.
    const istyle = vtkInteractorStyleRotatableMPRCrosshairs.newInstance();
    istyle.setOnInteractionOperation(this.onInteractionOperation);

    /**
     * Note: The contents of this Object are
     * considered part of the API contract
     * we make with consumers of this component.
     */
    const api = {
      uid, // Tracking id available on `api`
      genericRenderWindow: this.genericRenderWindow,
      widgetManager: this.widgetManager,
      container: this.container.current,
      volumes: this.props.volumes,
      _component: this,
      // Get the current error message (or undefined if everything is running as expected).
      getError: this.getError,
      getViewUp: this.getViewUp,
      getCameraDirection: this.getCameraDirection,
      resetCrosshairs: this.resetCrosshairs,
      // The crosshairWorldPosition or crosshairWorldAxis values have changed and we need to update the camera etc.
      repositionToCrosshairs: this.repositionToCrosshairs,
      resetView: this.resetView,
      refreshView: this.refreshView,
      refreshViewImmediately: this.refreshViewImmediately,
      getApiIndex: istyle.getApiIndex,
      setApiIndex: istyle.setApiIndex,
      // Get the viewIndex out of the istyle configuration.
      getViewIndex: istyle.getViewIndex,
      // Get the viewType out of the istyle configuration.
      getViewType: istyle.getViewType,
      updateMmPerPixel: this.updateMmPerPixel,
      // Convert a 2D display coordinate to a 3D world coordinate.
      displayToWorld: (pos) => displayToWorld(this.renderer, pos),
      // Convert a 3D world coordinate to a 2D display coordinate.
      worldToDisplay: (pos) => worldToDisplay(this.renderer, pos),
      get: this.getApiProperty,
      set: this.setApiProperty,
      type: 'VIEW2D',
    };

    // Add the api to the apis array and remember its position.
    const apiIndex = this.props.apis.length;
    this.props.apis[apiIndex] = api;

    // Set the interactor style.
    this.setInteractorStyle({
      istyle,
      configuration: {
        apis: this.props.apis,
        apiIndex,
        viewIndex: this.props.viewIndex,
        viewType: this.props.viewType,
      },
    });

    // Set the initial callbacks.
    this.updateCallbacks();

    // Set the desired refresh rate for the interactor to 30fps.
    api.genericRenderWindow.getInteractor().setDesiredUpdateRate(30.0);

    // Add the new volume.
    if (this.props.volumes?.length) {
      this.props.volumes.forEach((volume) => {
        // Set the initial windowLevels.
        setWindowLevels(this.props.windowLevels, volume);
        this.renderer.addVolume(volume);
      });
    }
    istyle.setVolumeActor(this.props.volumes[0]);

    this.renderer.getActiveCamera().setParallelProjection(true);
    this.renderer.resetCamera();

    // If the document is resized we need to prod vtk to resize its WebGL buffers and SVG overlay scale.
    window.addEventListener('resize', this.resizeView);

    // Set crosshairs position and axes directions for the view.
    this.apiProperties.crosshairWorldPosition = this.props.crosshairWorldPosition;
    this.apiProperties.crosshairWorldAxes = this.props.crosshairWorldAxes;
    // Only initialize the crosshair axes once, we know we need to if this is the first api added to the apis array.
    if (apiIndex === 0) {
      this.resetCrosshairs();
    }

    // Ensure the openGLRenderWindow has calculated its buffer size before calling resetView.
    this.genericRenderWindow.resize();

    // If we have got here we can clear the lost context and error flags.
    this.vtkError = undefined;
    this.lostContext = false;

    // Setup the camera position, focal point, slab thickness etc. Ensure the volume is 'zoomed to fit' the viewport.
    this.resetView();

    // Update the millimetres per pixel measurement values.
    this.updateMmPerPixel();

    if (openGLRenderWindow) {
      openGLRenderWindow
        .getCanvas()
        .addEventListener('webglcontextlost', this.webGLContextLost);

      openGLRenderWindow
        .getCanvas()
        .addEventListener('webglcontextrestored', this.webGLContextRestored);
    }

    // Init PIXI renderer.
    if (this.container.current && this.props.onReady) {
      const dims = this.container.current.getBoundingClientRect();
      this.appRef = new PIXI.Application({
        width: dims.width,
        height: dims.height,
        backgroundColor: 0x000000,
        backgroundAlpha: 0, // Fully transparent background.
        preserveDrawingBuffer: true, // this allows for screenshots but could slow the renderer down slightly.
        resizeTo: this.container.current,
        antialias: true,
        forceCanvas: true,
      });
      this.container.current.appendChild(this.appRef.view);
      this.appRef.view.style.position = 'absolute';
      this.appRef.view.style.top = '0px';

      // create the main container (used for scaling and dragging)
      this.containerPIXI = new PIXI.Container();
      this.containerPIXI.interactive = true;
      this.appRef.stage.addChild(this.containerPIXI);

      this.setHolderSize();
      this.defaultScale = this.getScale();

      if (this.containerPIXI && this.appRef && this.appRef.screen) {
        const container = this.containerPIXI;
        const app = this.appRef.screen;
        const openGLRenderWindow = this.genericRenderWindow.getOpenGLRenderWindow();
        const openGLRenderSize = openGLRenderWindow.getSize();
        this.containerPIXI.width = openGLRenderSize[0];
        this.containerPIXI.height = openGLRenderSize[1];
        this.containerPIXI.scale.set(this.defaultScale, this.defaultScale);
      }

      this.props.onReady({
        app: this.appRef,
        container: this.containerPIXI,
        holder: this.container.current,
        defaultScale: this.defaultScale,
      });

      this.centerContainer();
    }
  };

  componentDidMount() {
    this.initialise();
  }

  // Update the millimetres per pixel measurement values.
  updateMmPerPixel = () => {
    if (this.props.onMmPerPixelChanged) {
      // Get the openGL render size (this is also the view size).
      const openGLRenderWindow = this.genericRenderWindow.getOpenGLRenderWindow();
      const openGLRenderSize = openGLRenderWindow.getSize();

      // Get the current camera scale (this is the world distance rendered over the height of the viewport).
      const cameraScale = this.renderer.getActiveCamera().getParallelScale();

      // Calculate the mm per pixel based on the world distance over the height of the viewport and the height of the viewport in pixels.
      const mmPerPixel = (2.0 * cameraScale) / openGLRenderSize[1];
      const mmSpacing = { mmPerPixelX: mmPerPixel, mmPerPixelY: mmPerPixel };

      // Send the new values via the callback.
      this.props.onMmPerPixelChanged(mmSpacing);
    }
  };

  /**
   * Get the current error message (or undefined if everything is running as expected).
   * @return string | undefined
   */
  getError = () => {
    if (this.vtkError) return this.vtkError;
    if (this.lostContext) return 'vtk error: context currently lost';
    // Get the shader cache from the OpenGL render window.
    const openGLRenderWindow = this.genericRenderWindow?.getOpenGLRenderWindow();
    const shaderCache = openGLRenderWindow?.getShaderCache?.();
    // The last bound shader is the one that rendered (or tried to render) the volume.
    const lastShaderBound = shaderCache?.getLastShaderBound?.();
    if (lastShaderBound?.getCompiled) {
      // Check the shader compiled (we have a patch on vtk_js that stops if from crashing if this has happened.
      if (!lastShaderBound.getCompiled()) {
        return 'vtk error: Shader not compiled';
      }
    } else {
      // NOTE: We need to actually mount the View2D before the shader will compile and bind, so the first pass will always come though here.
      // This could in theory result in a one frame flicker of the GPU warning message but it hasn't done so so far.
      return 'vtk error: No shader bound';
    }
    return undefined;
  };

  getViewUp = () => {
    const renderWindow = this.genericRenderWindow.getRenderWindow();
    const currentIStyle = renderWindow.getInteractor().getInteractorStyle();
    return currentIStyle.getViewUp();
  };

  /**
   * Get the vector for the camera direction of projection [X, Y, Z].
   */
  getCameraDirection = () => {
    const renderWindow = this.genericRenderWindow.getRenderWindow();
    const currentIStyle = renderWindow.getInteractor().getInteractorStyle();
    return currentIStyle.getCameraDirection();
  };

  getApiProperty = (propertyName) => {
    return this.apiProperties[propertyName];
  };

  setApiProperty = (propertyName, value) => {
    this.apiProperties[propertyName] = value;
  };

  /**
   * Redraw the view (ie vtk volume) and / or the crosshairs widget.
   * @param refreshView TRUE to redraw the vtk volume.
   * @param refreshCrosshairs TRUE to redraw the SVG crosshairs.
   */
  refreshViewImmediately = (refreshView, refreshCrosshairs) => {
    // Don't try to refresh the view if the context has been lost.
    if (this.lostContext) {
      return;
    }

    this.pendingRefreshView = false;
    this.pendingRefreshCrosshairs = false;
    // Check if the component has resized.
    let resizeBuffers = false;
    if (this.container.current) {
      const dims = this.container.current.getBoundingClientRect();
      if (
        dims.width !== this.containerDims.width ||
        dims.height !== this.containerDims.height
      ) {
        this.containerDims.width = dims.width;
        this.containerDims.height = dims.height;
        resizeBuffers = true;
        // Resize the renderer for measurement tools
        this.appRef && this.appRef.resize && this.appRef.resize();
      }
    }

    // Resize VTK's WebGL buffers?
    if (resizeBuffers) {
      // Force the BlendMode to be the one we want for this view prior to refreshing the view.
      const mapper =
        this.props.volumes?.length > 0
          ? this.props.volumes[0].getMapper()
          : undefined;
      if (mapper && mapper.getBlendMode() !== this.props.blendMode) {
        mapper.setBlendMode(this.props.blendMode);
      }
      // NOTE: This internally calls render for us, so we don't need to call it below even if refreshView is true.
      try {
        this.genericRenderWindow.resize();
      } catch (error) {
        this.vtkError = 'vtk error: resize window failed';
      }

      // Update the millimetres per pixel measurement values.
      this.updateMmPerPixel();
      if (this.containerPIXI) {
        // Get the size of the View2D in pixels.
        const openGLRenderWindow = this.genericRenderWindow?.getOpenGLRenderWindow();
        const openGLRenderSize = openGLRenderWindow.getSize();
        this.containerPIXI.width = openGLRenderSize[0];
        this.containerPIXI.height = openGLRenderSize[1];
        const scale = this.getScale();
        this.containerPIXI.scale.set(scale, scale);
      }
    }
    // Otherwise refresh the view?
    else if (refreshView) {
      // Force the BlendMode to be the one we want for this view prior to refreshing the view.
      const mapper =
        this.props.volumes?.length > 0
          ? this.props.volumes[0].getMapper()
          : undefined;
      if (mapper && mapper.getBlendMode() !== this.props.blendMode) {
        mapper.setBlendMode(this.props.blendMode);
      }
      try {
        this.genericRenderWindow.getRenderWindow().render();
      } catch (error) {
        this.vtkError = 'vtk error: render window failed';
      }
    }

    // Refresh the centerline?
    if (resizeBuffers || refreshView) {
      // Refresh the centerline rendered on the view.
      const openGLRenderWindow = this.genericRenderWindow?.getOpenGLRenderWindow();
      if (
        this.centerlineRef.current &&
        this.centerlineRef.current.refresh &&
        openGLRenderWindow
      ) {
        // Get the size of the View2D in pixels.
        const [width, height] = openGLRenderWindow.getSize();
        // Refresh the centerline.
        this.centerlineRef.current.refresh(
          width,
          height,
          this.renderer,
          this.props.scanThickness + this.props.renderThickness
        );
      }
      // TODO: This should probably be called from the actual zoom operation.
      const istyle = this.genericRenderWindow
        ?.getInteractor()
        ?.getInteractorStyle();
      if (
        istyle?.getInteractionOperation() === InteractionOperations.ZOOM &&
        this.props.onZoom
      ) {
        this.props.onZoom();
      }
    }

    // And finally: refresh the crosshairs?
    if (refreshCrosshairs) {
      // Refresh the crosshairs rendered on the view.
      const openGLRenderWindow = this.genericRenderWindow?.getOpenGLRenderWindow();
      if (
        this.crosshairsRef.current &&
        this.crosshairsRef.current.refresh &&
        openGLRenderWindow
      ) {
        // Get the size of the View2D in pixels.
        const [width, height] = openGLRenderWindow.getSize();
        // Refresh the crosshairs.
        const istyle = this.genericRenderWindow
          ?.getInteractor()
          ?.getInteractorStyle();
        const apiIndex = istyle?.getApiIndex();
        if (this.props.apis && apiIndex !== undefined) {
          this.crosshairsRef.current.refresh(
            width,
            height,
            this.props.apis[apiIndex],
            this.props.viewType
          );
        }
      }
      this.centerContainer();
    }
  };

  centerContainer = () => {
    //center containerPIXI on screen
    if (this.containerPIXI) {
      const openGLRenderWindow = this.genericRenderWindow?.getOpenGLRenderWindow();
      // Convert worldPos into display coordinates [X, Y].
      const istyle = this.genericRenderWindow
        ?.getInteractor()
        ?.getInteractorStyle();
      const apiIndex = istyle?.getApiIndex();
      const api = this.props.apis[apiIndex];
      const worldPos = api.get('crosshairWorldPosition');
      const point = worldToDisplay(
        api.genericRenderWindow.getRenderer(),
        worldPos
      );
      const [width, height] = openGLRenderWindow.getSize();
      if (this.containerPIXI) {
        const center = [point[0], height - point[1]];
        this.containerPIXI.position.set(
          center[0] / window.devicePixelRatio,
          center[1] / window.devicePixelRatio
        );
      }
    }
  };

  /**
   * Throttled function to refresh the view (ie render the volume).
   */
  throttledRefreshView = throttle(
    () =>
      this.refreshViewImmediately(
        this.pendingRefreshView,
        this.pendingRefreshCrosshairs
      ),
    1000 / 30, // Aim for roughly 30fps, we can change this statically or dynamically if 30fps is too optimistic or not optimistic enough.
    { leading: false, trailing: true }
  );

  /**
   * Redraw the view (ie vtk volume) and / or the crosshairs widget.
   * @param refreshView TRUE to redraw the vtk volume.
   * @param refreshCrosshairs TRUE to redraw the SVG crosshairs.
   */
  refreshView = (refreshView, refreshCrosshairs) => {
    // Update the things we want to refresh in the throttledRefreshView call.
    this.pendingRefreshView = this.pendingRefreshView || refreshView;
    this.pendingRefreshCrosshairs =
      this.pendingRefreshCrosshairs || refreshCrosshairs;

    // Request the refresh.
    // This used to just call throttledRefreshView but with the measuement tool handling its own drawing we need
    // to be able to make these calls happen instantly when in measurement mode. We throttled because the draw
    // calls can build up on each other and bog everything down. At least then measuring we are generally only
    // modifying one view at a time as rotate and slice change are disabled.
    if (this.props.isMeasurementMode) {
      this.refreshViewImmediately(
        this.pendingRefreshView,
        this.pendingRefreshCrosshairs
      );
    } else {
      this.throttledRefreshView();
    }
  };

  getScale = () => {
    // Get the size of the View2D in pixels.
    const openGLRenderWindow = this.genericRenderWindow.getOpenGLRenderWindow();
    const openGLRenderSize = openGLRenderWindow.getSize();
    // Get the aspect ratio of the image and the view.
    const initialAspectRatio = this.size.width / this.size.height;
    const viewAspectRatio = openGLRenderSize[0] / openGLRenderSize[1];

    let defaultScale = 1;
    if (initialAspectRatio > viewAspectRatio) {
      defaultScale = openGLRenderSize[1] / this.size.height;
    } else {
      defaultScale = openGLRenderSize[0] / this.size.width;
    }
    return defaultScale;
  };
  /**
   * Resize vtk's WebGL buffer and the SVG overlay scale to match the current component size.
   */
  resizeView = () => {
    // Refresh the view, crosshairs and update the buffer sizes.
    // NOTE: refreshView is already throttled.
    this.refreshView(true, true);
  };

  setInteractorStyle = ({ istyle, callbacks = {}, configuration = {} }) => {
    const { volumes } = this.props;
    const renderWindow = this.genericRenderWindow.getRenderWindow();

    // Unsubscribe from previous iStyle's callbacks.
    while (this.interactorStyleSubs.length) {
      this.interactorStyleSubs.pop().unsubscribe();
    }

    const interactor = renderWindow.getInteractor();
    interactor.setInteractorStyle(istyle);
    istyle.setInteractor(interactor);
    if (istyle.getVolumeActor() !== volumes[0]) {
      if (istyle.setRenderThickness) {
        istyle.setRenderThickness(this.props.renderThickness);
      }
      istyle.setVolumeActor(volumes[0]);
    }
    // Add appropriate callbacks
    Object.keys(callbacks).forEach((key) => {
      if (typeof istyle[key] === 'function') {
        const subscription = istyle[key](callbacks[key]);

        if (subscription && typeof subscription.unsubscribe === 'function') {
          this.interactorStyleSubs.push(subscription);
        }
      }
    });

    // Set Configuration
    if (configuration) {
      istyle.set(configuration);
    }
  };

  onCrosshairsMoved = () => {
    const {
      lastCrosshairWorldPositionRef,
      crosshairWorldPosition,
      onCrosshairsMoved,
    } = this.props;
    // Update the current linked position.
    if (lastCrosshairWorldPositionRef) {
      lastCrosshairWorldPositionRef.current = [...crosshairWorldPosition];
    }
    if (onCrosshairsMoved) {
      onCrosshairsMoved();
    }
  };

  /**
   * Update the callbacks in case any of them changed.
   */
  updateCallbacks() {
    const istyle = this.genericRenderWindow
      ?.getInteractor()
      ?.getInteractorStyle();
    if (istyle) {
      istyle.setOnCrosshairsMoved(this.onCrosshairsMoved);
      istyle.setOnCrosshairsAdjusted(this.props.onCrosshairsAdjusted);
      istyle.setOnWindowLevelsChanged(this.props.onWindowLevelsChanged);
      istyle.setOnDoubleClick(this.props.onDoubleClick);
      istyle.setMeasurementToggleActive(this.props.isMeasurementMode);
      istyle.setContainerPIXI(this.containerPIXI);
    }
  }

  /**
   * Call this whenever the interactionOperation or operation changes on the istyle.
   */
  onInteractionOperation = (interactionOperation, operation) => {
    if (this.props.isMeasurementMode) {
      this.state.containerClassName !== '' &&
        this.setState({ containerClassName: '' });
      return;
    }
    let containerClassName = 'contrast-view-default';
    let hover = false;
    let hoverRotate = false;
    const istyle = this.genericRenderWindow
      ?.getInteractor()
      ?.getInteractorStyle();
    const apiIndex = istyle?.getApiIndex();
    if (this.props.apis && apiIndex !== undefined) {
      const api = this.props.apis[apiIndex];
      // Ensure all the crosshair controls on the other line are shown as inactive..
      const lines = api.crosshairs.referenceLines;
      lines?.forEach((line) => {
        // The mouse is over the line?
        if (line.selected) hover = true;
        // The mouse is over the rotate handle?
        if (line.rotateHandles.selected) hoverRotate = true;
      });
    }
    switch (interactionOperation) {
      case InteractionOperations.MOVE_CROSSHAIRS:
        if (operation.type === operations.ROTATE_CROSSHAIRS) {
          containerClassName = 'contrast-view-grab';
        } else if (operation.type === operations.MOVE_CROSSHAIRS) {
          containerClassName = 'contrast-view-none';
        } else {
          containerClassName = 'contrast-view-dragging';
        }
        break;
      case InteractionOperations.PAN:
        containerClassName = 'contrast-view-dragging';
        break;
      case InteractionOperations.ZOOM:
      case InteractionOperations.SLICE:
      case InteractionOperations.SCROLL_SLICE:
        containerClassName = 'contrast-view-sliding-ns';
        break;
      case InteractionOperations.WINDOW_LEVEL:
      default:
        if (hover) {
          containerClassName = 'contrast-view-dragging';
        }
        if (hoverRotate) {
          containerClassName = 'contrast-view-grab';
        }
        break;
    }
    //this is used to remove the focus from the mipValue input
    const elements = document.querySelectorAll('.contrast-view-header__input');
    elements.forEach((elem) => elem.blur());

    // Only call setstate if the containerClassName actually changed.
    if (containerClassName !== this.state.containerClassName) {
      this.setState({ containerClassName });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // If the WebGL context is abort the update.
    if (this.lostContext) {
      return;
    }

    let refreshView = false;
    let refreshCrosshairs = false;

    // Clear the centerlineRef and crosshairsRef if these components aren't shown.
    if (prevProps.showCenterline !== this.props.showCenterline) {
      if (!this.props.showCenterline) {
        this.centerlineRef.current = null;
      }
      // NOTE: The centerline is associated with the view vs the crosshairs.
      refreshView = true;
    }
    if (prevProps.showCrosshairs !== this.props.showCrosshairs) {
      if (!this.props.showCrosshairs) {
        this.crosshairsRef.current = null;
      }
      refreshCrosshairs = true;
    }

    // Update the callbacks in case any of them changed.
    this.updateCallbacks();

    // Update the volumes added to the renderer if the volumes have changed.
    if (prevProps.volumes !== this.props.volumes) {
      // Remove all the old volumes (ie the volume).
      if (prevProps.volumes?.length) {
        prevProps.volumes.forEach(this.renderer.removeVolume);
      }

      // Add the new volumes.
      if (this.props.volumes?.length) {
        this.props.volumes.forEach((volume) => {
          // Check the new volumes are actually volumes.
          if (!volume.isA('vtkVolume')) {
            console.warn('Data to <Vtk2D> is not vtkVolume data');
          }

          // Set the initial windowLevels.
          setWindowLevels(this.props.windowLevels, volume);

          // Add the new volume.
          this.renderer.addVolume(volume);
        });

        // Resize the buffers and render the view and crosshairs.
        refreshView = true;
        refreshCrosshairs = true;
      }
    } else {
      // Update the render thickness?
      const istyle = this.genericRenderWindow
        ?.getRenderWindow()
        ?.getInteractor()
        ?.getInteractorStyle();
      if (istyle && istyle.getRenderThickness && istyle.setRenderThickness) {
        if (istyle.getRenderThickness() !== this.props.renderThickness) {
          istyle.setRenderThickness(this.props.renderThickness);
          refreshView = true;
        }
      }

      // Refresh the view if the blend mode was changed.
      if (prevProps.blendMode !== this.props.blendMode) {
        refreshView = true;
      }

      // Refresh the view if the windowLevels were changed.
      if (
        !isEqual(prevProps.windowLevels, this.props.windowLevels) &&
        this.props.volumes?.length
      ) {
        // Set the new windowLevels.
        setWindowLevels(this.props.windowLevels, this.props.volumes[0]);
        refreshView = true;
      }

      // Refresh the centerline if it changed.
      if (prevProps.centerline !== this.props.centerline) {
        // Technically we just need to refresh the centerline in this case but this is neater and lets it sync with other updates.
        refreshView = true;
      }
    }

    // Refresh the view and / or crosshairs?
    if (refreshView || refreshCrosshairs) {
      this.refreshView(refreshView, refreshCrosshairs);
    }
  }

  cleanUp = () => {
    // Stop the resize event listener.
    window.removeEventListener('resize', this.resizeView);

    // Cancel any pending renders.
    this.throttledRefreshView.cancel();

    // We need to remove this view's api from the apis array and ensure the indexes are correct.
    const istyle = this.genericRenderWindow
      ?.getInteractor()
      ?.getInteractorStyle();
    const apis = istyle?.getApis();
    const viewIndex = istyle?.getViewIndex();
    if (apis && viewIndex !== undefined) {
      // Remove the api from the apis array.
      for (let apiIndex = 0; apiIndex < apis.length; apiIndex++) {
        if (apis[apiIndex].getViewIndex() === viewIndex) {
          apis.splice(apiIndex, 1);
        }
      }

      // Reset the apiIndex for each api in the apis array.
      for (let apiIndex = 0; apiIndex < apis.length; apiIndex++) {
        apis[apiIndex].setApiIndex(apiIndex);
      }
    }

    // This should not be required but WebGL (on Chrome at least) seems to have bug making it not correctly release the WebGL context.
    // Although this function is only meant to simulate a lost context it actually allows the context to properly release.
    // Without this fix thrashing the views could result in a lost context (and a blank view), Interestingly, calling get3DContext()
    // seems to help trigger this issue, possibly because vtkjs seems to add event listeners in this function that are never removed.
    const openGLRenderWindow = this.genericRenderWindow?.getOpenGLRenderWindow();
    if (openGLRenderWindow) {
      openGLRenderWindow
        .getCanvas()
        .removeEventListener('webglcontextlost', this.webGLContextLost);

      openGLRenderWindow
        .getCanvas()
        .removeEventListener('webglcontextrestored', this.webGLContextRestored);

      openGLRenderWindow
        .get3DContext()
        ?.getExtension('WEBGL_lose_context')
        ?.loseContext();
    }

    if (this.genericRenderWindow) {
      // Destroy the render window, clean up WebGL.
      this.genericRenderWindow.delete();
      this.genericRenderWindow = null;
    }

    // Destroy the PIXI container but not its children, the ContrastView will do that for us.
    if (this.containerPIXI) {
      this.containerPIXI.destroy({
        children: false,
        baseTexture: false,
        texture: false,
      });
      this.containerPIXI = null;
    }
  };

  componentWillUnmount() {
    this.cleanUp();
  }

  render() {
    // Return a black panel if the volume is still loading.
    if (!this.props.volumes || !this.props.volumes.length) {
      const style = {
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundColor: 'black',
      };
      return <div style={style} />;
    }

    // Otherwise return the view.
    const style = { width: '100%', height: '100%', position: 'relative' };
    return (
      <div style={style}>
        <div
          ref={this.container}
          className={this.state.containerClassName}
          style={style}
          onPointerDown={this.props.onMouseDown}
          onMouseMove={this.props.onMouseMove}
          onMouseUp={this.props.onMouseUp}
        />
        {this.props.showCenterline && (
          <Centerline
            ref={this.centerlineRef}
            centerline={this.props.centerline}
            strokeColor={this.props.centerlineColor}
            strokeWidth={this.props.centerlineWidth}
          />
        )}
        {this.props.showCrosshairs && (
          <Crosshairs
            ref={this.crosshairsRef}
            axisColours={this.props.axisColours}
            rotateHandleDistance={this.props.rotateHandleDistance}
          />
        )}
      </div>
    );
  }
}
