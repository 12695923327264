import React, { ReactElement, useCallback, useMemo } from 'react';
import Select from '../../components/Select/Select';
import { ETHNICITIES } from '../../config';
import { useStoreContext } from '../../context/store-context';
import { useReportContext } from '../../context/report-context';
import { CalciumStat } from '../../context/types';
import { useFetchMesaPercentile } from '../../hooks/use-get-mesa-percentile';
import { Loader } from '../Loader/Loader';

interface Props {
  calciumStat: CalciumStat;
  editingReport: boolean;
}

export default function CalciumScoreStats({
  calciumStat,
  editingReport,
}: Props): ReactElement<Props> {
  const { updateDraftReport } = useStoreContext();
  const { fetchingMesaPercentile } = useReportContext();

  const fetchMesaPercentile = useFetchMesaPercentile();

  const updateEthnicity = useCallback(
    (value) => {
      updateDraftReport({
        ethnicity: value,
      });

      let calciumScore: number;
      if (typeof calciumStat.value === 'number') {
        calciumScore = calciumStat.value;
      } else {
        calciumScore = parseFloat(calciumStat.value);
      }

      fetchMesaPercentile(
        calciumStat.sex,
        calciumStat.age,
        value,
        calciumScore
      );
    },
    [updateDraftReport, calciumStat, fetchMesaPercentile]
  );

  const mesaStr = useMemo(
    () =>
      calciumStat.mesaPercentile.length > 0
        ? `(${calciumStat.mesaPercentile}%)`
        : '',
    [calciumStat.mesaPercentile]
  );

  const ethnicities = useMemo(() => {
    // API expects ethnicity in lower case
    return ETHNICITIES.map((e) => ({ label: e, value: e.toLowerCase() }));
  }, []);

  const ethnicityStr = useMemo(() => {
    // Need to convert first letter to uppercase for display
    if (!calciumStat.ethnicity) {
      return '-';
    }

    return (
      calciumStat.ethnicity.substring(0, 1).toUpperCase() +
      calciumStat.ethnicity.substring(1, calciumStat.ethnicity.length)
    );
  }, [calciumStat.ethnicity]);

  return (
    <div className="calcium__stats">
      <div className="stats__stat calcium_section">
        {!fetchingMesaPercentile && (
          <div className="calcium_container">
            <div className="stats__stat-value">
              <span>
                {`${calciumStat.value} `}
                <span className="stats__stat-subtext">{mesaStr}</span>
              </span>
            </div>
          </div>
        )}
        {fetchingMesaPercentile && (
          <div className="stats__stat-loading">
            <Loader inline />
          </div>
        )}
        <div className="stats__stat-label">{calciumStat.label}</div>
      </div>
      <div className="calcium_section">
        {editingReport && (
          <Select
            options={ethnicities}
            value={calciumStat.ethnicity}
            onChange={updateEthnicity}
            disabled={!editingReport || fetchingMesaPercentile}
          />
        )}
        {!editingReport && (
          <div className="stats__stat-value">
            <span>{ethnicityStr}</span>
          </div>
        )}
        <div className={'stats__stat-label'}>Ethnicity/Race</div>
      </div>
    </div>
  );
}
