export default {
  container: () => ({}),
  valueContainer: () => ({}),
  menu: () => ({}),
  menuList: () => ({}),
  indicatorSeparator: () => ({}),
  control: () => ({}),
  singleValue: () => ({}),
  dropdownIndicator: () => ({}),
  option: () => ({
    cursor: 'pointer',
  }),
};
