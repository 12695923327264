import { useMemo } from 'react';
import { AUTH_USER_GROUP } from '../config';
import { useUserContext } from '../context/user-context';

export function useIsAuditorUserSelector() {
  const { user: loggedInUser } = useUserContext();

  return useMemo(() => loggedInUser.groups?.includes(AUTH_USER_GROUP.auditors), [
    loggedInUser.groups,
  ]);
}

export function useIsArtryaUserSelector() {
  const { user: loggedInUser } = useUserContext();

  return useMemo(() => loggedInUser.groups?.includes(AUTH_USER_GROUP.artrya), [
    loggedInUser.groups,
  ]);
}
