import { createSlice } from '@reduxjs/toolkit';
import { Study } from '../../context/types';
import {
  AsyncResource,
  getDefaultAsyncResourceState,
  onThunkFulfilled,
  onThunkPending,
  onThunkRejected,
} from '../util/async';
import { fetchStudyById } from './thunks';

export interface StudyState {
  currentStudy: AsyncResource<Study, string>;
}

export const DEFAULT_STUDY_STATE: StudyState = {
  currentStudy: getDefaultAsyncResourceState(),
};

export const studySlice = createSlice({
  name: 'study',
  initialState: DEFAULT_STUDY_STATE,
  reducers: {
    clearSelection: (state) => {
      state.currentStudy = getDefaultAsyncResourceState();
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchStudyById.fulfilled, (state, action) =>
        onThunkFulfilled({ state: state.currentStudy, data: action.payload })
      )
      .addCase(fetchStudyById.pending, (state, action) =>
        onThunkPending({
          state: state.currentStudy,
          /**
           * In future, we may like to change this to true, but that's now how the app
           * currently works, and we're currently trying to match parity with the
           * current functionality
           */
          clearDataOnFetch: false,
        })
      )
      .addCase(fetchStudyById.rejected, (state, action) =>
        onThunkRejected({
          state: state.currentStudy,
          error: action.error.message,
          /**
           * In future, we may like to change this to true, but that's now how the app
           * currently works, and we're currently trying to match parity with the
           * current functionality
           */
          clearDataOnFailure: false,
        })
      );
  },
});

export const StudyActions = studySlice.actions;

export const studyReducer = studySlice.reducer;
