import Moment from 'moment';
import React, { ReactElement, useCallback } from 'react';
import { ReactComponent as RevertReportIcon } from '../../assets/icons/reload.svg';
import { InlineReportingActions } from '../../context/inlineReporting/actions';
import { useInlineReportingContext } from '../../context/inlineReporting/context';

interface Props {
  note: string;
  date?: string;
  revertToThisVersion?: () => void;
  hideDivider?: boolean;
  disabled?: boolean;
  disabledReason?: string;
}

export default function HistorySection({
  note,
  date,
  revertToThisVersion,
  hideDivider = false,
  disabled = false,
  disabledReason = '',
}: Props): ReactElement<Props> {
  const { dispatch } = useInlineReportingContext();

  // Blow away any inline reporting state when reverting. If they want to revert,
  // they don't want the unsaved changes anyway.
  const revert = useCallback(() => {
    dispatch(InlineReportingActions.clearInlineReportingDraftState());
    revertToThisVersion?.();
  }, [revertToThisVersion, dispatch]);

  return (
    <>
      {!hideDivider && <div className="report-status__divider" />}
      <div className="report-status__history">
        <div className="report-status__history_status">
          <dd className="note">{note}</dd>
          {date && (
            <dd className="date">{Moment(date).format('MMM DD,yyyy')}</dd>
          )}
        </div>

        {revertToThisVersion && (
          <div className="report-status__revert_button_section">
            <button
              className="report-status__revert_button"
              onClick={revert}
              disabled={disabled}
              title={disabled ? disabledReason : ''}
            >
              <RevertReportIcon
                className="report-status__revert_button_icon"
                style={{ marginLeft: '0' }}
              />
            </button>
          </div>
        )}
      </div>
    </>
  );
}
