import * as PIXI from 'pixi.js-legacy';
import { ActionType, createAction } from 'typesafe-actions';
import { User } from '../config';
import {
  PlaqueCompositionCounts,
  VpBiomarkerCounts,
  XYCoords,
} from '../reducers/vessel-data';
import {
  RulerStates,
  EllipseStates,
  MeasurementGraphics,
} from '../utils/measurementTools/types';
import { LineArray, ObjectArray } from '../types';
import { Ethnicity } from '../types/common';
import { WindowLevels } from './window-types';

export interface CoronaryFindings {
  LAD?: string;
  LCX?: string;
  LM?: string;
  RCA?: string;
}

// Measurements
// coronal: y, z
// sagittal: x, z
// axial: x, y
export interface VolumeSpacing {
  z: number;
  y: number;
  x: number;
}

export const VesselToReportNameMapping: {
  [key: string]:
    | 'lad_coronary_findings'
    | 'lcx_coronary_findings'
    | 'lm_coronary_findings'
    | 'rca_coronary_findings';
} = {
  LAD: 'lad_coronary_findings',
  LCX: 'lcx_coronary_findings',
  LM: 'lm_coronary_findings',
  RCA: 'rca_coronary_findings',
};

export interface StudyData {
  patient_data: {
    name: string;
    age: string;
    sex: string;
  };
  scan_quality: {
    summary: string;
    noise?: {
      val: number;
      cat: string;
    };
    signal?: {
      val: number;
      cat: string;
    };
    snr?: {
      val: number;
      cat: string;
    };
  };
  meta_data: {
    bpm: number;
    kvp: number;
    ctdivol: number;
    scanner: string;
    scan_length: number;
  };
  vessels: Array<string>;
  calcium_score: number;
  lesion_count: number;
  plaque_volume: number;
  vulnerability: boolean;
  non_diagnostic: boolean;
  stent: boolean;
  graft: boolean;
  cad_rads_cat: string;
  cad_rads_str: string;
  sis: number;
  priority: number;
  priority_vessel_id: string;
  stenosis_max: string;
  stenosis_max_segment_id: string;
  vp_biomarker_counts: VpBiomarkerCounts;
  plaque_composition_counts: PlaqueCompositionCounts;
  vulnerable_count: number;
  detail?: string;
}

export type FFRStatus = 'Ready' | 'Unavailable' | 'Failed' | 'Processing';

export interface FFRVesselData {
  value: number[];
  status: FFRStatus;
  details?: string;
}

export interface FFRVesselDataResponse {
  [key: string]: FFRVesselData;
}

export interface ContrastLesionData {
  slices: Array<number>;
  added_slices: Array<number> | null;
  stenosis_max: string;
  vp_biomarker_counts: VpBiomarkerCounts;
  plaque_composition: 'none' | 'nca' | 'm' | 'ca';
  vulnerability: boolean;
  priority_slice: number;
  segment: 'total' | 'p' | 'm' | 'd';
}

export type ContrastLesionDataResponse = ObjectArray<
  ObjectArray<ContrastLesionData>
>;

export interface WorkflowStatus {
  state: string;
  timestamp: string | null;
  user: string;
  short_description?: string;
  detailed_description?: string;
}

export interface WorkflowReview {
  id: string;
  assignee: ReviewUser;
  review_type: string;
  message: string;
  assigner: ReviewUser;
  created_at: string;
  completed_at: string | null;
  cancelled_at: string | null;
}

export interface RequestReviewResponse {
  detail: string;
  review: WorkflowReview;
}

export interface AIAssessed {
  // The name of the contrast series that was AI assessed.
  contrast_id: string;
  // The name of the noncontrast series that was AI assessed.
  noncontrast_id: string;
}

/**
 * Response body for the /dashboard/datasets endpoint
 */
export interface DatasetItem {
  active_run: string;
  cad_rads: string;
  calcium_score: string;
  can_be_viewed: {
    reason: string;
    viewable: boolean;
  };
  client_id: string;
  date_of_birth: string;
  is_edited: boolean;
  is_report_approved: boolean;
  last_scan: string;
  patient_cipher: string;
  patient_id: string;
  patient_name: string;
  patient_plaintext: string;
  series: Record<string, DatasetSeries>;
  referring_doctor: string;
  report_active_review: ReportReview | null;
  report_approval: {
    run_id: string;
    timestamp: string;
    user: ReviewUser;
  } | null;
  stenosis: string;
  study_date: string;
  study_id: string;
  vulnerable_plaque: string;
  workflow_status: WorkflowStatus;
  status: any;
  mesa_percentile: string | null;
  // Details of which series were AI assessed.
  ai_assessed: AIAssessed;
  // The version of the tool that generated this study's analysis (eg "0.2.0").
  analysis_version: string;
  ris_status: {
    user: string;
    status: string;
    message: string;
    run_id: string;
    timestamp: string;
  };
}

export interface DatasetSeries {
  customer_series_uid: string;
  series_description: string;
  date: string;
  thickness: string;
  ww: string;
  wl: string;
  thumbnail_url: string;
  slices: SlicesPayload | OldSlicesPayload;
  processing_status: {
    code: SeriesProcessingStatus;
    internal_message: string;
    external_message: string;
  };
}

export interface SlicesPayload {
  path: string;
  num_slices: string;
}

export type OldSlicesPayload = string[];

export type SeriesProcessingStatus =
  | 'Ingested'
  | 'Processing'
  | 'Ready'
  | 'Analysis Failed';

/**
 * Study object derived from DatasetItem for use within the app. Identical to DatasetItem, but with a flattened
 * workflow_status object to facilitate searching in the Dashboard.
 */
export interface Study extends Omit<DatasetItem, 'workflow_status'> {
  workflow_status: WorkflowStatus;
  workflow_user: string;
}

export interface PatientStats {
  cad_rads: string;
  calcium_score: number | null;
  maximum_stenosis: string;
  priority_vessel: string;
  sis: number;
}

/**
 * A typed 2D point.
 */

/**
 * The mappings between slice index and centerline point array index for a given image slice.
 */
export interface CenterlineMapping {
  // The mapping from vessel slice index to centerline point array index.
  sliceToCenterline: number[];
  // The mapping from centerline point array index to vessel slice index.
  centerlineToSlice: number[];
}

// Centerline editing.
export interface EditMode {
  // Are we currently editing the centerline?
  editing: boolean;
  // Was the CPRViewer showing the annos (ie centerline)? If false we will hide it when exiting edit mode.
  showAnnos?: boolean;
  // Was the CPRViewer showing the auxAnnos (ie other centerlines)? If true we will show them again when exiting edit mode.
  showAuxAnnos?: boolean;
  // While editing the centerline have any changes actually been made?
  modified?: boolean;
  // The modified centerline (if modified).
  centerline?: LineArray;
}

export interface LesionData {
  'calcium-score': string;
  category: string;
  mask: ObjectArray<Array<[number, number]>>;
}
export type LesionDataResponse = {
  lesion_id: ObjectArray<LesionData>;
};
export interface ProcessedLesionData {
  x: number;
  y: number;
  lesion_id: number;
  category: string;
  score: string;
}

export const EditModeActions = {
  startEditing: createAction(
    'editMode/START_EDITING',
    (showAnnos: boolean, showAuxAnnos: boolean) => {
      return { showAnnos, showAuxAnnos };
    }
  )(),
  modifiedCenterline: createAction('editMode/MODIFIED_CENTERLINE')(),
  updateCenterline: createAction(
    'editMode/UPDATE_CENTERLINE',
    (centerline: LineArray) => centerline
  )(),
  stopEditing: createAction('editMode/STOP_EDITING')(),
};
export type EditModeAction = ActionType<typeof EditModeActions>;

export interface StoreContextValue {
  backendVersionHead: string | undefined;
  patientID: string | undefined;
  runID: string | undefined;
  currentPatientId: any;
  postingPartialReport: boolean;
  setPostingPartialReport: (posting: boolean) => void;
  postingReport: boolean;
  setPostingReport: (posting: boolean) => void;
  versionHead: string | undefined;
  updateVersionHead: (versionNumber?: string) => void;
  updateBackendVersionHead: (
    versionNumber: string,
    force?: boolean
  ) => Promise<void>;
  status: any;
  patientDataReloadCount: number;
  dashboardData: any;
  displayMeasurements: boolean;
  reportHistory: ReportHistoryResponse | undefined;
  setReportHistory: (reportHistory: ReportHistoryResponse) => void;
  reviewList: ListReview[] | undefined;
  setReviewList: (reviewList: ListReview[]) => void;
  reloadDashboardData: boolean;
  decryptDashboardData: any;
  decryptedStudies: any;
  newDashboardData: boolean;
  patientStats: PatientStats | undefined;
  setPatientStats: (stats: PatientStats | undefined) => void;
  studyData: StudyData | undefined;
  setStudyData: (studyData: StudyData) => void;
  calciumScoreData: any;
  lesionData: any;
  contrastLesionData: ContrastLesionDataResponse | undefined;
  initialDataLoaded: boolean;
  setInitialDataLoaded: (initialDataLoaded: boolean) => void;
  visibleTab: number;
  editMode: EditMode;
  studyLocked: boolean;
  studyLockedBy: string | undefined;
  inactivity: boolean;
  lastStudyId: string | undefined;
  modelImage: any;
  showReport: boolean;
  showCTVolume: boolean;
  currentReport: Report | undefined;
  draftReport: DraftReport | undefined;
  updateDraftReport: (report: DraftReport) => void;
  editingReport: boolean;
  setEditingReport: (editing: boolean) => void;
  editingImpressions: boolean;
  setEditingImpressions: (editing: boolean) => void;
  editingCoronaryFindings: boolean;
  setEditingCoronaryFindings: (editing: boolean) => void;
  clearDraftReport: () => void;
  setCurrentReport: (report: Report | undefined) => void;
  selectedStudyClient: any;
  stenosis: any[] | undefined;
  setPatientID: (patientId: string | undefined) => void;
  setRunID: (runId: string | undefined) => void;
  setDashboardData: (dashboardData: any | undefined) => void;
  setDisplayMeasurements: (display: boolean) => void;
  setNewDashboardData: (dashboardData: any | undefined) => void;
  setReloadDashboardData: (dashboardData: any | undefined) => void;
  setDecryptDashboardData: (dashboardData: any | undefined) => void;
  setDecryptedStudies: (decryptedStudies: any) => void;
  setCalciumScoreData: (calciumScoreData: any) => void;
  // Set the lesion data obtained for the given patientID and runID: We will check this is still the active study before setting the data in the store.
  setLesionData: (
    lesionData: LesionDataResponse,
    patientID: string,
    runID: string
  ) => void;
  setContrastLesionData: (
    contrastLesionData: ContrastLesionDataResponse
  ) => void;
  dispatchEditModeAction: (action: EditModeAction) => void;
  setStudyLocked: (studyLocked: boolean) => void;
  setStudyLockedBy: (studyLockedBy?: string) => void;
  setInactivity: (inactivity: boolean) => void;
  setLastStudyId: (lastStudyId?: string) => void;
  setModelImage: (modelImage: any) => void;
  setShowReport: (showReport: boolean) => void;
  setShowCTVolume: (showCTVolume: boolean) => void;
  clearPatient: () => void;
  setVisibleTab: (visibleTab: number) => void;
  setSelectedStudyClient: (selectedStudyClient: any) => void;
  setStenosis: (stenosis: any[] | undefined) => void;
  warnUserOfChangeInVesselGroup: boolean;
  setWarnUserOfChangeInVesselGroup: (changed: boolean) => void;
  // The number of milliseconds to pause between loading each 'not currently visible' WebGLViewer slice image.
  imageLoadingThrottle: number;
  setImageLoadingThrottle: (timeBetween: number) => void;
  fetchingReport: boolean;
  setFetchingReport: (fetching: boolean) => void;
  fetchingOverallMeasurements: boolean;
  setFetchingOverallMeasurements: (fetching: boolean) => void;
  nonContrastSlice: number;
  setNonContrastSlice: (slice: number) => void;
  nonContrastSpacing: VolumeSpacing | undefined;
  setNonContrastSpacing: (newValue: VolumeSpacing) => void;
}

export interface ReportContextValue {
  valid: boolean;
  summary: string;
  notes: string;
  screenshots: any;
  showEmailForm: boolean;
  emailAddresses: string;
  decryptedStudy: any;
  approved: boolean;
  setSummary: (summary: string) => void;
  setNotes: (notes: string) => void;
  clearReport: () => void;
  setShowEmailForm: (showEmailForm: boolean) => void;
  setEmailAddresses: (emailAddresses: string) => void;
  setDecryptedStudy: (decryptedStudy: any | undefined) => void;
  setApproved: (approve: boolean) => void;
  setScreenshots: (screenShots: any[]) => void;
  deletingScreenshot: string | undefined;
  setDeletingScreenshot: (filename: string | undefined) => void;
  fetchingMesaPercentile: boolean;
  setFetchingMesaPercentile: (fetching: boolean) => void;
  reviewUsers: ReviewUser[] | undefined;
  setReviewUsers: (users: ReviewUser[] | undefined) => void;
  amendmentModalPassthrough?: { passthrough?: (reason: string) => void };
  setAmendmentModalPassthrough: (obj: {
    passthrough?: (reason: string) => void;
  }) => void;
}

export interface CprContexValue {
  cprSliceidx: number;
  setCprSliceidx: (
    cprSliceidx: number | ((prevValue: number) => number)
  ) => void;
  cprSliceCount: number;
  setCprSliceCount: (
    cprSliceCount: number | ((prevValue: number) => number)
  ) => void;
  triggerResetPanAndZoom: number;
  setTriggerResetPanAndZoom: (
    triggerResetPanAndZoom: (prevValue: number) => number
  ) => void;
  // Choose which MPR view is shown on the patient overview tab.
  selectedMPRView: string;
  setSelectedMPRView: (mprView: string) => void;
}

export interface DashboardContextValue {
  dashboardCurrentPage: number;
  dashboardSortKey: string;
  dashboardAscending: boolean;
  dashboardSearchStr: string;
  setDashboardCurrentPage: (currentPage: number) => void;
  setDashboardSortKey: (sortKey: string) => void;
  setDashboardAscending: (ascending: boolean) => void;
  setDashboardSearchStr: (searchStr: string) => void;
}

export interface ClientConfig {
  client_id: string;
  client_logo: string;
  client_name: string;
  phi_service_url: string | null;
  ffr_enabled: boolean;
  measurement_enabled: boolean;
}

export interface UserContextValue {
  user: User;
  authData: any | null;
  clientConfig?: ClientConfig;
}

export interface WindowContextValue {
  contrastWindowLabel: string | null;
  contrastWindowLevels: WindowLevels;
  setContrastWindowLevels: (windowLevels: WindowLevels) => void;
  nonContrastWindowLabel: string | null;
  nonContrastWindowLevels: WindowLevels;
  setNonContrastWindowLevels: (windowLevels: WindowLevels) => void;
  resetWindowLevels: () => void;
  annotationModalOpen: boolean;
  setAnnotationModalOpen: (annotationModalOpen: boolean) => void;
}

export interface StatsDataType {
  label: string;
  value: string | number;
  subtext?: string;
  cubed?: boolean;
  riskLevel?: string;
}

export interface CalciumStat extends StatsDataType {
  age: string;
  sex: string;
  ethnicity: Ethnicity | '';
  mesaPercentile: string;
}

export interface PatientInlineStats {
  calcium_score: CalciumStat;
  maximum_stenosis: StatsDataType;
  vulnerable_plaque: StatsDataType;
  cad_rads: StatsDataType;
  sis_data: StatsDataType;
}

export interface CalciumScore {
  total: number | null;
  rca: number | null;
  lcx: number | null;
  lm: number | null;
  lad: number | null;
}

export interface Report extends ProcedureDetails, heartDominance {
  calcium_score: CalciumScore;
  ethnicity: Ethnicity | '';
  maximum_stenosis: string;
  vulnerable_plaque: string;
  referring_doctor: string;
  cad_rads: string;
  sis_data: string;
  clinical_indication: string;
  scan_quality: string;
  heart_rate: number | null;
  medications: Medication[] | null;
  prior_cardiac_procedures: string;
  kvp: number | null;
  impression: string[];
  lm_coronary_findings: string;
  lad_coronary_findings: string;
  lcx_coronary_findings: string;
  rca_coronary_findings: string;
  non_coronary_findings: string;
  extra_cardiac_findings: string;
  user: ReviewUser | null;
  timestamp: string;
  ai_generated_fields: string[];
  mesa_percentile: string;
  contrast_media: string;
  acquisition_mode: string;
}

export interface DraftReport extends Partial<Report> {
  // Only used when editing an approved report, not returned by /report/content
  update_message?: string;
}

export interface ReviewUser {
  name: string;
  email: string;
}

export type ReviewType = 'approve' | 'review';

export interface ReportReview {
  assignee: ReviewUser;
  assigner: ReviewUser;
  created_at: string;
  message: string;
  review_id: string;
  review_type: ReviewType;
}

// Review items returned from the `/report/review/list` endpoint
export interface ListReview extends Omit<ReportReview, 'review_id'> {
  id: string;
  completed_at: string | null;
  cancelled_at: string | null;
}

export type DashboardDataSetResponse = ObjectArray<Study>;

export interface ReportResponse {
  detail: string;
  changes: boolean;
  content: Report;
}
export interface Medication {
  key?: string;
  name: string;
  route_of_administration: string;
  dosage?: {
    amount: number | string | undefined;
    unit: string;
  } | null;
}
export interface ProcedureDetails {
  scan_quality: string;
  heart_rate: number | null;
  medications: Medication[] | null;
  prior_cardiac_procedures: string;
  kvp: number | null;
  contrast_volume: number | null;
  dlp: number | null;
  contrast_media: string;
  acquisition_mode: string;
}
export interface heartDominance {
  heart_dominance: string | null;
}

export interface ReportHistory {
  user: ReviewUser;
  timestamp: string;
}

export interface ReportHistoryResponse {
  created_at: string;
  previous: ReportHistory | null;
  current: ReportHistory | null;
  approvers: ReportHistory[];
}

export interface MesaPercentileResponse {
  // Rounded percentile (nearest integer) as a string, empty if it couldn't be calculated
  percentile: string;
}

// The default number of milliseconds to wait between background loading of images in the WebGLViewer.
export const defaultImageLoadingThrottle: number = 10;

export interface ImageSize {
  width: number;
  height: number;
}

export interface Marker extends PIXI.Container {
  // The number of the slice this marker is attached to.
  sliceIndex: number;
}
//Measurement information
export interface LVMassIndex {
  lv_mass_index: number | undefined;
}

export interface MassIndex {
  lv_mass: number | undefined;
}

export interface PatientData {
  weight: number | undefined;
  height: number | undefined;
}
export interface measurementToolContextValue {
  hitAreaEllipseToolRef: any;
  isMeasurementMode: boolean;
  setIsMeasurementMode: (value: boolean) => void;
  measurementToolStartPoint: XYCoords | null;
  setMeasurementToolStartPoint: (value: XYCoords | null) => void;
  isRulerActive: boolean;
  setIsRulerActive: (value: boolean) => void;
  isEllipseActive: boolean;
  setIsEllipseActive: (value: boolean) => void;
  isDraggingMeasurementToolRef: React.MutableRefObject<
    RulerStates | EllipseStates | undefined
  >;
  isClickDownMeasurementToolRef: React.MutableRefObject<boolean>;
  isOverMeasurementToolRef: React.MutableRefObject<string>;
  measurementTargetRef: React.MutableRefObject<MeasurementGraphics | null>;
  clearMeasurements: number;
  setClearMeasurements: (value: number) => void;
}
//used for plaque volume and plaque burden
//results from api will return as number, but when results are unavailable, we display "-" string
export interface PlaqueCategories {
  total: string | undefined;
  lap: string | undefined;
  ca: string | undefined;
  nca: string | undefined;
}

export interface PlaqueMeasurements {
  volume: PlaqueCategories;
  burden: PlaqueCategories;
}
export interface PlaqueCategoriesRatio {
  lap: string | undefined;
  ca: string | undefined;
  nca: string | undefined;
}
export interface PlaqueMeasurementsPerLesion {
  volume: PlaqueCategories;
  ratio: PlaqueCategoriesRatio;
}
export interface PlaqueMeasurementsPerSlice {
  area: TotalSliceMeasurement | undefined;
  burden: TotalSliceMeasurement | undefined;
}

export interface TotalSliceMeasurement {
  total: number[] | undefined;
}

export interface WallSliceMeasurements {
  lumen_area: number[] | undefined;
  outer_area: number[] | undefined;
}
