import React from 'react';
import cn from 'classnames';

interface TabButtonsProps {
  tabs: { value: string; label: string }[];
  value: string;
  onChange: (value: string) => void;
}

export const TabButtons: React.FC<TabButtonsProps> = ({
  tabs = [],
  value,
  onChange,
}) => {
  return (
    <div className="tab-buttons">
      {tabs.map((tab) => (
        <button
          key={tab.value}
          className={cn('tab-buttons__tab', {
            'tab-buttons__tab--active': value && tab.value === value,
          })}
          type="button"
          onClick={() => {
            if (onChange) onChange(tab.value);
          }}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};
