import {
  VesselGroupings,
  VESSEL_DEFAULT_COLOR,
  PRIMARY_VESSEL_COLOR_MAPPING,
} from '../config';

const TERRITORY: { [key: string]: VesselGroupings } = {
  LM: 'LM',
  LAD: 'LAD',
  D: 'LAD',
  S: 'LAD',
  LCX: 'LCX',
  OM: 'LCX',
  RI: 'LCX',
  'L-PDA': 'LCX',
  'L-PL': 'LCX',
  RCA: 'RCA',
  AM: 'RCA',
  RV: 'RCA',
  SAN: 'RCA',
  CA: 'RCA',
  AVN: 'RCA',
  'R-PDA': 'RCA',
  'R-PL': 'RCA',
};

export function getMajorVesselGrouping(
  vessel: string | undefined
): VesselGroupings | undefined {
  const baseName = getVesselBaseName(vessel?.toUpperCase() ?? '');

  if (baseName) {
    return TERRITORY[baseName];
  } else {
    return undefined;
  }
}

export function getVesselBaseName(vesselId: string) {
  const [baseName] = vesselId.split(/\d+/g);

  if (baseName && baseName.length > 0) {
    return baseName;
  } else {
    return undefined;
  }
}

export function getVesselColor(vessel: VesselGroupings) {
  if (!vessel) return VESSEL_DEFAULT_COLOR;

  const colorMapping: { [key: string]: string } = PRIMARY_VESSEL_COLOR_MAPPING;
  const baseName = getVesselBaseName(vessel);
  let col = colorMapping[(baseName ?? '').toLowerCase()];

  return col || VESSEL_DEFAULT_COLOR;
}

export const sortedVesselList = (vesselNames: string[]) => {
  return vesselNames.sort((a, b) => {
    a = a.toLocaleLowerCase();
    b = b.toLocaleLowerCase();
    if (a === 'lm') return -1;
    if (b === 'lm') return 1;
    if (a === 'lad') return -1;
    if (b === 'lad') return 1;
    if (a === 'lcx') return -1;
    if (b === 'lcx') return 1;
    if (a === 'rca') return -1;
    if (b === 'rca') return 1;
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  });
};
