import React, {
  ReactElement,
  useMemo,
  useCallback,
  ChangeEventHandler,
} from 'react';

interface Props {
  label: string;
  value: string | undefined;
  editingReport: boolean;
  handleUpdateReportStats: (stat: string, newValue: string) => void;
  editable?: boolean;
}

export default function SisDataStat({
  label,
  value,
  editingReport,
  handleUpdateReportStats,
  editable,
}: Props): ReactElement<Props> {
  const v = useMemo(() => {
    if (editingReport) {
      return value;
    }

    return value && value.length > 0 ? value : '-';
  }, [value, editingReport]);

  const updateValue: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      handleUpdateReportStats('sis_data', event.target.value);
    },
    [handleUpdateReportStats]
  );

  return (
    <div className={'stats__stat'}>
      {(!editingReport || !editable) && (
        <div className="stats__stat-value">{v}</div>
      )}
      {editingReport && editable && (
        <div className="stats__stat-value">
          <input
            type="text"
            className="stats__stat-value__input"
            value={v}
            onChange={updateValue}
          />
        </div>
      )}
      <div className="stats__stat-label">{label}</div>
    </div>
  );
}
