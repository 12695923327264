import React, { ReactElement, ReactNode, useContext, useState } from 'react';
import { CprContexValue } from './types';
import { KEY_CPR } from '../config';

const CprContext = React.createContext<CprContexValue | undefined>(undefined);
CprContext.displayName = 'CprContext';

interface Props {
  children: ReactNode;
}

export function CprProvider({ children }: Props): ReactElement<Props> {
  // The current CPR slice index for the selected vessel (rotational view index, each rotation having its own image, centerline etc).
  const [cprSliceidx, setCprSliceidx] = useState(0);
  // The total number CPR slices for the selected vessel (rotational views).
  const [cprSliceCount, setCprSliceCount] = useState(0);
  // Choose which MPR view is shown on the patient overview tab.
  const [selectedMPRView, setSelectedMPRView] = useState(KEY_CPR);
  // used to trigger Cpr viewer reset
  const [triggerResetPanAndZoom, setTriggerResetPanAndZoom] = useState(0);

  const cprContext: CprContexValue = {
    cprSliceidx,
    setCprSliceidx,
    cprSliceCount,
    setCprSliceCount,
    triggerResetPanAndZoom,
    setTriggerResetPanAndZoom,
    selectedMPRView,
    setSelectedMPRView,
  };

  return (
    <CprContext.Provider value={cprContext}>{children}</CprContext.Provider>
  );
}

export function useCprContext(): CprContexValue {
  const context = useContext(CprContext);

  if (context === undefined) {
    throw new Error('Context must be used within an CprProvider.');
  }
  return context;
}
