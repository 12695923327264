import { AuthPiece } from 'aws-amplify-react';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';
import { GlobalLoader } from '../components/GlobalLoader';
import IdleTimer from '../components/IdleTimer/IdleTimer';
import NavBar from '../components/NavBar/NavBar';
import { ContrastProvider } from '../context/contrast-context';
import { CprProvider } from '../context/cpr-context';
import { MeasurementProvider } from '../context/measurement-tools-context';
import { DashboardProvider } from '../context/dashboard-context';
import { InlineReportingProvider } from '../context/inlineReporting/context';
import { ReportProvider } from '../context/report-context';
import { StoreProvider } from '../context/store-context';
import { UserProvider } from '../context/user-context';
import { VesselDataProvider } from '../context/vessel-data-context';
import { WindowProvider } from '../context/window-context';
import { WorkflowProvider } from '../context/workflow/context';
import CTVolume from '../views/CTVolume/CTVolume';
import NotFound from './404';
import AuditLog from './AuditLog/AuditLog';
import Dashboard from './Dashboard/Dashboard';
import Error from './Error/Error';
import Patient from './Patient/Patient';
import ReviewReport from './Report/Report';
import Settings from './Settings/Settings';
import Testing from './Testing/Testing';

interface Props {
  authData: any;
}

class Pages extends AuthPiece<Props, {}> {
  constructor(props: Props) {
    super(props);
    /**
     * Only show this component for the following Auth states.
     * State options are:
     *  - signIn
     *  - signUp
     *  - confirmSignIn
     *  - confirmSignUp
     *  - forgotPassword
     *  - requireNewPassword
     *  - verifyContact
     *  - signedIn
     */
    this._validAuthStates = ['signedIn'];
  }

  showComponent() {
    return (
      <UserProvider authData={this.props.authData}>
        <DashboardProvider>
          <Router>
            <StoreProvider>
              <ContrastProvider>
                <CprProvider>
                  <MeasurementProvider>
                    <WindowProvider>
                      <ReportProvider>
                        <InlineReportingProvider>
                          <WorkflowProvider>
                            <VesselDataProvider>
                              <NavBar />
                              <GlobalLoader />
                              <Switch>
                                <Route exact path="/">
                                  <Dashboard />
                                </Route>
                                <Route exact path="/testing">
                                  <Testing />
                                </Route>
                                <Route exact path="/study/:id">
                                  <IdleTimer>
                                    <Patient />
                                    <ErrorBoundary>
                                      <CTVolume />
                                    </ErrorBoundary>
                                    <ReviewReport />
                                  </IdleTimer>
                                </Route>
                                <Route exact path="/settings">
                                  <Settings />
                                </Route>
                                <Route exact path="/error">
                                  <Error />
                                </Route>
                                {/* TODO: Change to ProtectedRoute for 'artrya' group */}
                                <Route exact path="/auditlog">
                                  <AuditLog />
                                </Route>
                                <Route path="*">
                                  <NotFound />
                                </Route>
                              </Switch>
                            </VesselDataProvider>
                          </WorkflowProvider>
                        </InlineReportingProvider>
                      </ReportProvider>
                    </WindowProvider>
                  </MeasurementProvider>
                </CprProvider>
              </ContrastProvider>
            </StoreProvider>
          </Router>
        </DashboardProvider>
      </UserProvider>
    );
  }
}

export default Pages;
