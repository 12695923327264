import produce from 'immer';
import { getType } from 'typesafe-actions';
import { WorkflowAction, WorkflowActions } from './actions';
import { DEFAULT_WORKFLOW_STATE } from './constants';
import { WorkflowState } from './types';

export function workflowReducer(
  state: WorkflowState = DEFAULT_WORKFLOW_STATE,
  action: WorkflowAction
): WorkflowState {
  return produce(state, (draft) => {
    switch (action.type) {
      case getType(WorkflowActions.setStudyToAction): {
        draft.studyToAction = action.payload;
        break;
      }

      case getType(WorkflowActions.setShowCancelReviewConfirm): {
        draft.showCancelReviewConfirm = action.payload;
        break;
      }

      case getType(WorkflowActions.setAwaitingApproveList): {
        draft.awaitingApproveList = action.payload;
        break;
      }

      case getType(WorkflowActions.setAwaitingReviewList): {
        draft.awaitingReviewList = action.payload;
        break;
      }

      case getType(WorkflowActions.setStudyReviewCount): {
        draft.studyReviewCount = action.payload;
        break;
      }

      default:
        break;
    }
  });
}
