import cornerstone from 'cornerstone-core';

export default function buildMetadata(imageIds: string[]) {
  // Retrieve the Cornerstone imageIds from the display set
  // TODO: In future, we want to get the metadata independently from Cornerstone
  let baseImageId = imageIds[0];

  // Loop through each image to get the first valid one to set it as base image id
  imageIds.every((imageId) => {
    const imagePixelModule = cornerstone.metaData.get(
      'imagePixelModule',
      imageId
    );
    const voiLutModule = cornerstone.metaData.get('voiLutModule', imageId);
    const generalSeriesModule = cornerstone.metaData.get(
      'generalSeriesModule',
      imageId
    );

    if (imagePixelModule && voiLutModule && generalSeriesModule) {
      baseImageId = imageId;
      return false;
    }
    return true;
  });

  const {
    pixelRepresentation,
    bitsAllocated,
    bitsStored,
    highBit,
    photometricInterpretation,
    samplesPerPixel,
  } = cornerstone.metaData.get('imagePixelModule', baseImageId);

  let { windowWidth, windowCenter } = cornerstone.metaData.get(
    'voiLutModule',
    baseImageId
  );

  // TODO maybe expose voi lut lists?
  if (Array.isArray(windowWidth)) {
    windowWidth = windowWidth[0];
  }

  if (Array.isArray(windowCenter)) {
    windowCenter = windowCenter[0];
  }

  const { modality } = cornerstone.metaData.get(
    'generalSeriesModule',
    baseImageId
  );

  // Compute the image size and spacing given the meta data we already have available.
  const metaDataMap = new Map();
  imageIds.forEach((imageId) => {
    // TODO: Retrieve this from somewhere other than Cornerstone
    const metaData = cornerstone.metaData.get('imagePlaneModule', imageId);
    if (metaData) {
      metaDataMap.set(imageId, metaData);
    } else {
      console.error(
        'Warning: cornerstone failed to load metaData for image',
        imageId
      );
    }
  });

  return {
    metaData0: metaDataMap.values().next().value,
    metaDataMap,
    imageIds,
    imageMetaData0: {
      bitsAllocated,
      bitsStored,
      samplesPerPixel,
      highBit,
      photometricInterpretation,
      pixelRepresentation,
      windowWidth,
      windowCenter,
      modality,
    },
  };
}
