import React, { ReactElement, useCallback } from 'react';
import { DraftReport, Medication, ProcedureDetails } from '../../context/types';
import Card from '../Card';
import Medications from './Medications';
import RowWithDropDown from './RowWithDropDown';
import RowWithTextField from './RowWithTextField';

const SCAN_QUALITIES = ['Poor', 'Fair', 'Good', 'Excellent'];
const ACQUISITION_MODES = ['Flash', 'Sequential'];
const CONTRAST_MEDIA = [
  'Isovue 300',
  'Isovue 370',
  'Omnipaque 300',
  'Omnipaque 350',
  'Optiray 300',
  'Optiray 350',
  'Ultravist 300',
  'Ultravist 370',
];
interface Props {
  editMode: boolean;
  procedureDetails: ProcedureDetails;
  draftProcedureDetails: ProcedureDetails | undefined;
  updateDraftReport: (report: DraftReport) => void;
}

const parseKey = (key: string) => {
  return key.replaceAll(' ', '_').toLocaleLowerCase();
};
export default function ProcedureDetailsCard({
  editMode,
  procedureDetails,
  draftProcedureDetails,
  updateDraftReport,
}: Props): ReactElement<Props> {
  const handleInputChange = useCallback(
    (value: string, key: string = '', type: string = 'text') => {
      if (type === 'number') {
        updateDraftReport({
          [parseKey(key)]: Number.isNaN(parseInt(value))
            ? null
            : parseInt(value),
        });
      } else {
        updateDraftReport({
          [parseKey(key)]: value,
        });
      }
    },
    [updateDraftReport]
  );

  const handleDropDownValue = useCallback(
    (value: string, key: string) => {
      updateDraftReport({ [key]: value });
    },
    [updateDraftReport]
  );

  const updateMedications = useCallback(
    (values: Medication[]) => {
      updateDraftReport({ medications: values });
    },
    [updateDraftReport]
  );
  return (
    <Card title="Procedure Details">
      <div className="procedure-details">
        <dl className="procedure-details__data">
          <div className="procedure-details__data-grid">
            <RowWithTextField
              type="number"
              title="Heart Rate"
              measureType="bpm"
              editMode={editMode}
              value={
                editMode
                  ? draftProcedureDetails?.heart_rate
                  : procedureDetails.heart_rate
              }
              onChange={(value, title, type) => {
                handleInputChange(value === '' ? 0 : value, title, type);
              }}
            />
            <Medications
              editMode={editMode}
              value={
                editMode
                  ? draftProcedureDetails?.medications
                  : procedureDetails.medications
              }
              onChange={updateMedications}
            />
            <RowWithTextField
              type="number"
              title="Contrast Volume"
              measureType="ml"
              editMode={editMode}
              value={
                editMode
                  ? draftProcedureDetails?.contrast_volume
                  : procedureDetails.contrast_volume
              }
              onChange={handleInputChange}
            />
            <RowWithDropDown
              title="Contrast Media"
              editMode={editMode}
              options={CONTRAST_MEDIA}
              value={
                editMode
                  ? draftProcedureDetails?.contrast_media
                  : procedureDetails.contrast_media
              }
              onChange={(value) => handleDropDownValue(value, 'contrast_media')}
            />
            <RowWithDropDown
              title="Acquisition Mode"
              editMode={editMode}
              options={ACQUISITION_MODES}
              value={
                editMode
                  ? draftProcedureDetails?.acquisition_mode
                  : procedureDetails.acquisition_mode
              }
              onChange={(value) =>
                handleDropDownValue(value, 'acquisition_mode')
              }
            />
            <RowWithTextField
              type="number"
              title="DLP"
              measureType="mGy*cm"
              editMode={editMode}
              value={
                editMode ? draftProcedureDetails?.dlp : procedureDetails.dlp
              }
              onChange={handleInputChange}
            />
            <RowWithTextField
              type="number"
              title="kVp"
              editMode={editMode}
              value={
                editMode ? draftProcedureDetails?.kvp : procedureDetails.kvp
              }
              onChange={(value, title, type) => {
                handleInputChange(value === '' ? 0 : value, title, type);
              }}
            />
            <RowWithDropDown
              title="Scan Quality"
              editMode={editMode}
              options={SCAN_QUALITIES}
              value={
                editMode
                  ? draftProcedureDetails?.scan_quality
                  : procedureDetails.scan_quality
              }
              onChange={(value) => handleDropDownValue(value, 'scan_quality')}
            />
            <RowWithTextField
              type="text"
              title="Prior Cardiac Procedures"
              editMode={editMode}
              value={
                editMode
                  ? draftProcedureDetails?.prior_cardiac_procedures
                  : procedureDetails?.prior_cardiac_procedures
              }
              onChange={handleInputChange}
            />
          </div>
        </dl>
      </div>
      <br />
    </Card>
  );
}
