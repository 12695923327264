import { isEqual } from 'lodash';
import React, { ChangeEvent, ReactElement, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import KeyVesselFinding from '../../components/KeyVesselFinding';
import { VesselGroupings } from '../../config';
import { InlineReportingActions } from '../../context/inlineReporting/actions';
import { useInlineReportingContext } from '../../context/inlineReporting/context';
import { useStoreContext } from '../../context/store-context';
import {
  CoronaryFindings,
  VesselToReportNameMapping,
} from '../../context/types';
import { useSaveCoronaryFindingCallback } from '../../hooks/useSaveCallBack';
import { RouteParams } from '../../types';
import EditableCard, { EditableCardHeaderType } from '../EditableCard';

interface Props {
  selectedVesselGroup: VesselGroupings;
  headerType: EditableCardHeaderType;
  coronaryFindings: CoronaryFindings;
  aiAssessed: boolean;
  loading?: boolean;
}

export default function CoronaryFindingsCard({
  selectedVesselGroup,
  headerType,
  coronaryFindings,
  aiAssessed,
  loading = false,
}: Props): ReactElement<Props> {
  const { id } = useParams<RouteParams>();

  const onSaveCoronaryFindings = useSaveCoronaryFindingCallback(
    id,
    selectedVesselGroup
  );

  const {
    state: inlineReportingState,
    dispatch: dispatchInlineReportingAction,
  } = useInlineReportingContext();

  const onClickEditButton = useCallback(() => {
    dispatchInlineReportingAction(
      InlineReportingActions.updateCoronaryFinding(
        selectedVesselGroup,
        coronaryFindings[selectedVesselGroup] ?? ''
      )
    );
  }, [selectedVesselGroup, coronaryFindings, dispatchInlineReportingAction]);

  const onCancel = useCallback(() => {
    dispatchInlineReportingAction(
      InlineReportingActions.updateCoronaryFinding(
        selectedVesselGroup,
        undefined
      )
    );
  }, [dispatchInlineReportingAction, selectedVesselGroup]);

  const coronaryFindingsState = useMemo(() => {
    const mapped = VesselToReportNameMapping[selectedVesselGroup];

    return inlineReportingState[mapped];
  }, [selectedVesselGroup, inlineReportingState]);

  const onSave = useCallback(() => {
    onSaveCoronaryFindings();

    dispatchInlineReportingAction(
      InlineReportingActions.updateCoronaryFinding(
        selectedVesselGroup,
        undefined
      )
    );
  }, [
    onSaveCoronaryFindings,
    dispatchInlineReportingAction,
    selectedVesselGroup,
  ]);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>, vessel: VesselGroupings) => {
      const value = event.target.value;

      dispatchInlineReportingAction(
        InlineReportingActions.updateCoronaryFinding(vessel, value)
      );
    },
    [dispatchInlineReportingAction]
  );

  const hasChanges = useMemo(
    () =>
      !isEqual(coronaryFindings[selectedVesselGroup], coronaryFindingsState),
    [coronaryFindings, coronaryFindingsState, selectedVesselGroup]
  );

  const { postingPartialReport } = useStoreContext();

  return (
    <EditableCard
      title={
        headerType === 'detailed' ? selectedVesselGroup : 'Coronary Findings'
      }
      headerType={headerType}
      editMode={coronaryFindingsState !== undefined}
      onClick={onClickEditButton}
      onCancel={onCancel}
      onSave={onSave}
      aiAssessed={aiAssessed}
      saveEnabled={hasChanges && !postingPartialReport}
      vesselGroup={headerType === 'detailed' ? selectedVesselGroup : undefined}
      loading={loading}
    >
      <KeyVesselFinding
        showVesselName={headerType === 'non-detailed'}
        vesselGroup={selectedVesselGroup}
        editMode={coronaryFindingsState !== undefined}
        coronaryFindings={coronaryFindings}
        draftCoronaryFindings={coronaryFindingsState ?? ''}
        handleInputChange={handleInputChange}
      />
    </EditableCard>
  );
}
