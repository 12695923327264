import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import showToast from '../components/Toast/showToast';
import { STUDY_LOAD_ERROR_MSG, PRIORITY_VESSEL_DEFAULT } from '../config';
import { useReportContext } from '../context/report-context';
import { useStoreContext } from '../context/store-context';
import {
  ContrastLesionDataResponse,
  LesionDataResponse,
  StudyData,
} from '../context/types';
import { VesselDataActions, VesselDataResponse } from '../reducers/vessel-data';
import { useVesselDataContext } from '../context/vessel-data-context';
import { useSetVesselDataSelector, sortVessels } from '../selectors/vessels';
import * as api from '../utils/api';

interface VesselDataFetchInfo {
  // The endpoint identifier the vessel data is fetched from.
  endpointID: string;
  // The name of the VesselData field where the data will be stored.
  fieldID: string;
  // Is the endpoint a calculated "web-data" one vs a direct "vessel/*" one?
  webData: boolean;
}

/**
 * Get all the vesselData in piecewise fashion for speed.
 */
export async function getVesselData(
  patientID: string,
  runID: string,
  versionHead: string,
  reqProperties: any
): Promise<VesselDataResponse> {
  const fetchInfoArray: VesselDataFetchInfo[] = [];

  // Helper function to make building the list of data to fetch easier.
  const addFetchInfo = (
    endpointID: string,
    fieldID: string,
    webData: boolean = false
  ) => {
    fetchInfoArray.push({
      endpointID,
      fieldID,
      webData,
    });
  };

  // Build the list of data to fetch.
  addFetchInfo('calcium-score', 'calcium_score');
  addFetchInfo('centerline', 'centerline');
  addFetchInfo('disease-range', 'disease_range');
  addFetchInfo('graft', 'graft');
  addFetchInfo('lesion-count', 'contrast_lesion_count');
  addFetchInfo('contrast-lesion-ids', 'contrast_lesion_ids');
  addFetchInfo('non-diagnostic', 'non_diagnostic');
  addFetchInfo('plaque', 'plaque');
  addFetchInfo('plaque-composition-counts', 'plaque_composition_counts');
  addFetchInfo('plaque-volume', 'plaque_volume');
  addFetchInfo('positive-remodelling', 'positive_remodelling');
  addFetchInfo('priority-lesion-id', 'priority_lesion_id');
  // addFetchInfo('priority-slice', 'priority_slice'); // This data is not currently required
  addFetchInfo('segments', 'segments');
  addFetchInfo('slice-cmap', 'slice_cmap');
  addFetchInfo('stenosis', 'stenosis');
  // addFetchInfo('stenosis-max', 'stenosis_max'); // This endpoint returns data in the wrong form, see vessel-stenosis-max below instead.
  addFetchInfo('stent', 'stent');
  addFetchInfo('vertex-to-slice-mapping', 'vertex_to_slice_mapping');
  addFetchInfo('vp-biomarker-counts', 'vp_biomarker_counts');
  addFetchInfo('vulnerability', 'vulnerability');
  addFetchInfo('vulnerable-count', 'vulnerable_count');
  addFetchInfo('slice-to-lesion-mapping', 'slice_to_lesion_mapping');
  addFetchInfo('vessel-n-slices', 'n_slices', true);
  addFetchInfo('vessel-stenosis-max', 'stenosis_max', true);

  // Create promises for all the required fetches.
  const promises: any[] = [];
  fetchInfoArray.forEach((fetchInfo) => {
    promises.push(
      api.getJSON(
        `/data/${patientID}/${runID}/${
          fetchInfo.webData ? 'web-data' : 'vessel/*'
        }/${fetchInfo.endpointID}?version=${versionHead}`,
        false,
        reqProperties
      )
    );
  });

  // Wait for all the fetches to finish.
  return Promise.all(promises).then((results) => {
    const vesselData: any = {};

    // Loop through each result and combine them into vesselData.
    fetchInfoArray.forEach((fetchInfo, index) => {
      // The vessel endpoints return their results under vessel_id, the webData endpoints don't.
      let result = results[index];
      if (!fetchInfo.webData && result) {
        result = result.vessel_id;
      }

      if (result) {
        // Loop through every vessel in the result.
        const vessels = Object.keys(result);
        vessels.forEach((vesselId) => {
          // Check this vessel exists in the vesselData.
          if (!vesselData[vesselId]) {
            vesselData[vesselId] = {};
          }
          // Set the bit of the vessel data this fetch retrieved.
          vesselData[vesselId][fetchInfo.fieldID] = result[vesselId];
        });
      } else {
        console.warn('Fetching', fetchInfo.fieldID, 'returned empty data');
      }
    });

    return vesselData;
  });
}

/**
 * Hook to retrieve patient, vessel and calcium data associated with the study in the StoreContext.
 * Responses get persisted in the StoreContext.
 */
export default function usePatientData() {
  const {
    patientID,
    runID,
    patientDataReloadCount,
    currentPatientId,
    setStudyData,
    updateVersionHead,
    updateBackendVersionHead,
    setCalciumScoreData,
    setLesionData,
    setStenosis,
    setInitialDataLoaded,
    setContrastLesionData,
    setNonContrastSpacing,
  } = useStoreContext();

  const setVesselData = useSetVesselDataSelector();
  const vesselDataDispatch = useVesselDataContext().dispatch;

  const { setScreenshots } = useReportContext();
  const prevPatientDataReloadCount = useRef(patientDataReloadCount);
  const history = useHistory();

  useEffect(() => {
    if (!patientID || !runID) return;
    prevPatientDataReloadCount.current = patientDataReloadCount;

    (async () => {
      const patientDataGroup = 'patient-data';
      const reqProperties = {
        patient_id: patientID,
        run_id: runID,
        group_type: patientDataGroup,
      };
      try {
        // Get the current head version which we will need to fetch the other data.
        const versionHead = (
          await api.getJSON(`/data/${patientID}/${runID}/version/head`, true)
        ).head_version;
        updateVersionHead(versionHead);
        updateBackendVersionHead(versionHead, true);

        // The lesion data should be fetched now but it should not block switching to the patient overview screen.
        api
          .getJSON(
            `/data/${patientID}/${runID}/web-data/lesions?version=${versionHead}`,
            false,
            reqProperties
          )
          .then((lesionData: LesionDataResponse) => {
            setLesionData(lesionData, patientID, runID);
          })
          .catch((err) => {
            console.error('DATALOAD ERROR for lesions', err);
          });

        // Request records from api
        const study_data_promise: Promise<StudyData> = api.getJSON(
          `/data/${patientID}/${runID}/web-data/study-data?version=${versionHead}`,
          false,
          reqProperties
        );
        const contrast_lesion_data_promise: Promise<ContrastLesionDataResponse> = api.getJSON(
          `/data/${patientID}/${runID}/web-data/contrast-lesion-data?version=${versionHead}`,
          false,
          reqProperties
        );
        // Fetch the VesselData using our custom piecewise function.
        const vessel_data_promise: Promise<VesselDataResponse> = getVesselData(
          patientID,
          runID,
          versionHead,
          reqProperties
        );
        const calcium_data_promise = api.getJSON(
          `/data/${patientID}/${runID}/web-data/calcium-score?version=${versionHead}`,
          false,
          reqProperties
        );
        const screenshot_list_promise = api.getJSON(
          `/data/${patientID}/${runID}/report/screenshot/list`,
          false,
          reqProperties
        );
        const non_contrast_spacing_promise = api.getJSON(
          `/data/${patientID}/${runID}/volume-non-contrast/spacing?version=${versionHead}`,
          false,
          reqProperties
        );
        const memo_study_promise = api.postJSON(
          `/cache/memo-run?study_id=${patientID}&run_id=${runID}&version=${versionHead}`
        );

        const [
          studyData,
          vesselData,
          calciumData,
          screenshotList,
          contrastLesionData,
          nonContrastSpacing,
        ] = await Promise.all([
          study_data_promise,
          vessel_data_promise,
          calcium_data_promise,
          screenshot_list_promise,
          contrast_lesion_data_promise,
          non_contrast_spacing_promise,
          memo_study_promise,
        ]);

        const stenosis: any = {};
        for (const key in vesselData) {
          stenosis[key] = vesselData[key].stenosis;
        }

        if (patientID === currentPatientId.current) {
          setStudyData(studyData);
          setVesselData(vesselData);
          setCalciumScoreData(calciumData);
          setScreenshots(screenshotList);
          setStenosis(stenosis);
          setContrastLesionData(contrastLesionData);
        }

        if (nonContrastSpacing?.length === 3) {
          setNonContrastSpacing({
            z: nonContrastSpacing[0],
            y: nonContrastSpacing[1],
            x: nonContrastSpacing[2],
          });
        }

        // Set the priority vessel for this study.
        const vessels = sortVessels(vesselData);
        const priorityVessel =
          vessels.find(
            (v) =>
              v.toLowerCase() === studyData?.priority_vessel_id?.toLowerCase()
          ) ?? PRIORITY_VESSEL_DEFAULT;
        vesselDataDispatch(
          VesselDataActions.updatePriorityVessel(priorityVessel)
        );

        // Find the slice on the priority vessel with the maximum stenosis.
        const data = vesselData[priorityVessel] || {};
        const vesselStenosis = data.stenosis || [];
        const highestStenosisIdx = vesselStenosis.indexOf(
          Math.max(...vesselStenosis)
        );
        // Set the the highest priority slice to be the one with the highest stenosis.
        vesselDataDispatch(
          VesselDataActions.updateHighestPriorityIdx(highestStenosisIdx)
        );
        // Set the selected vessel slices centered on the maximum stenosis.
        vesselDataDispatch(
          VesselDataActions.updateSliceIndices({
            mid: highestStenosisIdx,
            high: Math.max(highestStenosisIdx - 10, 0),
            low: Math.min(highestStenosisIdx + 10, vesselStenosis.length - 1),
          })
        );
        // Set the selected vessel to the priority vessel.
        vesselDataDispatch(VesselDataActions.selectVessel(priorityVessel));
      } catch (err) {
        console.error('DATALOAD ERROR', err);
        showToast.error(STUDY_LOAD_ERROR_MSG);
        history.push('/');
      } finally {
        setInitialDataLoaded(true);
      }
    })();
  }, [patientID, runID, patientDataReloadCount]); // eslint-disable-line
}
