import cn from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import { Loader } from '../Loader/Loader';

interface Props {
  theme?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'tertiary-square'
    | 'borderonly';
  inline?: boolean;
  selected?: boolean;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit';
  title?: string;
  children: ReactNode;
  size?: 'default' | 'small';
}

export default function Button({
  theme = 'primary',
  type = 'button',
  title = '',
  selected,
  inline,
  loading,
  disabled,
  onClick,
  children,
  size = 'default',
}: Props): ReactElement<Props> {
  const [first, second] = theme.split('-');
  return (
    <button
      className={cn('button', `button--${first}`, {
        'button--selected': selected,
        'button--inline': inline,
        'button--primary__square': second === 'square',
        'button--small': size === 'small',
        'button-icon': typeof children !== 'string',
      })}
      title={title}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading ? <Loader small inline /> : children}
    </button>
  );
}
