import React, {
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { DEFAULT_VIEW_CONFIG_STATE } from './constants';
import { viewConfigReducer } from './reducer';
import { ViewConfigContextValue } from './types';
import { isNonContrastSeries } from '../../context/drag-n-drop/helpers';

const ViewConfigContext = React.createContext<
  ViewConfigContextValue | undefined
>(undefined);

ViewConfigContext.displayName = 'ViewConfigContext';

interface Props {
  children: ReactNode;
}

export function ViewConfigProvider({ children }: Props): ReactElement<Props> {
  const [viewConfigState, dispatchViewConfigAction] = useReducer(
    viewConfigReducer,
    DEFAULT_VIEW_CONFIG_STATE
  );

  const viewConfigContextValue: ViewConfigContextValue = useMemo(
    () => ({
      state: viewConfigState,
      dispatch: dispatchViewConfigAction,
    }),
    [viewConfigState, dispatchViewConfigAction]
  );

  return (
    <ViewConfigContext.Provider value={viewConfigContextValue}>
      {children}
    </ViewConfigContext.Provider>
  );
}

/**
 * Hook to access the ViewConfigContext
 * @returns {ViewConfigContextValue} ViewConfigContextValue
 */
export function useViewConfigContext(): ViewConfigContextValue {
  const context = useContext(ViewConfigContext);
  if (context === undefined) {
    throw new Error(
      'useViewConfigContext must be used within a ViewConfigProvider.'
    );
  }
  return context;
}

// Returns true if the currently visible CT Volume is a non-contrast series.
export function useIsNonContrastSelector() {
  const {
    state: { seriesId },
  } = useViewConfigContext();
  return isNonContrastSeries(seriesId);
}
