import cn from 'classnames';
import React, { ReactElement, ReactNode } from 'react';
import * as ReactDOM from 'react-dom';
import { useUserContext } from '../../context/user-context';
import { IconButton } from '../IconButton/IconButton';

interface Props {
  children: ReactNode;
  visible: boolean;
  onClose: () => void;
  title: string | JSX.Element;
  inScreenshotMode?: boolean;
  showCloseIcon?: boolean;
}

export default function ScanModal({
  children,
  visible,
  onClose,
  title,
  inScreenshotMode = false,
  showCloseIcon = false,
}: Props): ReactElement<Props> {
  const { clientConfig } = useUserContext();

  return ReactDOM.createPortal(
    <div
      className={cn('scanModal', {
        'scanModal--hidden': !visible,
        'scanModal--screenshot': inScreenshotMode,
      })}
    >
      <div
        className={cn('scanModal__header', {
          'scanModal__header--dark': inScreenshotMode,
        })}
      >
        {title}
        {showCloseIcon && (
          <IconButton
            title="Close"
            icon="close"
            color="white"
            onClick={onClose}
          />
        )}
      </div>
      {clientConfig?.measurement_enabled && (
        <div className="disclaimer-text scanModal-measurement">
          Ruler tools for research purposes only.
        </div>
      )}
      <div className="scanModal__content">{visible && <>{children}</>}</div>
    </div>,
    document.body
  );
}
