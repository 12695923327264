import AWS from 'aws-sdk';
import cn from 'classnames';
import uniq from 'lodash/uniq';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Vec2 } from 'three';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { CalciumContextMenu } from '../../components/CalciumContextMenu/CalciumContextMenu';
import ContrastSeriesIcon, {
  BorderType,
} from '../../components/ContrastSeriesIcon/ContrastSeriesIcon';
import { CTVolumeNonContrastViewer } from '../../components/CTVolumeNonContrastViewer/CTVolumeNonContrastViewer';
import { getViewContrastType } from '../../components/DropZone/helpers';
import ListOfDraggableItems from '../../components/ListOfDraggableItems';
import { Loader } from '../../components/Loader/Loader';
import ViewConfig from '../../components/ViewConfig';
import { MAJOR_VESSELS, NAV_TABS } from '../../config';
import { EMPTY_VIEW_ARRAY } from '../../context/contrast-constants';
import { useContrastContext } from '../../context/contrast-context';
import {
  ContrastViewContent,
  ContrastViewType,
  CTVolumeOverlay,
} from '../../context/contrast-types';
import {
  DragAndDropActions,
  DragAndDropProvider,
  DraggableGroup,
  DraggableHighlight,
  DraggableItem,
  useDragAndDropContext,
} from '../../context/drag-n-drop';
import {
  isContrastSeries,
  isNonContrastSeries,
} from '../../context/drag-n-drop/helpers';
import { useStoreContext } from '../../context/store-context';
import { AIAssessed } from '../../context/types';
import {
  useViewConfigContext,
  ViewConfigActions,
  ViewConfigProvider,
} from '../../context/viewConfig';
import { useWindowContext } from '../../context/window-context';
import { WindowLevels } from '../../context/window-types';
import { useAppSelector } from '../../hooks';
import useMousePositionRef from '../../hooks/use-mouse-position-ref';
import useStudyTabVisibility from '../../hooks/use-study-tab-visibility';
import {
  currentStudySelector,
  SeriesStatus,
  useStudySeriesInfo,
} from '../../selectors/study';
import { useVesselStateSelector } from '../../selectors/vessels';
import ContrastViewer from './ContrastViewer/ContrastViewer';
import { thumbnail } from './default-thumbnail.js';
import MetaDetails from './MetaDetails/MetaDetails';
import { useChangeCalciumCategoryCallback } from './use-callbacks';
interface SeriesInfo {
  type: string;
  id: string;
  title: string;
  thumbnail?: string;
}

const s3Loader = (uri: string) => {
  const s3 = new AWS.S3();
  const parts = uri.split('/');
  const bucket = parts.slice(0, 2).join('/');
  const key = parts.slice(2).join('/');
  return s3.getSignedUrl('getObject', {
    Bucket: bucket,
    Key: key,
    Expires: 3000,
  });
};

function createDraggableGroups(
  ai_assessed: AIAssessed | undefined,
  seriesList: SeriesInfo[],
  showMPRView: boolean
) {
  return seriesList.map(
    ({ type, title, thumbnail, id }): DraggableGroup => {
      // Show if this study was AI assessed or not.
      let extraInfo = undefined;
      if (ai_assessed) {
        if (
          id === ai_assessed.contrast_id ||
          id === ai_assessed.noncontrast_id
        ) {
          extraInfo = 'AI ASSESSED';
        }
      }

      // MPR is only required for the first ai_assessed.contrast_id series
      // Don't show it for the extra contrast series
      // Also discard it if analysis failed (which would set `showMPRView` to false)
      const mprView: DraggableItem[] =
        ai_assessed && id === ai_assessed.contrast_id && showMPRView
          ? [
              {
                title: 'MPR',
                type: 'child',
                id,
                customType: 'mpr',
                extraInfo,
                isDragging: false,
                renderThumbnail: (borderType: BorderType) => (
                  <ContrastSeriesIcon
                    borderType={borderType}
                    imageUrlOrType={'mpr'}
                  />
                ),
              },
            ]
          : [];

      return {
        parent: {
          title,
          type: 'parent',
          id,
          customType: type,
          extraInfo,
          isDragging: false,
        },
        children:
          type !== 'noncontrast'
            ? [
                {
                  title: 'Axial',
                  type: 'child',
                  id,
                  customType: 'axial',
                  extraInfo,
                  isDragging: false,
                  renderThumbnail: (borderType: BorderType) => (
                    <ContrastSeriesIcon
                      borderType={borderType}
                      imageUrlOrType={{ url: thumbnail ?? '' }}
                    />
                  ),
                },
                {
                  title: 'Sagittal',
                  type: 'child',
                  id,
                  customType: 'sagittal',
                  extraInfo,
                  isDragging: false,
                  renderThumbnail: (borderType: BorderType) => (
                    <ContrastSeriesIcon
                      borderType={borderType}
                      imageUrlOrType={'sagittal'}
                    />
                  ),
                },
                {
                  title: 'Coronal',
                  type: 'child',
                  id,
                  customType: 'coronal',
                  extraInfo,
                  isDragging: false,
                  renderThumbnail: (borderType: BorderType) => (
                    <ContrastSeriesIcon
                      borderType={borderType}
                      imageUrlOrType={'coronal'}
                    />
                  ),
                },
                ...mprView,
              ]
            : [],
      };
    }
  );
}

export default function CTVolumeWrapper(): ReactElement {
  return (
    <DragAndDropProvider>
      <ViewConfigProvider>
        <CTVolume />
      </ViewConfigProvider>
    </DragAndDropProvider>
  );
}

function CTVolume(): ReactElement {
  const selectedStudy = useAppSelector(currentStudySelector);

  const {
    calciumScoreData,
    visibleTab,
    initialDataLoaded,
    nonContrastSlice,
    setNonContrastSlice,
  } = useStoreContext();
  const {
    state: { seriesId },
    dispatch: dispatchViewConfigAction,
  } = useViewConfigContext();
  const { ctNonContrastViewerData } = useVesselStateSelector();
  const [chosenVessel, setChosenVessel] = useState<string | null>(null);
  const [initialLoad, setInitialLoad] = useState(false);
  const [showNonContrastViewer, setShowNonContrastViewer] = useState(false);
  const [showContrastViewer, setShowContrastViewer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updatedLesions, setUpdatedLesions] = useState<any[]>([]);
  const [fullLesionData, setFullLesionData] = useState<any[]>([]);
  const [selectedLesions, setSelectedLesions] = useState<any[]>([]);
  const [calciumData, setCalciumData] = useState(null);
  const [nonContrastViewOverlay, setNonContrastViewOverlay] = useState<
    CTVolumeOverlay
  >(CTVolumeOverlay.INFO);
  const [showCalciumClear, setShowCalciumClear] = useState(false);
  const [vesselToClear, setVesselToClear] = useState<string>('');
  const [disableCalciumConfirm, setDisableCalciumConfirm] = useState<boolean>(
    false
  );
  const {
    nonContrastWindowLevels,
    setNonContrastWindowLevels,
    nonContrastWindowLabel,
  } = useWindowContext();
  const scanModalRef = useRef<HTMLDivElement>(null);
  const screenshotRef = useRef<any | undefined>();
  const ctHURef = useRef<any | undefined>();
  const windowWidthRef = useRef<any | undefined>();
  const windowLevelRef = useRef<any | undefined>();
  const deselectLesionsRef = useRef<any | null>(null);
  const { getStyles } = useStudyTabVisibility(NAV_TABS.ctVolumeTab);

  // TODO: switch out defaut thumbnail with series thumbnail when available
  const thumbnailDefault = thumbnail.data;

  const [seriesList, setSeriesList] = useState<any[]>([]);
  const [seriesScreenshot, setSeriesScreenshot] = useState(null);

  const [
    calciumContextMenuPosition,
    setCalciumContextMenuPosition,
  ] = useState<Vec2 | null>(null);

  const [showCalciumContextSubMenu, setShowCalciumContextSubMenu] = useState(
    false
  );

  const hideCalciumContextMenu = useCallback(() => {
    setCalciumContextMenuPosition(null);

    setShowCalciumContextSubMenu(false);
  }, [setCalciumContextMenuPosition, setShowCalciumContextSubMenu]);

  const [vesselCalciumVisible, setVesselCalciumVisible] = useState<{
    [key: string]: boolean;
  }>();
  const calciumVisible = useRef<{ [key: string]: boolean }>();

  useEffect(() => {
    if (initialLoad) setInitialLoad(true);
  }, [initialLoad, setInitialLoad]);

  useEffect(() => {
    if (seriesList.length > 0) {
      setLoading(false);
    }
  }, [seriesList]);

  useEffect(() => {
    setCalciumData(calciumScoreData ? calciumScoreData : null);
    // Setup the vessel calcium score toggles
    if (!vesselCalciumVisible && calciumScoreData) {
      const keyVisible: { [key: string]: boolean } = {};
      Object.keys(calciumScoreData).forEach((key) => {
        if (key === 'total') {
          keyVisible['other'] = true;
        } else {
          keyVisible[key] = true;
        }
      });
      keyVisible['all'] = true;
      setVesselCalciumVisible(keyVisible);
      calciumVisible.current = keyVisible;
    }
  }, [calciumScoreData, vesselCalciumVisible]);

  useEffect(() => {
    if (selectedStudy && selectedStudy.series) {
      setSeriesList(
        [
          ...Object.entries(selectedStudy.series).map(
            ([label, value]: any[]) => ({
              value: label,
              label: value.series_description,
              thickness: value.thickness ? `${value.thickness}mm` : '',
              studyDate: value.date,
              windowLevels:
                value.ww && value.wl
                  ? {
                      windowWidth: parseFloat(value.ww),
                      windowCenter: parseFloat(value.wl),
                    }
                  : undefined,
              thumbnail: value.thumbnail_url
                ? s3Loader(value.thumbnail_url)
                : thumbnailDefault,
            })
          ),
        ].sort((a, b) => {
          if (
            a.value === selectedStudy.ai_assessed.contrast_id ||
            a.value === selectedStudy.ai_assessed.noncontrast_id
          )
            return -1;
          return 1;
        })
      );
    }
  }, [selectedStudy, thumbnailDefault]);

  const onViewerReady = ({ deselectLesions }: any) => {
    setLoading(false);
    deselectLesionsRef.current = deselectLesions;
  };

  const updateVesselToggle = (toggle: { [key: string]: boolean }) => {
    if (!vesselCalciumVisible) return;
    if (Object.keys(toggle)[0] === 'all') {
      // Toggling all calcium vessels
      const newVisible: { [key: string]: boolean } = {};
      Object.keys(vesselCalciumVisible).forEach((key) => {
        newVisible[key] = toggle['all'];
      });
      calciumVisible.current = { ...newVisible, ...toggle };
    } else {
      const allToggle: { [key: string]: boolean } = {};
      if (!Object.values(toggle)[0]) {
        // if selecting any vessel off, set the show all to be off also
        allToggle['all'] = false;
      } else {
        // toggling on so check if all the other are on and check the 'show all' if so
        let allOn = true;
        Object.entries(vesselCalciumVisible).forEach(([key, val]) => {
          if (key !== Object.keys(toggle)[0] && key !== 'all' && !val) {
            allOn = false;
          }
        });
        allToggle['all'] = allOn;
      }
      calciumVisible.current = {
        ...vesselCalciumVisible,
        ...toggle,
        ...allToggle,
      };
    }
    setVesselCalciumVisible(calciumVisible.current);
  };

  const onTakeScreenshot = () => {
    setSeriesScreenshot(null);
  };

  const onCloseScreenshot = () => {
    setSeriesScreenshot(null);
  };

  const onLesionEnter = useCallback(
    (lesion: any, event: React.MouseEvent) => {
      if (calciumVisible.current && calciumVisible.current[lesion.category]) {
        setSelectedLesions([lesion]);
        setCalciumContextMenuPosition({
          x: event.clientX,
          y: event.clientY,
        });
      }
    },
    [setSelectedLesions, setCalciumContextMenuPosition]
  );

  const onLesionLeave = useCallback(() => {
    hideCalciumContextMenu();
  }, [hideCalciumContextMenu]);

  const mousePositionRef = useMousePositionRef();

  const onLesionLasso = (lesions: any, event: Event) => {
    const visibleVessels: string[] = [];
    // Check if the lesion vessels are visible (i.e. toggled on or off)
    if (calciumVisible.current) {
      Object.entries(calciumVisible.current).forEach(([key, value]) => {
        if (calciumVisible.current && value === true && key !== 'all') {
          visibleVessels.push(key);
        }
      });
    }
    const filteredLesions = lesions.filter((l: any) =>
      visibleVessels.includes(l.category)
    );
    setSelectedLesions(filteredLesions);

    const xPos = mousePositionRef.current.x;
    const yPos = mousePositionRef.current.y;
    lesions.length &&
      xPos !== null &&
      yPos !== null &&
      setCalciumContextMenuPosition({
        x: xPos,
        y: yPos,
      });
  };

  const onChangeCalciumCategory = useChangeCalciumCategoryCallback(
    selectedLesions,
    setUpdatedLesions,
    deselectLesionsRef,
    () => hideCalciumContextMenu(),
    setChosenVessel
  );

  const clearVesselCalcium = (vessel: string) => {
    if (fullLesionData.length <= 0) return;
    // Gether list of lesion data for the selected vessel to clear
    const selected: React.SetStateAction<any[]> = [];
    Object.values(fullLesionData).forEach((l: any[]) => {
      l.forEach((l: any) => {
        if (l.category === vessel) {
          selected.push(l);
        }
      });
    });
    setSelectedLesions(selected);
    setShowCalciumClear(true); // Show confirmation dialog
    setVesselToClear(vessel);
  };

  const confirmClearVessel = () => {
    setDisableCalciumConfirm(true);
    // Clearing the lesions within the vessel (i.e. setting to 'other')
    onChangeCalciumCategory({
      old: vesselToClear,
      new: 'other',
    }).then(() => {
      // Hide the confirmation modal
      setShowCalciumClear(false);
      setDisableCalciumConfirm(false);
    });
  };

  const getSelectedVesselType = () => {
    if (!selectedLesions.length) {
      return 'Other';
    } else {
      const cats = uniq(selectedLesions.map((l) => l.category));
      if (cats.length > 1) {
        return 'Multiple';
      } else {
        return cats[0] !== 'other' ? cats[0].toUpperCase() : 'Other';
      }
    }
  };

  const {
    dispatch: dispatchDragAndDropAction,
    state,
  } = useDragAndDropContext();

  const {
    contrastViews,
    volumeViewerRef,
    onDraggedContrastSeries,
    visibleViews,
  } = useContrastContext();

  const studySeriesInfo = useStudySeriesInfo();
  const showMprView = useMemo(() => {
    return (
      studySeriesInfo?.contrast !== SeriesStatus.Fail &&
      studySeriesInfo?.contrast !== SeriesStatus.NotAvailable
    );
  }, [studySeriesInfo]);

  useEffect(() => {
    if (visibleTab === NAV_TABS.ctVolumeTab) {
      const seriesInfos: SeriesInfo[] = seriesList.map((x: any) => ({
        id: x.value,
        type: isNonContrastSeries(x.value) ? 'noncontrast' : 'contrast',
        title: x.label,
        thumbnail: x.thumbnail,
      }));

      const draggableGroups = createDraggableGroups(
        selectedStudy?.ai_assessed,
        seriesInfos,
        showMprView
      );
      if (
        Object.keys(state.draggableGroup).length === 0 &&
        draggableGroups.length > 0 &&
        selectedStudy
      ) {
        dispatchDragAndDropAction(
          DragAndDropActions.createDraggableGroup(
            draggableGroups,
            selectedStudy.ai_assessed
          )
        );

        dispatchViewConfigAction(
          ViewConfigActions.changeSeriesId(draggableGroups[0].parent.id)
        );

        if (
          draggableGroups[0].parent.customType !== 'noncontrast' &&
          selectedStudy
        ) {
          const newConfig: ContrastViewContent[] = draggableGroups[0].children.map(
            (x) => {
              const viewtype = getViewContrastType(x.customType);
              return {
                study: selectedStudy,
                seriesName: draggableGroups[0].parent.id,
                viewType: viewtype ?? ContrastViewType.Empty,
              };
            }
          );

          // In case there are missing views, make 4 empty views, add after the actual number of views then limit to 4.
          const contrastViews = [...newConfig, ...EMPTY_VIEW_ARRAY].slice(0, 4);
          onDraggedContrastSeries(contrastViews);
        }
      }
    }
  }, [
    visibleTab,
    seriesList,
    dispatchDragAndDropAction,
    state.draggableGroup,
    dispatchViewConfigAction,
    selectedStudy,
    onDraggedContrastSeries,
    showMprView,
  ]);

  const keysToGroupsItemsToHighlight: string[] = useMemo(() => {
    if (seriesId !== undefined && isNonContrastSeries(seriesId)) {
      return [seriesId];
    } else {
      const allNonEmptyViews = contrastViews.filter(
        (x) => x.viewType !== ContrastViewType.Empty
      );

      if (
        seriesId &&
        allNonEmptyViews.every((x) => x.seriesName === seriesId)
      ) {
        return [seriesId];
      } else {
        return [];
      }
    }
  }, [contrastViews, seriesId]);

  const keysAndTypesToChildItemsToHighlight: DraggableHighlight[] = useMemo(() => {
    if (isNonContrastSeries(seriesId)) {
      return [];
    } else {
      return contrastViews.map(
        (contrastView: ContrastViewContent, viewIndex: number) => {
          return {
            key: contrastView.seriesName!,
            type: contrastView.viewType,
            visible: visibleViews.includes(viewIndex),
          };
        }
      );
    }
  }, [contrastViews, visibleViews, seriesId]);

  // Decide if we should now be showing the NonContrast and Contrast viewers (ie have them mounted).
  // They are initially unmounted but should stay mounted for a patient after they are first shown.
  useEffect(() => {
    if (visibleTab === NAV_TABS.ctVolumeTab) {
      if (isContrastSeries(seriesId)) {
        setShowContrastViewer(true);
      } else if (isNonContrastSeries(seriesId)) {
        setShowNonContrastViewer(true);
      }
    }
  }, [visibleTab, seriesId, setShowNonContrastViewer, setShowContrastViewer]);

  return (
    <div className="contrast-viewer-container" style={getStyles()}>
      <div className="viewport-config-container">
        <ViewConfig />
        <div className="draggable-list">
          <ListOfDraggableItems
            keysToGroupsItemsToHighlight={keysToGroupsItemsToHighlight}
            keysAndTypesToChildItemsToHighlight={
              keysAndTypesToChildItemsToHighlight
            }
          />
        </div>
      </div>
      <div className="volume-viewer" ref={volumeViewerRef}>
        {loading &&
        !initialDataLoaded &&
        visibleTab === NAV_TABS.ctVolumeTab ? (
          <Loader text={`Loading CT Volume`} large fullScreen />
        ) : (
          <div className="ct-volume">
            <div
              className="ct-volume__view-wrap"
              ref={scanModalRef}
              style={{
                maxWidth: '100%',
              }}
            >
              <div className="scanModal__content">
                <div className="ct-volume__view-wrap" ref={scanModalRef}>
                  {showContrastViewer && (
                    <div
                      id={`view_contrast`}
                      key={`view_contrast`}
                      className={cn('ct-volume__view', {
                        'ct-volume__view--show':
                          !seriesScreenshot && isContrastSeries(seriesId),
                      })}
                    >
                      <ContrastViewer />
                    </div>
                  )}
                  {showNonContrastViewer && (
                    <div
                      id={`view_noncontrast`}
                      key={`view_noncontrast`}
                      ref={screenshotRef}
                      className={cn('ct-volume__view', {
                        'ct-volume__view--show':
                          !seriesScreenshot && isNonContrastSeries(seriesId),
                      })}
                    >
                      <>
                        <div className="ct-volume__non-contrast-meta-details">
                          <MetaDetails
                            windowLevels={nonContrastWindowLevels}
                            windowLabel={nonContrastWindowLabel}
                            studyDetails={selectedStudy}
                            calciumScoreData={calciumData}
                            HURef={ctHURef}
                            windowWidthRef={windowWidthRef}
                            windowLevelRef={windowLevelRef}
                            slice={nonContrastSlice}
                            overlayToShow={nonContrastViewOverlay}
                            vesselCalciumVisible={calciumVisible.current}
                            onClearCalciumScore={clearVesselCalcium}
                            onToggleVesselCalcium={updateVesselToggle}
                          />
                        </div>
                        {calciumContextMenuPosition && (
                          <CalciumContextMenu
                            onChange={onChangeCalciumCategory}
                            vessels={MAJOR_VESSELS}
                            selectedVessel={getSelectedVesselType()}
                            setChosenVessel={setChosenVessel}
                            chosenVessel={chosenVessel}
                            menuPosition={calciumContextMenuPosition}
                            showCalciumContextSubMenu={
                              showCalciumContextSubMenu
                            }
                            setShowCalciumContextSubMenu={
                              setShowCalciumContextSubMenu
                            }
                          />
                        )}
                        <CTVolumeNonContrastViewer
                          onHueChange={(hue: any) =>
                            ctHURef.current && ctHURef.current.setValue(hue)
                          }
                          onWindowLevelsChange={(wl: WindowLevels) => {
                            windowWidthRef.current &&
                              windowWidthRef.current.setValue(wl.windowWidth);
                            windowLevelRef.current &&
                              windowLevelRef.current.setValue(wl.windowCenter);
                            setNonContrastWindowLevels(wl);
                          }}
                          slice={nonContrastSlice}
                          onSliceChange={setNonContrastSlice}
                          onLesionEnter={onLesionEnter}
                          onLesionLeave={onLesionLeave}
                          onLesionLasso={onLesionLasso}
                          onReady={onViewerReady}
                          updatedLesions={updatedLesions}
                          onUpdateLesions={() => setUpdatedLesions([])}
                          onZoom={() => hideCalciumContextMenu()}
                          onDrag={() => hideCalciumContextMenu()}
                          onDraw={() => hideCalciumContextMenu()}
                          label={
                            seriesList.find(
                              (series) => series.value === seriesId
                            )?.label
                          }
                          nonContrastViewOverlay={nonContrastViewOverlay}
                          setNonContrastViewOverlay={setNonContrastViewOverlay}
                          screenshotRef={screenshotRef}
                          onTakeScreenshot={onTakeScreenshot}
                          onCloseScreenshot={onCloseScreenshot}
                          onSetFullLesionData={setFullLesionData}
                          vesselCalciumVisible={calciumVisible.current}
                          viewerData={ctNonContrastViewerData}
                        />
                        <ActionModal
                          confirmText={'Delete'}
                          onClose={() => setShowCalciumClear(false)}
                          onConfirm={confirmClearVessel}
                          visible={showCalciumClear}
                          headerContent={<>Delete</>}
                          disabled={disableCalciumConfirm}
                        >
                          <p>
                            Are you sure you want to remove all{' '}
                            {vesselToClear.toUpperCase()} lesions?
                          </p>
                        </ActionModal>
                        {loading && <Loader />}
                      </>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
