import React, { ReactElement } from 'react';
import { useAppSelector } from '../hooks';
import { globalLoaderSelector } from '../selectors/globalLoader';
import { Loader } from './Loader/Loader';

export function GlobalLoader(): ReactElement | null {
  const { show, message } = useAppSelector(globalLoaderSelector);

  return show ? <Loader text={message} large fullScreen /> : null;
}
