import React, { ReactElement, useCallback, useMemo } from 'react';
import Card from '../../components/Card';
import { useStoreContext } from '../../context/store-context';
import { useDraftStudyStats, useStudyStats } from '../../selectors/reporting';
import { useStudySeriesInfo, SeriesStatus } from '../../selectors/study';
import CadRadsStat from './CadRadsStat';
import CalciumScoreStat from './CalciumScoreStats';
import MaximumStenosisStat from './MaximumStenosisStat';
import SisDataStat from './SisDataStat';
import VulnerablePlaque from './VulnerablePlaque';

interface Props {
  editingReport: boolean;
  highlightKeyVesselRelatedFields: boolean;
}

export default function InlineStats({
  highlightKeyVesselRelatedFields,
  editingReport,
}: Props): ReactElement<Props> {
  const studyStats = useStudyStats();
  const draftStudyStats = useDraftStudyStats();
  const { updateDraftReport } = useStoreContext();

  const studySeriesInfo = useStudySeriesInfo();
  const cadradsAndSisEditable = useMemo(() => {
    return studySeriesInfo?.contrast === SeriesStatus.Fail;
  }, [studySeriesInfo?.contrast]);

  const handleUpdateReportStats = useCallback(
    (stat: string, newValue: string) => {
      updateDraftReport({
        [stat]: newValue,
      });
    },
    [updateDraftReport]
  );

  const reportObject = useMemo(() => {
    return editingReport ? draftStudyStats : studyStats;
  }, [editingReport, studyStats, draftStudyStats]);

  return (
    <Card
      cardHighlited={highlightKeyVesselRelatedFields}
      style={{ padding: '16px 12px' }}
    >
      <div className="stats stats--inline">
        {reportObject?.calcium_score && (
          <CalciumScoreStat
            calciumStat={reportObject.calcium_score}
            editingReport={editingReport}
          />
        )}
        {reportObject?.maximum_stenosis && (
          <MaximumStenosisStat
            stats={reportObject.maximum_stenosis}
            editingReport={editingReport}
            handleUpdateReportStats={handleUpdateReportStats}
          />
        )}
        {reportObject?.vulnerable_plaque && (
          <VulnerablePlaque
            stats={reportObject.vulnerable_plaque}
            editingReport={editingReport}
            handleUpdateReportStats={handleUpdateReportStats}
          />
        )}
        {reportObject?.cad_rads && (
          <CadRadsStat
            stats={reportObject.cad_rads}
            editingReport={editingReport}
            handleUpdateReportStats={handleUpdateReportStats}
            editable={cadradsAndSisEditable}
          />
        )}
        {reportObject?.sis_data && (
          <SisDataStat
            label={reportObject.sis_data.label}
            value={`${reportObject.sis_data.value}`}
            editingReport={editingReport}
            handleUpdateReportStats={handleUpdateReportStats}
            editable={cadradsAndSisEditable}
          />
        )}
      </div>
    </Card>
  );
}
