import { ActionType, createAction } from 'typesafe-actions';
import { DraggableGroup, SelectedDraggable } from './types';
import { AIAssessed } from '../types';

export const DragAndDropActions = {
  createDraggableGroup: createAction(
    'Drag-n-drop/CREATE_DRAGGABLE_GROUP',
    (draggableGroups: DraggableGroup[], ai_assessed: AIAssessed) => {
      return { draggableGroups, ai_assessed };
    }
  )(),
  dragging: createAction(
    'Drag-n-drop/DRAGGING',
    (selected: SelectedDraggable) => selected
  )(),
  onSnapBack: createAction(
    'Drag-n-drop/ON_SNAP_BACK',
    (payload: SelectedDraggable) => payload
  )(),
};

export type DragAndDropAction = ActionType<typeof DragAndDropActions>;
