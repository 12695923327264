import { isEqual } from 'lodash';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { InlineReportingActions } from '../../context/inlineReporting/actions';
import { useInlineReportingContext } from '../../context/inlineReporting/context';
import { useStoreContext } from '../../context/store-context';
import { useSaveImpressionsCallback } from '../../hooks/useSaveCallBack';
import {
  useExtraCardiacFindings,
  useImpressionsSelector,
  useInlineReportingDraftImpressionsSelector,
} from '../../selectors/reporting';
import { RouteParams } from '../../types';
import EditableCard from '../EditableCard';
import EditableTextField from '../EditableTextField';

interface Props {
  aiAssessed: boolean;
  loading?: boolean;
}

export default function ImpressionsCard({
  aiAssessed,
  loading = false,
}: Props): ReactElement<Props> {
  const { postingPartialReport } = useStoreContext();
  const { id } = useParams<RouteParams>();
  const {
    state: inlineReportingState,
    dispatch: dispatchInlineReportingAction,
  } = useInlineReportingContext();

  const impressions = useImpressionsSelector();
  const extraCardiacFindings = useExtraCardiacFindings();

  const onClickEditButton = useCallback(() => {
    dispatchInlineReportingAction(
      InlineReportingActions.updateImpressions(impressions)
    );
  }, [impressions, dispatchInlineReportingAction]);

  const onCancel = useCallback(() => {
    dispatchInlineReportingAction(
      InlineReportingActions.updateImpressions(undefined)
    );
  }, [dispatchInlineReportingAction]);

  const onSaveImpressions = useSaveImpressionsCallback(
    id,
    inlineReportingState.impressions
  );

  const updateDraftImpression = useCallback(
    (value: string) => {
      dispatchInlineReportingAction(
        InlineReportingActions.updateImpressions(
          value.trim().length === 0 ? [] : value.split('\n')
        )
      );
    },
    [dispatchInlineReportingAction]
  );

  const hasChanges = useMemo(
    () => !isEqual(impressions, inlineReportingState.impressions),
    [inlineReportingState.impressions, impressions]
  );

  const draftImpressions = useInlineReportingDraftImpressionsSelector();

  const onSave = useCallback(() => {
    onSaveImpressions();

    dispatchInlineReportingAction(
      InlineReportingActions.updateImpressions(undefined)
    );
  }, [onSaveImpressions, dispatchInlineReportingAction]);

  return (
    <EditableCard
      editMode={!!inlineReportingState.impressions}
      onClick={onClickEditButton}
      onCancel={onCancel}
      onSave={onSave}
      aiAssessed={aiAssessed}
      title="Impression"
      headerType="non-detailed"
      saveEnabled={hasChanges && !postingPartialReport}
      loading={loading}
    >
      <EditableTextField
        editMode={!!inlineReportingState.impressions}
        text={
          extraCardiacFindings.length > 0
            ? [...impressions, extraCardiacFindings]
            : impressions
        }
        updateDraftText={updateDraftImpression}
        draftText={draftImpressions}
      />
    </EditableCard>
  );
}
