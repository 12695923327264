import React, { useEffect, useState } from 'react';
import Button from '../../components/Button/Button';
import { EditFindings } from '../../components/EditFindings/EditFindings';
import { IconButton } from '../../components/IconButton/IconButton';
import cn from 'classnames';

interface OtherFindingsProps {
  question: string;
  defaultAnswer: string;
  answer: string;
  draftAnswer: string;
  onAnswered: (answered: boolean) => void;
  onUpdateOtherFindings: (value: string) => void;
  editMode: boolean;
}

export const OtherFindings: React.FC<OtherFindingsProps> = ({
  question,
  defaultAnswer,
  onAnswered,
  onUpdateOtherFindings,
  editMode,
  answer,
  draftAnswer,
}) => {
  const [showButtons, setShowButtons] = useState<boolean>(
    editMode ? draftAnswer.length < 1 || draftAnswer === defaultAnswer : true
  );
  const [canEnterFindings, setCanEnterFindings] = useState<boolean>(false);

  useEffect(() => {
    if (editMode) {
      if (draftAnswer.length > 0) {
        setShowButtons(false);
      } else {
        setShowButtons(true);
      }
    }
  }, [editMode, setShowButtons, defaultAnswer, draftAnswer]);

  useEffect(() => {
    if (editMode) {
      setCanEnterFindings(draftAnswer !== defaultAnswer && !showButtons);
    } else {
      setCanEnterFindings(false);
    }
  }, [draftAnswer, defaultAnswer, editMode, showButtons]);

  const handleNo = () => {
    onAnswered(true);
    setShowButtons(false);
    onUpdateOtherFindings(defaultAnswer);
    setCanEnterFindings(true);
  };

  const handleYes = () => {
    setShowButtons(false); // Hide Yes / No buttons
    setCanEnterFindings(true); // Show input component
    onUpdateOtherFindings('');
  };
  const handleRevert = () => {
    onAnswered(true);
    setShowButtons(true);
    onUpdateOtherFindings('');
    setCanEnterFindings(false);
  };

  const handleExitNotes = (inputValue: string) => {
    if (inputValue) {
      onUpdateOtherFindings(inputValue);
      onAnswered(true);
      onUpdateOtherFindings(inputValue);
    } else {
      setCanEnterFindings(true);
      onUpdateOtherFindings(defaultAnswer);
    }
  };

  const displayAnswer = editMode ? draftAnswer : answer;

  return (
    <div className="other-findings">
      {!canEnterFindings && (
        <p
          className={cn('other-findings__text', {
            'other-findings__answer': !showButtons,
          })}
        >
          {displayAnswer.length > 0 ? displayAnswer : question}
        </p>
      )}
      {canEnterFindings && (
        <EditFindings
          placeholderText="Click here to add notes"
          savedValue={draftAnswer}
          onUpdateFindings={handleExitNotes}
          forceEdit={editMode ? !showButtons : false}
        />
      )}
      {editMode && (
        <>
          {showButtons ? (
            <div className="other-findings__actions">
              <Button theme="secondary" onClick={handleYes}>
                Yes
              </Button>
              <Button theme="secondary" onClick={handleNo}>
                No
              </Button>
            </div>
          ) : (
            <div className="other-findings__reload">
              <IconButton
                color="primary"
                icon="reload"
                onClick={handleRevert}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
