import React, { ReactElement } from 'react';
import Helmet from 'react-helmet';

interface Props {
  title: string | undefined;
}

export default function PageTitle({ title }: Props): ReactElement<Props> {
  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${process.env.REACT_APP_NAME}`}
    />
  );
}
