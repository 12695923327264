import { vec3 } from 'gl-matrix';
import vtkImageData from 'vtk.js/Sources/Common/DataModel/ImageData';
import vtkDataArray from 'vtk.js/Sources/Common/Core/DataArray';
import buildMetadata from './data/buildMetadata';
import imageDataCache from './data/imageDataCache';
import sortDatasetsByImagePosition from './data/sortDatasetsByImagePosition';
import { VolumeImageData } from '../ReactVTKJSTypes';

export default function getImageData(
  imageIds: string[],
  displaySetInstanceUid: string
) {
  const cachedImageDataObject = imageDataCache.get(displaySetInstanceUid);

  if (cachedImageDataObject) {
    return cachedImageDataObject;
  }

  const { metaData0, metaDataMap, imageMetaData0 } = buildMetadata(imageIds);

  const rowCosineVec = vec3.fromValues(
    metaData0.rowCosines[0],
    metaData0.rowCosines[1],
    metaData0.rowCosines[2]
  );
  const colCosineVec = vec3.fromValues(
    metaData0.columnCosines[0],
    metaData0.columnCosines[1],
    metaData0.columnCosines[2]
  );
  const scanAxisNormal: vec3 = [0, 0, 0];
  vec3.cross(scanAxisNormal, rowCosineVec, colCosineVec);

  const { spacing, origin, sortedDatasets } = sortDatasetsByImagePosition(
    scanAxisNormal,
    metaDataMap
  );

  const xSpacing = metaData0.columnPixelSpacing;
  const ySpacing = metaData0.rowPixelSpacing;
  const zSpacing = spacing;
  const xVoxels = metaData0.columns;
  const yVoxels = metaData0.rows;
  const zVoxels = sortedDatasets.length;
  const signed = imageMetaData0.pixelRepresentation === 1;
  const multiComponent = metaData0.numberOfComponents > 1;

  // TODO: Support numberOfComponents = 3 for RGB?
  if (multiComponent) {
    throw new Error('Multi component image not supported by this plugin.');
  }

  let pixelArray;
  switch (imageMetaData0.bitsAllocated) {
    case 8:
      if (signed) {
        throw new Error(
          '8 Bit signed images are not yet supported by this plugin.'
        );
      } else {
        throw new Error(
          '8 Bit unsigned images are not yet supported by this plugin.'
        );
      }

    case 16:
      // We used to store the data as a Float32Array but an Int16Array uses 1/2 the memory and loses no precision for the data we will give it.
      // NOTE: We could use a Uint8Array here and that would load the volume texture as Uint8 as well. This would be lossy however and need some value remapping for the hu values.
      pixelArray = new Int16Array(xVoxels * yVoxels * zVoxels);
      break;

    default:
      throw new Error(
        `Unexpected bit depth of ${imageMetaData0.bitsAllocated}`
      );
  }

  const scalarArray = vtkDataArray.newInstance({
    name: 'Pixels',
    numberOfComponents: 1,
    values: pixelArray,
  });

  const imageData = vtkImageData.newInstance();
  const direction = [...rowCosineVec, ...colCosineVec, ...scanAxisNormal];

  imageData.setDimensions(xVoxels, yVoxels, zVoxels);
  imageData.setSpacing([xSpacing, ySpacing, zSpacing]);
  imageData.setDirection(
    direction[0],
    direction[1],
    direction[2],
    direction[3],
    direction[4],
    direction[5],
    direction[6],
    direction[7],
    direction[8]
  );
  imageData.setOrigin([origin[0], origin[1], origin[2]]);
  imageData.getPointData().setScalars(scalarArray);

  const _publishPixelDataInserted = (count: number) => {
    imageDataObject.subscriptions.onPixelDataInserted.forEach((callback) => {
      callback(count);
    });
  };

  const _publishPixelDataInsertedError = (error: Error) => {
    imageDataObject.subscriptions.onPixelDataInsertedError.forEach(
      (callback) => {
        callback(error);
      }
    );
  };

  const _publishAllPixelDataInserted = () => {
    imageDataObject.subscriptions.onAllPixelDataInserted.forEach((callback) => {
      callback();
    });
    imageDataObject.isLoading = false;
    imageDataObject.loaded = true;
    imageDataObject.vtkImageData.modified();

    // Remove all subscriptions on completion.
    imageDataObject.subscriptions = {
      onPixelDataInserted: [],
      onPixelDataInsertedError: [],
      onAllPixelDataInserted: [],
    };
  };

  const imageDataObject: VolumeImageData = {
    imageIds,
    metaData0,
    imageMetaData0,
    dimensions: [xVoxels, yVoxels, zVoxels],
    spacing: [xSpacing, ySpacing, zSpacing],
    origin: [origin[0], origin[1], origin[2]],
    direction,
    vtkImageData: imageData,
    metaDataMap,
    sortedDatasets,
    loaded: false,
    subscriptions: {
      onPixelDataInserted: [],
      onPixelDataInsertedError: [],
      onAllPixelDataInserted: [],
    },
    isLoading: false,
    onPixelDataInserted: (callback: any) => {
      imageDataObject.subscriptions.onPixelDataInserted.push(callback);
    },
    onPixelDataInsertedError: (callback: (error: Error) => void) => {
      imageDataObject.subscriptions.onPixelDataInsertedError.push(callback);
    },
    onAllPixelDataInserted: (callback: any) => {
      imageDataObject.subscriptions.onAllPixelDataInserted.push(callback);
    },
    _publishPixelDataInserted,
    _publishAllPixelDataInserted,
    _publishPixelDataInsertedError,
  };

  imageDataCache.set(displaySetInstanceUid, imageDataObject);

  return imageDataObject;
}
