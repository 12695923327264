import React, { useEffect, useState } from 'react';
import { useStoreContext } from '../../context/store-context';
import { fetchPlaqueMeasurements } from '../../utils/api';
import { PlaqueCategories, PlaqueMeasurements } from '../../context/types';
import { forEach } from 'lodash';
import { Loader } from '../Loader/Loader';

export const OverallMeasurements: React.FC = () => {
  const {
    runID,
    patientID,
    fetchingOverallMeasurements,
    setFetchingOverallMeasurements,
  } = useStoreContext();

  const DEFAULT_PLAQUE_VALUES: PlaqueCategories = {
    total: undefined,
    lap: undefined,
    ca: undefined,
    nca: undefined,
  };

  const DEFAULT_PLAQUE_MEASUREMENT_VALUES: PlaqueMeasurements = {
    volume: DEFAULT_PLAQUE_VALUES,
    burden: DEFAULT_PLAQUE_VALUES,
  };

  const [plaqueMeasurements, setPlaqueMeasurements] = useState<
    PlaqueMeasurements
  >(DEFAULT_PLAQUE_MEASUREMENT_VALUES);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!patientID || !runID) {
      return;
    }
    setLoading(true);
    setFetchingOverallMeasurements(true);

    fetchPlaqueMeasurements(patientID, runID)
      .then((res: PlaqueMeasurements) => {
        let plaqueBurden: PlaqueCategories = { ...DEFAULT_PLAQUE_VALUES };
        let plaqueVolume: PlaqueCategories = { ...DEFAULT_PLAQUE_VALUES };

        forEach(res.burden, (value, key) => {
          let percentageBurden = Number(value) * 100;
          plaqueBurden[
            key as keyof PlaqueCategories
          ] = percentageBurden.toFixed(2);
        });
        forEach(res.volume, (value, key) => {
          plaqueVolume[key as keyof PlaqueCategories] = Number(value).toFixed(
            2
          );
        });

        res &&
          setPlaqueMeasurements({
            volume: plaqueVolume,
            burden: plaqueBurden,
          });

        setLoading(false);
        setFetchingOverallMeasurements(false);
      })
      .catch(() => {
        setPlaqueMeasurements(DEFAULT_PLAQUE_MEASUREMENT_VALUES);
        setLoading(false);
        setFetchingOverallMeasurements(false);
      });
  }, [patientID, runID]);

  return (
    <div>
      <div className="loading-measurement">{loading && <Loader inline />}</div>
      {!fetchingOverallMeasurements && (
        <div className="measurements">
          <div className="measurements__section">
            <div className="measurement__section-value">
              {plaqueMeasurements.volume.total &&
                !plaqueMeasurements.burden.total && (
                  <span>{plaqueMeasurements.volume.total} mm² (-%)</span>
                )}

              {!plaqueMeasurements.volume.total &&
                plaqueMeasurements.burden.total && (
                  <span>-mm² ({plaqueMeasurements.burden.total}%)</span>
                )}

              {plaqueMeasurements.volume.total &&
              plaqueMeasurements.burden.total ? (
                <span>
                  {plaqueMeasurements.volume.total} mm² (
                  {plaqueMeasurements.burden.total}%)
                </span>
              ) : (
                <span>-</span>
              )}
            </div>

            <div className="measurement__section-label">
              Total Plaque Volume (Burden)
            </div>
          </div>

          <div className="measurements__section">
            <div className="measurement__section-value">
              {plaqueMeasurements.volume.ca &&
                !plaqueMeasurements.burden.ca && (
                  <span>{plaqueMeasurements.volume.ca} mm² (-%)</span>
                )}

              {!plaqueMeasurements.volume.ca &&
                plaqueMeasurements.burden.ca && (
                  <span>-mm² ({plaqueMeasurements.burden.ca}%)</span>
                )}

              {plaqueMeasurements.volume.ca && plaqueMeasurements.burden.ca ? (
                <span>
                  {plaqueMeasurements.volume.ca} mm² (
                  {plaqueMeasurements.burden.ca}%)
                </span>
              ) : (
                <span>-</span>
              )}
            </div>

            <div className="measurement__section-label">
              Calcified Plaque Volume (Burden)
            </div>
          </div>

          <div className="measurements__section">
            <div className="measurement__section-value">
              {plaqueMeasurements.volume.nca &&
                !plaqueMeasurements.burden.nca && (
                  <span>{plaqueMeasurements.volume.nca} mm² (-%)</span>
                )}

              {!plaqueMeasurements.volume.nca &&
                plaqueMeasurements.burden.nca && (
                  <span>-mm² ({plaqueMeasurements.burden.nca}%)</span>
                )}

              {plaqueMeasurements.volume.nca &&
              plaqueMeasurements.burden.nca ? (
                <span>
                  {plaqueMeasurements.volume.nca} mm² (
                  {plaqueMeasurements.burden.nca}%)
                </span>
              ) : (
                <span>-</span>
              )}
            </div>

            <div className="measurement__section-label">
              Non-calcified Plaque Volume (Burden)
            </div>
          </div>

          <div className="measurements__section">
            <div className="measurement__section-value">
              {plaqueMeasurements.volume.lap &&
                !plaqueMeasurements.burden.lap && (
                  <span>{plaqueMeasurements.volume.lap} mm² (-%)</span>
                )}

              {!plaqueMeasurements.volume.lap &&
                plaqueMeasurements.burden.lap && (
                  <span>-mm² ({plaqueMeasurements.burden.lap}%)</span>
                )}

              {plaqueMeasurements.volume.lap &&
              plaqueMeasurements.burden.lap ? (
                <span>
                  {plaqueMeasurements.volume.lap} mm² (
                  {plaqueMeasurements.burden.lap}%)
                </span>
              ) : (
                <span>-</span>
              )}
            </div>
            <div className="measurement__section-label">
              LAP Plaque Volume (Burden)
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OverallMeasurements;
