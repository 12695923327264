import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as ChevronIcon } from '../../../assets/icons/chevron.svg';
import Button from '../../../components/Button/Button';
import Select from '../../../components/Select/Select';
import {
  PLAQUE,
  RISK_LEVELS,
  STENOSIS,
  STENOSIS_RISK_LEVEL_MAPPING,
  STENOSIS_TEXT_MAPPING,
  VULNERABLE_PLAQUE,
} from '../../../config';
import { useStoreContext } from '../../../context/store-context';
import { VpBiomarkerCounts } from '../../../reducers/vessel-data';
import { useVesselStateSelector } from '../../../selectors/vessels';
import { timeFuncFactory } from '../../../utils/shared';
import {
  ToggleLAPRequest,
  ToggleNRSRequest,
  TogglePRRequest,
  ToggleSCRequest,
  useSavePerLesionData,
} from './use-callbacks';

export type LesionInfoProps = {
  handleLesionidxChange: (dx: number) => void;
  lesionidx: number;
  lesionId?: string | null;
  lesionLength: number;
};

export const LesionInfo: React.FC<LesionInfoProps> = ({
  handleLesionidxChange,
  lesionidx,
  lesionId,
  lesionLength,
}) => {
  const { contrastLesionData } = useStoreContext();

  const {
    vesselData,
    midSliceIdx: sliceidx,
    selectedVesselName = '',
  } = useVesselStateSelector();

  const [savingLesion, setSavingLesion] = useState<boolean>(false);

  const [currentPlaqueComp, setCurrentPlaqueComp] = useState<
    string | undefined
  >();
  const [currentLesionId, setCurrentLesionId] = useState<string | null>(null);
  const [currentVPmarkers, setCurrentVPmarkers] = useState<
    VpBiomarkerCounts | undefined
  >();
  const [currentLesionMaxStenosis, setCurrentLesionMaxStenosis] = useState<
    string | null
  >(null);

  const plaqueOptions = [
    ...Object.entries(PLAQUE).map(([value, label]) => ({
      value,
      label,
    })),
  ];

  const stenosisOptions = [
    { value: '', label: 'No stenosis category' },
    ...Object.entries(STENOSIS_TEXT_MAPPING).map(([value, label]) => ({
      value,
      label: `${label}: ${value}`,
    })),
  ];

  const savePerLesionData = useSavePerLesionData(
    setSavingLesion,
    currentLesionId,
    contrastLesionData
  );

  const renderVPToggles = useCallback(
    (value: string, label: string) => {
      let cmd:
        | ToggleNRSRequest
        | ToggleSCRequest
        | TogglePRRequest
        | ToggleLAPRequest
        | null = null;
      const valueToSetOnClick =
        currentVPmarkers && currentVPmarkers[value] > 0 ? null : {};
      switch (value) {
        case 'pr':
          cmd = { toggle_pr: valueToSetOnClick, type: 'vulnerable plaque' };
          break;
        case 'nrs':
          cmd = { toggle_nrs: valueToSetOnClick, type: 'vulnerable plaque' };
          break;
        case 'lap':
          cmd = { toggle_lap: valueToSetOnClick, type: 'vulnerable plaque' };
          break;
        case 'sc':
          cmd = { toggle_sc: valueToSetOnClick, type: 'vulnerable plaque' };
          break;
        default:
          break;
      }

      return (
        <Button
          key={`vp__${value}`}
          selected={currentVPmarkers ? currentVPmarkers[value] > 0 : false}
          theme="tertiary-square"
          title={label}
          onClick={() => {
            cmd && savePerLesionData(cmd);
          }}
          disabled={savingLesion}
        >
          {value.toUpperCase()}
        </Button>
      );
    },
    [currentVPmarkers, savePerLesionData, savingLesion]
  );

  const reset = () => {
    setCurrentPlaqueComp('none');
    setCurrentLesionId(null);
    setCurrentVPmarkers(undefined);
    setCurrentLesionMaxStenosis(STENOSIS.none);
  };

  useEffect(() => {
    timeFuncFactory(() => {
      if (
        !contrastLesionData ||
        !selectedVesselName ||
        sliceidx < 0 ||
        !vesselData
      ) {
        console.error(
          'Error: contrastLesionData, vessel selected, or slice invalid',
          selectedVesselName
        );
        reset();
        return;
      }
      const lesionData = contrastLesionData[selectedVesselName];
      if (!lesionData) {
        console.error(
          'Error: no lesion data exists for vessel',
          selectedVesselName
        );
        reset();
        return;
      }
      const vData = vesselData[selectedVesselName];
      if (!vData) {
        console.error(
          'Error: no vessel data exists for vessel',
          selectedVesselName
        );
        reset();
        return;
      }

      const data = vesselData[selectedVesselName];
      const mapping = data.slice_to_lesion_mapping;
      let newID = null;
      if (mapping[sliceidx]) {
        // NOTE: There could be muliple lesions across a slice
        //       For now, retrieve the first lesion
        newID = mapping[sliceidx][lesionidx]
          ? String(mapping[sliceidx][lesionidx])
          : newID;
      }

      if (!newID) {
        reset();
        return;
      }

      const sliceLesionData = lesionData[newID];
      setCurrentPlaqueComp(
        sliceLesionData ? sliceLesionData.plaque_composition : 'none'
      );
      setCurrentLesionId(newID);
      setCurrentVPmarkers(
        sliceLesionData ? sliceLesionData.vp_biomarker_counts : undefined
      );
      setCurrentLesionMaxStenosis(
        sliceLesionData ? sliceLesionData.stenosis_max : STENOSIS.none
      );
    }, 'lesion info sliceidx lesionidx')();
  }, [sliceidx, selectedVesselName, contrastLesionData, vesselData, lesionidx]);

  const riskLevel =
    currentLesionMaxStenosis &&
    RISK_LEVELS.includes(STENOSIS_RISK_LEVEL_MAPPING[currentLesionMaxStenosis])
      ? `lesion-info__stenosis--risk-${STENOSIS_RISK_LEVEL_MAPPING[currentLesionMaxStenosis]}`
      : '';

  return (
    <div className="lesion-info">
      <div className="lesion-info__item">
        <div className="lesion-info__toggle">
          <button
            className="lesion-info__toggle--prev"
            type="button"
            disabled={lesionidx === 0}
            onClick={handleLesionidxChange.bind(null, -1)}
          >
            <ChevronIcon />
          </button>
          <div className="lesion-info__toggle--label">{lesionId}</div>
          <button
            className="lesion-info__toggle--next"
            type="button"
            disabled={
              lesionidx === lesionLength - 1 || lesionidx === lesionLength
            }
            onClick={handleLesionidxChange.bind(null, 1)}
          >
            <ChevronIcon />
          </button>
        </div>
        <div className="lesion-info__label">Stenosis</div>
        <div className={cn('lesion-info__stenosis', riskLevel)}>
          <Select
            showMenuOnTop
            placeholder="Select stenosis"
            options={stenosisOptions}
            value={currentLesionMaxStenosis ?? undefined}
            disabled={savingLesion}
            onChange={(val) => {
              savePerLesionData({
                edit_stenosis_max: val,
                type: 'stenosis',
              });
            }}
            hideFirstOption
          />
        </div>
      </div>
      <div className="lesion-info__item">
        <div className="lesion-info__label">Plaque</div>
        <div className="lesion-info__selector">
          <Select
            showMenuOnTop
            placeholder="Select Plaque"
            options={plaqueOptions}
            value={currentPlaqueComp}
            disabled={savingLesion}
            onChange={(val) => {
              savePerLesionData({
                edit_plaque_composition: val,
                type: 'plaque',
              });
            }}
          />
        </div>
      </div>
      <div className="lesion-info__item last">
        <div className="lesion-info__label">Vulnerable Plaque</div>
        <div className="lesion-info__vpselectors">
          {Object.entries(VULNERABLE_PLAQUE).map(([value, label]) =>
            renderVPToggles(value, label)
          )}
        </div>
      </div>
      <div className="lesion-info__saving">{savingLesion && `saving...`}</div>
    </div>
  );
};

export default LesionInfo;
