import produce from 'immer';
import { getType } from 'typesafe-actions';
import { VesselToReportNameMapping } from '../types';
import { InlineReportingAction, InlineReportingActions } from './actions';
import { DEFAULT_INLINE_REPORTING_STATE } from './constants';
import { InlineReportingState } from './types';

export function inlineReportingReducer(
  state: InlineReportingState = DEFAULT_INLINE_REPORTING_STATE,
  action: InlineReportingAction
): InlineReportingState {
  return produce(state, (draftState) => {
    switch (action.type) {
      case getType(InlineReportingActions.updateCoronaryFinding): {
        const { vessel, finding } = action.payload;

        const mapped = VesselToReportNameMapping[vessel];

        draftState[mapped] = finding;

        return draftState;
      }

      case getType(InlineReportingActions.updateImpressions): {
        draftState.impressions = action.payload;

        return draftState;
      }

      case getType(InlineReportingActions.setUnsavedChangesWarning): {
        draftState.showWarningType = action.payload;

        return draftState;
      }

      case getType(InlineReportingActions.clearInlineReportingDraftState): {
        return DEFAULT_INLINE_REPORTING_STATE;
      }

      default:
        return draftState;
    }
  });
}
