import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { BASE_KEY_3D_MODEL } from '../../config';
import { Screenshot } from '../../types/common';
import { Loader } from '../Loader/Loader';
import ScanModal from '../ScanModal/ScanModal';
import { ReactComponent as ExpandIcon } from '../../assets/icons/expand.svg';

interface ScreenshotsProps {
  screenshots: Screenshot[];
  onDelete: (filename: string) => void;
  deleting: string | undefined;
}

export const Screenshots: React.FC<ScreenshotsProps> = ({
  screenshots,
  onDelete,
  deleting,
}) => {
  const [displayScreenshots, setDisplayScreenshots] = useState<Screenshot[]>(
    []
  );
  const [selectedScreenshot, setSelectedScreenshot] = useState<Screenshot>();

  const openScreenshotModal = (s: Screenshot) => {
    if (s.url) {
      setSelectedScreenshot(s);
      document.body.classList.add('lock');
      document.addEventListener('keydown', onListenClose);
    }
  };

  const closeScreenshotModal = () => {
    document.body.classList.remove('lock');
    document.removeEventListener('keydown', onListenClose);
    setSelectedScreenshot(undefined);
  };

  const onListenClose = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      closeScreenshotModal();
    }
  };

  useEffect(() => {
    // exclude 3D model screenshot
    setDisplayScreenshots(
      screenshots.filter((s) => !s.title.includes(BASE_KEY_3D_MODEL))
    );
  }, [screenshots]);

  return !displayScreenshots || displayScreenshots.length <= 0 ? (
    <div className="no-screenshots">No screenshots added</div>
  ) : (
    <div className="screenshots">
      {displayScreenshots.map(({ filename, thumbnail, title }, idx) => (
        <div className="screenshots__chip" key={filename}>
          <div
            onClick={() => openScreenshotModal(displayScreenshots[idx])}
            className="screenshots__thumbnail"
            title="View screenshot"
            style={{
              backgroundImage: `url('${thumbnail}')`,
            }}
          >
            {displayScreenshots[idx].url && <ExpandIcon />}
          </div>
          <span className="screenshots__filename">{title}</span>
          {deleting === undefined && (
            <button
              className="screenshots__remove-btn"
              type="button"
              onClick={() => onDelete(filename)}
              title="Delete screenshot"
            >
              <CloseIcon />
            </button>
          )}
          {deleting !== undefined && filename === deleting && (
            <div className="screenshots__loading">
              <Loader large={false} />
            </div>
          )}
        </div>
      ))}
      {selectedScreenshot && (
        <ScanModal
          visible
          inScreenshotMode
          showCloseIcon
          title={selectedScreenshot.title}
          onClose={closeScreenshotModal}
        >
          <div
            className="screenshots__fullscreen"
            style={{
              backgroundImage: `url('${selectedScreenshot.url}')`,
            }}
          />
        </ScanModal>
      )}
    </div>
  );
};
