import { configureStore } from '@reduxjs/toolkit';
import { globalLoaderReducer } from './reducers/global-loader';
import { studyReducer } from './reducers/study';

export const store = configureStore({
  reducer: {
    globalLoader: globalLoaderReducer,
    study: studyReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
