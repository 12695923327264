import React from 'react';

interface RangeSliderProps {
  value?: number;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  className?: string;
  onChange: (value: number) => void;
}

export const RangeSlider: React.FC<RangeSliderProps> = ({
  value = 1,
  min = 1,
  max = 100,
  step = 1,
  disabled,
  onChange,
  className, // Optional className override
}) => {
  return (
    <input
      className={className ? className : 'range-slider'}
      type="range"
      value={value}
      min={min}
      max={max}
      step={step}
      disabled={disabled}
      onChange={(e) => {
        onChange && onChange(parseFloat(e.target.value));
        e.target.blur();
      }}
    />
  );
};
