import React, { useCallback, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import useOnClickOutside from '../../hooks/use-on-click-outside';

interface EditFindingsProps {
  savedValue: string;
  onUpdateFindings: (value: string) => void;
  placeholderText?: string;
  maxRows?: number;
  clickToEdit?: boolean;
  clickOutsideToSave?: boolean;
  forceEdit?: boolean;
  singleLine?: boolean;
}

export const EditFindings: React.FC<EditFindingsProps> = ({
  placeholderText,
  savedValue,
  onUpdateFindings,
  maxRows = 7,
  clickToEdit = false,
  clickOutsideToSave = false,
  forceEdit = false,
  singleLine = false,
}) => {
  const [showNotes, setShowNotes] = useState(false);
  const findingsRef = useRef<HTMLDivElement | null>(null);

  useOnClickOutside(findingsRef, () => {
    if (clickOutsideToSave) {
      if (showNotes) {
        setShowNotes(false);
      }
      onUpdateFindings(savedValue);
    }
  });

  const handleAddNotes = useCallback(() => {
    if (clickToEdit) {
      setShowNotes(true);
    }
  }, [clickToEdit, setShowNotes]);

  const handleInputChange = useCallback(
    (event) => {
      onUpdateFindings(event.target.value);
    },
    [onUpdateFindings]
  );

  return (
    <div className="findings" ref={findingsRef}>
      {showNotes || forceEdit ? (
        singleLine ? (
          <input
            type="text"
            className="findings__enter"
            value={savedValue}
            onChange={handleInputChange}
          />
        ) : (
          <TextareaAutosize
            maxRows={maxRows}
            className="findings__enter"
            autoFocus
            value={savedValue}
            onChange={handleInputChange}
          />
        )
      ) : (
        <div className="findings__enter" onClick={handleAddNotes}>
          {placeholderText && savedValue.length < 1 && (
            <span className="findings__placeholder">{placeholderText}</span>
          )}
          <span>{savedValue}</span>
        </div>
      )}
    </div>
  );
};
