import React, { ReactElement, useCallback, useMemo } from 'react';
import {
  DragAndDropActions,
  useDragAndDropContext,
  DraggableHighlight,
} from '../../context/drag-n-drop';
import {
  useAnyGroupItemDragging,
  useAnySingleItemDragging,
  useDraggableGroupItems,
  useDraggableGroupsOfItems,
} from '../../selectors/dragAndDrop';
import GroupDraggable from './GroupDraggable';
import { SingleDraggableProps } from './types';

interface Props {
  keysToGroupsItemsToHighlight: string[];
  keysAndTypesToChildItemsToHighlight: DraggableHighlight[];
}

export default function ListOfDraggableItems({
  keysToGroupsItemsToHighlight,
  keysAndTypesToChildItemsToHighlight,
}: Props): ReactElement<Props> {
  const onStart = useCallback(
    (e: React.DragEvent<HTMLSpanElement>, id: string) => {},
    []
  );

  const { dispatch } = useDragAndDropContext();

  const parentItems = useDraggableGroupItems();

  const childItems = useDraggableGroupsOfItems();

  const groupItemBeingDragged = useAnyGroupItemDragging();
  const singleItemBeingDragged = useAnySingleItemDragging();

  const handleParentDrag = useCallback(
    (e: React.DragEvent<HTMLSpanElement>, id: string) => {
      e.preventDefault();
      if (!groupItemBeingDragged) {
        dispatch(
          DragAndDropActions.dragging({
            id,
            itemType: 'parent',
          })
        );
      }
    },
    [dispatch, groupItemBeingDragged]
  );

  const handleChildDrag = useCallback(
    (e: React.DragEvent<HTMLSpanElement>, id: string, index: number) => {
      if (!singleItemBeingDragged) {
        dispatch(
          DragAndDropActions.dragging({
            id,
            childIndex: index,
            itemType: 'child',
          })
        );
      }
    },
    [dispatch, singleItemBeingDragged]
  );

  const onResetParentItem = useCallback(
    (id: string) => {
      dispatch(DragAndDropActions.onSnapBack({ id, itemType: 'parent' }));
    },
    [dispatch, groupItemBeingDragged]
  );

  const onResetChildItem = useCallback(
    (id: string, childIndex: number) => {
      dispatch(
        DragAndDropActions.onSnapBack({
          id,
          childIndex,
          itemType: 'child',
        })
      );
    },
    [dispatch]
  );

  const onStop = useCallback(
    (e: React.DragEvent<HTMLSpanElement>, id: string, index: number) => {
      onResetChildItem(id, index);
    },
    [onResetChildItem, singleItemBeingDragged]
  );

  const singleDraggables: SingleDraggableProps[][] = useMemo(
    () =>
      childItems.map((x) =>
        x.map(
          (item, index): SingleDraggableProps => ({
            onDrag: (e) => handleChildDrag(e, item.id, index),
            onStart: (e) => onStart(e, item.id),
            onStop: (e) => onStop(e, item.id, index),
            draggableItem: item,
          })
        )
      ),
    [childItems, handleChildDrag, onStart, onStop]
  );
  return (
    <div className="draggables">
      {parentItems.map((parentItem, index) => (
        <div className="draggable-section" key={`group-${index}`}>
          {parentItem.groupTitle && (
            <span onDrag={(e) => {}} className="series-title">
              {parentItem.groupTitle}
            </span>
          )}
          <GroupDraggable
            groupTitle={parentItem.title}
            singleDraggables={singleDraggables[index]}
            renderThumbnail={parentItem.renderThumbnail}
            isDragging={parentItem.isDragging}
            onDrag={(e) => handleParentDrag(e, parentItem.id)}
            onStart={(e) => onStart(e, parentItem.id)}
            onStop={() => onResetParentItem(parentItem.id)}
            extraInfo={parentItem.extraInfo}
            showLeftBorder={keysToGroupsItemsToHighlight.includes(
              parentItem.id
            )}
            keysAndTypesToChildItemsToHighlight={
              keysAndTypesToChildItemsToHighlight
            }
          />
        </div>
      ))}
    </div>
  );
}
