import cn from 'classnames';
import { round } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import {
  useContrastContext,
  useContrastVolumeSelector,
  useContrastViewTypeSelector,
} from '../../../context/contrast-context';
import {
  BlendMode,
  ContrastVolumeActions,
  ContrastViewType,
  CTVolumeOverlay,
} from '../../../context/contrast-types';
import { getContrastViewTypeName } from '../ContrastViewer/Utils';
import { RangeSlider } from '../../../components/RangeSlider/RangeSlider';
import Select from '../../../components/Select/Select';
import HorizontalButtonList, {
  HorizontalButtonProp,
} from '../../../components/HorizontalButtonList';
import { ReactComponent as InfoIcon } from '../../../assets/icons/alert.svg';
import { ReactComponent as ReportIcon } from '../../../assets/icons/report.svg';
import { ReactComponent as LinkedIcon } from '../../../assets/icons/link.svg';
import { ReactComponent as UnlinkedIcon } from '../../../assets/icons/unlink.svg';
import AnnotationModal from '../../../components/AnnotationModal/AnnotationModal';
import VesselSelection from './VesselSelection';
import { clampNumber } from '../../../utils/shared';
import { isContrastOnlyStudy } from '../../../context/drag-n-drop/helpers';
import { useDraggableGroupItems } from '../../../selectors/dragAndDrop';

interface ContrastViewHeaderProps {
  // The index of the view this slider is attached to.
  viewIndex: number;
}

/**
 * Allow the user to adjust the MIP slab thickness.
 */
export const ContrastViewHeader: React.FC<ContrastViewHeaderProps> = ({
  viewIndex,
}) => {
  const {
    dispatchContrastVolumeAction,
    vesselSync,
    setVesselSync,
    onTakeScreenshot,
    onCloseScreenshot,
    contrastOverlays,
    setContrastOverlayForViewIndex,
  } = useContrastContext();
  // manualThicknessText is the manually entered number for the scan thickness (or undefined if one was not entered
  // or the slider was moved after the manual number was entered).
  const [manualThicknessText, setManualThicknessText] = useState<
    string | undefined
  >();

  const studySeries = useDraggableGroupItems();
  const contrastVolume = useContrastVolumeSelector(viewIndex);
  const viewType =
    useContrastViewTypeSelector(viewIndex) || ContrastViewType.Axial;
  const contrastVolumeViewProps = contrastVolume?.viewProps[viewType];

  // Try to get the scan thickness of the study from the selectedStudy + selectedSeries, otherwise default to 0.1.
  // NOTE: thickness is saved as a string.
  const scanThicknessString =
    contrastVolume?.study?.series[contrastVolume?.seriesName]?.thickness;
  const scanThickness = scanThicknessString
    ? parseFloat(scanThicknessString)
    : 0.5;

  // The min and max render thickness in mm (max is now set to 125mm).
  const minRenderThickness: number = 0;
  const maxRenderThickness: number = 125 - scanThickness;
  // The increments on the slider (now set to the scan thickness).
  const sliderStep: number = scanThickness;

  const setRenderThickness = (value: number) => {
    if (contrastVolume) {
      dispatchContrastVolumeAction({
        study: contrastVolume.study,
        seriesName: contrastVolume.seriesName,
        type: ContrastVolumeActions.SET_RENDER_THICKNESS,
        viewType: viewType,
        renderThickness: value,
      });
    }
  };

  const isManualThicknessTextInvalid = (): boolean => {
    // An undefined manualThickness means we're using the renderThickness os it will be valid.
    if (manualThicknessText === undefined) return false;
    // Otherwise an non-number manualThickness is invalid.
    const totalThicknessNumber = parseFloat(manualThicknessText);
    if (isNaN(totalThicknessNumber)) return true;
    // Otherwise the manualThicknessText must be at least the minRenderThickness and at most the maxRenderThickness.
    const renderThickness: number = round(
      totalThicknessNumber - scanThickness,
      1
    ); // round to 1 decimal place
    return (
      renderThickness < minRenderThickness ||
      renderThickness > maxRenderThickness
    );
  };

  const onManualThicknessTextChange = (event: any) => {
    const totalThicknessNumber = parseFloat(event.target.value);
    // We need to check the number is actually a number (it might not be!).
    const renderThickness: number = isNaN(totalThicknessNumber)
      ? minRenderThickness
      : clampNumber(
          round(totalThicknessNumber - scanThickness, 1), // round to 1 decimal place
          minRenderThickness,
          maxRenderThickness
        );
    // Set the render thickness to the manually entered total thickness value minus the scan thickness and then limited to the valid range.
    const roundedRenderThickness =
      Math.round(renderThickness / sliderStep) * sliderStep;
    setRenderThickness(roundedRenderThickness);
    // Remember the total thickness text value exactly as the user entered it.
    setManualThicknessText(event.target.value);
  };

  const onManualThicknessTextBlur = () => {
    // When the user stops typing a text value and has hit return or clicked somewhere else then we can clear the typed value and show the actual set value.
    setManualThicknessText(undefined);
  };

  const onRenderThicknessSliderChange = (value: number) => {
    // Set the render thickness (the value here is already the renderThickness, no need to subtract off the scanThickness).
    setRenderThickness(value);
    // Clear any manually entered thickness number.
    setManualThicknessText(undefined);
  };

  const setBlendMode = (value: string) => {
    if (contrastVolume) {
      dispatchContrastVolumeAction({
        study: contrastVolume.study,
        seriesName: contrastVolume.seriesName,
        type: ContrastVolumeActions.SET_BLEND_MODE,
        viewType: viewType,
        blendMode: parseInt(value) as BlendMode,
      });
    }
  };

  let renderThickness: number = 0.0;
  let blendMode: BlendMode = BlendMode.MAXIMUM_INTENSITY_BLEND;
  if (contrastVolumeViewProps) {
    renderThickness = contrastVolumeViewProps.renderThickness;
    blendMode = contrastVolumeViewProps.blendMode;
  }

  const toggleOverlay = useCallback(
    (overlay: CTVolumeOverlay) => {
      if (contrastOverlays[viewIndex] === overlay) {
        setContrastOverlayForViewIndex(CTVolumeOverlay.HIDE, viewIndex);
      } else {
        setContrastOverlayForViewIndex(overlay, viewIndex);
      }
    },
    [setContrastOverlayForViewIndex, contrastOverlays, viewIndex]
  );

  const toggleInfoSection = useCallback(
    () => toggleOverlay(CTVolumeOverlay.INFO),
    [toggleOverlay]
  );
  const toggleReportSection = useCallback(
    () => toggleOverlay(CTVolumeOverlay.REPORT),
    [toggleOverlay]
  );
  const toggleVesselSync = useCallback(() => setVesselSync(!vesselSync), [
    setVesselSync,
    vesselSync,
  ]);

  /**
   * Ensure the view is enlarged, capture the screenshot and fire the onTakeScreenshot method given to us.
   */
  const onRequestScreenshot = useCallback(
    (onTakeScreenshotCallback: (imageData: string) => void) => {
      if (onTakeScreenshotCallback) {
        onTakeScreenshot(viewIndex, onTakeScreenshotCallback);
      }
    },
    [viewIndex, onTakeScreenshot]
  );

  const horizontalButtonList: HorizontalButtonProp[] = useMemo(() => {
    const result: HorizontalButtonProp[] = [];

    if (viewType === ContrastViewType.MPR) {
      result.push({
        type: 'Action',
        onClick: toggleVesselSync,
        title: 'Toggle sync of volume views to vessel centerline',
        icon: vesselSync ? <LinkedIcon /> : <UnlinkedIcon />,
        selected: vesselSync,
      });
    }

    if (viewType !== ContrastViewType.Empty) {
      result.push({
        type: 'Action',
        onClick: toggleInfoSection,
        title: isContrastOnlyStudy(studySeries) ? '' : 'Toggle meta data',
        icon: <InfoIcon />,
        selected: contrastOverlays[viewIndex] === CTVolumeOverlay.INFO,
        disabled: isContrastOnlyStudy(studySeries),
      });
    }

    if (viewType === ContrastViewType.MPR) {
      result.push({
        type: 'Action',
        onClick: toggleReportSection,
        title: 'Toggle meta data',
        icon: <ReportIcon />,
        selected: contrastOverlays[viewIndex] === CTVolumeOverlay.REPORT,
      });
    }

    if (viewType !== ContrastViewType.Empty) {
      result.push({
        type: 'Custom',
        element: (
          <AnnotationModal
            screenshotRef={undefined}
            onRequestScreenshot={onRequestScreenshot}
            viewName={contrastVolume?.seriesName ?? 'mpr'}
            onClose={onCloseScreenshot}
            hideText={true}
            headerStyle={true}
            screenshotDisabled={contrastVolume?.volume === undefined}
          />
        ),
      });
    }

    return result;
  }, [
    contrastVolume?.volume,
    onCloseScreenshot,
    onRequestScreenshot,
    toggleInfoSection,
    toggleReportSection,
    toggleVesselSync,
    vesselSync,
    contrastOverlays,
    contrastVolume?.seriesName,
    viewIndex,
    viewType,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  // Empty views don't have a header.
  if (viewType === ContrastViewType.Empty) {
    return null;
  }
  return (
    <div className="contrast-view-header">
      {contrastVolume && (
        <label
          className="contrast-view-header__series_name_label"
          title={
            contrastVolume.study.series[contrastVolume.seriesName]
              .series_description
          }
        >
          {
            contrastVolume.study.series[contrastVolume.seriesName]
              .series_description
          }
        </label>
      )}
      <label className="contrast-view-header__view_type_label">
        {getContrastViewTypeName(viewType)}
      </label>
      <div className="contrast-view-header__flex_spacer" />

      {/* The contrast views (Axial, Sagittal, Coronal have a blend mode selector and a thickness slider */}
      {viewType !== ContrastViewType.MPR && (
        <>
          <div className="contrast-view-header__divider" />
          <div className="contrast-view-header__group">
            <Select
              theme="alternative"
              options={[
                {
                  value: BlendMode.MAXIMUM_INTENSITY_BLEND.toString(),
                  label: 'MIP',
                },
                {
                  value: BlendMode.MINIMUM_INTENSITY_BLEND.toString(),
                  label: 'MinIP',
                },
                {
                  value: BlendMode.AVERAGE_INTENSITY_BLEND.toString(),
                  label: 'AvgIP',
                },
              ]}
              value={blendMode.toString()}
              onChange={setBlendMode}
              scrollable
            />
            <input
              className={cn('contrast-view-header__input', {
                'contrast-view-header__input_invalid': isManualThicknessTextInvalid(),
              })}
              type="number"
              value={
                manualThicknessText !== undefined
                  ? manualThicknessText
                  : (scanThickness + renderThickness).toFixed(1)
              }
              onChange={onManualThicknessTextChange}
              onBlur={onManualThicknessTextBlur}
            />
            <label className="contrast-view-header__mm_label">{'mm'}</label>

            <div
              className="contrast-view-header__slider"
              id="set-slab-thickness"
            >
              <RangeSlider
                className="range-slider-thin"
                min={minRenderThickness}
                max={maxRenderThickness + 0.5 * sliderStep} // NOTE: We add 1/2 the sliderStep to handle rounding errors.
                value={renderThickness}
                step={sliderStep}
                onChange={onRenderThicknessSliderChange}
              />
            </div>
          </div>
        </>
      )}
      <div className="contrast-view-header__action_buttons">
        <HorizontalButtonList buttons={horizontalButtonList} />
      </div>
      {viewType === ContrastViewType.MPR && (
        <>
          <div className="contrast-view-header__divider" />
          <VesselSelection />
        </>
      )}
    </div>
  );
};

export default ContrastViewHeader;
