import React, {
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import { DEFAULT_WORKFLOW_STATE } from './constants';
import { workflowReducer } from './reducer';
import { WorkflowContextValue } from './types';

const WorkflowContext = React.createContext<WorkflowContextValue | undefined>(
  undefined
);

WorkflowContext.displayName = 'WorkflowContext';

interface Props {
  children: ReactNode;
}

export function WorkflowProvider({ children }: Props): ReactElement<Props> {
  const [workflowState, workflowAction] = useReducer(
    workflowReducer,
    DEFAULT_WORKFLOW_STATE
  );

  const workflowContextValue: WorkflowContextValue = useMemo(
    () => ({
      state: workflowState,
      dispatch: workflowAction,
    }),
    [workflowState, workflowAction]
  );

  return (
    <WorkflowContext.Provider value={workflowContextValue}>
      {children}
    </WorkflowContext.Provider>
  );
}

/**
 * Hook to access the WorkflowContext
 * @returns {WorkflowContextValue} WorkflowContextValue
 */
export function useWorkflowContext(): WorkflowContextValue {
  const context = useContext(WorkflowContext);
  if (context === undefined) {
    throw new Error(
      'useWorkflowContext must be used within a WorkflowProvider.'
    );
  }
  return context;
}
