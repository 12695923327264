import React from 'react';
import cn from 'classnames';
import { RISK_LEVELS } from '../../config';

function formatValue(
  value: number | string,
  subtext: {} | null | undefined,
  cubed: boolean
) {
  if (!value && value !== 0) return '-'; // Check if value is falsy except 0

  return (
    <span>
      {`${value} `}
      {!!subtext && (
        <span className="stats__stat-subtext">
          {subtext}
          {cubed ? <sup>3</sup> : ''}
        </span>
      )}
    </span>
  );
}

interface DataType {
  label: string;
  value: string | number;
  measurement: boolean;
  show: boolean;
  subtext: string;
  cubed: boolean;
  riskLevel: string;
}

interface StatsProps {
  data: DataType[];
  inline?: boolean;
}

export const Stats: React.FC<StatsProps> = ({ data = [], inline }) => {
  return (
    <div
      className={cn('stats', {
        'stats--inline': inline,
      })}
    >
      {data.map(
        ({ label, value, measurement, subtext, cubed, riskLevel, show }, i) =>
          show && (
            <div
              className={cn(
                'stats__stat',
                RISK_LEVELS.includes(riskLevel)
                  ? `stats__stat--risk-${riskLevel}`
                  : '',

                { 'stats__stat-burden': measurement }
              )}
              key={i}
            >
              <div
                className={cn('stats__stat-value', {
                  'stats__stat-burden-value': measurement,
                })}
              >
                {formatValue(value, subtext, cubed)}
              </div>
              <div
                className={cn('stats__stat-label', {
                  'stats__stat-burden-label': measurement,
                })}
              >
                {label}
              </div>
            </div>
          )
      )}
    </div>
  );
};

export default Stats;
