import cn from 'classnames';
import React, { ReactElement } from 'react';
import { RISK_LEVELS } from '../../config';
import { StatsDataType } from '../../context/types';

interface Props {
  stats: StatsDataType;
  editingReport: boolean;
  handleUpdateReportStats: (field: string, newValue: string) => void;
}

export default function MaximumStenosisStat({
  stats,
}: Props): ReactElement<Props> {
  return (
    <div
      className={cn(
        'stats__stat',
        RISK_LEVELS.includes(stats.riskLevel ?? '')
          ? `stats__stat--risk-${stats.riskLevel}`
          : ''
      )}
    >
      <div className="stats__stat-value">{stats.value ? stats.value : '-'}</div>
      <div className="stats__stat-label">{stats.label}</div>
    </div>
  );
}
