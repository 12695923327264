import React, {
  ReactElement,
  ReactNode,
  useContext,
  useRef,
  useState,
} from 'react';
import { XYCoords } from '../reducers/vessel-data';
import { measurementToolContextValue } from './types';
import {
  EllipseStates,
  RulerStates,
  MeasurementGraphics,
} from '../utils/measurementTools/types';
const MeasurementToolContext = React.createContext<any | undefined>(undefined);
MeasurementToolContext.displayName = 'MeasurementToolContext';

interface Props {
  children: ReactNode;
}

export function MeasurementProvider({ children }: Props): ReactElement<Props> {
  // measurementMode is used to determine if the user is in measurement mode
  // and update the measumrent toolbar state
  const [isMeasurementMode, setIsMeasurementMode] = useState(false);
  // clear the measurements whenever state is updated
  const [clearMeasurements, setClearMeasurements] = useState(0);
  const isDraggingMeasurementToolRef = useRef<
    RulerStates | EllipseStates | undefined
  >(undefined);
  const isClickDownMeasurementToolRef = useRef(false);
  const isOverMeasurementToolRef = useRef<string>('');
  const hitAreaEllipseToolRef = useRef<any>({});
  const [isRulerActive, setIsRulerActive] = useState(false);
  const [isEllipseActive, setIsEllipseActive] = useState(false);
  const measurementTargetRef = useRef<MeasurementGraphics | null>(null);

  // measurement start point state
  const [
    measurementToolStartPoint,
    setMeasurementToolStartPoint,
  ] = useState<XYCoords | null>(null);

  const measurementToolContext: measurementToolContextValue = {
    hitAreaEllipseToolRef,
    isMeasurementMode,
    setIsMeasurementMode,
    measurementToolStartPoint,
    setMeasurementToolStartPoint,
    isRulerActive,
    setIsRulerActive,
    isEllipseActive,
    setIsEllipseActive,
    isDraggingMeasurementToolRef,
    isClickDownMeasurementToolRef,
    isOverMeasurementToolRef,
    measurementTargetRef,
    clearMeasurements,
    setClearMeasurements,
  };

  return (
    <MeasurementToolContext.Provider value={measurementToolContext}>
      {children}
    </MeasurementToolContext.Provider>
  );
}

export function useMeasurementToolContext() {
  const context = useContext(MeasurementToolContext);

  if (context === undefined) {
    throw new Error('Context must be used within an MeasurementProvider.');
  }
  return context;
}
