import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Button from '../Button/Button';
import { IconButton } from '../IconButton/IconButton';

interface ConfirmProps {
  title: string;
  children: React.ReactNodeArray | React.ReactNode;
  onSuccess: () => void;
  onDismiss?: () => void;
  open?: boolean;
  dontShowAgainCheckbox?: boolean;
}

export const Confirm: React.FC<ConfirmProps> = ({
  title,
  children,
  onSuccess,
  onDismiss,
  open = false,
  dontShowAgainCheckbox = false,
}) => {
  const id = btoa(title);
  const dontShowAgain = localStorage.getItem(id);
  const [isOpen, setIsOpen] = useState<boolean>(dontShowAgain ? false : open);
  const checkboxRef = useRef<HTMLInputElement>(null);

  const close = useCallback(() => {
    setIsOpen(false);
    if (checkboxRef.current?.checked) {
      localStorage.setItem(id, 'true');
    }
  }, [setIsOpen, id]);

  const success = useCallback(() => {
    close();
    onSuccess();
  }, [close, onSuccess]);

  useEffect(() => {
    setIsOpen(dontShowAgain ? false : open);
    if (dontShowAgain && open) {
      success();
    }
  }, [open, dontShowAgain, success]);

  const dismiss = useCallback(() => {
    close();
    onDismiss && onDismiss();
  }, [close, onDismiss]);

  const classNames = cn('confirm', {
    'confirm--open': isOpen,
  });

  return (
    <div className={classNames}>
      <div className="confirm--modal">
        <div className="confirm--title">
          <h3>{title}</h3>
          <IconButton
            onClick={dismiss}
            icon="close"
            color="white"
            compact={true}
          />
        </div>
        <div className="confirm--content">
          {children}
          {dontShowAgainCheckbox && (
            <div className="confirm--dont-show-again">
              <input type="checkbox" ref={checkboxRef} id="confirm-checkbox" />
              <label htmlFor="confirm-checkbox">Don't ask me again</label>
            </div>
          )}
        </div>
        <div className="confirm--actions">
          {onDismiss && (
            <Button onClick={dismiss} theme="secondary">
              No
            </Button>
          )}
          <Button onClick={success}>Yes</Button>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
