import React, { ReactElement } from 'react';
import cn from 'classnames';

interface Props {
  label: string;
  name: string;
  checked?: boolean;
  onChange?: () => void;
  children?: React.ReactNodeArray | React.ReactNode;
}

export default function Toggle({
  label,
  name,
  checked,
  onChange,
  children,
}: Props): ReactElement<Props> {
  return (
    <div className="toggle">
      <input
        id={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="toggle__checkbox"
      />

      <label
        htmlFor={name}
        className={cn(
          'toggle__label',
          name === 'measurement' ? 'measurement-toggle' : ''
        )}
      >
        {label}
      </label>
      {children}
    </div>
  );
}
