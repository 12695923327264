import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import showToast from '../components/Toast/showToast';
import { STUDY_LOAD_ERROR_MSG } from '../config';
import { useStoreContext } from '../context/store-context';
import { useUserContext } from '../context/user-context';
import { useAppDispatch } from '../hooks';
import { fetchStudyById } from '../reducers/study';
import { useIsArtryaUserSelector } from '../selectors/user';

/**
 * Hook to fetch the data of a given study through the API.
 * Response gets decrypted and persisted to the StoreContext.
 * @param {string} studyId
 */
export default function useStudyData(studyId: string | undefined) {
  const { setStudyLocked, setStudyLockedBy } = useStoreContext();
  const { user } = useUserContext();
  const isArtryaUser = useIsArtryaUserSelector();
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (studyId === undefined) return;

    (async () => {
      try {
        const response = await dispatch(fetchStudyById(studyId));

        const study = unwrapResult(response);
        // If the study can't be viewed, is locked, is not an Artrya user
        // and is not the user reviewing the study (workflow_status.user), ensure it's locked
        // in the context along with the user and go back to the dashboard.
        if (
          !study.can_be_viewed.viewable &&
          study.workflow_status.state === 'Locked' &&
          !isArtryaUser &&
          study.workflow_status.user !== user.name
        ) {
          setStudyLocked(true);
          setStudyLockedBy(study.workflow_status.user);
          history.push('/');
        }
      } catch (err) {
        console.log('DATALOAD ERROR', err);
        showToast.error(STUDY_LOAD_ERROR_MSG);
        history.push('/');
      }
    })();
  }, [
    dispatch,
    studyId,
    history,
    setStudyLocked,
    setStudyLockedBy,
    user.name,
    isArtryaUser,
  ]);
}
