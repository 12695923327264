import { isEqual } from 'lodash';
import * as PIXI from 'pixi.js-legacy';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CPR_SLICE_INDICATOR_BUFFER,
  KEY_CPR,
  KEY_MPR_LONG_AXIS,
  MOUSE_BUTTONS,
  VIEWER_SCROLL_RATIO,
  WINDOW_LEVEL_DEFAULT,
  WINDOW_LEVEL_MAX,
  WINDOW_LEVEL_MIN,
  WINDOW_WIDTH_DEFAULT,
  WINDOW_WIDTH_MAX,
  WINDOW_WIDTH_MIN,
  LOW_PERFORMANCE_GPUS,
} from '../../config';
import { useCprContext } from '../../context/cpr-context';
import { useMeasurementToolContext } from '../../context/measurement-tools-context';
import { useStoreContext } from '../../context/store-context';
import { WindowLevels } from '../../context/window-types';
import useWindowResize from '../../hooks/use-window-resize';
import { Shape, XYCoords } from '../../reducers/vessel-data';
import { useVesselStateSelector } from '../../selectors/vessels';
import { captureMouse } from '../../utils/captureMouse';
import { getMeanValue, getStandardDeviation } from '../../utils/math-utils';
import { MEASUREMENT_SETTINGS } from '../../utils/measurementTools';
import { clampNumber, timeFuncFactory } from '../../utils/shared';
import { Loader } from '../Loader/Loader';
import { SliceSlider } from '../SliceSlider/SliceSlider';
import { HuData } from '../../utils/measurementTools/types';
import {
  ImageSize,
  OnReadyInf,
  ZoomInf,
  isWebGLRenderer,
  RESIZE_MODE,
} from './types';
import { blankImageBuffer, createTexture, ImageBuffer } from './Utils';

const HUE_SETTINGS = {
  SHOWOVERLAY: window.location.search.includes('huegridoverlay') || false,
};

const WINDOWING_SETTINGS = {
  MAX_CONTROLBOX_SIZE: 200,
  MIN_CONTROLBOX_SIZE: 50,
  SHOW_CONTROL_BOX:
    window.location.search.includes('windowingcontrols') || false,
  WINDOW_LEVEL_DEFAULT,
  WINDOW_LEVEL_MAX,
  WINDOW_LEVEL_MIN,
  WINDOW_WIDTH_DEFAULT,
  WINDOW_WIDTH_MAX,
  WINDOW_WIDTH_MIN,
};

enum MOUSE_MODE {
  HOVER = 'hover',
  ZOOM = 'zoom',
  PAN = 'pan',
  SLIDE = 'slide',
  WINDOWING = 'windowing',
}

// The vertex shader for simple rectangular mesh adjusted by WW/WL.
const windowedVertSource = `
  precision mediump float;
  attribute vec2 aVertexPosition;
  attribute vec2 aUvs;
  uniform mat3 translationMatrix;
  uniform mat3 projectionMatrix;
  varying vec2 vUvs;

  void main() {
    vUvs = aUvs;
    gl_Position = vec4((projectionMatrix * translationMatrix * vec3(aVertexPosition.xy, 1.0)).xy, 0.0, 1.0);
  }
  `;

// The fragment shader for simple rectangular mesh adjusted by WW/WL.
const windowedFragSource = `
  precision highp float;
  precision highp sampler2D;

  varying vec2 vUvs;
  uniform sampler2D uSampler;
  uniform float uWindowMin;
  uniform float uWindowMax;

  void main() {
    float grey = (texture2D(uSampler, vUvs).r - uWindowMin) / (uWindowMax - uWindowMin);
    gl_FragColor = vec4(grey, grey, grey, 1);
  }
  `;

// The string defining the WebGL renderer being used.
let webGLRenderer: string | undefined;
// This lets us identify a system with a low performance GPU and take extra steps to minimize resources used.
export let lowPerformanceGPU: boolean = false;

// See if we can retrieve info on the type of renderer being used.
const updateWebGLRendererInfo = (app: PIXI.Application | null) => {
  if (!webGLRenderer && app && isWebGLRenderer(app.renderer)) {
    const gl = app.renderer.gl;
    if (gl && !gl.isContextLost()) {
      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      if (debugInfo) {
        // const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
        webGLRenderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        // Check if the webGLRenderer includes any of the known LOW_PERFORMANCE_GPUS substrings.
        if (webGLRenderer) {
          lowPerformanceGPU = false;
          LOW_PERFORMANCE_GPUS.forEach((gpuIdentifier) => {
            if (webGLRenderer?.includes(gpuIdentifier))
              lowPerformanceGPU = true;
          });
        }
      }
    }
  }
};

/**
 * Update the WW/WL uniforms for the sprite's shader.
 */
const setWindowingUniforms = (
  sprite: PIXI.Mesh,
  windowLevel: number,
  windowWidth: number
) => {
  if (sprite) {
    sprite.shader.uniforms.uWindowMin = windowLevel - windowWidth / 2.0;
    sprite.shader.uniforms.uWindowMax = windowLevel + windowWidth / 2.0;
  }
};

/**
 * Create a PIXI.Mesh to render the specified source image with the window levels shader.
 */
const createMesh = (
  imageBuffer: ImageBuffer,
  imageSize: ImageSize
): PIXI.Mesh => {
  const geometry: PIXI.Geometry = new PIXI.Geometry()
    .addAttribute('aVertexPosition', [0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 1], 2)
    .addAttribute('aUvs', [0, 0, 1, 0, 1, 1, 0, 1, 0, 0, 1, 1], 2);
  const program = PIXI.Program.from(windowedVertSource, windowedFragSource);
  const material = new PIXI.MeshMaterial(createTexture(imageBuffer), {
    program,
    uniforms: {
      uWindowMin: 0.0,
      uWindowMax: 1.0,
    },
  });
  const mesh = new PIXI.Mesh(geometry, material);
  // Set the dimensions of the mesh to the scaled image size.
  mesh.width = imageSize.width;
  mesh.height = imageSize.height;
  // We get a crash without this set but we can set it to an empty rectangle?!
  mesh.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
  mesh.visible = true;
  return mesh;
};

interface Props {
  viewType: string;
  sliderMarkers?: any;
  width?: number;
  height?: number;
  // The index of the slice to display.
  slice: number;
  // Callback to move to a new slice index.
  onSliceChange: (sliceIndex: number) => void;
  onHueChange?: (x: number | null) => any;
  windowLevels?: WindowLevels;
  onWindowLevelsChange?: (x: any) => any;
  onZoom?: (index: number) => void;
  onDrag?: (coords: { x: number; y: number }) => any;
  onReady?: (x: OnReadyInf) => any;
  onLoad?: () => any;
  onContainerReady?: () => any;
  onResize?: (sliceIndex: number) => void;
  onCleanup?: () => void;
  onUpdateHuData?: (huData: HuData) => void;
  minZoom?: number;
  maxZoom?: number;
  transpose?: boolean;
  showSlider?: boolean;
  disableControls?: boolean;
  disableKeyboardControls?: boolean;
  disableLoadingProgress?: boolean;
  loadingInfo?: string;
  triggerResize?: any;
  shapeData?: Shape;
  imageBufferData?: ImageBuffer[];
  debug?: boolean;
  editMode?: boolean;
  minThresholdSlice?: number;
  maxThresholdSlice?: number;
  resizeMode: RESIZE_MODE;
  cover?: boolean;
  triggerResetPanAndZoom?: number;
  reverseSlider?: boolean;
  loadingCTvolume?: boolean;
}

const WebGLViewer: React.FC<Props> = ({
  viewType,
  sliderMarkers,
  width,
  height,
  onHueChange,
  slice,
  onSliceChange,
  minThresholdSlice,
  maxThresholdSlice,
  resizeMode,
  onReady,
  onLoad,
  onResize,
  onCleanup,
  onZoom,
  onDrag,
  windowLevels,
  onWindowLevelsChange,
  onUpdateHuData,
  minZoom = 0.5,
  maxZoom = 5,
  transpose,
  showSlider,
  disableControls,
  disableKeyboardControls = false,
  disableLoadingProgress = false,
  loadingInfo,
  triggerResize,
  shapeData,
  imageBufferData = [],
  editMode,
  cover = false,
  triggerResetPanAndZoom,
  reverseSlider = true,
  loadingCTvolume,
}) => {
  const { initialDataLoaded } = useStoreContext();
  const { setCprSliceCount } = useCprContext();
  const { selectedVesselName } = useVesselStateSelector();

  //measurement tool context
  const {
    isEllipseActive,
    measurementTargetRef,
    isDraggingMeasurementToolRef,
    isMeasurementMode,
    isOverMeasurementToolRef,
  } = useMeasurementToolContext();

  // Is the component still initializing?
  const [loading, setLoading] = useState<boolean>(true);
  // Has the context been lost on this component?
  const [lostContext, setLostContext] = useState<boolean>(false);
  // This is set to true when the active slice image is loading.
  const [loadingImage, setLoadingImage] = useState<boolean>(true);
  // The view size.
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: width || 0,
    height: height || 0,
  });

  // The view size as a ref (it's fairly tragic but because of how hooks work a new size may be set
  // but the old size still used until the next update cycle.
  const sizeRef = useRef<{ width: number; height: number }>({
    width: width || 0,
    height: height || 0,
  });

  const currentMaxThreshold = useRef<number | undefined>(maxThresholdSlice);
  const currentMinThreshold = useRef<number | undefined>(minThresholdSlice);
  const resizableRef = useRef<boolean>(width || height ? false : true);
  const firstLoadRef = useRef<boolean>(true);
  const holderRef = useRef<HTMLDivElement | null>(null);
  const appRef = useRef<PIXI.Application | null>(null);
  const containerRef = useRef<PIXI.Container | null>(null);
  const sliceWindowDataRef = useRef<any>({});
  // Everything else uses refs, so this will have to as well
  // Used by the onMouseMove event
  const editModeRef = useRef<boolean>(!!editMode);
  // The PIXI.Mesh that renders the main slice image.
  const sliceMeshRef = useRef<PIXI.Mesh | undefined>();
  const sliceContainerRef = useRef<PIXI.Container | undefined>();
  // The default scaling to apply to the image so that it will fit snugly inside the view.
  const defaultScaleRef = useRef<number>(1);
  const imageSizeRef = useRef<ImageSize>({
    width: 0,
    height: 0,
  });
  const utilityCanvasRef = useRef<HTMLCanvasElement | null>(
    document.createElement('canvas')
  );
  const mountedRef = useRef<boolean>(true);
  const modeRef = useRef<MOUSE_MODE>(MOUSE_MODE.HOVER);
  const onBothButtonsRef = useRef<boolean>(false);
  const windowWidthRef = useRef<number>(
    windowLevels?.windowWidth || WINDOW_WIDTH_DEFAULT
  );
  const windowLevelRef = useRef<number>(
    windowLevels?.windowCenter || WINDOW_LEVEL_DEFAULT
  );
  const windowingContainerRef = useRef<PIXI.Container | null>(null);
  const windowingPointRef = useRef<PIXI.Graphics | null>(null);
  const mouseDownRef = useRef<boolean>(false);
  const startDragSliceIdx = useRef<number | null>(null);
  const startMousePosRef = useRef<XYCoords>({ x: 0, y: 0 });
  const currentMousePosRef = useRef<XYCoords>({ x: 0, y: 0 });
  const lastMousePosRef = useRef<XYCoords>({ x: 0, y: 0 });
  const startContainerPosRef = useRef<XYCoords>({ x: 0, y: 0 });
  const zoomInfoRef = useRef<ZoomInf | null>(null);
  const hueRef = useRef<number | null>(null);
  const hueOverlay = useRef<PIXI.ParticleContainer | null>(null);
  const throttleEvent = useRef<boolean>(false);
  const sliderMouseDownRef = useRef<boolean>(false);
  // When we have promises etc we generally want the most current slice value rather than the one baked in when the function was created.
  const sliceRef = useRef<number>(slice);
  useEffect(() => {
    // CTvolumen use this effect to set the last image as active to see it first
    if (loadingCTvolume === false) {
      sliceRef.current = slice;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCTvolume]);
  // The number of image slices to load and display.
  const sliceCountRef = useRef<number>(0);
  const disableControlsRef = useRef<boolean>(!!disableControls);
  // These are just used to remember the previous shape and image data props, so we can tell if and what changed.
  const prevVesselNameRef = useRef<string | undefined>(undefined);
  const prevShapeDataRef = useRef<Shape | undefined>(shapeData);
  const prevImageBufferDataRef = useRef<ImageBuffer[] | undefined>(
    imageBufferData
  );
  const primaryColorRef = useRef<number>(
    parseInt(
      (
        getComputedStyle(document.documentElement).getPropertyValue(
          '--color-primary'
        ) || '#ff0000'
      ).replace('#', '0x')
    )
  );
  const blueColorRef = useRef<number>(
    parseInt(
      (
        getComputedStyle(document.documentElement).getPropertyValue(
          '--color-blue'
        ) || '#ff0000'
      ).replace('#', '0x')
    )
  );
  // Ref to the background loading timeout function currently in progress (if any).
  const loadTextureTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);
  // Ref of the list of images that are pending background loading.
  const backgroundImageLoadingRef = useRef<number[]>([]);

  /**
   * Memorize the number of slices currently loaded.
   */
  const slicesLoaded = useMemo(() => {
    let count: number = 0;
    // If we're not showing the loading progress then we don't need to update the number of slices loaded.
    if (!disableLoadingProgress) {
      imageBufferData.forEach((imageBuffer) => {
        if (imageBuffer) {
          count++;
        }
      });
    }
    return count;
  }, [disableLoadingProgress, imageBufferData]);

  useEffect(() => {
    if (!isMeasurementMode) restoreCursor();
  }, [isMeasurementMode]);

  useEffect(() => {
    if (!isNaN(slice) && !loading) {
      sliceRef.current = slice;
      goToSlice(slice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slice, loading]);

  useEffect(() => {
    editModeRef.current = !!editMode;
  }, [editMode]);

  useEffect(() => {
    if (minThresholdSlice !== undefined && minThresholdSlice >= 0) {
      currentMinThreshold.current = minThresholdSlice;
    }
  }, [minThresholdSlice]);

  useEffect(() => {
    if (maxThresholdSlice !== undefined && maxThresholdSlice >= 0) {
      currentMaxThreshold.current = maxThresholdSlice;
    }
  }, [maxThresholdSlice]);

  /**
   * Load the specified imageBuffer as a texture and show it on the main PIXI mesh.
   */
  const loadImageBufferInMesh = useCallback(
    (imageBuffer: ImageBuffer | undefined) => {
      if (sliceMeshRef.current) {
        sliceMeshRef.current.texture = createTexture(
          imageBuffer || blankImageBuffer
        );
        if (imageBuffer) {
          // Set the dimensions of the mesh to the image size.
          sliceMeshRef.current.width = imageBuffer.width;
          sliceMeshRef.current.height = imageBuffer.height;
          // Update the imageSizeRef.
          if (imageSizeRef.current) {
            imageSizeRef.current.width = imageBuffer.width;
            imageSizeRef.current.height = imageBuffer.height;
          }
        }
        setLoadingImage(!imageBuffer);
      }
    },
    [setLoadingImage]
  );

  /**
   * Update the imageBufferRef with the newly loaded image and update the texture if this is the current slice.
   */
  const onImageBufferLoaded = useCallback(
    (imageBuffer: ImageBuffer, sliceIndex: number) => {
      // If the loaded slice image is the currently active slice then display it.
      if (sliceIndex === sliceRef.current) {
        loadImageBufferInMesh(imageBuffer);
      }
    },
    [loadImageBufferInMesh]
  );

  /**
   * @param index The index of the image slice to go to.
   */
  const goToSlice = useCallback(
    (index: number) => {
      // Update the slice texture.
      const imageBuffer = imageBufferData[index];
      loadImageBufferInMesh(imageBuffer);
    },
    [loadImageBufferInMesh, imageBufferData]
  );

  /**
   * We need the image for the current slice to be loaded before we can initialize this component.
   * This keeps track of if we have the current slice image loaded or not
   */
  const imageBufferReady: boolean = useMemo(() => {
    // If we already have the image data we need to load our first image from that.
    return imageBufferData[slice] !== undefined;
  }, [imageBufferData, slice]);

  /**
   * Initialize the WebGLViewer only once we have valid shape data.
   */
  useEffect(() => {
    // NOTE: The sliceCountRef check ensures we only call this once per vessel.
    if (
      initialDataLoaded &&
      shapeData &&
      shapeData.length > 0 &&
      imageBufferReady &&
      sliceCountRef.current === 0
    ) {
      // Set the prev ref data so we don't trigger a reset etc.
      prevShapeDataRef.current = [...shapeData];
      prevImageBufferDataRef.current = imageBufferData;
      prevVesselNameRef.current = selectedVesselName;
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDataLoaded, shapeData, imageBufferReady]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
      cleanup();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    disableControlsRef.current = !!disableControls;
  }, [disableControls]);

  useEffect(() => {
    appRef.current && appRef.current.resize && appRef.current.resize();
    if (containerRef.current) {
      // Re-center the containerRef.
      centerContainer();
      onResize && onResize(sliceRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  useEffect(() => {
    setHolderSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerResize]);

  useEffect(() => {
    timeFuncFactory(() => {
      // Reset if shape changed (the actual shape, not just reference) or the vessel changed
      // Sometimes LM and LAD have the same shape data
      if (
        shapeData &&
        shapeData.length > 0 &&
        selectedVesselName &&
        imageBufferReady &&
        (!isEqual(prevShapeDataRef.current, shapeData) ||
          selectedVesselName !== prevVesselNameRef.current)
      ) {
        // Check we are still mounted.
        if (mountedRef.current) {
          // Cleanup immediately.
          cleanup();
          // Set the new ref data after the reset (because it is cleared in the reset).
          prevShapeDataRef.current = [...shapeData];
          prevImageBufferDataRef.current = imageBufferData;
          prevVesselNameRef.current = selectedVesselName;
          // Reinitialize with the new shape.
          init();
        }
      }
      // Redraw if images changed
      else if (
        imageBufferData &&
        prevImageBufferDataRef.current !== imageBufferData
      ) {
        // If the image buffer for the current slice changed then refresh the texture.
        const imageBuffer = imageBufferData[sliceRef.current];
        if (
          imageBuffer !== prevImageBufferDataRef.current?.[sliceRef.current] &&
          !isEqual(
            imageBuffer,
            prevImageBufferDataRef.current?.[sliceRef.current]
          )
        ) {
          loadImageBufferInMesh(imageBuffer);
        }
        prevImageBufferDataRef.current = imageBufferData;
      }
    }, 'setImageData')();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shapeData, imageBufferData, selectedVesselName, imageBufferReady]);

  useWindowResize(() => {
    // Check we are still mounted.
    if (mountedRef.current) {
      setHolderSize();
    }
  }, 100);

  useEffect(() => {
    if (windowLevels) {
      windowWidthRef.current = Math.min(
        Math.max(WINDOWING_SETTINGS.WINDOW_WIDTH_MIN, windowLevels.windowWidth),
        WINDOWING_SETTINGS.WINDOW_WIDTH_MAX
      );
      windowLevelRef.current = Math.min(
        Math.max(
          WINDOWING_SETTINGS.WINDOW_LEVEL_MIN,
          windowLevels.windowCenter
        ),
        WINDOWING_SETTINGS.WINDOW_LEVEL_MAX
      );
      if (sliceMeshRef.current) {
        setWindowingUniforms(
          sliceMeshRef.current,
          windowLevelRef.current,
          windowWidthRef.current
        );
      }
    }
  }, [windowLevels]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!containerRef.current) return;
    containerRef.current.scale.x = defaultScaleRef.current;
    containerRef.current.scale.y = defaultScaleRef.current;
    onZoom && onZoom(sliceRef.current);
    centerContainer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerResetPanAndZoom]);

  const getHolderMousePos = useCallback((point: XYCoords) => {
    const holder = holderRef.current;
    if (!holder) return point;
    const offset = holder.getBoundingClientRect();
    return {
      x: point.x - offset.x,
      y: point.y - offset.y,
    };
  }, []);

  const onMouseDown = useCallback(
    (event: React.PointerEvent) => {
      if (!containerRef.current) return;
      if (!mouseDownRef.current) {
        const pos = getHolderMousePos({
          x: event.pageX,
          y: event.pageY,
        });
        startContainerPosRef.current = {
          x: containerRef.current.x,
          y: containerRef.current.y,
        };
        // Update the position of the mouse at the start of the button press.
        startMousePosRef.current = pos;
        // Update the current mouse position.
        currentMousePosRef.current = pos;
        // Update the last mouse position (used by zoom).
        lastMousePosRef.current = pos;
        // Remember the slice idx at the start of the button press.
        startDragSliceIdx.current = sliceRef.current;
        // Remember that a button is pressed.
        mouseDownRef.current = true;
        // Capture the mouse pointer.
        captureMouse(event as any);
      }
    },
    [getHolderMousePos]
  );

  const onEndWindowing = useCallback(() => {
    if (windowingContainerRef.current) {
      windowingContainerRef.current.destroy({
        children: true,
        texture: true,
        baseTexture: true,
      });
      windowingContainerRef.current = null;
    }
  }, []);

  const onMouseDrag = useCallback(() => {
    const dist = {
      x: startMousePosRef.current.x - currentMousePosRef.current.x,
      y: startMousePosRef.current.y - currentMousePosRef.current.y,
    };
    const [x, y] = [
      startContainerPosRef.current.x - dist.x,
      startContainerPosRef.current.y - dist.y,
    ];
    if (containerRef.current) {
      containerRef.current.x = x;
      containerRef.current.y = y;
    }
    onDrag && onDrag({ x, y });
  }, [onDrag]);

  const restoreCursor = () => {
    if (holderRef.current) {
      holderRef.current.classList.remove('webGLViewer-zooming');
      holderRef.current.classList.remove('webGLViewer-dragging');
      holderRef.current.classList.remove('webGLViewer-sliding-ew');
      holderRef.current.classList.remove('webGLViewer-sliding-ns');
      holderRef.current.classList.remove('webGLViewer-windowing');
      holderRef.current.classList.remove('webGLViewer-crosshair');
      holderRef.current.classList.remove('measurement-crosshair-tool');
      holderRef.current.classList.remove('measurement-no-cursor');
      holderRef.current.classList.remove('measurement-dragging-tool');
    }
  };

  const onMouseUp = useCallback(
    (event: React.MouseEvent) => {
      if (modeRef.current === MOUSE_MODE.WINDOWING) {
        onEndWindowing();
      }
      onBothButtonsRef.current = false;
      if (!isMeasurementMode) restoreCursor();
      sliderMouseDownRef.current = false;
      startDragSliceIdx.current = null;
      mouseDownRef.current = false;
      zoomInfoRef.current = null;
      modeRef.current = MOUSE_MODE.HOVER;
    },
    [onEndWindowing, isMeasurementMode]
  );

  /**
   * Get the mouse position in the coordinate space of the container (this is the scaled image) from the coordinate space of the holder (ie the main view element).
   * If a holderPoint is not specified then use the current mouse position.
   */
  const getContainerMousePos = useCallback(
    (holderPoint?: XYCoords): XYCoords => {
      const pos = { x: 0, y: 0 };
      if (containerRef.current) {
        // If no point is specified use the current mouse position.
        if (!holderPoint) {
          holderPoint = currentMousePosRef.current;
        }
        pos.x =
          (holderPoint.x - containerRef.current.x) /
          containerRef.current.scale.x;
        pos.y =
          (holderPoint.y - containerRef.current.y) /
          containerRef.current.scale.y;
      }
      return pos;
    },
    []
  );

  const getMultipleHueValues = (
    points: { x: number; y: number }[],
    ellipse: PIXI.Ellipse
  ) => {
    const pointsHueArray = [];
    const pointA = points[0];
    const pointB = points[1];
    const xDist = Math.abs(pointA.x - pointB.x);
    const yDist = Math.abs(pointA.y - pointB.y);

    for (let a = 0; a <= xDist; a++) {
      for (let b = 0; b <= yDist; b++) {
        const xPos = pointA.x + a;
        const yPos = pointA.y + b;
        const xy: XYCoords = { x: xPos, y: yPos };
        if (ellipse.contains(xy.x, xy.y)) {
          const huValue = getHueValue(xy);
          huValue && pointsHueArray.push(huValue);
        }
      }
    }

    return pointsHueArray;
  };

  const getHueValue = (point: { x: number; y: number }) => {
    const xIndex = Math.floor(point.x);
    const yIndex = Math.floor(point.y);

    const imageBuffer = imageBufferData[sliceRef.current];
    if (
      imageBuffer &&
      xIndex >= 0 &&
      yIndex >= 0 &&
      xIndex < imageBuffer.width &&
      yIndex < imageBuffer.height
    ) {
      const index = yIndex * imageBuffer.width + xIndex;
      if (!isNaN(imageBuffer.buffer[index])) {
        return imageBuffer.buffer[index];
      }
    }
    return null;
  };

  const getHue = useCallback(() => {
    const { x, y } = getContainerMousePos();
    const xIndex = Math.floor(x);
    const yIndex = Math.floor(y);
    const imageBuffer = imageBufferData[sliceRef.current];
    if (
      imageBuffer &&
      xIndex >= 0 &&
      yIndex >= 0 &&
      xIndex < imageBuffer.width &&
      yIndex < imageBuffer.height
    ) {
      const index = yIndex * imageBuffer.width + xIndex;
      if (!isNaN(imageBuffer.buffer[index])) {
        return imageBuffer.buffer[index];
      }
    }
    return null;
  }, [getContainerMousePos, imageBufferData]);

  /**
   * Get the minimum and maximum permitted value for the slice index.
   */
  const getIndexRange = useCallback(() => {
    const [currMin, currMax] = [
      currentMinThreshold.current,
      currentMaxThreshold.current,
    ];
    let min = 0;
    if (typeof currMin !== 'undefined' && currMin >= 0) {
      min =
        currMin + CPR_SLICE_INDICATOR_BUFFER > sliceCountRef.current
          ? currMin
          : currMin + CPR_SLICE_INDICATOR_BUFFER;
    }
    let max = sliceCountRef.current - 1;
    if (typeof currMax !== 'undefined' && currMax >= 0) {
      max = currMax;
    }
    return { min, max };
  }, []);

  const onMouseSlide = useCallback(() => {
    // disable rotation when measurement is on
    if (isMeasurementMode) return;

    if (!throttleEvent.current) {
      const ratio = VIEWER_SCROLL_RATIO[viewType] || 1.0;
      // TODO set the ratio by user config
      let amount = 0;
      // left to right direction to scroll through slice for CPR and long axis view only
      if (viewType === KEY_CPR || viewType === KEY_MPR_LONG_AXIS) {
        amount = Math.round(
          (currentMousePosRef.current.x - startMousePosRef.current.x) / ratio
        );
      } else {
        // All other views are up and down direction
        amount = Math.round(
          (currentMousePosRef.current.y - startMousePosRef.current.y) / ratio
        );
      }
      const indexRange = getIndexRange();
      let nextSliceIdx = (startDragSliceIdx.current || 0) + amount;
      //if reverse slider is false, the slider will go sliderNo to 0
      if (!reverseSlider) {
        nextSliceIdx = (startDragSliceIdx.current || 0) - amount;
      }
      // When changing the slice 'rotation' on the curved or straight MPR we now allow the rotation to loop around (a continuous 360 loop).
      if (
        viewType === KEY_CPR ||
        (viewType === KEY_MPR_LONG_AXIS && sliceCountRef.current > 0)
      ) {
        // Use the mod operator to let the value loop.
        nextSliceIdx = nextSliceIdx % sliceCountRef.current;
        // The js mod operator doesn't like negative values (it returns a negative value).
        if (nextSliceIdx < 0) {
          nextSliceIdx += sliceCountRef.current;
        }
      }
      // By default we don't want the index to loop; the ends are the end.
      else {
        nextSliceIdx = Math.min(
          Math.max(indexRange.min, nextSliceIdx),
          indexRange.max
        );
      }
      // Change the slice.
      onSliceChange && onSliceChange(nextSliceIdx);
      throttleEvent.current = true;
      setTimeout(() => (throttleEvent.current = false), 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getIndexRange, viewType, onSliceChange, isMeasurementMode]);

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent): boolean | void => {
      if (
        disableControlsRef.current ||
        disableKeyboardControls ||
        isMeasurementMode
      )
        return false;
      if (
        event.nativeEvent.code === 'ArrowUp' ||
        event.nativeEvent.code === 'ArrowDown' ||
        event.nativeEvent.code === 'ArrowLeft' ||
        event.nativeEvent.code === 'ArrowRight'
      ) {
        event.preventDefault();
        let idxCount = 0;
        switch (event.nativeEvent.code) {
          case 'ArrowLeft':
            idxCount = -10;
            if (!reverseSlider) idxCount = 10;
            break;
          case 'ArrowRight':
            idxCount = 10;
            if (!reverseSlider) idxCount = -10;
            break;
          case 'ArrowUp':
            idxCount = -1;
            if (!reverseSlider) idxCount = 1;
            break;
          case 'ArrowDown':
            idxCount = 1;
            if (!reverseSlider) idxCount = -1;
            break;
          default:
            break;
        }
        const indexRange = getIndexRange();
        const sliceIndex = Math.min(
          Math.max(indexRange.min, slice + idxCount),
          indexRange.max
        );
        onSliceChange && onSliceChange(sliceIndex);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      disableKeyboardControls,
      getIndexRange,
      slice,
      onSliceChange,
      isMeasurementMode,
    ]
  );

  const getWindowingControlBoxSize = useCallback(() => {
    if (size) {
      const percentage = 4;
      return Math.min(
        size.width / percentage,
        WINDOWING_SETTINGS.MAX_CONTROLBOX_SIZE
      );
    }
    return WINDOWING_SETTINGS.MAX_CONTROLBOX_SIZE;
  }, [size]);

  const getWindowingRatio = useCallback(() => {
    const controlBoxSize = getWindowingControlBoxSize();
    const range = {
      x:
        WINDOWING_SETTINGS.WINDOW_WIDTH_MAX -
        WINDOWING_SETTINGS.WINDOW_WIDTH_MIN,
      y:
        WINDOWING_SETTINGS.WINDOW_LEVEL_MAX -
        WINDOWING_SETTINGS.WINDOW_LEVEL_MIN,
    };
    const ratio = {
      x: controlBoxSize / range.x,
      y: controlBoxSize / range.y,
    };
    return ratio;
  }, [getWindowingControlBoxSize]);

  /**
   * @param mousePos: The mouse position {x: number, y: number} relative to the top left of this component.
   */
  const onStartWindowing = useCallback(
    (mousePos: XYCoords) => {
      const ratio = getWindowingRatio();
      const controlBoxSize = getWindowingControlBoxSize();
      const offset = controlBoxSize / 2;
      const pointPosition = {
        x:
          (windowWidthRef.current - WINDOWING_SETTINGS.WINDOW_WIDTH_MIN) *
          ratio.x,
        y:
          (windowLevelRef.current - WINDOWING_SETTINGS.WINDOW_LEVEL_MIN) *
          ratio.y,
      };
      const boxPosition = {
        x: mousePos.x - pointPosition.x + offset,
        y: mousePos.y - pointPosition.y + offset,
      };
      const midPoint = controlBoxSize / 2;
      windowingContainerRef.current = new PIXI.Container();
      windowingContainerRef.current.alpha = WINDOWING_SETTINGS.SHOW_CONTROL_BOX
        ? 1
        : 0;

      const box = new PIXI.Graphics();
      box.lineStyle(2, blueColorRef.current, 1);
      box.beginFill(blueColorRef.current, 0.2);
      box.drawRect(0, 0, controlBoxSize, controlBoxSize);
      box.endFill();
      windowingContainerRef.current.addChild(box);

      const windowingPoint = new PIXI.Graphics();
      windowingPoint.lineStyle(0); // draw a circle, set the lineStyle to zero so the circle doesn't have an outline
      windowingPoint.beginFill(primaryColorRef.current, 1);
      windowingPoint.drawCircle(pointPosition.x, pointPosition.y, 4);
      windowingPoint.endFill();
      windowingPointRef.current = windowingPoint;
      windowingContainerRef.current.addChild(windowingPointRef.current);

      const style = new PIXI.TextStyle({
        fontFamily: 'Arial',
        fontSize: 10,
        fill: '#ffffff',
        align: 'center',
      });

      const topText = new PIXI.Text(
        `Level: ${WINDOWING_SETTINGS.WINDOW_LEVEL_MIN}`,
        style
      );
      topText.x = midPoint;
      topText.y = -10;
      topText.anchor.set(0.5, 0.5);
      topText.roundPixels = true;
      windowingContainerRef.current.addChild(topText);

      const botText = new PIXI.Text(
        `Level: ${WINDOWING_SETTINGS.WINDOW_LEVEL_MAX}`,
        style
      );
      botText.x = midPoint;
      botText.y = controlBoxSize + 10;
      botText.anchor.set(0.5, 0.5);
      botText.roundPixels = true;
      windowingContainerRef.current.addChild(botText);

      const leftText = new PIXI.Text(
        `Width: ${WINDOWING_SETTINGS.WINDOW_WIDTH_MIN}`,
        style
      );
      leftText.x = -10;
      leftText.y = midPoint;
      leftText.anchor.set(1, 0.5);
      leftText.roundPixels = true;
      windowingContainerRef.current.addChild(leftText);

      const rightText = new PIXI.Text(
        `Width: ${WINDOWING_SETTINGS.WINDOW_WIDTH_MAX}`,
        style
      );
      rightText.x = controlBoxSize + 10;
      rightText.y = midPoint;
      rightText.anchor.set(0, 0.5);
      rightText.roundPixels = true;
      windowingContainerRef.current.addChild(rightText);

      windowingContainerRef.current.x = boxPosition.x;
      windowingContainerRef.current.y = boxPosition.y;

      appRef.current?.stage.addChild(windowingContainerRef.current);
    },
    [getWindowingControlBoxSize, getWindowingRatio]
  );

  /**
   * @param mousePos: The mouse position {x: number, y: number} relative to the top left of this component.
   */
  const onWindowing = useCallback(
    (mousePos: XYCoords) => {
      if (throttleEvent.current || !windowingContainerRef.current) return;
      let windowWidth = windowWidthRef.current;
      let windowLevel = windowLevelRef.current;
      const controlBoxSize = getWindowingControlBoxSize();

      const offset = controlBoxSize / 2;
      const ratio = getWindowingRatio();
      const position = {
        x: mousePos.x + offset - windowingContainerRef.current.x,
        y: mousePos.y + offset - windowingContainerRef.current.y,
      };
      windowWidth = position.x / ratio.x + WINDOWING_SETTINGS.WINDOW_WIDTH_MIN;
      windowLevel = position.y / ratio.y + WINDOWING_SETTINGS.WINDOW_LEVEL_MIN;
      windowWidthRef.current = Math.min(
        Math.max(WINDOWING_SETTINGS.WINDOW_WIDTH_MIN, windowWidth),
        WINDOWING_SETTINGS.WINDOW_WIDTH_MAX
      );
      windowLevelRef.current = Math.min(
        Math.max(WINDOWING_SETTINGS.WINDOW_LEVEL_MIN, windowLevel),
        WINDOWING_SETTINGS.WINDOW_LEVEL_MAX
      );
      if (sliceMeshRef.current) {
        setWindowingUniforms(
          sliceMeshRef.current,
          windowLevelRef.current,
          windowWidthRef.current
        );
      }
      onWindowLevelsChange &&
        onWindowLevelsChange({
          windowWidth: windowWidthRef.current,
          windowCenter: windowLevelRef.current,
        });

      if (windowingPointRef.current) {
        const pointPosition = {
          x:
            (windowWidthRef.current - WINDOWING_SETTINGS.WINDOW_WIDTH_MIN) *
              ratio.x -
            (startMousePosRef.current.x - windowingContainerRef.current.x) -
            offset,
          y:
            (windowLevelRef.current - WINDOWING_SETTINGS.WINDOW_LEVEL_MIN) *
              ratio.y -
            (startMousePosRef.current.y - windowingContainerRef.current.y) -
            offset,
        };
        windowingPointRef.current.x = pointPosition.x;
        windowingPointRef.current.y = pointPosition.y;
      }

      throttleEvent.current = true;
      setTimeout(() => (throttleEvent.current = false), 0);
    },
    [getWindowingControlBoxSize, getWindowingRatio, onWindowLevelsChange]
  );

  /**
   * @param mousePos: The mouse position {x: number, y: number} relative to the top left of this component.
   */
  const zoom = useCallback(
    (mousePos: XYCoords, amount: number) => {
      // if no container this shouldn't fire
      if (!containerRef.current) return;
      // Set the initial zoom info as required.
      if (!zoomInfoRef.current) {
        zoomInfoRef.current = {
          // Remember the starting position of the top left corner of the image relative to the top left corner of this component.
          containerX: containerRef.current.x,
          containerY: containerRef.current.y,
          // Remember the starting position of mouse relative to the top left corner of this component.
          mouseX: mousePos.x,
          mouseY: mousePos.y,
          // Remember the starting image scale (NOTE: scale.x === scale.y so a single value is fine).
          scale: containerRef.current.scale.x,
        };
      }
      // Adjust the image scale by the specified amount. Clamp the size to be between minSize and maxSize the view size.
      const scale = clampNumber(
        containerRef.current.scale.x + amount * -0.01,
        minZoom * defaultScaleRef.current,
        maxZoom * defaultScaleRef.current
      );
      containerRef.current.scale.x = scale;
      containerRef.current.scale.y = scale;

      // Adjust the image's top left corner so that the point we initially clicked on is fixed to
      // the same position on the component.
      const zoomInfo = zoomInfoRef.current;
      containerRef.current.x =
        zoomInfo.mouseX -
        (zoomInfo.mouseX - zoomInfo.containerX) * (scale / zoomInfo.scale);
      containerRef.current.y =
        zoomInfo.mouseY -
        (zoomInfo.mouseY - zoomInfo.containerY) * (scale / zoomInfo.scale);

      onZoom && onZoom(sliceRef.current);
    },
    [maxZoom, minZoom, onZoom]
  );

  const mouseIsOverStage = useCallback((e: any) => {
    const event = e.data ? e.data.originalEvent : e;
    return event.target && event.target === appRef.current?.view;
  }, []);

  const calculateHuData = useCallback(
    (start: XYCoords, end: XYCoords) => {
      // Retrieve width and height
      const graphicWidth = Math.abs(start.x - end.x);
      const graphicHeight = Math.abs(start.y - end.y);

      // Create phantom ellipse map image data to
      const ellipseCenter: XYCoords = {
        x: start.x + graphicWidth / 2,
        y: start.y + graphicHeight / 2,
      };

      const newEllipse = new PIXI.Ellipse(
        ellipseCenter.x,
        ellipseCenter.y,
        graphicWidth / 2,
        graphicHeight / 2
      );

      // Retrieve HU values within ellipse
      const huValues = getMultipleHueValues([start, end], newEllipse);
      // Calculate HU Mean
      const huMean = getMeanValue(huValues);
      // Calculate HU Standard deviation
      const huStdDeviation = getStandardDeviation(huValues);

      onUpdateHuData &&
        onUpdateHuData({ mean: huMean, stdDev: huStdDeviation });
    },
    [onUpdateHuData, getMultipleHueValues]
  );

  const onMouseMove = useCallback(
    (event: React.MouseEvent): boolean | void => {
      // Return if the component's controls are disabled.
      if (disableControlsRef.current) return false;

      // Update the current mouse position.
      currentMousePosRef.current = getHolderMousePos({
        x: event.pageX,
        y: event.pageY,
      });

      // Check the mouse drag started on this component.
      // The mouseDownRef is still set even if the user is draging the slider.
      // If they are dragging the slider we should ignore this event.
      if (mouseDownRef.current && !sliderMouseDownRef.current) {
        switch (event.buttons) {
          case MOUSE_BUTTONS.BOTH:
            if (modeRef.current !== MOUSE_MODE.ZOOM) {
              modeRef.current = MOUSE_MODE.ZOOM;
              onBothButtonsRef.current = true;
              restoreCursor();
              holderRef.current?.classList.add('webGLViewer-zooming');
            }
            zoom(
              currentMousePosRef.current,
              currentMousePosRef.current.y - lastMousePosRef.current.y
            );
            break;
          case MOUSE_BUTTONS.LEFT:
            if (!onBothButtonsRef.current && !editModeRef.current) {
              switch (isDraggingMeasurementToolRef.current) {
                case MEASUREMENT_SETTINGS.RULER_STATE.New:
                case MEASUREMENT_SETTINGS.RULER_STATE.Handle:
                case MEASUREMENT_SETTINGS.RULER_STATE.HandleEnd:
                case MEASUREMENT_SETTINGS.RULER_STATE.HandleStart:
                case MEASUREMENT_SETTINGS.ELLIPSE_STATE.New:
                case MEASUREMENT_SETTINGS.ELLIPSE_STATE.Handle:
                  restoreCursor();
                  holderRef.current?.classList.add('measurement-no-cursor');

                  // Creating a phantom ellipse to get HU mean value of points contained with ellipse
                  if (isEllipseActive) {
                    const startPoint = measurementTargetRef.current
                      ? measurementTargetRef.current.points[0]
                      : getContainerMousePos(startMousePosRef.current);

                    const endPoint = measurementTargetRef.current
                      ? measurementTargetRef.current.points[2]
                      : getContainerMousePos(currentMousePosRef.current);

                    calculateHuData(startPoint, endPoint);
                  }
                  return;
                case MEASUREMENT_SETTINGS.RULER_STATE.Moving:
                case MEASUREMENT_SETTINGS.RULER_STATE.Active:
                  restoreCursor();
                  holderRef.current?.classList.add('measurement-dragging-tool');
                  return;
                case 'moving-ellipse':
                  if (measurementTargetRef.current) {
                    // Moving / dragging an existing ellipse measurement graphic
                    const targetPoints = measurementTargetRef.current.points;

                    calculateHuData(targetPoints[0], targetPoints[2]);
                  }
                  return;
              }

              if (modeRef.current !== MOUSE_MODE.SLIDE) {
                modeRef.current = MOUSE_MODE.SLIDE;
                restoreCursor();
                const cursorClass =
                  viewType === KEY_CPR || viewType === KEY_MPR_LONG_AXIS
                    ? 'webGLViewer-sliding-ew'
                    : 'webGLViewer-sliding-ns';
                holderRef.current?.classList.add(cursorClass);
              }
              onMouseSlide();
            }
            break;
          case MOUSE_BUTTONS.RIGHT:
            if (!onBothButtonsRef.current) {
              if (modeRef.current !== MOUSE_MODE.PAN) {
                modeRef.current = MOUSE_MODE.PAN;
                restoreCursor();
                holderRef.current?.classList.add('webGLViewer-dragging');
              }
              onMouseDrag();
            }
            break;
          case MOUSE_BUTTONS.MIDDLE:
            if (modeRef.current !== MOUSE_MODE.WINDOWING) {
              onStartWindowing(currentMousePosRef.current);
              modeRef.current = MOUSE_MODE.WINDOWING;
              restoreCursor();
              holderRef.current?.classList.add('webGLViewer-windowing');
            }
            onWindowing(currentMousePosRef.current);
            break;
          default:
        }
      }
      if (isMeasurementMode) {
        restoreCursor();
        holderRef.current?.classList.add('measurement-crosshair-tool');
        if (isOverMeasurementToolRef.current) {
          restoreCursor();
          holderRef.current?.classList.add('measurement-dragging-tool');
        }
      }
      // Update the last mouse position (used by zoom).
      lastMousePosRef.current = currentMousePosRef.current;

      hueRef.current = getHue();
      onHueChange && onHueChange(hueRef.current);
    },
    [
      getHue,
      getHolderMousePos,
      onHueChange,
      onMouseDrag,
      onMouseSlide,
      onStartWindowing,
      onWindowing,
      viewType,
      zoom,
      calculateHuData,
      getContainerMousePos,
      isDraggingMeasurementToolRef,
      isEllipseActive,
      measurementTargetRef,
      isMeasurementMode,
      isOverMeasurementToolRef,
    ]
  );

  const onContextMenu = useCallback((event: any) => {
    event.preventDefault();
  }, []);

  const createHueGridTexture = useCallback((width: number, height: number) => {
    if (!utilityCanvasRef.current)
      utilityCanvasRef.current = document.createElement('canvas');
    utilityCanvasRef.current.width = width;
    utilityCanvasRef.current.height = height;
    const ctx = utilityCanvasRef.current.getContext('2d');
    if (ctx) {
      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = 'red';
      ctx.fillRect(0, 0, width, height);
    } else console.error('could not acquire 2d context');
    return PIXI.Texture.from(utilityCanvasRef.current.toDataURL());
  }, []);

  const drawHueGridOverlay = useCallback(() => {
    if (!hueOverlay.current && containerRef.current) {
      hueOverlay.current = new PIXI.ParticleContainer();
      const { width, height } = imageSizeRef.current;
      const verticalLine = createHueGridTexture(1, height);
      for (let i = 0; i < width; i++) {
        const x = i;
        const sprite = new PIXI.Sprite(verticalLine);
        sprite.x = x;
        sprite.y = 0;
        sprite.alpha = i % 2 === 0 ? 0.2 : 0;
        hueOverlay.current.addChild(sprite);
      }
      const horizontalLine = createHueGridTexture(width, 1);
      for (let i = 0; i < height; i++) {
        const y = i;
        const sprite = new PIXI.Sprite(horizontalLine);
        sprite.x = 0;
        sprite.y = y;
        sprite.alpha = i % 2 === 0 ? 0.2 : 0;
        hueOverlay.current.addChild(sprite);
      }
      containerRef.current.addChild(hueOverlay.current);
    }
  }, [createHueGridTexture]);

  const setHolderSize = useCallback(() => {
    if (resizableRef.current && holderRef.current) {
      const parent = holderRef.current.parentNode;
      if (!parent) return;
      const newSize = {
        width: (parent as HTMLElement).clientWidth,
        height: (parent as HTMLElement).clientHeight || size.width,
      };

      // Scale the container to match the new size as well as possible.
      if (
        size.height &&
        newSize.width &&
        newSize.height &&
        containerRef.current
      ) {
        // Scale by Y vs the minimum scale change because this is the most restrictive dimension
        // and will allow resizing not wander over time.
        const scaleY = newSize.height / size.height;
        containerRef.current.scale.x *= scaleY;
        containerRef.current.scale.y *= scaleY;
      }

      // Set the new size and don't forget to adjust the ref.
      setSize(newSize);
      sizeRef.current = { ...newSize };
    }
  }, [size, setSize]);

  /**
   * Get the image scaling required to fit the image snugly in the view.
   */
  const setImageRenderSize = useCallback(
    (width: number, height: number): number => {
      let defaultScale = 1;
      // Check the image and view have valid sizes.
      // Get the aspect ratio of the image and the view.
      const imageAspectRatio = width / height;
      const viewAspectRatio = sizeRef.current.width / sizeRef.current.height;

      switch (resizeMode) {
        case RESIZE_MODE.COVER:
          // Ensure the image covers the whole rendering window, scaling it up if need be.
          if (imageAspectRatio > viewAspectRatio) {
            defaultScale = sizeRef.current.height / height;
          } else {
            defaultScale = sizeRef.current.width / width;
          }
          break;

        case RESIZE_MODE.FIT_VERTICAL:
          // Scale so the height of the image fits the height of the view.
          defaultScale = sizeRef.current.height / height;
          break;

        case RESIZE_MODE.FIT:
        default:
          // Contain the image within the rendering window, scaling it down if need be.
          if (imageAspectRatio > viewAspectRatio) {
            defaultScale = sizeRef.current.width / width;
          } else {
            defaultScale = sizeRef.current.height / height;
          }
          break;
      }
      // Set the image size.
      imageSizeRef.current = { width, height };
      return defaultScale;
    },
    [resizeMode]
  );

  const contextRestoredListener = useCallback(
    (event: any) => {
      console.log('WebGLViewer context has been restored');
      setLostContext(false);
    },
    [setLostContext]
  );

  const contextLostListener = useCallback(
    (event: any) => {
      console.log('WebGLViewer context has been lost');
      // Inform WebGL that we handle context restoration.
      event.preventDefault();
      setLostContext(true);
    },
    [setLostContext]
  );

  /**
   * We do the actual handling of the lost context here, to avoid any cyclic references.
   */
  useEffect(() => {
    if (lostContext) {
      cleanup();
      // Set the new ref data after the reset (because it is cleared in the reset).
      prevShapeDataRef.current = shapeData ? [...shapeData] : undefined;
      prevImageBufferDataRef.current = imageBufferData;
      prevVesselNameRef.current = selectedVesselName;
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lostContext]);

  /**
   * Clean up all allocated PIXI buffers etc and ensure we have exited add and editing modes.
   */
  const cleanup = useCallback(() => {
    // Allow any component using this WebGLViewer to clean itself up first.
    if (onCleanup) {
      onCleanup();
    }

    // Clear the load texture timer if one was set.
    if (loadTextureTimerRef.current) {
      clearTimeout(loadTextureTimerRef.current);
      loadTextureTimerRef.current = undefined;
    }
    backgroundImageLoadingRef.current = [];
    firstLoadRef.current = true;
    if (windowingContainerRef.current) {
      const windowingContainer = windowingContainerRef.current;
      setTimeout(() => {
        windowingContainer.destroy({
          children: true,
          texture: true,
          baseTexture: true,
        });
      }, 0);
      windowingContainerRef.current = null;
    }
    if (appRef.current) {
      const view = appRef.current.view;
      view.removeEventListener('webglcontextlost', contextLostListener);
      view.removeEventListener('webglcontextrestored', contextRestoredListener);

      // This should not be required but PIXI has a bug making it not correctly release the WebGL context. Although
      // this function is only meant to simulate a lost context it actually allows PIXI to properly release it in destroy.
      if (
        isWebGLRenderer(appRef.current.renderer) &&
        !appRef.current.renderer.gl.isContextLost()
      ) {
        const ext = appRef.current.renderer.gl.getExtension(
          'WEBGL_lose_context'
        );
        ext?.loseContext();
      }
      appRef.current.destroy(true);
      appRef.current = null;
    }

    // It's important to clean up references that may be cyclic so JavaScript can garbage collect objects no longer actually being used.
    if (!mountedRef.current) {
      holderRef.current = null;
      utilityCanvasRef.current = null;
    }
    containerRef.current = null;
    sliceWindowDataRef.current = {};
    sliceMeshRef.current = undefined;
    sliceContainerRef.current = undefined;
    windowingContainerRef.current = null;
    windowingPointRef.current = null;
    startDragSliceIdx.current = null;
    zoomInfoRef.current = null;
    hueRef.current = null;
    hueOverlay.current = null;
    prevShapeDataRef.current = undefined;
    prevImageBufferDataRef.current = undefined;
    prevVesselNameRef.current = undefined;
    loadTextureTimerRef.current = undefined;
    if (mountedRef.current) {
      setLoading(true);
      setLoadingImage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextLostListener, contextRestoredListener]);

  const centerContainer = useCallback(() => {
    if (containerRef.current && appRef.current && appRef.current.screen) {
      const container = containerRef.current;
      container.x = (appRef.current.screen.width - container.width) / 2;
      container.y = (appRef.current.screen.height - container.height) / 2;
    }
  }, []);

  const initPIXI = useCallback(() => {
    if (!holderRef.current) return;
    if (appRef.current) return;
    appRef.current = new PIXI.Application({
      width: sizeRef.current.width,
      height: sizeRef.current.height,
      backgroundColor: 0x000000,
      preserveDrawingBuffer: true, // this allows for screenshots but could slow the renderer down slightly.
      resizeTo: holderRef.current,
      antialias: true,
      // resolution: window.devicePixelRatio || 1,
    });
    holderRef.current.appendChild(appRef.current.view);

    // create the main container (used for scaling and dragging)
    containerRef.current = new PIXI.Container();
    containerRef.current.interactive = true;

    // add the sprite container
    sliceContainerRef.current = new PIXI.Container();
    containerRef.current.addChild(sliceContainerRef.current);

    // make the stage interactive and add the main container
    appRef.current.stage.interactive = true;
    appRef.current.stage.addChild(containerRef.current);

    // Set the default container scale.
    if (containerRef.current) {
      containerRef.current.scale.x = defaultScaleRef.current;
      containerRef.current.scale.y = defaultScaleRef.current;
    }

    setLoading(false);
    // Call the callback now that the initial load has finished.
    if (onLoad) {
      onLoad();
    }

    // Create the mesh with the slice loaded.
    const mesh = createMesh(blankImageBuffer, imageSizeRef.current);
    setWindowingUniforms(mesh, windowLevelRef.current, windowWidthRef.current);
    sliceContainerRef.current?.addChild(mesh);
    sliceMeshRef.current = mesh;
    centerContainer();

    // The first image has loaded.
    const imageBuffer = imageBufferData[slice];
    loadImageBufferInMesh(imageBuffer);

    // Add listener to check for lost context so we can restore it
    const view = appRef.current.view;
    view.addEventListener('webglcontextlost', contextLostListener);
    view.addEventListener('webglcontextrestored', contextRestoredListener);
    // We've just added the new listeners, so they aren't lost.
    setLostContext(false);

    // See if we can retrieve info on the type of renderer being used.
    updateWebGLRendererInfo(appRef.current);

    // draw the debug Hue overlay if we need to check the accuracy of the
    // hue value on mouse over
    HUE_SETTINGS.SHOWOVERLAY && drawHueGridOverlay();
    onReady &&
      onReady({
        sliceNo: sliceCountRef.current,
        app: appRef.current,
        container: containerRef.current,
        imageSize: imageSizeRef.current,
        defaultScale: defaultScaleRef.current,
        holder: holderRef.current,
        getContainerMousePos,
        mouseIsOverStage,
        sliceContainer: sliceContainerRef.current,
      });
  }, [
    imageBufferData,
    drawHueGridOverlay,
    getContainerMousePos,
    mouseIsOverStage,
    onReady,
    slice,
    centerContainer,
    loadImageBufferInMesh,
    onLoad,
    contextLostListener,
    contextRestoredListener,
  ]);

  const init = useCallback(() => {
    setHolderSize();
    // Check we are still mounted.
    if (!mountedRef.current) return;

    // Update the number of CPR slices if this is the CPR viewer.
    if (viewType === KEY_CPR || viewType === KEY_MPR_LONG_AXIS) {
      setCprSliceCount(shapeData?.length ?? 0);
    }
    // Remember the slice count.
    sliceCountRef.current = shapeData?.length ?? 0;

    // If we already have the image data we need to load our first image from that.
    let firstImageBuffer: ImageBuffer | undefined = imageBufferData[slice];
    if (!firstImageBuffer) {
      console.error('WebGLViewer init called without a first image to show!');
    }

    // Check we are still mounted.
    if (mountedRef.current && firstImageBuffer) {
      defaultScaleRef.current = setImageRenderSize(
        firstImageBuffer.width,
        firstImageBuffer.height
      );
      onImageBufferLoaded(firstImageBuffer, slice);
      initPIXI();
    }
  }, [
    imageBufferData,
    initPIXI,
    setHolderSize,
    setImageRenderSize,
    slice,
    setCprSliceCount,
    viewType,
    onImageBufferLoaded,
    shapeData?.length,
  ]);

  const onSliderMouseDown = useCallback(() => {
    sliderMouseDownRef.current = true;
  }, []);

  const onSliderMouseUp = useCallback(() => {
    sliderMouseDownRef.current = false;
    // Put focus on container rather than keeping it on slider
    holderRef.current?.focus();
  }, []);

  return (
    <div
      tabIndex={-1}
      className="webGLViewer"
      ref={holderRef}
      style={{ width: `${size.width}px`, height: `${size.height}px` }}
      onPointerDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onContextMenu={onContextMenu}
      onKeyDown={onKeyDown}
    >
      {!loading && showSlider && (
        <div style={isMeasurementMode ? { pointerEvents: 'none' } : {}}>
          <SliceSlider
            reverse={reverseSlider}
            min={0}
            max={sliceCountRef.current - 1}
            setValue={slice}
            onChange={(slice) => {
              onSliceChange && onSliceChange(slice);
            }}
            onBeforeChange={onSliderMouseDown}
            onAfterChange={onSliderMouseUp}
            marks={sliderMarkers}
          />
        </div>
      )}
      {/*
      We show the loading spinner while initializing and then when the user is over a slice whose image is yet to load.
      When the slice image is loading we still allow interaction with the component.
      */}
      {(loading || loadingImage) && (
        <Loader allowPointerEvents={!loading} text={'Loading Image'} />
      )}
      <div className="webGLViewer-loadingInfo">
        {loadingInfo && (
          <div className="webGLViewer-loadingInfoData">
            {loadingInfo}
            <span className="webGLViewer-loadingInfoDataLoader">
              <Loader small />
            </span>
          </div>
        )}
        {!disableLoadingProgress && (
          <div className="webGLViewer-loadingInfoSlices">
            {/* It's possible we have started loading slices before the sliceCountRef is set, so set the 'out of' to the max of the two */}
            Loaded {slicesLoaded} out of{' '}
            {Math.max(sliceCountRef.current, slicesLoaded)} slices
          </div>
        )}
      </div>
    </div>
  );
};

export default WebGLViewer;
