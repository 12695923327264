import { useCallback } from 'react';
import { showToast } from '../components/Toast/showToast';
import { useStoreContext } from '../context/store-context';
import { useReportContext } from '../context/report-context';
import { Ethnicity } from '../types/common';
import * as api from '../utils/api';

/**
 * Returns a function to fetch the MESA percentile using the standalone API
 * endpoint (as opposed to getting it from the report content). Sets the
 * fetchingMesaPercentile flag in ReportContext while the network request is in
 * progress. Shows a toast on error. Called when changing the ethnicity while
 * editing the report.
 *
 * Since the value only needs to be shown while editing the report, update the
 * draft report with the response. If the user saves the report, the MESA score
 * will be recalculated by the API and returned in the report content response.
 */
export function useFetchMesaPercentile() {
  const { updateDraftReport } = useStoreContext();
  const { setFetchingMesaPercentile } = useReportContext();

  return useCallback(
    async (
      sex: string,
      age: string,
      ethnicity: Ethnicity,
      calciumScore: number
    ) => {
      setFetchingMesaPercentile(true);

      try {
        const response = await api.fetchMesaPercentile(
          sex,
          age,
          ethnicity,
          calciumScore
        );
        // Including ethnicity here as a workaround for this callback capturing
        // the old ethnicity value.
        updateDraftReport({
          ethnicity: ethnicity,
          mesa_percentile: response.percentile,
        });
      } catch (err) {
        console.warn('Failed to update MESA score:', err);
        showToast.error('Failed to update MESA score');
      } finally {
        setFetchingMesaPercentile(false);
      }
    },
    [setFetchingMesaPercentile, updateDraftReport]
  );
}
