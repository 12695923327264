import React, {
  ReactElement,
  ReactNode,
  useContext,
  useState,
  useReducer,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import {
  ContrastContextValue,
  ContrastViewType,
  ContrastViewContent,
  ContrastVolumeOverlayMode,
  ContrastVolumeViewProps,
  ContrastVolume,
  ContrastVolumeMap,
  ContrastVolumeActions,
  ContrastVolumeAction,
  ContrastPendingScreenshot,
  BlendMode,
  getStudySeriesId,
  StudySeriesMap,
  CTVolumeOverlay,
  VesselSyncInfo,
  ContrastVolumeStatus,
} from './contrast-types';
import { vtkApi } from '../views/CTVolume/ReactVTKJS/ReactVTKJSTypes';
import {
  getCrosshairValues,
  viewTypeToAxis,
} from '../views/CTVolume/ContrastViewer/Utils';
import isEqual from 'lodash/isEqual';
import { NON_CONTRAST_DROP_ZONE } from '../components/DropZone';

// The initial type of slab blend mode to use when rendering a contrast CT volume with a render thickness > 0.0.
const initialSlabBlendMode: BlendMode = BlendMode.MAXIMUM_INTENSITY_BLEND;

function contrastVolumeMapReducer(
  state: ContrastVolumeMap,
  action: ContrastVolumeAction
) {
  // console.log('action', action.type);

  let studySeriesId = '';
  let contrastVolume: ContrastVolume | undefined = undefined;

  if (
    action.type !== ContrastVolumeActions.RESET_VIEWS &&
    action.type !== ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES &&
    action.type !== ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES_FOR_VOLUME
  ) {
    // Get the studySeriesId.
    studySeriesId = getStudySeriesId(action.study, action.seriesName);
    // Get the contrast volume for the specifed seriesName (or undefined if it doesn't exist).
    contrastVolume = state.get(studySeriesId);
  }

  switch (action.type) {
    case ContrastVolumeActions.LOAD:
      // If the volume is in the map we don't need to do anything.
      // Otherwise we need to initialise a new volume object.
      if (contrastVolume === undefined) {
        const newState = new Map(state);
        const apis: vtkApi[] = [];
        newState.set(studySeriesId, {
          study: action.study,
          seriesName: action.seriesName,
          imageCount: 0,
          imageCountLoaded: 0,
          status: ContrastVolumeStatus.LOADING,
          /* NOTE: We no longer use per-series WW/WL.
          defaultWindowLevels: {
            windowWidth: WINDOW_WIDTH_DEFAULT,
            windowCenter: WINDOW_LEVEL_DEFAULT,
          },
          */
          spacing: [1, 1, 1],
          volume: undefined,
          volumes: [],
          apis,
          centerlineMap: new Map(),
          crosshairValues: {
            crosshairPos: undefined,
            huValue: undefined,
          },
          crosshairWorldPosition: [0, 0, 0],
          crosshairWorldAxes: [
            [0, 0, 1],
            [1, 0, 0],
            [0, 1, 0],
          ],
          viewProps: [
            {
              blendMode: initialSlabBlendMode,
              renderThickness: 0.0,
              overlayMode: ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE,
            },
            {
              blendMode: initialSlabBlendMode,
              renderThickness: 0.0,
              overlayMode: ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE,
            },
            {
              blendMode: initialSlabBlendMode,
              renderThickness: 0.0,
              overlayMode: ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE,
            },
          ],
          getApi: (viewIndex: number) => {
            let result: vtkApi | undefined = undefined;
            apis.forEach((api) => {
              const apiViewIndex = api.getViewIndex();
              if (apiViewIndex === viewIndex) {
                result = api;
              }
            });
            return result;
          },
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.SET_STATUS:
      if (contrastVolume && contrastVolume.status !== action.status) {
        const newState = new Map(state);
        newState.set(studySeriesId, {
          ...contrastVolume,
          status: action.status,
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.IMAGE_LOADED:
      if (
        contrastVolume &&
        contrastVolume.imageCountLoaded !== action.imageCountLoaded
      ) {
        const newState = new Map(state);
        newState.set(studySeriesId, {
          ...contrastVolume,
          imageCountLoaded: action.imageCountLoaded,
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.SET_IMAGE_COUNT:
      if (contrastVolume && contrastVolume.imageCount !== action.imageCount) {
        const newState = new Map(state);
        newState.set(studySeriesId, {
          ...contrastVolume,
          imageCount: action.imageCount,
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.SET_VOLUME_SPACING_AND_WINDOW_LEVELS:
      if (contrastVolume) {
        const newState = new Map(state);
        newState.set(studySeriesId, {
          ...contrastVolume,
          volume: action.volume,
          volumes: [action.volume],
          spacing: action.spacing,
          /* NOTE: We no longer use per-series WW/WL.
          defaultWindowLevels: action.defaultWindowLevels,
          */
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.SET_CENTERLINE:
      if (contrastVolume) {
        const newState = new Map(state);
        const newCenterlineMap = new Map(contrastVolume.centerlineMap);
        newCenterlineMap.set(action.vesselName, { points: action.points });
        newState.set(studySeriesId, {
          ...contrastVolume,
          centerlineMap: newCenterlineMap,
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.SET_BLEND_MODE:
      if (
        contrastVolume &&
        contrastVolume.viewProps[action.viewType].blendMode !== action.blendMode
      ) {
        const newState = new Map(state);
        const newViewProps = contrastVolume.viewProps.slice();
        newViewProps[action.viewType].blendMode = action.blendMode;
        newState.set(studySeriesId, {
          ...contrastVolume,
          viewProps: newViewProps,
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.SET_RENDER_THICKNESS:
      if (
        contrastVolume &&
        contrastVolume.viewProps[action.viewType].renderThickness !==
          action.renderThickness
      ) {
        const newState = new Map(state);
        const newViewProps = contrastVolume.viewProps.slice();
        newViewProps[action.viewType].renderThickness = action.renderThickness;
        newState.set(studySeriesId, {
          ...contrastVolume,
          viewProps: newViewProps,
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.SET_OVERLAY_MODE:
      if (contrastVolume) {
        const newState = new Map(state);
        const newViewProps = contrastVolume.viewProps.slice();
        newViewProps[action.viewType].overlayMode = action.overlayMode;
        newState.set(studySeriesId, {
          ...contrastVolume,
          viewProps: newViewProps,
        });
        return newState;
      }
      break;

    case ContrastVolumeActions.RESET_VIEWS: {
      const newState = new Map();
      state.forEach((contrastVolume, key) => {
        // Reset the crosshairs position and orientation.
        if (contrastVolume.apis.length) {
          contrastVolume.apis[0].resetCrosshairs();
        }

        // Reset each api on the contrastVolume.
        contrastVolume.apis.forEach((api) => {
          // Reset the camera position, focal point, viewUp, slab thickness.
          // Reset the istyle.
          // Reset the crosshairWorldPosition.
          // Reset the crosshairWorldAxes.
          api.resetView();
        });

        newState.set(key, {
          ...contrastVolume,
          crosshairValues: getCrosshairValues(contrastVolume),
          viewProps: [
            {
              blendMode: initialSlabBlendMode,
              renderThickness: 0.0,
              overlayMode: ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE,
            },
            {
              blendMode: initialSlabBlendMode,
              renderThickness: 0.0,
              overlayMode: ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE,
            },
            {
              blendMode: initialSlabBlendMode,
              renderThickness: 0.0,
              overlayMode: ContrastVolumeOverlayMode.CROSSHAIRS_AND_CENTERLINE,
            },
          ],
        });
      });
      return newState;
    }

    case ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES: {
      let newState = undefined;
      state.forEach((contrastVolume, key) => {
        const api = contrastVolume.getApi(action.viewIndex);
        if (api) {
          const crosshairValues = getCrosshairValues(contrastVolume);
          // Initialize the newState and set the new crosshairValues.
          newState = new Map(state);
          newState.set(key, {
            ...contrastVolume,
            crosshairValues,
          });
        }
      });
      return newState || state;
    }

    case ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES_FOR_VOLUME: {
      const crosshairValues = getCrosshairValues(action.contrastVolume);
      // Initialize the newState and set the new crosshairValues.
      const newState = new Map(state);
      const studySeriesId = getStudySeriesId(
        action.contrastVolume.study,
        action.contrastVolume.seriesName
      );
      newState.set(studySeriesId, {
        ...action.contrastVolume,
        crosshairValues,
      });
      return newState;
    }

    case ContrastVolumeActions.REMOVE:
      if (contrastVolume) {
        const newState = new Map(state);
        newState.delete(studySeriesId);
        return newState;
      }
      break;

    default:
      // throw new Error();
      break;
  }
  // We get here if nothing has changed.
  return state;
}

const ContrastContext = React.createContext<ContrastContextValue | undefined>(
  undefined
);
ContrastContext.displayName = 'ContrastContext';

interface Props {
  children: ReactNode;
}

export function ContrastProvider({ children }: Props): ReactElement<Props> {
  const [vesselSync, setVesselSync] = useState<boolean>(true);
  const vesselSyncInfo = useRef<VesselSyncInfo | undefined>(undefined);

  // NOTE: This set must be mutated NOT overwritten so there is no set method.
  const [activeStudySeries] = useState<StudySeriesMap>(new Map());
  const [lastVisibleViews, setLastVisibleViews] = useState<number[]>([]);
  const [visibleViews, setVisibleViews] = useState<number[]>([0]);
  const [contrastViews, setContrastViews] = useState<ContrastViewContent[]>([]);
  const [contrastVolumeMap, dispatchContrastVolumeAction] = useReducer(
    contrastVolumeMapReducer,
    new Map()
  );

  // If set we are waiting for the view to resize so we can take a screenshot.
  const [pendingScreenshot, setPendingScreenshot] = useState<
    ContrastPendingScreenshot | undefined
  >(undefined);
  // If set we are waiting for the view to resize so we can take a screenshot.
  const onTakeScreenshot = useCallback(
    (
      viewIndex: number,
      onTakeScreenshotCallback: (imageData: string) => void
    ) => {
      setVisibleViews([viewIndex]);
      setPendingScreenshot({
        viewIndex,
        onTakeScreenshotCallback,
        lastVisibleViews: [...visibleViews],
      });
    },
    [visibleViews, setVisibleViews]
  );

  // Call if the pending screenshot has now been taken.
  const onCloseScreenshot = useCallback(() => {
    if (pendingScreenshot) {
      setVisibleViews(pendingScreenshot.lastVisibleViews);
      setPendingScreenshot(undefined);
    }
  }, [pendingScreenshot, setPendingScreenshot, setVisibleViews]);

  /**
   * When a view is double clicked and multiple views are currently visible we switch to showing this single view.
   * When a view is double clicked and only that view is visible we restore the previous multi-view configuration.
   */
  const onDoubleClickView = useCallback(
    (viewIndex: number) => {
      if (visibleViews.length > 1) {
        setLastVisibleViews(visibleViews);
        setVisibleViews([viewIndex]);
      } else if (visibleViews.length === 1 && lastVisibleViews.length > 1) {
        setVisibleViews(lastVisibleViews);
        setLastVisibleViews([]);
      }
    },
    [visibleViews, setVisibleViews, lastVisibleViews, setLastVisibleViews]
  );

  /**
   * Helper function to get the ContrastViewType for the given view index.
   */
  const getViewType = useCallback(
    (viewIndex: number): ContrastViewType | undefined => {
      if (viewIndex >= 0 && viewIndex <= contrastViews.length) {
        return contrastViews[viewIndex]?.viewType;
      }
      return undefined;
    },
    [contrastViews]
  );

  /**
   * Adjust the overlay mode on the view to the next overlay mode.
   */
  const setNextOverlayMode = useCallback(
    (viewIndex: number) => {
      const contrastVolume = getContrastVolumeForViewIndex(
        contrastViews,
        contrastVolumeMap,
        viewIndex
      );
      const viewType = getViewType(viewIndex);
      if (contrastVolume && viewType !== undefined) {
        dispatchContrastVolumeAction({
          study: contrastVolume.study,
          seriesName: contrastVolume.seriesName,
          type: ContrastVolumeActions.SET_OVERLAY_MODE,
          viewType: viewType,
          overlayMode:
            (contrastVolume.viewProps[viewType].overlayMode + 1) %
            ContrastVolumeOverlayMode.COUNT,
        });
      }
    },
    [
      contrastViews,
      contrastVolumeMap,
      getViewType,
      dispatchContrastVolumeAction,
    ]
  );

  /**
   * Adjust the slice on the specified view by the specified delta.
   */
  const adjustSlice = useCallback(
    (viewIndex: number, deltaSlice: number) => {
      const contrastVolume = getContrastVolumeForViewIndex(
        contrastViews,
        contrastVolumeMap,
        viewIndex
      );
      const api = contrastVolume?.getApi(viewIndex);
      if (contrastVolume && api) {
        const viewType = api.getViewType();
        const spacing = contrastVolume.spacing;
        // We multiply the delta by the spacing of the voxel in the base orientation of the axis.
        // TODO: We should ideally do the maths to work out what the spacing should be in the currently rotated orientation.
        let viewSpacing = spacing ? spacing[viewTypeToAxis(viewType!)] : 1;
        deltaSlice *= viewSpacing;

        // Get the interactor.
        const istyle = api.genericRenderWindow
          .getInteractor()
          .getInteractorStyle();

        // Get the slice range.
        const range = istyle.getSliceRange();
        // Get the current slice.
        let slice = istyle.getSlice();

        // Adjust the slice and limit the range.
        slice += deltaSlice;
        if (slice < range[0]) slice = range[0];
        if (slice > range[1]) slice = range[1];

        // Actually adjust the slice.
        istyle.scrollToSlice(slice);
      }
    },
    [contrastViews, contrastVolumeMap]
  );

  /**
   * Respond to a keydown event when the mouse is over the specified viewIndex.
   */
  const onKeyDown = useCallback(
    (viewIndex: number, event: KeyboardEvent) => {
      const viewType = getViewType(viewIndex);
      // Check the mouse is over a contrast volume view.
      if (
        viewType !== undefined &&
        viewType in
          [
            ContrastViewType.Axial,
            ContrastViewType.Sagittal,
            ContrastViewType.Coronal,
          ]
      ) {
        // Determine the number of slices to add or subtract from the current slice.
        let delta = 0;
        switch (event.code) {
          case 'ArrowLeft':
            delta = -10;
            break;
          case 'ArrowRight':
            delta = 10;
            break;
          case 'ArrowUp':
            delta = 1;
            break;
          case 'ArrowDown':
            delta = -1;
            break;
          case 'Space':
            event.preventDefault();
            setNextOverlayMode(viewIndex);
            break;
          default:
            break;
        }
        if (delta !== 0) {
          adjustSlice(viewIndex, delta);
        }
      }
    },
    [getViewType, setNextOverlayMode, adjustSlice]
  );

  /**
   * Update the visibleViews to reflect the newly requested visible view count.
   */
  const onVisibleViewCountButton = useCallback(
    (visibleViewCount: number) => {
      // When the user chooses the number of visible views via one of the buttons this clears the lastVisibleViews set when a view was double clicked.
      setLastVisibleViews([]);
      if (visibleViewCount !== visibleViews.length) {
        // TODO: Choose which visible views should remain visible vs defaulting to the first two?
        const newVisibleViews: number[] = [];
        for (let i = 0; i < visibleViewCount; i++) {
          newVisibleViews.push(i);
        }
        setVisibleViews(newVisibleViews);
      }
    },
    [visibleViews, setVisibleViews, setLastVisibleViews]
  );

  /**
   * Switch to showing all (3 or 4) views of the contrast series.
   */
  const onDraggedContrastSeries = useCallback(
    (contrastViews: ContrastViewContent[]) => {
      setContrastViews(contrastViews);
      setVisibleViews([0, 1, 2, 3]);
    },
    [setContrastViews, setVisibleViews]
  );

  /**
   * Update the single view of index viewIndex with the specified contrastViewContent.
   */
  const onDraggedContrastView = useCallback(
    (contrastView: ContrastViewContent, viewIndex: number) => {
      // Copy the existing contrast views array. Empty any views that are the same as the new contrast view.
      const newContrastViews: ContrastViewContent[] = [];
      for (let i = 0; i < 4; i++) {
        newContrastViews.push({
          study: undefined,
          seriesName: undefined,
          viewType: ContrastViewType.Empty,
        });
      }

      // The viewIndex is NON_CONTRAST_DROP_ZONE if we were viewing the non-contrast volume:
      // Drag the view to view 0 and only show view 0. Keep all the other contrast views empty.
      if (viewIndex === NON_CONTRAST_DROP_ZONE) {
        viewIndex = 0;
        setVisibleViews([0]);
      }
      // Otherwise copy the existing contrast views if they don't clash with the newly dragged in view content.
      else {
        contrastViews.forEach((view, index) => {
          if (!isEqual(view, contrastView)) {
            newContrastViews[index] = { ...view };
          }
        });
      }

      // Set the new contrast view in the array.
      newContrastViews[viewIndex] = contrastView;

      // Set the contrast views.
      setContrastViews(newContrastViews);
    },
    [contrastViews, setContrastViews, setVisibleViews]
  );

  const [contrastOverlays, setContrastOverlays] = useState<
    Record<number, CTVolumeOverlay>
  >({
    0: CTVolumeOverlay.HIDE,
    1: CTVolumeOverlay.HIDE,
    2: CTVolumeOverlay.HIDE,
    3: CTVolumeOverlay.HIDE,
  });

  const setContrastOverlayForViewIndex = useCallback(
    (overlay: CTVolumeOverlay, viewIndex: number) => {
      const newOverlays = { ...contrastOverlays };
      newOverlays[viewIndex] = overlay;
      setContrastOverlays(newOverlays);
    },
    [contrastOverlays]
  );

  const volumeViewerRef = useRef();

  const contrastContext: ContrastContextValue = {
    pendingScreenshot,
    onTakeScreenshot,
    onCloseScreenshot,
    vesselSync,
    setVesselSync,
    vesselSyncInfo,
    visibleViews,
    onDoubleClickView,
    onKeyDown,
    onVisibleViewCountButton,
    contrastViews,
    onDraggedContrastSeries,
    onDraggedContrastView,
    activeStudySeries,
    contrastVolumeMap,
    dispatchContrastVolumeAction,
    contrastOverlays,
    setContrastOverlayForViewIndex,
    volumeViewerRef,
  };

  return (
    <ContrastContext.Provider value={contrastContext}>
      {children}
    </ContrastContext.Provider>
  );
}

/**
 * Hook to access the ContrastContext
 * @returns {ContrastContextValue} ContrastContextValue
 */
export function useContrastContext(): ContrastContextValue {
  const context = useContext(ContrastContext);
  if (context === undefined) {
    throw new Error(
      'useContrastContext must be used within a ContrastProvider.'
    );
  }
  return context;
}

/**
 * A helper function to get the contrastVolume for a specific viewIndex (or undefined if there is none).
 */
export function getContrastVolumeForViewIndex(
  contrastViews: ContrastViewContent[],
  contrastVolumeMap: ContrastVolumeMap,
  viewIndex: number
): ContrastVolume | undefined {
  if (viewIndex >= 0 && viewIndex < contrastViews.length) {
    // Empty views have no associated volume.
    const viewContent: ContrastViewContent = contrastViews[viewIndex];
    if (
      viewContent.viewType !== ContrastViewType.Empty &&
      viewContent.study &&
      viewContent.seriesName
    ) {
      return contrastVolumeMap.get(
        getStudySeriesId(viewContent.study, viewContent.seriesName)
      );
    }
  }
  return undefined;
}

/**
 * Return the ContrastVolume that is currently associated with the specified viewIndex (0 to 3).
 */
export function useContrastVolumeSelector(
  viewIndex: number
): ContrastVolume | undefined {
  const { contrastViews, contrastVolumeMap } = useContrastContext();
  const contrastVolume: ContrastVolume | undefined = useMemo(() => {
    return getContrastVolumeForViewIndex(
      contrastViews,
      contrastVolumeMap,
      viewIndex
    );
  }, [viewIndex, contrastViews, contrastVolumeMap]);
  return contrastVolume;
}

/**
 * Return the ContrastViewType that is currently shown on specified viewIndex (0 to 3).
 */
export function useContrastViewTypeSelector(
  viewIndex: number
): ContrastViewType | undefined {
  const { contrastViews } = useContrastContext();
  const contrastViewType: ContrastViewType | undefined = useMemo(() => {
    if (viewIndex >= 0 && viewIndex < contrastViews.length) {
      return contrastViews[viewIndex].viewType;
    }
    return undefined;
  }, [viewIndex, contrastViews]);
  return contrastViewType;
}

/**
 * Return the CTVolumeOverlay state for the specified viewIndex (0 to 3).
 */
export function useContrastOverlaySelector(
  viewIndex: number
): CTVolumeOverlay | undefined {
  const { contrastOverlays } = useContrastContext();
  const contrastOverlay: CTVolumeOverlay | undefined = useMemo(() => {
    return contrastOverlays[viewIndex];
  }, [viewIndex, contrastOverlays]);
  return contrastOverlay;
}

/**
 * Get the array of ContrastViewProps used for each viewIndex [0 to 3].
 */
export function useContrastAllViewPropsSelector(): (
  | ContrastVolumeViewProps
  | undefined
)[] {
  const { contrastViews, contrastVolumeMap } = useContrastContext();
  const contrastViewProps: (
    | ContrastVolumeViewProps
    | undefined
  )[] = useMemo(() => {
    const newContrastViewProps: (ContrastVolumeViewProps | undefined)[] = [];
    for (let viewIndex = 0; viewIndex < 4; viewIndex++) {
      const contrastVolume = getContrastVolumeForViewIndex(
        contrastViews,
        contrastVolumeMap,
        viewIndex
      );
      newContrastViewProps.push(
        contrastVolume
          ? contrastVolume.viewProps[contrastViews[viewIndex].viewType]
          : undefined
      );
    }
    return newContrastViewProps;
  }, [contrastViews, contrastVolumeMap]);

  return contrastViewProps;
}
