import { XYCoords } from '../../reducers/vessel-data';
import { PointObject } from '../../types/common';
import * as PIXI from 'pixi.js-legacy';

export const MM_TO_PX = 0.26458333333333333;

export interface MeasurementGraphics extends PIXI.Graphics {
  measurementId: string;
  lineName: string | null;
  points: PointObject[];
  nodeIndex: number;
  startPoint: PointObject;
  endPoint: PointObject;
}

export enum HandlePosition {
  End = 'end',
  Start = 'start',
}

export enum RulerStates {
  New = 'new',
  Moving = 'moving',
  Active = 'active',
  Handle = 'handle',
  HandleEnd = 'endPoint',
  HandleStart = 'startPoint',
}

export enum EllipseStates {
  New = 'new',
  Moving = 'moving',
  Active = 'active',
  Handle = 'handle',
}

export enum ParentType {
  MPR = 'MPR',
  ShortAxis = 'ShortAxis',
  Contrast = 'Contrast',
  NonContrast = 'NonContrast',
}

export enum ToolTypes {
  Ruler = 'ruler',
  Ellipse = 'ellipse',
}

export interface CreateMarkerSpriteInterface {
  type: ToolTypes;
  sprite: MeasurementGraphics;
  label: string;
  position: PointObject;
  scale: number;
  state: string;
  markerPositionsRef?: markerPositionsInterface;
  isColliding?: boolean;
  huData?: HuData;
}

export interface CreateEllipseSpriteInterface {
  parent: ParentType;
  sprite: MeasurementGraphics | null;
  start?: PointObject;
  end?: PointObject;
  points?: PointObject[];
  lineName: string;
  state: string;
  scale: number;
  callback?: (event: React.MouseEvent) => void;
  hitAreaEllipseToolRef: any;
  huData?: HuData;
  nodeIndex?: number;
  pixelsPerMillimeter: number;
  cursor?: string;
}

export interface MouseMoveEllipseInterface {
  isDraggingMeasurementToolRef: RulerStates | EllipseStates | undefined;
  measurementSpriteRef: MeasurementGraphics | null;
  measurementTargetRef: any;
  start: XYCoords;
  mousePosition: XYCoords;
  scale: number;
  offsetX: number;
  offsetY: number;
  isOverMeasurementToolRef?: string | undefined;
  hitAreaEllipseToolRef: any;
  huData?: HuData;
  recalculatePixelsToMm: () => number;
}

export interface MouseUpEllipseInterface {
  isDraggingMeasurementToolRef: RulerStates | EllipseStates | undefined;
  measurementSpriteRef: MeasurementGraphics | null;
  measurementTargetRef: MeasurementGraphics | null;
  scale: number;
  callbackMouseDownEllipseNode: (event: React.MouseEvent) => void;
  hitAreaEllipseToolRef: any;
  huData?: HuData;
  pixelsPerMillimeter: number;
}

export interface CreateRulerSpriteInterface {
  parent: ParentType;
  sprite: MeasurementGraphics | null;
  start: PointObject | null;
  end: PointObject;
  lineName: string;
  state: string;
  scale: number;
  callback?: (event: React.MouseEvent) => void;
  pixelsPerMillimeter: number;
  cursor?: string;
}

export interface MouseMoveRulerInterface {
  isDraggingMeasurementToolRef: RulerStates | EllipseStates | undefined;
  measurementSpriteRef: MeasurementGraphics | null;
  measurementTargetRef: any;
  handleTargetNameRef: HandlePosition | null;
  start: PointObject;
  mousePosition: PointObject;
  scale: number;
  offsetX: number;
  offsetY: number;
  recalculatePixelsToMm: () => number;
}

export type markerPositionsInterface = {
  [measurementId: string]: {
    x: number;
    y: number;
    width: number;
    height: number;
    isColliding?: boolean | undefined;
    oldX?: number | undefined;
    oldY?: number | undefined;
  };
};

export interface MouseUpRulerInterface {
  isDraggingMeasurementToolRef: RulerStates | EllipseStates | undefined;
  measurementSpriteRef: MeasurementGraphics | null;
  measurementTargetRef: MeasurementGraphics | null;
  start: PointObject;
  end: PointObject;
  scale: number;
  callbackMouseDownStraightLine: (event: React.MouseEvent) => void;
  pixelsPerMillimeter: number;
}

export interface MouseDownRulerInterface {
  isClickDownMeasurementToolRef: boolean;
  isDraggingMeasurementToolRef: RulerStates | EllipseStates | undefined;
  measurementTargetRef: MeasurementGraphics | null;
  scale: number;
  btnClicked: number;
  pixelsPerMillimeter: number;
}

export interface MouseDownEllipseInterface {
  isClickDownMeasurementToolRef: boolean;
  isDraggingMeasurementToolRef: RulerStates | EllipseStates | undefined;
  measurementTargetRef: MeasurementGraphics | null;
  measurementSpriteRef: MeasurementGraphics | null;
  scale: number;
  btnClicked: number;
  pixelsPerMillimeter: number;
  onMouseDownEllipseNode: (event: React.MouseEvent) => void;
  hitAreaEllipseToolRef: any;
  huData?: HuData;
  msmToolsContainerRef: PIXI.Container;
  isOverMeasurementToolRef?: string | undefined;
}

export interface DrawDashedLineInterface {
  line: PIXI.Graphics;
  start: XYCoords;
  end: XYCoords;
  dashLength: number;
  lineWidth: number;
  alpha?: number;
}

export type HuData = {
  mean?: number;
  stdDev?: number;
};
