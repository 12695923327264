import { ContrastViewContent, ContrastViewType } from './contrast-types';

export const EMPTY_VIEW: ContrastViewContent = {
  study: undefined,
  seriesName: undefined,
  viewType: ContrastViewType.Empty,
};

export const EMPTY_VIEW_ARRAY: ContrastViewContent[] = [
  EMPTY_VIEW,
  EMPTY_VIEW,
  EMPTY_VIEW,
  EMPTY_VIEW,
];
