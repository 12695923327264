import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DEFAULT_GLOBAL_LOADER_STATE } from './constants';

export const globalLoaderSlice = createSlice({
  name: 'globalLoader',
  initialState: DEFAULT_GLOBAL_LOADER_STATE,
  reducers: {
    show: (state, action: PayloadAction<string | undefined>) => {
      state.show = true;
      state.message = action.payload;
    },
    hide: (state) => {
      state.show = false;
      state.message = undefined;
    },
  },
});

export const GlobalLoaderActions = globalLoaderSlice.actions;

export const globalLoaderReducer = globalLoaderSlice.reducer;
