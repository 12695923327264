import React from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import { ReactComponent as LoaderIcon } from '../../assets/icons/loader.svg';

interface LoaderProps {
  large?: boolean;
  small?: boolean;
  text?: string;
  fullScreen?: boolean;
  inline?: boolean;
  background?: boolean;
  allowPointerEvents?: boolean;
}
const LoaderElement: React.FC<LoaderProps> = ({
  large,
  small,
  text,
  fullScreen,
  inline,
  background,
  allowPointerEvents,
}) => {
  return (
    <div
      className={cn('loader', {
        'loader--large': large,
        'loader--small': small,
        'loader--full-screen': fullScreen,
        'loader--inline': inline,
        'loader--allowPointerEvents': allowPointerEvents,
      })}
    >
      {background && <div className="loader__background" />}
      <LoaderIcon className="loader__spinner" />
      {text && <div className="loader__text">{text}</div>}
    </div>
  );
};

export const Loader: React.FC<LoaderProps> = ({
  large,
  small,
  text,
  fullScreen,
  inline,
  background,
  allowPointerEvents,
}) => {
  return fullScreen ? (
    ReactDOM.createPortal(
      <LoaderElement
        large={large}
        small={small}
        text={text}
        fullScreen
        allowPointerEvents={allowPointerEvents}
      />,
      document.body
    )
  ) : (
    <LoaderElement
      large={large}
      small={small}
      text={text}
      inline={inline}
      background={background}
      allowPointerEvents={allowPointerEvents}
    />
  );
};
