import { MutableRefObject, useCallback } from 'react';
import showToast from '../../components/Toast/showToast';
import { useStoreContext } from '../../context/store-context';
import { LesionDataResponse } from '../../context/types';
import * as api from '../../utils/api';

function useRefreshAndSetReportData() {
  const { setCurrentReport, setFetchingReport } = useStoreContext();

  return useCallback(
    (patientId: string, runId: string, versionString?: string) => {
      setFetchingReport(true);
      return api
        .fetchReport(patientId, runId, versionString)
        .then((response) => {
          setCurrentReport(response);
        })
        .finally(() => setFetchingReport(false));
    },
    [setCurrentReport, setFetchingReport]
  );
}

function useRefreshLesionsCallback() {
  const { patientID, runID, setLesionData } = useStoreContext();

  const refreshAndSetReportData = useRefreshAndSetReportData();

  return useCallback(
    async (versionString: string) => {
      try {
        if (patientID && runID) {
          const lesions: LesionDataResponse = await api.getJSON(
            `/data/${patientID}/${runID}/web-data/lesions?version=${versionString}`
          );
          setLesionData(lesions, patientID, runID);

          // Refresh report data when lesions have updated
          refreshAndSetReportData(patientID, runID, versionString).catch(
            (error) => {
              console.error(error);

              // try again if there is an error, but only once
              refreshAndSetReportData(patientID, runID, versionString).catch(
                (error) => {
                  console.error(
                    'Failed a retry on fetching the report.',
                    error
                  );
                  // It's highly unlikely that we'd ever get to this point
                  showToast.warning(
                    'Lesions have successfully been updated. The Report Overview may not reflect the new calcium score. Please refresh your browser.'
                  );
                }
              );
            }
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    [patientID, runID, setLesionData, refreshAndSetReportData]
  );
}

export function useChangeCalciumCategoryCallback(
  selectedLesions: any[],
  setUpdatedLesions: (lesions: []) => void,
  deselectLesionsRef: MutableRefObject<any | null>,
  hideAll: () => void,
  setChosenVessel: (vesel: any | null) => void
) {
  const {
    patientID,
    runID,
    setCalciumScoreData,
    updateVersionHead,
    updateBackendVersionHead,
    versionHead,
  } = useStoreContext();

  const refreshLesions = useRefreshLesionsCallback();

  return useCallback(
    async (category: any) => {
      // below structure important such that edit lock is released
      // regardless of promise result
      const innerPromise = new Promise<void>(async (resolve, reject) => {
        const errorMsg = 'Failed to update lesion categories';
        const updated: any = {};
        selectedLesions.forEach((lesion) => {
          if (lesion.category.toLowerCase() !== category.new.toLowerCase()) {
            updated[lesion.lesion_id] = {
              lesion_id: lesion.lesion_id,
              category: category.new.toLowerCase(),
            };
          }
        });
        const updatedArray: any = [];
        Object.keys(updated).forEach((key) => {
          updatedArray.push(updated[key]);
        });
        const payload: any = {
          study_id: patientID,
          run_id: runID,
          payload: {
            lesions: updatedArray,
            version_id: versionHead,
          },
        };

        if (!updatedArray.length) {
          return resolve();
        }
        try {
          const socket = await api.getWebsocket('/ws/tasks/edit-lesions');
          socket.addEventListener('open', (_event) => {
            socket.send(JSON.stringify(payload));
          });
          socket.addEventListener('error', (error) => {
            console.error(error);
            socket.close();
            reject(errorMsg);
          });
          socket.addEventListener('message', (event) => {
            const eventObj = JSON.parse(event.data);
            if (eventObj.type === 'error') {
              socket.close();
              reject(eventObj.data.message);
            }
            switch (eventObj.type) {
              // TODO currently blocking until save occurs however
              // it is possible to unblock on result if the can_save
              // attribute is set (this has not been implemented due)
              // to an ongoing issue with edits being blocked incorrectly
              case 'connected':
                break;
              case 'result':
                setCalciumScoreData(eventObj.data.result);
                break;
              case 'result_save':
                const newVersionString = eventObj.data.version_id;
                updateVersionHead(newVersionString);
                updateBackendVersionHead(newVersionString).then(() => {
                  refreshLesions(newVersionString);
                  setUpdatedLesions(updatedArray);
                  socket.close();
                  resolve();
                });
                break;
              default:
                socket.close();
                console.error(
                  `received unknown message type: ${eventObj.type}`
                );
                reject(errorMsg);
            }
          });
        } catch (err) {
          reject(errorMsg);
        }
      });
      try {
        await innerPromise;
      } catch (errMsg) {
        showToast.error(errMsg);
      } finally {
        hideAll();
        setChosenVessel(null);
        deselectLesionsRef.current && deselectLesionsRef.current();
      }
    },
    [
      selectedLesions,
      patientID,
      runID,
      versionHead,
      setCalciumScoreData,
      updateBackendVersionHead,
      updateVersionHead,
      refreshLesions,
      hideAll,
      deselectLesionsRef,
      setChosenVessel,
      setUpdatedLesions,
    ]
  );
}
