import React, { useState, useCallback, useEffect } from 'react';

const MODIFIERKEYS = {
  control: 'ctrl',
  shift: 'shift',
  alt: 'alt',
  meta: 'meta',
};

function SliderSetting({
  setValue,
  value,
  minValue,
  maxValue,
  name,
  disabled,
}) {
  const [val, setVal] = useState(value);
  const changeHandler = useCallback(
    (e) => {
      setVal(e.target.value);
      setValue(e.target.value);
    },
    [setValue]
  );
  useEffect(() => {
    setVal(value);
  }, [value]);
  return (
    <tr>
      <td className="col1">{name}</td>
      <td className="col2">{val}</td>
      <td className="col3">
        <input
          type="range"
          disabled={disabled}
          min={minValue}
          max={maxValue}
          value={val}
          onChange={changeHandler}
        />
      </td>
    </tr>
  );
}

function CheckboxSetting({
  setValue,
  value,
  name,
  disabled,
  enableKeys,
  disableKeys,
  addKey,
  delKey,
}) {
  const [val, setVal] = useState(value);
  const changeHandler = useCallback(
    (e) => {
      setVal(!val);
      setValue(!val);
    },
    [setValue, val]
  );
  useEffect(() => {
    if (val !== value) setVal(value);
  }, [val, value]);
  useEffect(() => {
    if (enableKeys) {
      for (let i = 0; i < enableKeys.length; i++) {
        if (val) delKey(enableKeys[i]);
        else addKey(enableKeys[i]);
      }
    }
    if (disableKeys) {
      for (let i = 0; i < disableKeys.length; i++) {
        if (val) addKey(disableKeys[i]);
        else delKey(disableKeys[i]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);
  return (
    <tr>
      <td className="col1">{name}</td>
      <td className="col2"></td>
      <td className="col3" style={{ position: 'absolute', right: '10px' }}>
        <input
          type="checkbox"
          disabled={disabled}
          checked={val}
          onChange={changeHandler}
        />
      </td>
    </tr>
  );
}

function KeyBindSetting({ setValue, value, name, disabled }) {
  const [isActive, setIsActive] = useState(false);
  const [val, setVal] = useState(value);
  const [metaDown, setMetaDown] = useState(false);
  const [altDown, setAltDown] = useState(false);
  const [shiftDown, setShiftDown] = useState(false);
  const [ctrlDown, setCtrlDown] = useState(false);
  // creates new array every update
  const [keysDown, setKeysDown] = useState([]);

  useEffect(() => {
    setVal(value);
  }, [value]);

  // TODO add lock below
  const canSet = useCallback(() => {
    setIsActive(true);
    setMetaDown(false);
    setAltDown(false);
    setShiftDown(false);
    setCtrlDown(false);
    setKeysDown([]);
  }, []);

  //thoughts on adding escape as a way to stop the binding and reset to buff
  const keydownHandler = useCallback(
    (e) => {
      e.preventDefault();
      switch (e.key) {
        case 'Alt':
          setAltDown(true);
          break;
        case 'Meta':
          setMetaDown(true);
          break;
        case 'Shift':
          setShiftDown(true);
          break;
        case 'Control':
          setCtrlDown(true);
          break;
        default:
          let keyStr = e.key;
          if (e.code.startsWith('Key')) keyStr = e.code.slice(3);
          const newKeysDown = [...new Set([...keysDown, keyStr.toLowerCase()])];
          newKeysDown.sort((a, b) => a - b);
          setKeysDown(newKeysDown);
          break;
      }
    },
    [keysDown]
  );

  // TODO very unsure how to fix this if there is a way
  // known bug where Meta + key does not fire the keyup event on MacOs
  // hence the actions Meta down -> z down -> z up -> u down -> Meta up should set the binding
  // to Meta + z but instead sets it to Meta + z + u
  const keyupHandler = useCallback(
    (e) => {
      e.preventDefault();
      if (keysDown.length) {
        setValue(val);
        setIsActive(false);
        return;
      }
      switch (e.key) {
        case 'Alt':
          setAltDown(false);
          break;
        case 'Meta':
          setMetaDown(false);
          break;
        case 'Shift':
          setShiftDown(false);
          break;
        case 'Control':
          setCtrlDown(false);
          break;
        default:
          break;
      }
    },
    [keysDown.length, setValue, val]
  );

  useEffect(() => {
    //TODO add ordering depending on OS here
    if (!isActive) return;
    let vals = [];
    if (ctrlDown) vals.push('ctrl');
    if (altDown) vals.push('alt');
    if (metaDown) vals.push('meta');
    if (shiftDown) vals.push('shift');
    vals = vals.concat(keysDown);
    setVal(vals.join('+'));
  }, [isActive, altDown, shiftDown, metaDown, ctrlDown, keysDown]);

  return (
    <>
      <tr>
        <td className="col1">{name}</td>
        <td className="col2">{val}</td>
        <td className="col3">
          <button type="button" disabled={disabled} onClick={canSet}>
            Change
          </button>
        </td>
      </tr>
      {isActive && (
        <KeyListener onKeyDown={keydownHandler} onKeyUp={keyupHandler} />
      )}
    </>
  );
}

function DragBindSetting({ setValue, value, name, disabled }) {
  const [isActive, setIsActive] = useState(false);
  const [val, setVal] = useState(value);
  const [metaDown, setMetaDown] = useState(false);
  const [altDown, setAltDown] = useState(false);
  const [shiftDown, setShiftDown] = useState(false);
  const [ctrlDown, setCtrlDown] = useState(false);
  //TODO add ability to bind more than 3 mouse buttons
  const [mbDownSet, setMbDownSet] = useState(new Set());
  const [updateVal, setUpdateVal] = useState(false);
  const forceUpdateVal = useCallback(() => setUpdateVal(!updateVal), [
    updateVal,
  ]);

  useEffect(() => {
    setVal(value);
  }, [value]);

  //TODO add lock below
  const canSet = useCallback(() => {
    setIsActive(true);
    setMetaDown(false);
    setAltDown(false);
    setShiftDown(false);
    setCtrlDown(false);
    setMbDownSet(new Set());
  }, []);

  const keydownHandler = useCallback((e) => {
    e.preventDefault();
    if (e.key === 'Alt') setAltDown(true);
    if (e.key === 'Meta') setMetaDown(true);
    if (e.key === 'Shift') setShiftDown(true);
    if (e.key === 'Control') setCtrlDown(true);
  }, []);

  const keyupHandler = useCallback((e) => {
    e.preventDefault();
    if (e.key === 'Alt') setAltDown(false);
    if (e.key === 'Meta') setMetaDown(false);
    if (e.key === 'Shift') setShiftDown(false);
    if (e.key === 'Control') setCtrlDown(false);
  }, []);

  const mousedownHandler = useCallback(
    (e) => {
      console.log(e.button);
      mbDownSet.add(e.button);
      forceUpdateVal();
      e.preventDefault();
    },
    [mbDownSet, forceUpdateVal]
  );

  const mouseupHandler = useCallback(
    (e) => {
      setValue(val);
      setIsActive(false);
      e.preventDefault();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [val]
  );

  useEffect(() => {
    if (!isActive) return;
    const vals = [];
    if (ctrlDown) vals.push('ctrl');
    if (altDown) vals.push('alt');
    if (metaDown) vals.push('meta');
    if (shiftDown) vals.push('shift');
    const mbs = Array.from(mbDownSet);
    mbs.sort((a, b) => a - b);
    for (let i = 0; i < mbs.length; i++) vals.push('mb' + mbs[i].toString());
    setVal(vals.join('+'));
  }, [isActive, altDown, shiftDown, metaDown, ctrlDown, mbDownSet, updateVal]);

  return (
    <>
      <tr>
        <td className="col1">{name}</td>
        <td className="col2">{val}</td>
        <td className="col3">
          <button type="button" disabled={disabled} onClick={canSet}>
            Change
          </button>
        </td>
      </tr>
      {isActive && (
        <>
          <KeyListener onKeyDown={keydownHandler} onKeyUp={keyupHandler} />
          <MouseListener
            noContext
            onMouseDown={mousedownHandler}
            onMouseUp={mouseupHandler}
          />
        </>
      )}
    </>
  );
}

function KeyListener({ onKeyDown, onKeyUp, onKeyPress }) {
  useEffect(() => {
    if (onKeyDown) window.addEventListener('keydown', onKeyDown);
    if (onKeyUp) window.addEventListener('keyup', onKeyUp);
    if (onKeyPress) window.addEventListener('keypress', onKeyPress);
    return () => {
      if (onKeyDown) window.removeEventListener('keydown', onKeyDown);
      if (onKeyUp) window.removeEventListener('keyup', onKeyUp);
      if (onKeyPress) window.removeEventListener('keypress', onKeyPress);
    };
  }, [onKeyPress, onKeyUp, onKeyDown]);
  return <></>;
}

function MouseListener({ noContext, onMouseDown, onMouseUp }) {
  useEffect(() => {
    const disableContextMenu = (e) => e.preventDefault();
    if (noContext) window.addEventListener('contextmenu', disableContextMenu);
    if (onMouseDown) window.addEventListener('mouseup', onMouseUp);
    if (onMouseUp) window.addEventListener('mousedown', onMouseDown);
    return () => {
      if (noContext)
        window.removeEventListener('contextmenu', disableContextMenu);
      if (onMouseDown) window.removeEventListener('mouseup', onMouseUp);
      if (onMouseUp) window.removeEventListener('mousedown', onMouseDown);
    };
  }, [noContext, onMouseDown, onMouseUp]);
  return <></>;
}

export {
  SliderSetting,
  CheckboxSetting,
  KeyBindSetting,
  DragBindSetting,
  MODIFIERKEYS,
};
