import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../utils/api';
import * as phi from '../../utils/phi';

export const fetchStudyById = createAsyncThunk(
  'study/fetchStudyById',
  async (studyId: string, thunkAPI) => {
    const study = await api.getJSON(`/dashboard/${studyId}/info`);
    const decrypted = await phi.decryptDashboardItem(study);

    // Set the default AI assessed values if none were returned by the backend.
    if (!decrypted.ai_assessed) {
      decrypted.ai_assessed = {
        contrast_id: 'contrast',
        noncontrast_id: 'noncontrast',
      };
    }

    return decrypted;
  }
);
