import cn from 'classnames';
import Moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { DATE_FORMAT, DATE_FORMAT_DOB } from '../../config';

interface PatientDetailsProps {
  id?: string;
  name?: string;
  birthDate?: string;
  doctor?: string;
  age?: string | number;
  editMode: boolean;
}

export const PatientDetails: React.FC<PatientDetailsProps> = ({
  id: patientID,
  name: patientName,
  birthDate: patientDOB,
  doctor: patientDoctor,
  age,
  editMode,
}) => {
  const dobMoment = useMemo(() => Moment(patientDOB, DATE_FORMAT_DOB), [
    patientDOB,
  ]);

  const birthDate = useMemo(
    () =>
      Moment(dobMoment).isValid() ? dobMoment.format(DATE_FORMAT) : undefined,
    [dobMoment]
  );

  const doctor = useMemo(
    () => (patientDoctor || editMode ? patientDoctor : '-'),
    [patientDoctor, editMode]
  );

  const renderDropDownRow = useCallback(
    (title: string, info: string | undefined) => {
      return (
        <div className="patient-details__data-row">
          {info && (
            <div
              className={cn('patient-details__data-cell', {
                'patient-details__data-cell-edit-mode': editMode,
              })}
            >
              <dt className="visually-hidden">{title}</dt>
              <dd className="patient-details__data-name">{info}</dd>
            </div>
          )}
        </div>
      );
    },
    [editMode]
  );

  const renderNonEditableRow = useCallback(
    (title: string, value: string | undefined) => {
      return (
        <div className="patient-details__data-row">
          <div className="patient-details__data-cell">
            <dt>{title}</dt>
          </div>
          <div className="patient-details__data-cell">
            <dd className="patient-details__data-value">{value}</dd>
          </div>
        </div>
      );
    },
    []
  );

  return (
    <div className={'patient-details'}>
      <dl className="patient-details__data">
        {renderDropDownRow('Patient Name', patientName)}
        {renderNonEditableRow('Patient ID', patientID)}
        {age && (
          <div className="patient-details__data-row">
            <div
              className={cn('patient-details__data-cell', {
                'patient-details__data-cell-edit-mode': editMode,
              })}
            >
              <dt>Age</dt>
            </div>
            <div
              className={cn('patient-details__data-cell', {
                'patient-details__data-cell-edit-mode': editMode,
              })}
            >
              <dd className="patient-details__data-value">{age}</dd>
            </div>
          </div>
        )}
        {birthDate && renderNonEditableRow('Date of birth', birthDate)}
        {renderNonEditableRow('Referring Doctor and/or GP', doctor)}
      </dl>
    </div>
  );
};
