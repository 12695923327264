import { useCallback } from 'react';
import { showToast } from '../components/Toast/showToast';
import { useStoreContext } from '../context/store-context';
import { useReportContext } from '../context/report-context';
import * as api from '../utils/api';

/**
 * Returns a function to make an API call to delete a screenshot, setting a flag in the ReportContext to indicate which
 * screenshot is being deleted. Shows a toast if the API call fails.
 */
export function useDeleteScreenshot() {
  const { patientID, runID } = useStoreContext();

  const { setScreenshots, setDeletingScreenshot } = useReportContext();

  return useCallback(
    (filename: string) => {
      if (patientID && runID) {
        setDeletingScreenshot(filename);
        api
          .deleteScreenShot(filename, patientID, runID)
          .then((response) => setScreenshots(response))
          .catch((err) => {
            showToast.error('Failed to remove screenshot');
          })
          .finally(() => setDeletingScreenshot(undefined));
      }
    },
    [patientID, runID, setScreenshots, setDeletingScreenshot]
  );
}
