import { InteractionOperations } from './customBase';
import { vtkEvent, vtkModel } from '../../ReactVTKJSTypes';

/**
 * Start the zoom operation.
 */
function onStart(publicAPI: any, model: vtkModel, callData: vtkEvent) {
  model.interactionOperation = InteractionOperations.ZOOM;

  // From vtkMouseCameraTrackballZoomManipulator
  const interactor = model.interactor;
  const renderer = interactor.getCurrentRenderer();
  const camera = renderer.getActiveCamera();

  model.previousPosition = callData.position;
  const size = interactor.getView().getSize();

  const direction = model.flipDirection ? 1 : -1;
  if (camera.getParallelProjection()) {
    model.zoomScale = (1.5 / size[1]) * direction;
  } else {
    const range = camera.getClippingRange();
    model.zoomScale = 1.5 * (range[1] / size[1]) * direction;
  }
}

/**
 * Adjust the zoom based on the mouse movement.
 */
function onMove(publicAPI: any, model: vtkModel, callData: vtkEvent) {
  const position = callData.position;
  const interactor = model.interactor;
  const renderer = interactor.getCurrentRenderer();
  const camera = renderer.getActiveCamera();

  // From vtkMouseCameraTrackballZoomManipulator
  if (!position) {
    return;
  }

  const inputDeltaY = model.previousPosition.y - position.y;
  const inputChangeDistance = inputDeltaY * model.zoomScale;
  if (camera.getParallelProjection()) {
    const vdkCurrentZoom = camera.getParallelScale();
    const zoomChange =
      vdkCurrentZoom / (vdkCurrentZoom * (1.0 - inputChangeDistance));
    camera.setParallelScale(vdkCurrentZoom * zoomChange);
    if (model.containerPIXI) {
      // Pixi's scale is the reverse of vdk's. IE when zooming in; vdk's scale will get bigger
      // while Pixi's will get smaller. Thus we divide here rather than multiply.
      const pixiCurrentZoom = model.containerPIXI.scale.y;
      model.containerPIXI.scale.y = pixiCurrentZoom / zoomChange;
      model.containerPIXI.scale.x = pixiCurrentZoom / zoomChange;
    }
  } else {
    const cameraPosition = camera.getPosition();
    const cameraFocalPoint = camera.getFocalPoint();
    const cameraDirection = camera.getDirectionOfProjection();

    let changeDistance = inputChangeDistance * cameraDirection[0];
    cameraPosition[0] += changeDistance;
    cameraFocalPoint[0] += changeDistance;

    changeDistance = inputChangeDistance * cameraDirection[1];
    cameraPosition[1] += changeDistance;
    cameraFocalPoint[1] += changeDistance;

    changeDistance = inputChangeDistance * cameraDirection[2];
    cameraPosition[2] += changeDistance;
    cameraFocalPoint[2] += changeDistance;

    if (!camera.getFreezeFocalPoint()) {
      camera.setFocalPoint(
        cameraFocalPoint[0],
        cameraFocalPoint[1],
        cameraFocalPoint[2]
      );
    }

    camera.setPosition(cameraPosition[0], cameraPosition[1], cameraPosition[2]);
    renderer.resetCameraClippingRange();
  }

  if (interactor.getLightFollowCamera()) {
    renderer.updateLightsGeometryToFollowCamera();
  }

  model.previousPosition = position;

  // Refresh the view and crosshairs.
  const { apis, apiIndex } = model;
  const api = apis[apiIndex];
  api.refreshView(true, true);
  // Update the pixel to millimetre mappings.
  api.updateMmPerPixel();
}

/**
 * Initialize any additional publicAPI functions we may need.
 */
function initialize(publicAPI: any, model: vtkModel) {}

const DEFAULT_VALUES = {
  zoomScale: 0.0,
  flipDirection: true, // True for up = zoom in, down = zoom out, false for the opposite.
};

const defaults = {
  onStart,
  onMove,
  initialize,
  DEFAULT_VALUES,
};
export default defaults;
