import React from 'react';
import { MODEL_FULLSCREEN_DEMO } from '../../../config';

// This component takes a provided colourmap file, and creates a legend of
// continous color and plots numbers on said legend, which is of a given size
export const Legend: React.FC = () => {
  return (
    <div
      className={MODEL_FULLSCREEN_DEMO ? 'legend__fullscreen_demo' : 'legend'}
    >
      <div className="legend__title">DISEASE SEVERITY</div>
      <div className="legend__scale">
        <div className="legend__scale-level"></div>
        <div className="legend__scale-level"></div>
        <div className="legend__scale-level"></div>
        <div className="legend__scale-level"></div>
        <span className="legend__scale-value legend__scale-value--top">
          100%
        </span>
        <span className="legend__scale-value legend__scale-value--bottom">
          0%
        </span>
      </div>
    </div>
  );
};
