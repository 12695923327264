import cn from 'classnames';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import CoronaryFindingsCard from '../../components/CoronaryFindingsCard';
import ImpressionsCard from '../../components/ImpressionsCard';
import { Loader } from '../../components/Loader/Loader';
import PageTitle from '../../components/PageTitle/PageTitle';
import { NAV_TABS } from '../../config';
import { useStoreContext } from '../../context/store-context';
import usePatientData from '../../hooks/use-patient-data';
import { useReportData } from '../../hooks/use-report-data';
import useStudyData from '../../hooks/use-study-data';
import useStudyTabVisibility from '../../hooks/use-study-tab-visibility';
import {
  useCalciumScoreUpdate,
  useVesselDataLoader,
  useImageLoader,
} from '../../reducers/vessel-data/side-effects';
import {
  useCoronaryFindingIsAiAssessedSelector,
  useCoronaryFindingsSelector,
  useImpressionIsAiAssessedSelector,
} from '../../selectors/reporting';
import { SeriesStatus, useStudySeriesInfo } from '../../selectors/study';
import { useVesselStateSelector } from '../../selectors/vessels';
import { RouteParams } from '../../types';
import { getMajorVesselGrouping } from '../../utils/vessel';
import Model from '../../views/Model/Model';
import PatientSummary from '../../views/PatientSummary/PatientSummary';
import VesselViewer from '../../views/VesselViewer/VesselViewer';

const Patient: React.FC = (): ReactElement | null => {
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  usePatientData();
  useStudyData(id);
  useReportData(id);
  useCalciumScoreUpdate();
  useVesselDataLoader();
  useImageLoader();

  const {
    initialDataLoaded,
    setVisibleTab,
    studyData,
    fetchingReport,
    fetchingOverallMeasurements,
  } = useStoreContext();

  const { vesselData, selectedVesselName } = useVesselStateSelector();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const { getStyles } = useStudyTabVisibility(NAV_TABS.patientTab);

  const selectedVesselGroup = useMemo(
    () => getMajorVesselGrouping(selectedVesselName),
    [selectedVesselName]
  );

  const studySeriesInfo = useStudySeriesInfo();
  const hidePatientOverview = useMemo(() => {
    return (
      studySeriesInfo?.contrast === SeriesStatus.Fail ||
      studySeriesInfo?.contrast === SeriesStatus.NotAvailable
    );
  }, [studySeriesInfo]);

  useEffect(() => {
    if (!id) return history.push('/');
    if (!initialDataLoaded || !isFirstLoad) return;
    setIsFirstLoad(false);
  }, [history, id, initialDataLoaded, isFirstLoad]);

  // Start on the CT Volume tab if analysis failed
  useEffect(() => {
    if (hidePatientOverview) {
      setVisibleTab(1);
    } else if (!initialDataLoaded) {
      setVisibleTab(0);
    }
  }, [setVisibleTab, initialDataLoaded, hidePatientOverview]);

  const impressionIsAiAssessed = useImpressionIsAiAssessedSelector();
  const coronaryFindingsAiAssessed = useCoronaryFindingIsAiAssessedSelector();
  const coronaryFindings = useCoronaryFindingsSelector();

  if (hidePatientOverview) {
    return null;
  }

  return (
    <>
      <PageTitle title={id} />
      <div style={getStyles()} className={cn('patient')}>
        {!initialDataLoaded ? (
          <Loader
            text={isFirstLoad ? 'Loading study data' : 'Reloading study data'}
            large
            fullScreen
          />
        ) : (
          <div className="patient__container">
            <div className="patient__column patient__column--left">
              <PatientSummary
                loading={fetchingReport && fetchingOverallMeasurements}
              />
              <ImpressionsCard
                aiAssessed={impressionIsAiAssessed}
                loading={fetchingReport}
              />
              {selectedVesselGroup && (
                <CoronaryFindingsCard
                  selectedVesselGroup={selectedVesselGroup}
                  headerType="non-detailed"
                  coronaryFindings={coronaryFindings}
                  aiAssessed={coronaryFindingsAiAssessed[selectedVesselGroup]}
                  loading={fetchingReport}
                />
              )}
            </div>
            <div className="patient__column patient__column--full-height">
              <Model />
            </div>
            {
              <div className="patient__column patient__column--full-height">
                {studyData && vesselData && <VesselViewer />}
              </div>
            }
          </div>
        )}
      </div>
    </>
  );
};

export default Patient;
