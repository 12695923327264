import * as THREE from 'three';
import {
  MODEL_LM_CAMERA,
  MODEL_LAD_CAMERA,
  MODEL_LCX_CAMERA,
  MODEL_RCA_CAMERA,
  MODEL_LM_GROUP,
  MODEL_LAD_GROUP,
  MODEL_LCX_GROUP,
  MODEL_RCA_GROUP,
} from '../../../config';

// Creates a map between a provided 1D range and corresponding colours at
// points in that range (used to colour different types of risk differently)
export function linear1dColorMap(colorProfile, value) {
  for (var i = 0; i < colorProfile.length - 1; i++) {
    // This is the colour range that the provided colour could be in
    let start = colorProfile[i];
    let end = colorProfile[i + 1];

    // Is it in this range? If so provide interpolated colour
    if (start.value <= value && value < end.value) {
      return new THREE.Color(start.color);
    } else if (value === end.value) {
      return new THREE.Color(end.color);
    }
  }
  console.log(
    'Warning, provided value ',
    value,
    ' outside of provided color map value range, returning closest'
  );
  if (value <= colorProfile[0].value) {
    return new THREE.Color(colorProfile[0].color);
  } else if (value >= colorProfile[colorProfile.length - 1].value) {
    return new THREE.Color(colorProfile[colorProfile.length - 1].color);
  }
  console.log(
    'Error, provided value ',
    value,
    ' not compatible, returning null'
  );
  return null;
}

// Returns the default camera position for the vessel
export function getCameraPosition(vessel) {
  if (MODEL_LM_GROUP.includes(vessel)) return MODEL_LM_CAMERA;
  if (MODEL_LAD_GROUP.includes(vessel)) return MODEL_LAD_CAMERA;
  if (MODEL_LCX_GROUP.includes(vessel)) return MODEL_LCX_CAMERA;
  if (MODEL_RCA_GROUP.includes(vessel)) return MODEL_RCA_CAMERA;
  return MODEL_LM_CAMERA; // LM will be the default camera in case the vessel is not included in any group.
}
