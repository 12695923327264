import produce from 'immer';
import { getType } from 'typesafe-actions';
import { DragAndDropAction, DragAndDropActions } from './actions';
import { DEFAULT_DRAG_AND_DROP_STATE } from './constants';
import {
  handleGroupDrag,
  handleSingleItemDrag,
  onSnapBackDraggableItem,
  onSnapBackGroupItem,
  sortSeries,
} from './helpers';
import { DragAndDropState, DraggableGroup } from './types';

export function dragAndDropReducer(
  state: DragAndDropState = DEFAULT_DRAG_AND_DROP_STATE,
  action: DragAndDropAction
): DragAndDropState {
  return produce(state, (draftState) => {
    switch (action.type) {
      case getType(DragAndDropActions.createDraggableGroup): {
        const draggableGroup: { [key: string]: DraggableGroup } = {};

        const sorted = sortSeries(
          action.payload.draggableGroups,
          action.payload.ai_assessed
        );

        sorted.forEach((draggable) => {
          draggableGroup[draggable.parent.id] = draggable;
        });

        draftState.draggableGroup = draggableGroup;
        break;
      }

      case getType(DragAndDropActions.dragging): {
        const selected = action.payload;
        if (selected.itemType === 'parent') {
          const { id } = selected;

          draftState.draggableGroup = handleGroupDrag(id, draftState);
        } else {
          const { id, childIndex } = selected;
          draftState.draggableGroup = handleSingleItemDrag(
            id,
            childIndex,
            draftState
          );
        }

        break;
      }

      case getType(DragAndDropActions.onSnapBack): {
        if (action.payload.itemType === 'parent') {
          const { id } = action.payload;

          draftState.draggableGroup = onSnapBackGroupItem(id, draftState);
        } else {
          const { id, childIndex } = action.payload;

          draftState.draggableGroup = onSnapBackDraggableItem(
            id,
            childIndex,
            draftState
          );
        }

        break;
      }

      default:
        break;
    }
  });
}
