import { useQuery } from 'react-query';
import showToast from '../components/Toast/showToast';
import { FFR_DATA_REFETCH_INTERVAL } from '../config';
import { useStoreContext } from '../context/store-context';
import { FFRVesselDataResponse } from '../context/types';
import { useUserContext } from '../context/user-context';
import { useVesselStateSelector } from '../selectors/vessels';
import * as api from '../utils/api';

async function getVesselFfrData(
  studyId: string,
  runID?: string,
  vesselName?: string
) {
  return await api.getJSON(
    `/ffr/${studyId}/${runID}/workflow/${vesselName}/data`
  );
}

export default function useFfrData(studyId: string, enabled: boolean) {
  const { runID } = useStoreContext();
  const { clientConfig } = useUserContext();

  const { selectedVesselName } = useVesselStateSelector();

  const refetchInterval = FFR_DATA_REFETCH_INTERVAL;

  return useQuery<FFRVesselDataResponse>(
    ['vessel-ffr', selectedVesselName],
    () => getVesselFfrData(studyId, runID, selectedVesselName),
    {
      enabled: enabled && clientConfig?.ffr_enabled,
      refetchInterval: enabled ? refetchInterval : false,
      retry: false,
      onError: (error: any) => {
        if (error?.hasOwnProperty('silent')) return;
        console.error('useFfrData error', error);
        showToast.error(
          `Error fetching FFR data for ${selectedVesselName?.toUpperCase()}`
        );
      },
    }
  );
}
