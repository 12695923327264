import React, { useState, useImperativeHandle } from 'react';
import { ReactComponent as VisibilityOnIcon } from '../../assets/icons/visibility-on.svg';
import { ReactComponent as VisibilityOffIcon } from '../../assets/icons/visibility-off.svg';
import AnnotationModal from '../../components/AnnotationModal/AnnotationModal';
import { WindowLevels } from '../../context/window-types';

interface ToolBarProps {
  vessel?: string;
  slice?: string;
  lesionId?: string;
  viewName?: string;
  screenshotRef?: any;
  HURef?: { current: { setValue: any } };
  windowLevels?: WindowLevels;
  windowLabel?: string | null;
  showWindowLevels?: boolean;
  visibility?: boolean;
  showVisibilityIcon: boolean;
  onVisibilityChange?: () => void;
}

export const ToolBar: React.FC<ToolBarProps> = ({
  vessel = '',
  slice = '',
  lesionId,
  viewName,
  HURef,
  windowLevels,
  windowLabel,
  screenshotRef,
  showWindowLevels,
  visibility = true,
  onVisibilityChange,
  showVisibilityIcon = true,
}) => {
  // Use imperative handle to prevent having to constantly update state in parent
  const [HUValue, setHUValue] = useState(null);
  useImperativeHandle(HURef, () => ({ setValue: setHUValue }));

  return (
    <div className="tool-bar">
      <div className="tool-bar__left">
        {showVisibilityIcon && (
          <button
            className="tool-bar__visibility-icon"
            type="button"
            onClick={onVisibilityChange}
            title="Toggle annotations"
          >
            {visibility ? <VisibilityOnIcon /> : <VisibilityOffIcon />}
          </button>
        )}
        {screenshotRef && viewName && (
          <AnnotationModal
            screenshotRef={screenshotRef}
            vesselName={vessel}
            viewName={viewName}
            altIcon={true}
            hideText
          />
        )}
      </div>
      <div className="tool-bar__middle">{lesionId && lesionId}</div>
      <div className="tool-bar__right">
        {showWindowLevels && windowLevels && (
          <div className="window-levels">
            <div className="window-levels__ww-wl">
              WW/WL{' '}
              {`${windowLevels.windowWidth?.toFixed(
                0
              )}/${windowLevels.windowCenter?.toFixed(0)}`}
            </div>
            {windowLabel && <div>({windowLabel})</div>}
          </div>
        )}
        <div className="hu-value">
          {
            HUValue ? `HU ${HUValue}` : `${vessel} #${parseInt(slice) + 1}` // Adjust slice from 0-indexed to 1-indexed for UI
          }
        </div>
      </div>
    </div>
  );
};
