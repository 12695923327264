import cn from 'classnames';
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Vec2 } from 'three';
import useMousePositionRef from '../../hooks/use-mouse-position-ref';
import { Colors } from '../../styles/styles';
import { Loader } from '../Loader/Loader';
import { CalciumContextSubMenu } from './CalciumContextSubMenu';
import { getVesselColor } from './helpers';

interface CalciumContextMenuProps {
  onChange: (value: { new: string; old: string }) => void;
  vessels: Readonly<string[]>;
  chosenVessel: string | null;
  setChosenVessel: (state: SetStateAction<string | null>) => void;
  selectedVessel: string;
  menuPosition: Vec2;
  showCalciumContextSubMenu: boolean;
  setShowCalciumContextSubMenu: (show: React.SetStateAction<boolean>) => void;
}

const MENU_HIGH_TOLERANCE = 400;
const MENU_BLOCKER_BUFFER = 5;

export const CalciumContextMenu: React.FC<CalciumContextMenuProps> = ({
  onChange,
  vessels: vesselsOnly,
  setChosenVessel,
  chosenVessel,
  selectedVessel,
  menuPosition: position,
  showCalciumContextSubMenu,
  setShowCalciumContextSubMenu,
}) => {
  // Add other as an option to the vessels
  const vessels = useMemo(
    () => [...vesselsOnly.map((v) => v.toUpperCase()), 'Other'],
    [vesselsOnly]
  );

  const mousePositionRef = useMousePositionRef();
  const [mousePosHigh, setMousePosHigh] = useState(true);

  const toggleSubMenu = useCallback(
    (e: React.MouseEvent) => {
      const { x, y } = mousePositionRef.current;
      e.stopPropagation();
      if (x !== null && y !== null) {
        setMousePosHigh(y > window.innerHeight - MENU_HIGH_TOLERANCE);
      }
      setShowCalciumContextSubMenu((prevShow) => !prevShow);
    },
    [mousePositionRef, setShowCalciumContextSubMenu]
  );

  const changeVessel = (e: React.MouseEvent, v: string) => {
    e.stopPropagation();
    setChosenVessel(v);
    if (selectedVessel) {
      setShowCalciumContextSubMenu(false);
    }
    if (onChange)
      onChange({
        new: v,
        old: selectedVessel ? selectedVessel.toLowerCase() : 'reclassify',
      });
  };

  const menuDivRef = useRef<HTMLDivElement | null>(null);

  const [itemWidth, setItemWidth] = useState(menuDivRef.current?.clientWidth);
  const [itemHeight, setItemHeight] = useState(
    menuDivRef.current?.clientHeight
  );

  useEffect(() => {
    setItemHeight(menuDivRef.current?.clientHeight);
    setItemWidth(menuDivRef.current?.clientWidth);
  }, [
    menuDivRef.current?.clientHeight,
    menuDivRef.current?.clientWidth,
    setItemHeight,
    setItemWidth,
  ]);

  const contextMenuPos: Vec2 = useMemo(
    () => ({
      // Push the context menu to the right by a small amount so the cursor is roughly over the tip
      // of the arrow.
      x: position.x + 12,
      // Push the context menu up by half it's height so the arrow aligns with the cursor
      // Without this, the cursor lines up with the top of the menu
      y: position.y - (itemHeight ?? 0) / 2,
    }),
    [position.x, position.y, itemHeight]
  );

  return (
    <div
      // This div is for placement only and visibility only
      style={{
        top: contextMenuPos.y,
        left: contextMenuPos.x,
        position: 'fixed',
        zIndex: 1,
        // Don't show anything until the height has been calculated. This will avoid any popping
        visibility: itemHeight !== undefined ? 'visible' : 'hidden',
      }}
    >
      {/** Lesion selection blocker. This hovers above the viewer, but beneath the context menu and puts a small
        buffer around the context menu so it's not overly sensitive to gaps between the context menu and sub
       context menu. */}
      {itemHeight && itemWidth && (
        <div
          style={{
            position: 'fixed',
            // Slightly above the context menu
            top: contextMenuPos.y - MENU_BLOCKER_BUFFER,
            // Slightly to the left of the context menu
            left: contextMenuPos.x - MENU_BLOCKER_BUFFER,
            // Extend slightly to the right of the context menu
            width: itemWidth + 2 * MENU_BLOCKER_BUFFER,
            // Extend slightly below the context menu
            height: itemHeight + 2 * MENU_BLOCKER_BUFFER,
            // Render beneath the context menu
            zIndex: -1,
          }}
        />
      )}
      {/** The initial menu item which shows the colour key and the vessel name */}
      {selectedVessel && !chosenVessel && (
        <div
          ref={menuDivRef}
          className={cn('context-menu__item split', 'left', 'selected', {
            chosen: chosenVessel,
            'multiple-selected': selectedVessel === 'Multiple',
          })}
          onClick={toggleSubMenu}
        >
          {!chosenVessel && selectedVessel && (
            <span
              style={{
                background: getVesselColor(selectedVessel.toLowerCase()),
              }}
              className="context-menu__color-block"
            />
          )}
          <span className="context-menu__text">{selectedVessel}</span>
        </div>
      )}

      {/** When reclassifying the vessel */}
      {selectedVessel && chosenVessel && (
        <div
          className={cn('context-menu__item split', 'left', {
            chosen: chosenVessel,
          })}
        >
          <div
            style={{
              justifyContent: 'left',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor: Colors.mineShaft,
              borderRadius: '0.3rem',
              marginRight: 0,
              width: itemWidth,
              height: itemHeight,
            }}
          >
            <Loader />
          </div>
        </div>
      )}

      {showCalciumContextSubMenu &&
        itemWidth !== undefined &&
        itemHeight !== undefined && (
          <CalciumContextSubMenu
            mousePosHigh={mousePosHigh}
            vessels={vessels}
            itemHeight={itemHeight}
            itemWidth={itemWidth}
            contextMenuPos={contextMenuPos}
            selectedVessel={selectedVessel}
            changeVessel={changeVessel}
          />
        )}
    </div>
  );
};
