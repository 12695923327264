import * as hdf5 from 'jsfive';
import { LoadingManager } from 'three';
import {
  DraftReport,
  MesaPercentileResponse,
  Report,
  ReportHistoryResponse,
  ReportResponse,
  RequestReviewResponse,
  ReviewUser,
  ListReview,
  LVMassIndex,
  MassIndex,
  PatientData,
  PlaqueMeasurements,
  PlaqueMeasurementsPerLesion,
  PlaqueMeasurementsPerSlice,
  WallSliceMeasurements,
} from '../context/types';
import { Ethnicity } from '../types/common';
import { getAPIToken } from './auth';
import { uuidv4 } from './shared';

const retry = require('async-retry');
const fetch = require('node-fetch');

// define retry properties for requests receiving a throttled response
const THROTTLED_RETRY_PROPS = {
  retries: 100,
  factor: 2,
  minTimeout: 50,
  randomize: true,
};

// WIP wrap all await calls to throw the error returned
// or reject error returned
// completed: fetchJSON, fetchH5, postJSON
// TODO: all others (excluding getJSON and getH5)

// THREE with include guards
let THREE = window.THREE;
if (typeof THREE === 'undefined') {
  THREE = window.THREE = require('three');
}
const target = window as any;
// @ts-ignore no typing for PLYLoader
if (typeof THREE.PLYLoader === 'undefined') {
  require('three/examples/js/loaders/PLYLoader');
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_WS_BASE_URL = process.env.REACT_APP_API_WS_BASE_URL;

let pendingH5Requests: {
  [id: string]: { group?: any; request?: any; promise?: any };
} = {};
let pendingJsonRequests: {
  [id: string]: { group?: any; request?: any; promise?: any };
} = {};
let pendingPLYRequests: { [id: string]: { group?: any; request?: any } } = {};

// debug request dicts
target.PENDINGH5 = pendingH5Requests;
target.PENDINGJSON = pendingJsonRequests;
target.PENDINGPLY = pendingPLYRequests;

export const clearPendingRequests = (groups: string | any[]) => {
  // Clear pending h5 requests
  Object.entries(pendingH5Requests)
    .filter(([_, { group }]) =>
      Array.isArray(groups) ? groups.includes(group) : true
    )
    .forEach(([key, { request }]) => {
      request.abort();
      delete pendingH5Requests[key];
    });

  // Clear pending JSON requests
  Object.entries(pendingJsonRequests)
    .filter(([_, { group }]) =>
      Array.isArray(groups) ? groups.includes(group) : true
    )
    .forEach(([key, { request }]) => {
      request.abort();
      delete pendingJsonRequests[key];
    });

  // Clear pending PLY requests
  Object.entries(pendingPLYRequests)
    .filter(([_, { group }]) =>
      Array.isArray(groups) ? groups.includes(group) : true
    )
    .forEach(([key, { request }]) => {
      request.abort();
      delete pendingPLYRequests[key];
    });
};

export const getRawH5 = (path: string): Promise<Buffer> => {
  return new Promise((resolve, reject) => {
    const url = new URL(path, API_BASE_URL);
    getAPIToken()
      .then((token) => {
        fetch(url.href, {
          method: 'GET',
          headers: {
            Authorization: `Cognito-Bearer ${token}`,
            'Content-Type': 'application/octet-stream',
          },
        })
          .then((resp: any) => {
            if (resp.status === 200) {
              return resp.arrayBuffer();
            } else {
              reject(`Error: ${resp.status} whilst fetching '${path}'`);
            }
          })
          .then((buffer: Buffer) => {
            resolve(buffer);
          })
          .catch((e: Error) => {
            reject(e);
          });
      })
      .catch((e) => reject(e));
  });
};

const fetchH5 = (
  path: string,
  group: any,
  userGroup: any,
  retryProps = {} as any,
  retryStatusCodes = [] as any
) => {
  const url = new URL(path, API_BASE_URL);
  if (pendingH5Requests[path]) {
    return pendingH5Requests[path].promise;
  }
  pendingH5Requests[path] = {};
  pendingH5Requests[path].group = group;
  pendingH5Requests[path].request = new AbortController();
  const { signal } = pendingH5Requests[path].request;
  const rv = new Promise(async (resolve, reject) => {
    // wrap in promise to guarantee cleanup on abort
    const promiseWrapper = new Promise(async (resolve, reject) => {
      let token: any;
      try {
        token = await getAPIToken();
      } catch (err) {
        return reject(err);
      }
      const error = new DOMException(
        `Request aborted for ${path}`,
        'AbortError'
      );
      if (signal.aborted) return reject(error);
      signal.addEventListener('abort', () => {
        reject(error);
      });
      let result;
      try {
        result = await retry(
          async (bail: (arg0: DOMException | Error) => void) => {
            if (signal.aborted) return bail(error);
            // wrap in retry in case of 429s
            let response;
            try {
              response = await retry(
                async (bail: (arg0: DOMException) => any) => {
                  if (signal.aborted) {
                    return bail(error);
                  }
                  let response;
                  try {
                    response = await fetch(url.href, {
                      method: 'GET',
                      headers: {
                        Authorization: `Cognito-Bearer ${token}`,
                        'Content-Type': 'application/octet-stream',
                      },
                      signal,
                    });
                  } catch (err) {
                    // FIXME currently retry on all failures
                    // Due to CORS being undectable from the browser API
                    // retrying on abort is intended as it will immediately error
                    throw err;
                  }
                  if (response.status === 429) {
                    throw new Error('Network error:' + response.status);
                  }
                  return response;
                },
                THROTTLED_RETRY_PROPS
              );
            } catch (err) {
              return bail(err);
            }
            // If we aren't using retries ignore this.
            // Otherwise decide whether we're falling through to a retry or
            // quitting out based on the error code
            if (retryProps.retries > 0 && response.status !== 200) {
              if (retryStatusCodes.includes(response.status)) {
                throw new Error('Network error:' + response.status);
              } else {
                bail(new Error('Network error:' + response.status));
              }
            }
            const data = await response.arrayBuffer();
            try {
              const file = new hdf5.File(data, '');
              return file;
            } catch {
              console.error('Failed to retieve hdf5 file');
              return null;
            }
          },
          retryProps
        );
      } catch (err) {
        return reject(err);
      }
      return resolve(result);
    });
    try {
      const result = await promiseWrapper;
      resolve(result);
    } catch (err) {
      reject(err);
    } finally {
      delete pendingH5Requests[path];
    }
  });
  pendingH5Requests[path].promise = rv;
  return rv;
};

const fetchJSON = (
  path: string,
  group: any,
  retryProps = {} as any,
  retryStatusCodes = [] as any
) => {
  const url = new URL(path, API_BASE_URL);
  if (pendingJsonRequests[path]) {
    return pendingJsonRequests[path].promise;
  }
  pendingJsonRequests[path] = {};
  pendingJsonRequests[path].group = group;
  pendingJsonRequests[path].request = new AbortController();
  const { signal } = pendingJsonRequests[path].request;
  const rv = new Promise(async (resolve, reject) => {
    // wrap in promise to guarantee cleanup on abort
    const promiseWrapper = new Promise(async (resolve, reject) => {
      let token: any;
      try {
        token = await getAPIToken();
      } catch (err) {
        return reject(err);
      }
      const error = new DOMException(
        `Request aborted for ${path}`,
        'AbortError'
      );
      if (signal.aborted) return reject(error);
      signal.addEventListener('abort', () => {
        reject(error);
      });
      let result;
      try {
        result = await retry(
          async (bail: (arg0: DOMException | Error) => void) => {
            if (signal.aborted) return bail(error);
            // wrap in retry in case of 429s
            let response;
            try {
              response = await retry(
                async (bail: (arg0: DOMException) => any) => {
                  if (signal.aborted) {
                    return bail(error);
                  }
                  let response;
                  try {
                    response = await fetch(url.href, {
                      method: 'GET',
                      headers: {
                        Authorization: `Cognito-Bearer ${token}`,
                        'Content-Type': 'application/json',
                      },
                      signal,
                    });
                  } catch (err) {
                    // FIXME currently retry on all failures
                    // Due to CORS being undectable from the browser API
                    throw err;
                  }
                  if (response.status === 429) {
                    throw new Error('Network error:' + response.status);
                  }

                  return response;
                },
                THROTTLED_RETRY_PROPS
              );
            } catch (err) {
              return bail(err);
            }
            // If we aren't using retries ignore this.
            // Otherwise decide whether we're falling through to a retry or
            // quitting out based on the error code
            if (retryProps.retries > 0 && response.status !== 200) {
              if (retryStatusCodes.includes(response.status)) {
                throw new Error('Network error:' + response.status);
              } else {
                bail(new Error('Network error:' + response.status));
              }
            }

            if (response.status === 404) {
              return undefined;
            }

            const json = await response.json();
            return json;
          },
          retryProps
        );
      } catch (err) {
        return reject(err);
      }
      return resolve(result);
    });
    try {
      const result = await promiseWrapper;
      resolve(result);
    } catch (err) {
      reject(err);
    } finally {
      delete pendingJsonRequests[path];
    }
  });
  pendingJsonRequests[path].promise = rv;
  return rv;
};

export async function getWebsocket(path: string) {
  let token;
  try {
    token = await getAPIToken();
  } catch (err) {
    throw err;
  }

  return await new WebSocket(
    API_WS_BASE_URL + path + '?access_token=' + encodeURIComponent(token)
  );
}

export async function websocket(
  path: string,
  body: { [id: string]: any },
  expectedResponseTypes: string[],
  handleResponse: (json: any, key?: string) => void,
  handleError: (error: Error) => void
) {
  const socket = await getWebsocket(path);

  // Once we have an open connection send the request
  socket.addEventListener('open', (_event) => {
    if (body) {
      socket.send(JSON.stringify(body));
    }
  });

  socket.addEventListener('error', (error) => {
    console.log('error event', error);
    socket.close();
    if (handleError) handleError(new Error(`${error}`));
  });

  let expectedResponses = expectedResponseTypes.map((x) => ({
    responseType: x,
    handled: false,
  }));
  // Only call back when we get the result we expect or an error
  socket.addEventListener('message', (event) => {
    const json = JSON.parse(event.data);
    const foundResponse = expectedResponses.findIndex(
      (x) => x.responseType === json.type
    );
    if (json.type === 'error') {
      socket.close();
      handleError(new Error(json.data.message));
    } else if (foundResponse > -1) {
      expectedResponses[foundResponse].handled = true;

      if (expectedResponses.every((x) => x.handled)) {
        socket.close();
      }
      handleResponse(json, json.type);
    }
  });
}

export async function postJSON(path: string, body = {}) {
  return sendJSON(path, body, 'POST');
}

export async function putJSON(path: string, body = {}) {
  return sendJSON(path, body, 'PUT');
}

export class APIError extends Error {
  response: any;
  response_body: any;

  constructor(message: string, response: any, response_body: object) {
    super(message);
    this.response = response;
    this.response_body = response_body;
  }
}

async function sendJSON(path: string, body = {}, method: string) {
  let token: any;
  try {
    token = await getAPIToken();
  } catch (err) {
    throw err;
  }
  const url = new URL(path, API_BASE_URL);
  let response;
  try {
    response = await retry(async () => {
      let response;
      try {
        response = await fetch(url.href, {
          method,
          headers: {
            Authorization: `Cognito-Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
      } catch (err) {
        // FIXME currently retry on all failures
        // Due to CORS being undetectable from the browser API
        throw err;
      }
      if (response.status === 429) {
        throw new Error('Network error:' + response.status);
      }
      return response;
    }, THROTTLED_RETRY_PROPS);
  } catch (err) {
    throw err;
  }

  let resJSON;
  try {
    resJSON = await response.json();
  } catch {}
  if (!response.ok)
    throw new APIError(resJSON ?? ['Unknown server error'], response, resJSON);

  return resJSON;
}

function downloadOrOpenPDF(
  blob: BlobPart,
  filename: string | null,
  download = false
) {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: 'application/pdf' });
  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob);
    return;
  }
  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.target = '_blank';
  if (download) {
    link.download = filename ?? 'file.pdf';
  }
  link.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
}

export async function generatePDF(
  path: string,
  body = {},
  download = false,
  fileName: string
) {
  const token = await getAPIToken();
  const url = new URL(path, API_BASE_URL);
  const response = await retry(async () => {
    let response;
    try {
      response = await fetch(url.href, {
        method: 'POST',
        headers: {
          Authorization: `Cognito-Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });
    } catch (err) {
      // FIXME currently retry on all failures
      // Due to CORS being undectable from the browser API
      throw err;
    }
    if (response.status === 429) {
      throw new Error('Network error:' + response.status);
    }
    return response;
  }, THROTTLED_RETRY_PROPS);

  if (response) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      const json = await response.json();
      console.log('Report failed to generate', json);
    } else {
      await response.blob().then((blob: BlobPart) => {
        downloadOrOpenPDF(blob, fileName, download);
      });
      return true;
    }
  }
  return false;
}

let pendingH5Promises: { [id: string]: any } = {};
let pendingJsonPromises: { [id: string]: any } = {};

// debug request dicts
target.PENDINGH5GET = pendingH5Promises;
target.PENDINGJSONGET = pendingJsonPromises;
export async function getJSON(
  path: string,
  getLatest = false,
  properties = {} as any,
  retryProps = {} as any,
  retryStatusCodes = [] as any
) {
  /**
   * @TODO
   * add IndexDB caching
   */
  // getLatest not currently used but will be used to force a request rather than
  // fetch from cache
  const promId: string = uuidv4();
  if (typeof pendingJsonPromises[path] === 'undefined') {
    pendingJsonPromises[path] = {};
  }
  const sendRetryProps = {
    retries: 0,
    factor: 1,
    minTimeout: 2000,
    randomize: false,
  };
  Object.assign(sendRetryProps, retryProps);
  let result;
  pendingJsonPromises[path][promId] = fetchJSON(
    path,
    properties.user_group,
    sendRetryProps,
    retryStatusCodes
  );
  let err_rv;
  try {
    result = await pendingJsonPromises[path][promId];
  } catch (err) {
    err_rv = err;
  }
  delete pendingJsonPromises[path][promId];
  if (Object.keys(pendingJsonPromises[path]).length === 0) {
    delete pendingJsonPromises[path];
  }
  if (typeof err_rv !== 'undefined') {
    throw err_rv;
  }
  return result;
}

export async function getH5(
  path: string,
  getLatest = false,
  properties: { group_type?: string; user_group?: string } = {},
  retryProps = {},
  retryStatusCodes = []
) {
  /**
   * @TODO
   * add IndexDB caching
   */
  const promId = uuidv4();
  if (typeof pendingH5Promises[path] === 'undefined') {
    pendingH5Promises[path] = {};
  }
  const sendRetryProps = {
    retries: 0,
    factor: 1,
    minTimeout: 2000,
    randomize: false,
  };
  Object.assign(sendRetryProps, retryProps);
  let result;
  pendingH5Promises[path][promId] = await fetchH5(
    path,
    properties.group_type,
    properties.user_group,
    sendRetryProps,
    retryStatusCodes
  );
  let err_rv;
  try {
    result = await pendingH5Promises[path][promId];
  } catch (err) {
    err_rv = err;
  }
  // perform cleanup regardless of error
  delete pendingH5Promises[path][promId];
  if (Object.keys(pendingH5Promises[path]).length === 0) {
    delete pendingH5Promises[path];
  }
  if (typeof err_rv !== 'undefined') {
    throw err_rv;
  }
  return result;
}

/**
 * Due to no threejs loader not supporting promise retries with their
 * callback structure. A custom fileloader has been built such that the same
 * retry structure is used. This fileloader is almost a direct clone fo the current
 * threejs fileloader, exposing the same interface, with now two additional
 * constructor parameters. Additionally taking this opportunity to wrap the requests
 * in cleanup
 */

const loading = {} as any;

class RetryFileLoader extends THREE.Loader {
  retryProps: { retries?: number };
  retryStatusCodes: any[];
  responseType?: string;
  mimeType?: any;
  status?: number;
  constructor(
    manager: LoadingManager,
    retryProps = {},
    retryStatusCodes: any[] = []
  ) {
    super(manager);
    this.retryProps = retryProps;
    this.retryStatusCodes = retryStatusCodes;
  }

  public setResponseType(value: string) {
    this.responseType = value;
    return this;
  }
  public setMimeType(value: any) {
    this.mimeType = value;
    return this;
  }
  // TODO investigate why this function was missing from the Loader prototype
  public setRequestHeader(requestHeader: { [header: string]: string }) {
    this.requestHeader = requestHeader;
    return this;
  }

  public load(
    url: string,
    onLoad: (arg0: any) => void,
    onProgress: any,
    onError: (arg0: any) => void
  ) {
    if (url === undefined) url = '';
    if (this.path !== undefined) url = this.path + url;
    url = this.manager.resolveURL(url);
    const scope = this;
    const cached = THREE.Cache.get(url);
    if (cached !== undefined) {
      scope.manager.itemStart(url);
      setTimeout(function () {
        if (onLoad) onLoad(cached);
        scope.manager.itemEnd(url);
      }, 0);
      return cached;
    }
    // Check if request is duplicate
    if (loading[url] !== undefined) {
      loading[url].push({
        onLoad: onLoad,
        onProgress: onProgress,
        onError: onError,
      });
      return;
    }
    // Check for data: URI
    const dataUriRegex = /^data:(.*?)(;base64)?,(.*)$/;
    const dataUriRegexResult = url.match(dataUriRegex);
    let request;
    // Safari can not handle Data URIs through XMLHttpRequest so process manually
    if (dataUriRegexResult) {
      const mimeType = dataUriRegexResult[1] as DOMParserSupportedType;
      const isBase64 = !!dataUriRegexResult[2];
      let data = dataUriRegexResult[3];
      data = decodeURIComponent(data);
      if (isBase64) data = atob(data);
      try {
        let response: string | Document | ArrayBuffer | Blob;
        const responseType = (this.responseType || '').toLowerCase();
        switch (responseType) {
          case 'arraybuffer':
          case 'blob':
            const view = new Uint8Array(data.length);
            for (let i = 0; i < data.length; i++) {
              view[i] = data.charCodeAt(i);
            }
            if (responseType === 'blob') {
              response = new Blob([view.buffer], { type: mimeType });
            } else {
              response = view.buffer;
            }
            break;
          case 'document':
            const parser = new DOMParser();
            response = parser.parseFromString(data, mimeType);
            break;
          case 'json':
            response = JSON.parse(data);
            break;
          default:
            // 'text' or other
            response = data;
            break;
        }
        // Wait for next browser tick like standard XMLHttpRequest event dispatching does
        setTimeout(function () {
          if (onLoad) onLoad(response);
          scope.manager.itemEnd(url);
        }, 0);
      } catch (error) {
        // Wait for next browser tick like standard XMLHttpRequest event dispatching does
        setTimeout(function () {
          if (onError) onError(error);
          scope.manager.itemError(url);
          scope.manager.itemEnd(url);
        }, 0);
      }
    } else {
      // Initialise array for duplicate requests
      loading[url] = [];
      loading[url].push({
        onLoad: onLoad,
        onProgress: onProgress,
        onError: onError,
      });
      pendingPLYRequests[url] = {};
      pendingPLYRequests[url].request = new AbortController();
      const { signal } = pendingPLYRequests[url].request;
      request = new Promise(async (resolve, reject) => {
        const error = new DOMException(
          `Request aborted for ${url}`,
          'AbortError'
        );
        const errorCallback = (err: DOMException) => {
          const callbacks = loading[url];
          delete loading[url];
          for (let i = 0, il = callbacks.length; i < il; i++) {
            const callback = callbacks[i];
            callback.onError && callback.onError(err);
            scope.manager.itemError(url);
            scope.manager.itemEnd(url);
            return reject(err);
          }
        };
        const progressCallback = (
          event: ProgressEvent<XMLHttpRequestEventTarget>
        ) => {
          const callbacks = loading[url];
          for (let i = 0, il = callbacks.length; i < il; i++) {
            const callback = callbacks[i];
            callback.onProgress && callback.onProgress(event);
          }
        };
        const successCallback = (response: any) => {
          const callbacks = loading[url];
          delete loading[url];
          THREE.Cache.add(url, response);
          for (let i = 0, il = callbacks.length; i < il; i++) {
            const callback = callbacks[i];
            if (callback.onLoad) callback.onLoad(response);
          }
          scope.manager.itemEnd(url);
          return resolve(response);
        };
        if (signal.aborted) return errorCallback(error);
        signal.addEventListener('abort', (event: DOMException) => {
          return errorCallback(event);
        });
        try {
          const requestPromise = retry(
            async (bail: (arg0: DOMException | Error) => void) => {
              if (signal.abort) {
                return bail(error);
              }
              // wrap in a retry for 429s
              let resp;
              try {
                resp = await retry(
                  async (bail: (arg0: DOMException) => any) => {
                    if (signal.abort) {
                      return bail(error);
                    }
                    const resp = (await new Promise((resolve, reject) => {
                      const tpRequest = new XMLHttpRequest();
                      tpRequest.open('GET', url, true);
                      tpRequest.addEventListener('load', function (event) {
                        resolve(tpRequest);
                      });
                      tpRequest.addEventListener('progress', function (event) {
                        progressCallback(event);
                      });
                      tpRequest.addEventListener('error', function (event) {
                        reject(event);
                      });
                      tpRequest.addEventListener('abort', function (event) {
                        reject(event);
                      });
                      if (this.responseType !== undefined)
                        tpRequest.responseType = this
                          .responseType as XMLHttpRequestResponseType;
                      if (this.withCredentials !== undefined)
                        tpRequest.withCredentials = this.withCredentials;
                      if (tpRequest.overrideMimeType)
                        tpRequest.overrideMimeType(
                          this.mimeType !== undefined
                            ? this.mimeType
                            : 'text/plain'
                        );
                      for (const header in this.requestHeader) {
                        tpRequest.setRequestHeader(
                          header,
                          this.requestHeader[header]
                        );
                      }
                      tpRequest.send(null);
                    })) as any;
                    if (resp.status === 429) {
                      throw new Error('Network error:' + resp.status);
                    }
                    return resp;
                  },
                  THROTTLED_RETRY_PROPS
                );
              } catch (err) {
                return bail(err);
              }
              // if retries are specified, check if status is successful and if not, whether
              // it's within retryStatus codes
              if (
                this.retryProps.retries &&
                this.retryProps.retries > 0 &&
                [200, 0].includes(resp.status)
              ) {
                if (this.retryStatusCodes.includes(resp.status)) {
                  throw new Error('Network error:' + resp.status);
                } else {
                  bail(new Error('Network error:' + resp.status));
                }
              }
              // Some browsers return HTTP Status 0 when using non-http protocol
              // e.g. 'file://' or 'data://'. Handle as success.
              if (this.status === 0)
                console.warn('RetryFileLoader: HTTP Status 0 received.');
              return resp.response;
            }
          ).then(
            (value: any) => {
              if (url.includes('s1a')) {
                console.log(`${url} value`, value);
              }
              delete pendingPLYRequests[url];
              return value;
            },
            (err: any) => {
              console.error(err);
              delete pendingPLYRequests[url];
              return err;
            }
          );
          successCallback(await requestPromise);
        } catch (err) {
          console.error(err);
          errorCallback(err);
        }
      });
    }
    scope.manager.itemStart(url);
    return request;
  }
}

// @ts-ignore although we have types for THREE we don't have for the examples
class APIPLYLoader extends THREE.PLYLoader {
  requestHeader: {};
  retryProps: {};
  retryStatusCodes: any[];

  constructor(manager: LoadingManager, retryProps = {}, retryStatusCodes = []) {
    super(manager);
    this.requestHeader = {};
    this.retryProps = retryProps;
    this.retryStatusCodes = retryStatusCodes;
  }

  public setRequestHeader(requestHeader: { Authorization?: string }) {
    this.requestHeader = requestHeader;
  }

  // method is a clone of PLYLoader aside form API_BASE_URL
  // and the addition of the request header to the FILELoader
  public load(
    apiPath: string,
    onLoad: (arg0: any) => void,
    onProgress: any,
    onError: (arg0: any) => void
  ) {
    const scope = this;
    const loader = new RetryFileLoader(
      // @ts-ignore
      this.manager,
      this.retryProps,
      this.retryStatusCodes
    );
    // @ts-ignore
    loader.setPath(this.path);
    loader.setResponseType('arraybuffer');
    loader.setRequestHeader(this.requestHeader);
    const url = new URL(apiPath, API_BASE_URL);

    loader.load(
      url.href,
      function (text) {
        // @ts-ignore although we have types for THREE we don't have for the examples
        onLoad(scope.parse(text));
      },
      onProgress,
      onError
    );
  }
}

// wrapping new api ply loader in this function to enable async constructor
export async function newPLYLoader(
  manager?: any,
  retryProps = {},
  retryStatusCodes = []
) {
  const token = await getAPIToken();
  const sendRetryProps = {
    retries: 0,
    factor: 1,
    minTimeout: 2000,
    randomize: false,
  };
  Object.assign(sendRetryProps, retryProps);
  const rv = new APIPLYLoader(manager, sendRetryProps, retryStatusCodes);
  rv.setRequestHeader({
    Authorization: `Cognito-Bearer ${token}`,
  });
  return rv;
}

export async function fetchReport(
  studyId: string,
  runId: string,
  versionID?: string
): Promise<Report> {
  let uri = `/data/${studyId}/${runId}/report/content`;
  if (versionID) uri += `?version=${versionID}`;
  return await getJSON(uri);
}

export async function saveReport(
  studyId: string,
  runId: string,
  report: DraftReport
): Promise<ReportResponse> {
  return await putJSON(`data/${studyId}/${runId}/report/content`, report);
}

export async function saveFieldInReport(
  studyId: string,
  runId: string,
  data: any
) {
  return await putJSON(`data/${studyId}/${runId}/report/content`, data);
}

export async function deleteScreenShot(
  filename: string,
  patientID: string,
  runID: string
) {
  return postJSON(`/data/${patientID}/${runID}/report/screenshot/delete`, {
    filename,
  });
}

export async function fetchReportHistory(
  studyId: string,
  runId: string
): Promise<ReportHistoryResponse> {
  return getJSON(`/data/${studyId}/${runId}/report/content/history`);
}

export async function revertReportVersion(
  studyId: string,
  runId: string,
  version: 'initial' | 'previous'
): Promise<Report> {
  return postJSON(`/data/${studyId}/${runId}/report/content/revert`, {
    version,
  });
}

export async function fetchScanQualities(studyId: string, runId: string) {
  return getJSON(`/data/${studyId}/${runId}/scan-quality`);
}

export async function fetchMesaPercentile(
  sex: string,
  age: string,
  ethnicity: Ethnicity,
  calciumScore: number
): Promise<MesaPercentileResponse> {
  return postJSON('/dashboard/mesa-percentile', {
    sex,
    age,
    ethnicity,
    calcium_score: calciumScore,
  });
}

export async function fetchReviewUsers(): Promise<ReviewUser[]> {
  return getJSON('/client/users');
}

export async function fetchReviewList(
  studyId: string,
  runId: string
): Promise<ListReview[]> {
  return getJSON(`/data/${studyId}/${runId}/report/review/list`);
}

export async function requestReview(
  studyId: string,
  runId: string,
  assignee: ReviewUser,
  review_type: string,
  message: string
): Promise<RequestReviewResponse> {
  return postJSON(`/data/${studyId}/${runId}/report/review/request`, {
    assignee,
    review_type,
    message,
  });
}

export async function completeReview(
  studyId: string,
  runId: string,
  review_id: string
): Promise<RequestReviewResponse> {
  return postJSON(`/data/${studyId}/${runId}/report/review/complete`, {
    review_id,
  });
}

export async function fetchLVMassIndex(
  studyId: string,
  runId: string,
  versionHead: any
): Promise<LVMassIndex> {
  return getJSON(
    `/data/${studyId}/${runId}/lv-mass-index?version=${versionHead}`
  );
}

export async function fetchPatientData(
  studyId: string,
  runId: string,
  versionHead: any
): Promise<PatientData> {
  return getJSON(
    `/data/${studyId}/${runId}/patient-data?version=${versionHead}`
  );
}

export async function fetchLVMass(
  studyId: string,
  runId: string,
  versionHead: any
): Promise<MassIndex> {
  return getJSON(`/data/${studyId}/${runId}/lv-mass?version=${versionHead}`);
}

export async function calculateLVMassIndex(
  studyId: string,
  runId: string,
  version_id: string,
  height: number,
  weight: number,
  lv_mass: number
): Promise<any> {
  return postJSON(
    `/services/${studyId}/${runId}/lv-mass-index?version=${version_id}`,
    {
      height,
      weight,
      lv_mass,
      version_id,
    }
  );
}
export async function fetchPlaqueMeasurements(
  studyId: string,
  runId: string
): Promise<PlaqueMeasurements> {
  return getJSON(`data/${studyId}/${runId}/plaque-measurements`);
}

export async function getLatestVersion(
  studyId: string,
  runId: string,
  version_id: any
): Promise<any> {
  return postJSON(
    `data/${studyId}/${runId}/version/head?version_id=${version_id}`,
    {}
  );
}
export async function fetchPlaqueMeasurementPerVessel(
  studyId: string,
  runId: string,
  vesselId: string
): Promise<PlaqueMeasurements> {
  return getJSON(
    `data/${studyId}/${runId}/vessel/${vesselId}/plaque-measurements`
  );
}

export async function fetchVesselMaximumStenosis(
  studyId: string,
  runId: string,
  versionHead: any
): Promise<any> {
  return getJSON(
    `data/${studyId}/${runId}/web-data/vessel-stenosis-max?version=${versionHead}`
  );
}
export async function fetchSignificantLesions(
  studyId: string,
  runId: string,
  vesselId: string,
  versionHead: any
): Promise<any> {
  return getJSON(
    `data/${studyId}/${runId}/vessel/${vesselId}/sig-lesion-count?version=${versionHead}`
  );
}
export async function fetchVulnerablePlaqueVessel(
  studyId: string,
  runId: string,
  vesselId: string,
  versionHead: any
): Promise<any> {
  return getJSON(
    `data/${studyId}/${runId}/vessel/${vesselId}/parsed-vp-biomarker?version=${versionHead}`
  );
}
export async function fetchPlaqueMeasurementsPerLesion(
  studyId: string,
  runId: string,
  vesselId: string,
  contrastLesionId: number,
  versionHead: any
): Promise<PlaqueMeasurementsPerLesion> {
  return getJSON(
    `data/${studyId}/${runId}/vessel/${vesselId}/contrast-lesion/${contrastLesionId}/plaque-measurements?version=${versionHead}`
  );
}

export async function fetchPlaqueMeasurementsLesionLength(
  studyId: string,
  runId: string,
  vesselId: string,
  contrastLesionId: number,
  versionHead: any
): Promise<any> {
  return getJSON(
    `data/${studyId}/${runId}/vessel/${vesselId}/contrast-lesion/${contrastLesionId}/lesion-length?version=${versionHead}`
  );
}
export async function fetchPlaqueMeasurementsPerSlice(
  studyId: string,
  runId: string,
  vesselId: string
): Promise<PlaqueMeasurementsPerSlice> {
  return getJSON(
    `data/${studyId}/${runId}/vessel/${vesselId}/plaque-slice-measurements`
  );
}
export async function fetchWallSliceMeasurementsSlice(
  studyId: string,
  runId: string,
  vesselId: string
): Promise<WallSliceMeasurements> {
  return getJSON(
    `data/${studyId}/${runId}/vessel/${vesselId}/wall-slice-measurements`
  );
}
