import React, { CSSProperties } from 'react';
import { vec3 } from 'gl-matrix';
import vtkRenderer from 'vtk.js/Sources/Rendering/Core/Renderer';
import { worldArrayToDisplayPathsByThickness, getScreenScale } from './Utils';

interface CenerlineProps {
  // The array centerline points [][X, Y, Z].
  centerline: vec3[];
  // The colour of the centerline in front of the active slab volume.
  nearColor: string;
  // The colour of the centerline in inside of the active slab volume.
  activeColor: string;
  // The colour of the centerline behind the active slab volume.
  farColor: string;
  // The width of the centerline in front of the active slab volume.
  nearWidth: number;
  // The width of the centerline inside the active slab volume.
  activeWidth: number;
  // The width of the centerline behind the active slab volume.
  farWidth: number;
}

interface CenerlineState {
  // The width of the View2D.
  width?: number;
  // The height of the View2D.
  height?: number;
  // The SVG paths for centerline { near: string, active: string, far: string }.
  centerlinePaths: {
    near: string;
    active: string;
    far: string;
  };
}

export class Centerline extends React.Component<
  CenerlineProps,
  CenerlineState
> {
  static defaultProps = {
    nearColor: '#0386BF',
    activeColor: '#04B3FF',
    farColor: '#025980',
    nearWidth: 3,
    activeWidth: 5,
    farWidth: 3,
  };

  constructor(props: CenerlineProps) {
    super(props);
    this.state = {
      // The width of the View2D.
      width: undefined,
      // The height of the View2D.
      height: undefined,
      // The SVG paths for centerline { near: string, active: string, far: string }.
      centerlinePaths: {
        near: '',
        active: '',
        far: '',
      },
    };
  }

  /**
   * Refresh the centerline path, view dimensions, and trigger a re-render
   */
  refresh(
    width: number,
    height: number,
    renderer: vtkRenderer,
    renderThickness: number
  ) {
    const { centerline } = this.props;
    // Construct the centerline SVG paths from the world points.
    // NOTE: The last param (false) prevents the near and far paths from being generated.
    const centerlinePaths = worldArrayToDisplayPathsByThickness(
      renderer,
      width,
      height,
      centerline,
      renderThickness,
      false
    );
    // Update the state and force a re-render.
    this.setState({ width, height, centerlinePaths });
  }

  render() {
    const { width, height, centerlinePaths } = this.state;
    if (!width || !height) return null;
    const {
      nearColor,
      activeColor,
      farColor,
      nearWidth,
      activeWidth,
      farWidth,
    } = this.props;

    // Scale the stroke widths to look correct on the given display.
    const scale = getScreenScale();
    const scaledFarWidth = scale * farWidth;
    const scaledActiveWidth = scale * activeWidth;
    const scaledNearWidth = scale * nearWidth;

    const style: CSSProperties = {
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
    };

    return (
      <svg
        version="1.1"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
        style={style}
      >
        {/* Render the centerline that is further than the active slab volume. */}
        {centerlinePaths.far.length && (
          <path
            stroke={farColor}
            strokeWidth={scaledFarWidth}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
            fill="none"
            d={centerlinePaths.far}
          />
        )}
        {/* Render the centerline that is nearer than the active slab volume. */}
        {centerlinePaths.near.length && (
          <path
            stroke={nearColor}
            strokeWidth={scaledNearWidth}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
            fill="none"
            d={centerlinePaths.near}
          />
        )}
        {/* Render the centerline that is inside the active slab volume. */}
        {centerlinePaths.active.length && (
          <path
            stroke={activeColor}
            strokeWidth={scaledActiveWidth}
            strokeLinecap={'round'}
            strokeLinejoin={'round'}
            fill="none"
            d={centerlinePaths.active}
          />
        )}
      </svg>
    );
  }
}
