import cornerstone from 'cornerstone-core';
import { requestPoolManager } from 'cornerstone-tools';
import insertSlice from './data/insertSlice';
import getPatientWeightAndCorrectedDose from './data/getPatientWeightAndCorrectedDose';
import {
  CornerstoneImage,
  VolumeImageData,
  ModalitySpecificScalingParameters,
} from '../ReactVTKJSTypes';

// TODO: If we attempt to load multiple imageDataObjects at once this will break.
export default function loadImageData(imageDataObject: VolumeImageData) {
  if (imageDataObject.loaded || imageDataObject.isLoading) {
    // Returning instantly resolved promise as good to go.
    // Returning promise to be resolved by other process as loading.
    return;
  }

  const seriesModule = cornerstone.metaData.get(
    'generalSeriesModule',
    imageDataObject.imageIds[0]
  );

  // If no seriesModule is present will default to linear scaling function.
  const modality = seriesModule && seriesModule.modality;
  let modalitySpecificScalingParameters: ModalitySpecificScalingParameters;
  if (modality === 'PT') {
    modalitySpecificScalingParameters = getPatientWeightAndCorrectedDose(
      imageDataObject.imageIds[0]
    );
  }

  imageDataObject.isLoading = true;

  // This is straight up a hack: vtkjs cries when you feed it data with a range of zero.
  // So lets set the first voxel to 1, which will be replaced when the first image comes in.
  const scalars = imageDataObject.vtkImageData.getPointData().getScalars();
  const scalarData = scalars.getData();

  scalarData[0] = 1;

  const range = {
    max: Number.NEGATIVE_INFINITY,
    min: Number.POSITIVE_INFINITY,
  };

  const numberOfFrames = imageDataObject.imageIds.length;
  let numberProcessed = 0;

  const reRenderFraction = numberOfFrames / 5;
  let reRenderTarget = reRenderFraction;

  const insertPixelDataErrorHandler = (error: Error) => {
    numberProcessed++;
    imageDataObject._publishPixelDataInsertedError(error);

    if (numberProcessed === numberOfFrames) {
      // Done loading, publish complete and remove all subscriptions.
      imageDataObject._publishAllPixelDataInserted();
    }
  };

  const insertPixelData = (image: CornerstoneImage) => {
    const imagePositionPatient = imageDataObject.metaDataMap.get(image.imageId)
      ?.imagePositionPatient;

    if (!imagePositionPatient) {
      console.error(
        'insertPixelData has missing imagePositionPatient for',
        image.imageId
      );
    } else {
      const sliceIndex = imageDataObject.sortedDatasets.findIndex(
        (dataset) =>
          dataset && dataset.imagePositionPatient === imagePositionPatient
      );
      if (sliceIndex < 0) {
        console.error('insertPixelData slice index not found', image.imageId);
      } else {
        const { max, min } = insertSlice(
          imageDataObject.vtkImageData,
          sliceIndex,
          image,
          modality,
          modalitySpecificScalingParameters
        );

        if (max > range.max) {
          range.max = max;
        }

        if (min < range.min) {
          range.min = min;
        }

        const dataArray = imageDataObject.vtkImageData
          .getPointData()
          .getScalars();
        dataArray.setRange(range, 1);
      }
    }
    numberProcessed++;

    if (numberProcessed > reRenderTarget) {
      reRenderTarget += reRenderFraction;
      imageDataObject.vtkImageData.modified();
    }

    imageDataObject._publishPixelDataInserted(numberProcessed);

    if (numberProcessed === numberOfFrames) {
      // Done loading, publish complete and remove all subscriptions.
      imageDataObject._publishAllPixelDataInserted();
    }
  };

  prefetchImageIds(
    imageDataObject.imageIds,
    insertPixelData,
    insertPixelDataErrorHandler
  );
}

function prefetchImageIds(
  imageIds: string[],
  insertPixelData: (image: CornerstoneImage) => void,
  insertPixelDataErrorHandler: (error: Error) => void
) {
  const requestType = 'prefetch';
  const preventCache = false;
  imageIds.forEach((imageId) => {
    requestPoolManager.addRequest(
      {},
      imageId,
      requestType,
      preventCache,
      insertPixelData,
      insertPixelDataErrorHandler
    );
  });
  requestPoolManager.startGrabbing();
}
