import { useCallback, useEffect, useRef } from 'react';

// This is used to ensure any `useState` hooks won't be used to set state after a component is unmounted.
// Pass it into the hook calling something asynchronously, and on success/failure ensure that the state
// is not `getSignal().aborted` before setting new state.
export function useAbortController() {
  const abortControllerRef = useRef<AbortController | undefined>();

  const getAbortController = useCallback(() => {
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController();
    }
    return abortControllerRef.current;
  }, []);

  useEffect(() => {
    // On cleanup, set controller state to aborted
    return () => getAbortController().abort();
  }, [getAbortController]);

  const getSignal = useCallback(() => getAbortController().signal, [
    getAbortController,
  ]);

  return getSignal;
}
