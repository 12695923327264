import { ActionType, createAction } from 'typesafe-actions';
import { ImageBuffer } from '../../components/WebGLViewer/Utils';
import {
  CalciumScorePerMajorVessel,
  CPRSliceData,
  CPRVesselData,
  Shape,
  UpdateSliceIndicesPayload,
  VesselDataResponse,
  WallPolygons,
} from './types';

export const VesselDataActions = {
  addVesselData: createAction(
    'VesselData/ADD_VESSEL_DATA',
    (data: VesselDataResponse) => data
  )(),
  updateCPRVesselDataForVessel: createAction(
    'VesselData/UPDATE_CPR_VESSEL_DATA_FOR_VESSEL',
    (
      patientID: string,
      runID: string,
      vesselID: string,
      data: CPRVesselData
    ) => ({ patientID, runID, vesselID, data })
  )(),
  updateCPRSliceData: createAction(
    'VesselData/UPDATE_CPR_SLICE_DATA',
    (
      patientID: string,
      runID: string,
      vesselID: string,
      sliceIndex: number,
      data: CPRSliceData
    ) => ({ patientID, runID, vesselID, sliceIndex, data })
  )(),
  selectVessel: createAction(
    'VesselData/SELECT_VESSEL',
    (vesselID: string | undefined) => vesselID
  )(),
  updateSliceIndices: createAction(
    'VesselData/UPDATE_SLICE_INDICES',
    (payload: UpdateSliceIndicesPayload) => payload
  )(),
  updateCalciumScores: createAction(
    'VesselData/UPDATE_CALCIUM_SCORES',
    (payload: CalciumScorePerMajorVessel) => payload
  )(),
  updatePriorityVessel: createAction(
    'VesselData/UPDATE_PRIORITY_VESSEL',
    (vesselID: string | undefined) => vesselID
  )(),
  updateHighestPriorityIdx: createAction(
    'VesselData/UPDATE_HIGHEST_PRIORITY_IDX',
    (index: number) => index
  )(),
  initialisePatient: createAction(
    'VesselData/INITIALISE_PATIENT',
    (patientID: string, runID: string) => ({ patientID, runID })
  )(),
  addVesselViewerDataCPRViewerData: createAction(
    'VesselData/ADD_VESSEL_VIEWER_DATA_CPR_VIEWER_DATA',
    (
      patientID: string,
      runID: string,
      vesselID: string,
      cprVesselData: CPRVesselData
    ) => ({ patientID, runID, vesselID, cprVesselData })
  )(),
  addVesselViewerDataCPRViewerDataImage: createAction(
    'VesselData/ADD_VESSEL_VIEWER_DATA_CPR_VIEWER_DATA_IMAGE',
    (
      patientID: string,
      runID: string,
      vesselID: string,
      sliceIndex: number,
      image: Buffer
    ) => ({ patientID, runID, vesselID, sliceIndex, image })
  )(),
  clearSavingVessel: createAction('VesselData/CLEAR_SAVING_VESSEL')(),
  clear: createAction('VesselData/CLEAR')(),
  savingVessel: createAction(
    'VesselData/SAVING_VESSEL',
    (id: string | undefined) => id
  )(),
  updateShortAxisVesselData: createAction(
    'VesselData/UPDATE_SHORT_AXIS_VESSEL_DATA',
    (
      patientID: string,
      runID: string,
      vesselID: string,
      shape: Shape,
      lumen: WallPolygons,
      outer: WallPolygons
    ) => ({ patientID, runID, vesselID, shape, lumen, outer })
  )(),
  updateLongAxisShapeData: createAction(
    'VesselData/UPDATE_LONG_AXIS_SHAPE_DATA',
    (patientID: string, runID: string, vesselID: string, shape: Shape) => ({
      patientID,
      runID,
      vesselID,
      shape,
    })
  )(),
  updateCTNonContrastShapeData: createAction(
    'VesselData/UPDATE_CT_NONCONTRAST_SHAPE_DATA',
    (patientID: string, runID: string, shape: Shape) => ({
      patientID,
      runID,
      shape,
    })
  )(),
  updateCPRVesselSliceImageData: createAction(
    'VesselData/UPDATE_CPR_VESSEL_SLICE_IMAGE_DATA',
    (
      patientID: string,
      runID: string,
      vesselID: string,
      sliceIndex: number,
      image: ImageBuffer
    ) => ({ patientID, runID, vesselID, sliceIndex, image })
  )(),
  updateShortAxisVesselSliceImageData: createAction(
    'VesselData/UPDATE_SHORT_AXIS_VESSEL_SLICE_IMAGE_DATA',
    (
      patientID: string,
      runID: string,
      vesselID: string,
      sliceIndex: number,
      image: ImageBuffer
    ) => ({ patientID, runID, vesselID, sliceIndex, image })
  )(),
  updateLongAxisVesselSliceImageData: createAction(
    'VesselData/UPDATE_LONG_AXIS_VESSEL_SLICE_IMAGE_DATA',
    (
      patientID: string,
      runID: string,
      vesselID: string,
      sliceIndex: number,
      image: ImageBuffer
    ) => ({ patientID, runID, vesselID, sliceIndex, image })
  )(),
  updateCTNonContrastVesselSliceImageData: createAction(
    'VesselData/UPDATE_CT_NONCONTRAST_VESSEL_SLICE_IMAGE_DATA',
    (
      patientID: string,
      runID: string,
      sliceIndex: number,
      image: ImageBuffer
    ) => ({ patientID, runID, sliceIndex, image })
  )(),
};

export type VesselDataAction = ActionType<typeof VesselDataActions>;
