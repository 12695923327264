import { useMemo } from 'react';
import { DraggableItem, useDragAndDropContext } from '../context/drag-n-drop';
import {
  isNonContrastSeries,
  isContrastSeries,
} from '../context/drag-n-drop/helpers';

export function useDraggableGroups() {
  const { state } = useDragAndDropContext();
  return useMemo(() => state.draggableGroup, [state.draggableGroup]);
}

export function useDraggableGroupItems() {
  const draggableGroup = useDraggableGroups();

  return useMemo(() => {
    const result: any[] = [];
    let wasNonContrast = false;
    let wasContrast = false;
    Object.values(draggableGroup).forEach((draggable) => {
      const isNonContrast = isNonContrastSeries(draggable.parent.id);
      const isContrast = isContrastSeries(draggable.parent.id);
      result.push({
        ...draggable.parent,
        // This is to create the titles in the list above each change in series type.
        groupTitle:
          isNonContrast && !wasNonContrast
            ? 'Non contrast series'
            : isContrast && !wasContrast
            ? 'Contrast series'
            : undefined,
      });
      wasNonContrast = isNonContrast;
      wasContrast = isContrast;
    });
    return result;
  }, [draggableGroup]);
}

export function useDraggableGroupsOfItems() {
  const draggableGroup = useDraggableGroups();

  return useMemo(
    () => Object.values(draggableGroup).map((draggable) => draggable.children),
    [draggableGroup]
  );
}

export function useAnyGroupItemDragging() {
  const draggableGroup = useDraggableGroups();

  return useMemo(() => {
    const draggingGroupItem = Object.values(draggableGroup).find(
      (x) => x.parent.isDragging
    );

    if (draggingGroupItem) {
      return draggingGroupItem.parent;
    }

    return undefined;
  }, [draggableGroup]);
}

export interface SingleItemBeingDragged {
  id: string;
  singleItemIndex: number;
}

export function useAnySingleItemDragging(): DraggableItem | undefined {
  const draggableGroup = useDraggableGroups();

  return useMemo(() => {
    const singleItemsBeingDragged: (
      | DraggableItem
      | undefined
    )[] = Object.values(draggableGroup).map((x): DraggableItem | undefined => {
      const draggingSingleItemIndex = x.children.find(
        (singleDraggable) => singleDraggable.isDragging
      );

      return draggingSingleItemIndex;
    });

    const singleItemDragging = singleItemsBeingDragged.filter(
      (x): x is DraggableItem => x !== undefined
    );

    if (singleItemDragging.length === 1) {
      return singleItemDragging[0];
    }

    return undefined;
  }, [draggableGroup]);
}
