import cn from 'classnames';
import React, { ReactNode, useEffect, useMemo } from 'react';
import { VesselGroupings } from '../../config';
import { getVesselColor } from '../../utils/vessel';
import { ReactComponent as EditReportIcon } from '../../assets/icons/edit.svg';
import { useStoreContext } from '../../context/store-context';
import Button from '../Button/Button';
import { Loader } from '../Loader/Loader';
import { useHistory } from 'react-router-dom';

export type EditableCardHeaderType = 'detailed' | 'non-detailed';

interface Props {
  editMode: boolean;
  onClick: () => void;
  children: ReactNode;
  onCancel: () => void;
  onSave: () => void;
  saveEnabled: boolean;
  aiAssessed: boolean;
  title: string;
  headerType: EditableCardHeaderType;
  // Used to display a vessel legend next to the card title
  vesselGroup?: VesselGroupings;
  loading?: boolean;
}

export default function EditableCard({
  editMode,
  onClick,
  onCancel,
  onSave,
  saveEnabled,
  aiAssessed,
  title,
  headerType,
  children,
  vesselGroup,
  loading = false,
}: Props): React.ReactElement<Props> {
  const { postingPartialReport, editingReport } = useStoreContext();
  const history = useHistory();

  const showLoader = useMemo(() => loading || postingPartialReport, [
    loading,
    postingPartialReport,
  ]);

  // clear unsaved inline changes on browser back button,
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (editMode) {
        onCancel();
      }
      unlisten();
    });
  }, [editMode, history, onCancel]);

  return (
    <div className="card">
      <div className="card__header">
        <div className="card__title">
          <span>
            {vesselGroup && (
              <div
                style={{
                  background: getVesselColor(vesselGroup),
                  position: 'relative',
                  top: '0px',
                  marginRight: '12px',
                }}
                className="key-findings__legend"
              />
            )}
            {title}
          </span>
        </div>
        {!editMode && (
          <span
            className={cn('card__label', {
              card__label__detailed_header: headerType === 'detailed',
              card__label__edited: !aiAssessed,
            })}
          >
            {aiAssessed ? 'AI ASSESSED' : 'EDITED'}
          </span>
        )}

        {showLoader && <Loader inline />}
        {!showLoader && (
          <>
            <div className="card__editable">
              <button
                className="card__button"
                onClick={onClick}
                disabled={editMode}
              >
                <EditReportIcon />
              </button>
            </div>
            {headerType === 'detailed' && <></>}
          </>
        )}
      </div>
      {children}
      {editMode && (
        <div className="card__edit-mode">
          <Button inline theme="secondary" onClick={onCancel}>
            <span className="card__cancel">Cancel</span>
          </Button>
          <Button
            inline
            theme="primary"
            disabled={!saveEnabled}
            onClick={onSave}
          >
            <span className="card__save">
              {' '}
              {editingReport ? 'Save All' : 'Save'}
            </span>
          </Button>
        </div>
      )}
    </div>
  );
}
