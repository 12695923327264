import React, { useContext, useMemo } from 'react';
import { AUTH_BASE_USER, AUTH_USER_GROUP } from '../config';
import { createUserFromCognitoData } from '../utils/auth';
import { UserContextValue } from './types';
import { useClientConfig } from '../hooks/use-client-config';

export const UserContext = React.createContext<UserContextValue>({
  user: AUTH_BASE_USER,
  authData: null,
});

UserContext.displayName = 'UserContext';

export function UserProvider({ authData, ...props }: any) {

  // We create and use our own user object with extracted CognitoUser properties
  const user = useMemo(() => createUserFromCognitoData(authData), [authData]);

  // Will fetch the client config whenever authData changes and is non-null
  // Don't call endpoint if user is auditor
  const isAuditor = user.groups?.includes(AUTH_USER_GROUP.auditors);
  const clientConfig = useClientConfig(authData, isAuditor);

  // Additionally, we make the `authData` that comes in from the Authenticator available.
  const userContext: UserContextValue = useMemo(
    () => ({ user, authData, clientConfig }),
    [user, authData, clientConfig]
  );
  return <UserContext.Provider value={userContext} {...props} />;
}

export function useUserContext(): UserContextValue {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider.');
  }
  return context;
}
