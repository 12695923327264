import { uuidv4 } from '../../utils/shared';
import * as PIXI from 'pixi.js-legacy';
import {
  CreateRulerSpriteInterface,
  MouseMoveRulerInterface,
  MouseDownRulerInterface,
  MouseUpRulerInterface,
  MeasurementGraphics,
  HandlePosition,
} from './types';
import {
  createMarkerSprite,
  drawDashedLine,
  drawPerpendicularLine,
  getPerpendicularLine,
  drawBothCrosshairs,
  drawHandle,
  makingLineInteractive,
  lineStyle,
  MEASUREMENT_SETTINGS,
  drawCrosshair,
} from './utils';
const RULER_DRAGGING_STATES = ['new', 'moving', 'endPoint', 'startPoint'];

/**
 * createRulerSprite
 * @param parent {parent: MEASUREMENT_SETTINGS.PARENT.MPR | 'ShortAxis' | 'CTVolume',
 * @param sprite {MeasurementGraphics},
 * @param start {PointObject},
 * @param end {PointObject},
 * @param lineName {string},
 * @param state {string},
 * @param scale {number},
 * @param callback {(event: any) => void},
 * @param markerPositionsRef {[measurementId]:{x: number, y: number,width: number, height: number}}
 * @returns
 */

export const createRulerSprite = ({
  parent,
  sprite,
  start,
  end,
  lineName = MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
  state = MEASUREMENT_SETTINGS.STATES.new,
  scale,
  callback,
  pixelsPerMillimeter,
  cursor = 'move',
}: CreateRulerSpriteInterface) => {
  if (sprite && start) {
    //clean and remove all children from the sprite
    sprite.clear();
    sprite.removeChildren(0, sprite.children.length);
    sprite.lineName = lineName;
    sprite.startPoint = start;
    sprite.endPoint = end;
    sprite.cursor = cursor;
    let measurementId = sprite.measurementId || uuidv4();
    if (!sprite.measurementId) {
      sprite.measurementId = measurementId;
    }
    // Calculate the mm per pixel
    const x = (start.x - end.x) * pixelsPerMillimeter;
    const y = (start.y - end.y) * pixelsPerMillimeter;
    const rulerLength = parseFloat(Math.sqrt(x * x + y * y).toFixed(2));
    // delete ruler if it is too small
    if (state === MEASUREMENT_SETTINGS.STATES.finish && rulerLength < 0.5) {
      sprite.clear();
      sprite.removeChildren(0, sprite.children.length);
      sprite = null;
      return null;
    }

    createMarkerSprite({
      type: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
      sprite,
      label: `${rulerLength} mm`,
      position: end,
      scale,
      state,
    });
    let lineWidth = MEASUREMENT_SETTINGS.WIDTH / scale;
    switch (state) {
      case MEASUREMENT_SETTINGS.STATES.new:
        //line style
        sprite.lineStyle(MEASUREMENT_SETTINGS.WIDTH / scale, 0xffffff);

        drawDashedLine({
          line: sprite,
          start,
          end,
          lineWidth,
          dashLength: 5 / scale,
        });

        sprite.lineStyle(MEASUREMENT_SETTINGS.WIDTH / scale, 0xffffff);
        lineWidth = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.inactive / scale;
        //draw crosshairs
        drawBothCrosshairs(sprite, scale, start, end);

        break;
      case MEASUREMENT_SETTINGS.STATES.moving:
      case MEASUREMENT_SETTINGS.STATES.active:
        //line style
        sprite.lineStyle(MEASUREMENT_SETTINGS.WIDTH / scale, 0xffffff);
        drawDashedLine({
          line: sprite,
          start,
          end,
          lineWidth,
          dashLength: 5 / scale,
        });

        // draw Inactive crosshairs for ruler moving
        if (lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler) {
          let spriteCrosshair = new PIXI.Graphics() as MeasurementGraphics;
          //crosshair for staring point
          drawCrosshair(spriteCrosshair, start, scale);
          //crosshair for ending point
          drawCrosshair(spriteCrosshair, end, scale);
          sprite.addChild(spriteCrosshair);
        }

        let inactivePoint = end;
        let activePoint = start;
        if (lineName === HandlePosition.End) {
          inactivePoint = start;
          activePoint = end;
        }
        if (
          lineName === HandlePosition.End ||
          lineName === HandlePosition.Start
        ) {
          //draw crosshairs
          drawBothCrosshairs(sprite, scale, inactivePoint, activePoint);
        }

        lineWidth = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.active / scale;
        let HandlePointPosition = getPerpendicularLine(
          [end, end],
          lineWidth,
          180
        );
        drawHandle(
          sprite,
          HandlePosition.End,
          HandlePointPosition,
          scale,
          callback || (() => {})
        );
        HandlePointPosition = getPerpendicularLine(
          [start, start],
          lineWidth,
          180
        );
        drawHandle(
          sprite,
          HandlePosition.Start,
          HandlePointPosition,
          scale,
          callback || (() => {})
        );
        //draw the hit area to interact with the line
        makingLineInteractive(sprite, start, end, scale);

        break;

      case MEASUREMENT_SETTINGS.STATES.finish:
        //line style
        sprite.lineStyle(MEASUREMENT_SETTINGS.WIDTH / scale, 0x000000);
        let perpLineStart, perpLineEnd;
        //draw shadow and line
        for (let i = 0; i < 2; i++) {
          //drwa perpendicular line to the start and end point
          let width = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.active;
          if (i === 0) {
            width += 2;
          }
          width /= scale;
          perpLineStart = getPerpendicularLine([end, start], width);
          perpLineEnd = getPerpendicularLine([start, end], width);
          //line style
          lineStyle(sprite, i === 0, scale);
          //draw perpendicular line to the start and end point
          drawPerpendicularLine(sprite, perpLineEnd);
          drawPerpendicularLine(sprite, perpLineStart);
          //draw line
          sprite.moveTo(start.x, start.y);
          sprite.lineTo(end.x, end.y);

          if (i > 0) {
            drawHandle(
              sprite,
              HandlePosition.End,
              perpLineEnd,
              scale,
              callback
            );
            drawHandle(
              sprite,
              HandlePosition.Start,
              perpLineStart,
              scale,
              callback
            );
          }
        }

        //draw the hit area to interact with the line
        makingLineInteractive(sprite, start, end, scale);
        break;
    }
    return sprite;
  }

  return null;
};

export const onMouseUpRuler = ({
  isDraggingMeasurementToolRef,
  measurementSpriteRef,
  measurementTargetRef,
  start,
  end,
  scale,
  callbackMouseDownStraightLine,
  pixelsPerMillimeter,
}: MouseUpRulerInterface) => {
  // change dashed line to solid line
  if (
    isDraggingMeasurementToolRef &&
    RULER_DRAGGING_STATES.includes(isDraggingMeasurementToolRef)
  ) {
    let sprite = measurementSpriteRef;
    if (
      measurementTargetRef &&
      (isDraggingMeasurementToolRef ===
        MEASUREMENT_SETTINGS.RULER_STATE.HandleEnd ||
        isDraggingMeasurementToolRef ===
          MEASUREMENT_SETTINGS.RULER_STATE.HandleStart ||
        isDraggingMeasurementToolRef ===
          MEASUREMENT_SETTINGS.RULER_STATE.Moving)
    ) {
      sprite = measurementTargetRef;
      start = measurementTargetRef.startPoint;
      end = measurementTargetRef.endPoint;
      measurementTargetRef.position.set(0, 0);
    }
    sprite = createRulerSprite({
      parent: MEASUREMENT_SETTINGS.PARENT.MPR,
      sprite,
      start,
      end,
      lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
      state: MEASUREMENT_SETTINGS.STATES.finish,
      scale,
      pixelsPerMillimeter,
    });
    if (
      isDraggingMeasurementToolRef === MEASUREMENT_SETTINGS.RULER_STATE.New &&
      sprite
    ) {
      sprite.on('mousedown', callbackMouseDownStraightLine);
    }
    return {
      measurementToolStartPoint: null,
      isDraggingMeasurementToolRef: undefined,
      measurementTargetRef: null,
      handleTargetNameRef: '',
      isClickDownMeasurementToolRef: false,
    };
  }

  return {
    isClickDownMeasurementToolRef: false,
  };
};

export const onMouseDownRuler = ({
  isClickDownMeasurementToolRef,
  isDraggingMeasurementToolRef,
  measurementTargetRef,
  scale,
  btnClicked,
  pixelsPerMillimeter,
}: MouseDownRulerInterface) => {
  if (measurementTargetRef && !isClickDownMeasurementToolRef) {
    if (measurementTargetRef.startPoint && measurementTargetRef.endPoint) {
      createRulerSprite({
        parent: MEASUREMENT_SETTINGS.PARENT.MPR,
        sprite: measurementTargetRef,
        start: measurementTargetRef.startPoint,
        end: measurementTargetRef.endPoint,
        lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
        state: MEASUREMENT_SETTINGS.STATES.finish,
        scale,
        pixelsPerMillimeter,
      });
    }
    return {
      measurementTargetRef: null,
      handleTargetNameRef: '',
      isClickDownMeasurementToolRef: true,
      isDraggingMeasurementToolRef: MEASUREMENT_SETTINGS.RULER_STATE.New,
    };
  } else if (!isDraggingMeasurementToolRef && btnClicked === 1) {
    return {
      isClickDownMeasurementToolRef: true,
      isDraggingMeasurementToolRef: MEASUREMENT_SETTINGS.RULER_STATE.New,
    };
  }
  return null;
};
/**
 *
 * @param isDraggingMeasurementToolRef
 * @param measurementSpriteRef new ruler sprite
 * @param measurementTargetRef current measurement target
 * @param handleTargetNameRef handle anem target
 * @param start
 * @param mousePosition
 * @param scale
 * @param offsetX
 * @param offsetY
 * @param markerPositionsRef {[measurementId]:{x: number, y: number,width: number, height: number}}
 * @returns
 */

export const onMouseMoveRuler = ({
  isDraggingMeasurementToolRef,
  measurementSpriteRef,
  measurementTargetRef,
  handleTargetNameRef,
  start,
  mousePosition,
  scale,
  offsetX,
  offsetY,
  recalculatePixelsToMm,
}: MouseMoveRulerInterface) => {
  switch (isDraggingMeasurementToolRef) {
    case MEASUREMENT_SETTINGS.RULER_STATE.New:
      const spriteRuler = createRulerSprite({
        parent: MEASUREMENT_SETTINGS.PARENT.MPR,
        sprite: measurementSpriteRef,
        start,
        end: mousePosition,
        lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
        state: MEASUREMENT_SETTINGS.STATES.new,
        scale,
        pixelsPerMillimeter: recalculatePixelsToMm(),
        cursor: 'none',
      });
      return spriteRuler;
    case MEASUREMENT_SETTINGS.RULER_STATE.Handle:
    case MEASUREMENT_SETTINGS.RULER_STATE.HandleEnd:
    case MEASUREMENT_SETTINGS.RULER_STATE.HandleStart:
      if (measurementTargetRef) {
        const startPoint = {
          x:
            handleTargetNameRef &&
            MEASUREMENT_SETTINGS.RULER_STATE.HandleStart.includes(
              handleTargetNameRef
            )
              ? mousePosition.x
              : measurementTargetRef.startPoint.x,
          y:
            handleTargetNameRef &&
            MEASUREMENT_SETTINGS.RULER_STATE.HandleStart.includes(
              handleTargetNameRef
            )
              ? mousePosition.y
              : measurementTargetRef.startPoint.y,
        };
        const endPoint = {
          x:
            handleTargetNameRef &&
            MEASUREMENT_SETTINGS.RULER_STATE.HandleEnd.includes(
              handleTargetNameRef
            )
              ? mousePosition.x
              : measurementTargetRef.endPoint.x,
          y:
            handleTargetNameRef &&
            MEASUREMENT_SETTINGS.RULER_STATE.HandleEnd.includes(
              handleTargetNameRef
            )
              ? mousePosition.y
              : measurementTargetRef.endPoint.y,
        };
        createRulerSprite({
          parent: MEASUREMENT_SETTINGS.PARENT.MPR,
          sprite: measurementTargetRef,
          start: startPoint,
          end: endPoint,
          lineName: handleTargetNameRef || HandlePosition.End,
          state: MEASUREMENT_SETTINGS.STATES.moving,
          scale,
          pixelsPerMillimeter: recalculatePixelsToMm(),
          cursor: 'none',
        });
      }
      if (handleTargetNameRef === HandlePosition.Start)
        isDraggingMeasurementToolRef =
          MEASUREMENT_SETTINGS.RULER_STATE.HandleStart;
      else if (handleTargetNameRef === HandlePosition.End)
        isDraggingMeasurementToolRef =
          MEASUREMENT_SETTINGS.RULER_STATE.HandleEnd;
      return {
        handleTargetNameRef,
        measurementTargetRef,
        isDraggingMeasurementToolRef,
      };
    case MEASUREMENT_SETTINGS.RULER_STATE.Active:
    case MEASUREMENT_SETTINGS.RULER_STATE.Moving:
      if (
        measurementTargetRef &&
        measurementTargetRef.startPoint &&
        measurementTargetRef.endPoint
      ) {
        let { startPoint, endPoint, lineName } = measurementTargetRef;
        startPoint.x = measurementTargetRef.startPoint.x += offsetX / scale;
        startPoint.y = measurementTargetRef.startPoint.y += offsetY / scale;
        endPoint.x = measurementTargetRef.endPoint.x += offsetX / scale;
        endPoint.y = measurementTargetRef.endPoint.y += offsetY / scale;

        createRulerSprite({
          parent: MEASUREMENT_SETTINGS.PARENT.MPR,
          sprite: measurementTargetRef,
          start: startPoint,
          end: endPoint,
          lineName,
          state: MEASUREMENT_SETTINGS.STATES.moving,
          scale: scale,
          pixelsPerMillimeter: recalculatePixelsToMm(),
        });

        isDraggingMeasurementToolRef = MEASUREMENT_SETTINGS.RULER_STATE.Moving;
      }
      return { isDraggingMeasurementToolRef };
  }
  return null;
};
