/**
 * Type used for Async calls in Reducers. Particularly useful in Redux Thunks so
 * we know the status as an operation (e.g. a fetch/post being in flight)
 *
 * @param T - the type of data to put in the store
 * @param E - the type of error to put in the store
 */
export interface AsyncResource<T, E> {
  data: T | undefined;
  status: 'idle' | 'failed' | 'fetching';
  error?: E;
}

/**
 * Creates the default AsyncResource state
 */
export function getDefaultAsyncResourceState<T, E>(): AsyncResource<T, E> {
  return {
    data: undefined,
    status: 'idle',
    error: undefined,
  };
}

/**
 * Helper function to be called on the 'thunk.fulfilled' case under 'extraReducers'
 *
 * @param state - the state to modify
 * @param data - will be assigned to state.data
 *
 * Will set state.status to 'idle' and clear out state.error
 */
export function onThunkFulfilled<T, E>({
  state,
  data,
}: ThunkFulfilledPayload<T, E>) {
  state.data = data;
  state.status = 'idle';
  state.error = undefined;
}

/**
 * Helper function to be called on the 'thunk.pending' case under 'extraReducers'
 *
 * @param state - the state to modify
 * @param clearDataOnFetch - state.data will be cleared if this is true
 *
 * Will set state.status to 'fetching' and clear out state.error
 */
export function onThunkPending<T, E>({
  state,
  clearDataOnFetch,
}: ThunkPendingPayload<T, E>) {
  if (clearDataOnFetch) {
    state.data = undefined;
  }
  state.status = 'fetching';
  state.error = undefined;
}

/**
 * Helper function to be called on the 'thunk.rejected' case under 'extraReducers'
 *
 * @param state - the state to modify
 * @param error - will be assigned to state.error
 * @param clearDataOnFailure - state.data will be cleared if this is true
 *
 * Will set state.status to 'failed'
 */
export function onThunkRejected<T, E>({
  state,
  error,
  clearDataOnFailure,
}: ThunkRejectedPayload<T, E>) {
  if (clearDataOnFailure) {
    state.data = undefined;
  }
  state.status = 'failed';
  state.error = error;
}

export interface ThunkFulfilledPayload<T, E> {
  state: AsyncResource<T, E>;
  data: T;
}

export interface ThunkPendingPayload<T, E> {
  state: AsyncResource<T, E>;
  clearDataOnFetch: boolean;
}

export interface ThunkRejectedPayload<T, E> {
  state: AsyncResource<T, E>;
  error: E;
  clearDataOnFailure: boolean;
}
