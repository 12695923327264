import React, { ReactElement, useCallback } from 'react';
import { ReactComponent as ScreenshotIcon } from '../../assets/icons/screenshot.svg';
import { isCanvas } from '../../utils/shared';
import cn from 'classnames';

interface Props {
  screenshotRef: any;
  onRequestScreenshot: any;
  onTakeScreenshot: any;
  hideText?: boolean;
  altIcon?: boolean;
  headerStyle?: boolean;
  disabled?: boolean;
}

export default function AnnotationButton({
  screenshotRef,
  onRequestScreenshot,
  onTakeScreenshot,
  hideText = true,
  altIcon = false,
  headerStyle = false,
  disabled = false,
}: Props): ReactElement<Props> {
  const takeScreenshot = useCallback(() => {
    if (!screenshotRef || !screenshotRef.current) {
      if (onRequestScreenshot) {
        onRequestScreenshot(onTakeScreenshot);
      }
      return;
    }

    const canvas = isCanvas(screenshotRef.current)
      ? screenshotRef.current
      : screenshotRef.current.querySelector('canvas');

    if (!canvas || !canvas.toDataURL) {
      console.error(
        'Error saving screenshot. The provided ref is not an instance of konva stage, nor is it a canvas or an element that contains a canvas.'
      );
      return;
    }

    const imageData = canvas.toDataURL();
    onTakeScreenshot && onTakeScreenshot(imageData);
  }, [onTakeScreenshot, onRequestScreenshot, screenshotRef]);

  return (
    <div className="annotation-modal-action">
      <button
        className={cn('screenshot-button', {
          alternative: altIcon,
          header: headerStyle,
        })}
        onClick={() => takeScreenshot()}
        title="Show screenshot to annotate"
        disabled={disabled}
      >
        <span className="screenshot-button__icon">
          <ScreenshotIcon />
        </span>
        {!hideText && (
          <span className="screenshot-button__text">Add screenshot</span>
        )}
      </button>
    </div>
  );
}
