import cn from 'classnames';
import React, { ReactElement } from 'react';
import Select from '../Select/Select';

interface Props {
  editMode: boolean;
  title: string;
  value: string | undefined;
  measureType?: string | null;
  options: any[];
  customClass?: string;
  onChange: (value: string) => void;
}

const transformToStringOrDash = (value: string | number | undefined) => {
  return value?.toString() && value.toString().length > 0 ? value : '-';
};

const RowWithDropDown = ({
  editMode,
  options,
  title,
  value,
  customClass = '',
  measureType,
  onChange,
}: Props): ReactElement<Props> => {
  return (
    <>
      <div
        className={cn('procedure-details__data-cell', customClass, {
          'procedure-details__data-cell-edit-mode': editMode,
        })}
      >
        <dd className="procedure_details__data-name">{title}</dd>
      </div>

      <div
        className={cn('procedure-details__data-cell', {
          'procedure-details__data-cell-edit-mode': editMode,
        })}
      >
        {editMode && (
          <Select
            addPlaceholderAsOption={true}
            options={options}
            value={value}
            onChange={onChange}
            disabled={!editMode}
          />
        )}
        {!editMode && (
          <dd className="procedure-details__data-value">
            {transformToStringOrDash(value ?? undefined)}
          </dd>
        )}
      </div>
    </>
  );
};

export default RowWithDropDown;
