import Moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useHistory, useParams } from 'react-router-dom';
import Card from '../../components/Card';
import EditableTextField from '../../components/EditableTextField';
import InlineStats from '../../components/InlineStats';
import { KeyVesselFindings } from '../../components/KeyVesselFindings/KeyVesselFindings';
import { Loader } from '../../components/Loader/Loader';
import { OtherFindings } from '../../components/OtherFindings/OtherFindings';
import PageTitle from '../../components/PageTitle/PageTitle';
import { PatientDetails } from '../../components/PatientDetails/PatientDetails';
import ProcedureDetails from '../../components/ProcedureDetails';
import { ReportStatus } from '../../components/ReportStatus/ReportStatus';
import { Screenshots } from '../../components/Screenshots/Screenshots';
import showToast from '../../components/Toast/showToast';
import { BASE_KEY_3D_MODEL, NAV_TABS, VesselGroupings } from '../../config';
import { useReportContext } from '../../context/report-context';
import { useStoreContext } from '../../context/store-context';
import { VesselToReportNameMapping } from '../../context/types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useDeleteScreenshot } from '../../hooks/use-delete-screenshot';
import useStudyTabVisibility from '../../hooks/use-study-tab-visibility';
import { useFetchReviewUsers } from '../../hooks/use-workflow-helpers';
import { useSaveCallBack } from '../../hooks/useSaveCallBack';
import { fetchStudyById } from '../../reducers/study';
import {
  useClinicalIndications,
  useCoronaryFindingsSelector,
  useDraftClinicalIndications,
  useDraftCoronaryFindingsSelector,
  useDraftHeartDominanceSelector,
  useDraftImpressionsSelector,
  useDraftProcedureDetails,
  useExtraCardiacFindings,
  useHeartDominanceSelector,
  useImpressionIsAiAssessedSelector,
  useImpressionsSelector,
  useProcedureDetails,
  useStudyStats,
} from '../../selectors/reporting';
import { currentStudySelector } from '../../selectors/study';
import { RouteParams } from '../../types';
import * as api from '../../utils/api';

const NON_CORONARY_FINDINGS_QUESTION =
  'Any significant non-coronary abnormality identified within the scanned field of view?';
const NON_CORONARY_FINDINGS_DEFAULT_ANSWER =
  'No significant non-coronary abnormality was identified within the scanned field of view';
const EXTRA_CARDIAC_FINDINGS_QUESTION =
  'Any significant extra-cardiac abnormality identified within the scanned field of view?';
const EXTRA_CARDIAC_FINDINGS_DEFAULT_ANSWER =
  'No significant extra-cardiac abnormality was identified within the scanned field of view';

const Report: React.FC = () => {
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  const {
    patientID,
    runID,
    modelImage,
    currentReport,
    patientStats,
    updateDraftReport,
    clearDraftReport,
    draftReport,
    postingReport,
    editingImpressions,
    editingCoronaryFindings,
    setEditingImpressions,
    setEditingCoronaryFindings,
    editingReport,
    setEditingReport,
    studyData,
    setReportHistory,
    setReviewList,
    fetchingReport,
  } = useStoreContext();

  const selectedStudy = useAppSelector(currentStudySelector);
  const dispatch = useAppDispatch();

  const updateDraftImpression = useCallback(
    (value: string) => {
      updateDraftReport({
        impression: value.trim().length === 0 ? [] : value.split('\n'),
      });
    },
    [updateDraftReport]
  );

  const updateDraftClinicalIndication = useCallback(
    (value: string) => {
      updateDraftReport({ clinical_indication: value });
    },
    [updateDraftReport]
  );

  const procedureDetails = useProcedureDetails();
  const draftProcedureDetails = useDraftProcedureDetails();
  const {
    screenshots = [],
    decryptedStudy,
    deletingScreenshot,
    setApproved,
  } = useReportContext();

  const extraCardiacFindings = useExtraCardiacFindings();

  const { getStyles } = useStudyTabVisibility(NAV_TABS.reportReviewTab);

  const [canApproveReport, setCanApproveReport] = useState(true);

  const coronaryFindings = useCoronaryFindingsSelector();
  const heartDominance = useHeartDominanceSelector();
  const [pdfData, setPdfData] = useState<any | null>(null);

  const [approving, setApproving] = useState(false);
  const [generating, setGenerating] = useState(false);

  const impressionAiAssessed = useImpressionIsAiAssessedSelector();
  const impressions = useImpressionsSelector();
  const clinicalIndication = useClinicalIndications();
  const draftClinicalIndication = useDraftClinicalIndications();
  const draftImpressions = useDraftImpressionsSelector();
  const draftCoronaryFindings = useDraftCoronaryFindingsSelector();
  const draftHeartDominance = useDraftHeartDominanceSelector();
  const studyStats = useStudyStats();
  // Highlight summary and impression area when one of the findings has been edited and is in focus
  const [
    highlightKeyVesselRelatedFields,
    setHighlightKeyVesselRelatedFields,
  ] = useState(false);

  const gender = useMemo(() => {
    switch (studyData?.patient_data.sex) {
      case 'M':
        return 'Male';
      case 'F':
        return 'Female';
      case 'O':
        return 'Other';
      default:
        return 'Unknown';
    }
  }, [studyData?.patient_data.sex]);

  const referringDoctor = useMemo(() => {
    if (
      draftReport?.referring_doctor &&
      draftReport.referring_doctor.length > 0
    ) {
      return draftReport.referring_doctor;
    }

    if (
      currentReport?.referring_doctor &&
      currentReport.referring_doctor.length > 0
    ) {
      return currentReport.referring_doctor;
    }

    return selectedStudy?.referring_doctor;
  }, [
    currentReport?.referring_doctor,
    draftReport?.referring_doctor,
    selectedStudy?.referring_doctor,
  ]);

  const fetchReviewUsers = useFetchReviewUsers();

  useEffect(() => {
    fetchReviewUsers();
  }, [fetchReviewUsers]);

  useEffect(() => {
    const screenshotsUrls =
      screenshots &&
      screenshots
        .filter((s: any) => !s.title.includes(BASE_KEY_3D_MODEL))
        .map(({ title, path }: any) => {
          return {
            path: path,
            title: title,
          };
        });

    setPdfData({
      patient_name: decryptedStudy ? decryptedStudy['patient_name'] : '',
      patient_id: decryptedStudy ? decryptedStudy['patient_id'] : '',
      referring_physician: referringDoctor,
      birth_date: decryptedStudy ? decryptedStudy['date_of_birth'] : '',
      gender,
      heart_dominance: heartDominance.heart_dominance,
      requested_procedure: 'CT Coronary Angiogram',
      calcium_score: currentReport?.calcium_score ?? 'N/A',
      maximum_stenosis: currentReport?.maximum_stenosis ?? '',
      vulnerable_plaque: currentReport?.vulnerable_plaque ?? '',
      cad_rads: currentReport?.cad_rads ?? '',
      impressions,
      vessel_model_image: modelImage,
      coronary_findings: coronaryFindings,
      other_findings:
        currentReport?.extra_cardiac_findings ??
        EXTRA_CARDIAC_FINDINGS_DEFAULT_ANSWER,
      screenshots: screenshotsUrls,
    });
  }, [
    coronaryFindings,
    decryptedStudy,
    impressions,
    modelImage,
    currentReport?.extra_cardiac_findings,
    currentReport?.cad_rads,
    currentReport?.calcium_score,
    currentReport?.maximum_stenosis,
    currentReport?.referring_doctor,
    currentReport?.vulnerable_plaque,
    heartDominance.heart_dominance,
    screenshots,
    studyStats,
    patientStats,
    gender,
    referringDoctor,
  ]);

  const handleUpdateKeyFindings = useCallback(
    (newValue: string, vessel: VesselGroupings) => {
      const reportNaming = VesselToReportNameMapping[vessel];
      updateDraftReport({
        [reportNaming]: newValue,
      });
    },
    [updateDraftReport]
  );
  const handleUpdateHeartDominance = useCallback(
    (value: string | null) => {
      updateDraftReport({
        heart_dominance: value ? value.replace(' dominant', '') : value,
      });
    },
    [updateDraftReport]
  );
  const handleEditReport = useCallback(() => {
    setEditingReport(true);
    updateDraftReport({ ...currentReport });
  }, [updateDraftReport, setEditingReport, currentReport]);

  const handleRevertImpressions = useCallback(() => {
    clearDraftReport();
  }, [clearDraftReport]);

  const handleCancelReport = useCallback(() => {
    setEditingReport(false);
    if (editingCoronaryFindings) {
      setEditingCoronaryFindings(false);
    }
    if (editingImpressions) {
      setEditingImpressions(false);
    }
    clearDraftReport();
  }, [
    setEditingReport,
    clearDraftReport,
    editingImpressions,
    editingCoronaryFindings,
    setEditingImpressions,
    setEditingCoronaryFindings,
  ]);

  const handleSaveReport = useSaveCallBack();

  const handleDeleteScreenshot = useDeleteScreenshot();

  // Make sure we cancel report edits and reset the
  // draft report when navigating away from the page
  // using the browser back / forward buttons or the
  // adress bar directly
  useEffect(() => {
    const unlisten = history.listen(() => {
      handleCancelReport();
      unlisten();
    });
  }, [history, id, handleCancelReport]);

  // Generate report PDF
  const handleGenerateREport = async () => {
    setGenerating(true);
    try {
      const date = Moment().format('DDMMyyyyHHmmss');
      const fileName = patientID + '-report-' + date + '.pdf';
      const pdfSuccess = await api.generatePDF(
        `/data/${patientID}/${runID}/report/generate`,
        pdfData,
        true,
        fileName
      );

      if (!pdfSuccess) {
        showToast.error('Failed to generate report');
      } else {
        showToast.success('Report generated successfully');
      }
      setGenerating(false);
    } catch (err) {
      setGenerating(false);
      showToast.error('Failed to generate report');
    }
  };

  // Approving report and generating PDF
  const handleApprove = useCallback(async () => {
    if (!runID || !id) return;

    setApproving(true);
    try {
      const response = await api.postJSON(
        `/data/${patientID}/${runID}/report/approve`,
        {}
      );
      if (response && response.success) {
        const promises = Promise.all([
          dispatch(fetchStudyById(id)),
          api.fetchReportHistory(decryptedStudy.study_id, runID),
          api.fetchReviewList(decryptedStudy.study_id, runID),
        ]);
        const [, history, reviewList] = await promises;

        setReportHistory(history);
        setReviewList(reviewList);

        setApproved(true);

        showToast.success(`Analysis approved for patient '${patientID}'`);
      } else {
        showToast.error('Failed to approve analysis');
      }
      setApproving(false);
    } catch (err) {
      setApproving(false);
      showToast.error('Failed to approve analysis');
    }
  }, [
    id,
    decryptedStudy?.study_id,
    patientID,
    runID,
    setApproved,
    dispatch,
    setReportHistory,
    setReviewList,
  ]);

  return (
    <>
      {postingReport && <Loader fullScreen large text="Saving Report" />}
      <PageTitle title={`Report for ${id}`} />
      <div
        className={cn('page-report', {
          'page-report-edit-mode': editingReport,
        })}
        style={getStyles()}
      >
        <div className="page-report__container">
          <div className="page-report__patient-details">
            <PatientDetails
              id={selectedStudy?.patient_id ?? patientID}
              name={selectedStudy?.patient_name ?? patientID}
              birthDate={selectedStudy?.date_of_birth}
              doctor={referringDoctor}
              age={studyData?.patient_data.age}
              editMode={editingReport}
            />
          </div>
          <div className="page-report__clinical-indication">
            <Card title="Clinical Indication">
              <EditableTextField
                editMode={editingReport}
                text={[clinicalIndication]}
                updateDraftText={updateDraftClinicalIndication}
                draftText={draftClinicalIndication}
              />
            </Card>
          </div>
          {procedureDetails && (
            <div className="page-report__procedure-details">
              <ProcedureDetails
                editMode={editingReport}
                procedureDetails={procedureDetails}
                draftProcedureDetails={draftProcedureDetails}
                updateDraftReport={updateDraftReport}
              />
            </div>
          )}
          <div className="page-report__stats">
            <InlineStats
              editingReport={editingReport}
              highlightKeyVesselRelatedFields={highlightKeyVesselRelatedFields}
            />
          </div>
          <div className="page-report__impressions">
            <Card
              cardHighlited={highlightKeyVesselRelatedFields}
              title="Impression"
              statusVersion={!impressionAiAssessed}
              onRevertFindings={handleRevertImpressions}
            >
              <EditableTextField
                editMode={editingReport}
                text={
                  extraCardiacFindings.length > 0
                    ? [...impressions, extraCardiacFindings]
                    : impressions
                }
                updateDraftText={updateDraftImpression}
                draftText={draftImpressions}
              />
            </Card>
          </div>
          <div className="page-report__coronary-findings">
            <Card
              cardHighlited={false}
              title="Key Coronary Findings"
              style={{ height: '100%' }}
            >
              <KeyVesselFindings
                draftHeartDominance={draftHeartDominance}
                heartDominance={heartDominance}
                onUpdateHeartDominance={handleUpdateHeartDominance}
                coronaryFindings={coronaryFindings}
                draftCoronaryFindings={draftCoronaryFindings}
                editFindings={editingReport}
                onUpdateKeyFindings={handleUpdateKeyFindings}
                onActiveDirtyField={setHighlightKeyVesselRelatedFields}
              />
            </Card>
          </div>
          <div className="page-report__non-coronary-cardiac-findings">
            <Card cardHighlited={false} title="Non-Coronary Cardiac Findings">
              <OtherFindings
                question={NON_CORONARY_FINDINGS_QUESTION}
                defaultAnswer={NON_CORONARY_FINDINGS_DEFAULT_ANSWER}
                answer={currentReport?.non_coronary_findings ?? ''}
                draftAnswer={draftReport?.non_coronary_findings ?? ''}
                onAnswered={setCanApproveReport}
                onUpdateOtherFindings={(value: string) => {
                  updateDraftReport({ non_coronary_findings: value });
                }}
                editMode={editingReport}
              />
            </Card>
          </div>
          <div className="page-report__extra-cardiac-findings">
            <Card cardHighlited={false} title="Extra-Cardiac Findings">
              <OtherFindings
                question={EXTRA_CARDIAC_FINDINGS_QUESTION}
                defaultAnswer={EXTRA_CARDIAC_FINDINGS_DEFAULT_ANSWER}
                answer={currentReport?.extra_cardiac_findings ?? ''}
                draftAnswer={draftReport?.extra_cardiac_findings ?? ''}
                onAnswered={setCanApproveReport}
                onUpdateOtherFindings={(value: string) => {
                  updateDraftReport({ extra_cardiac_findings: value });
                }}
                editMode={editingReport}
              />
            </Card>
          </div>
          <div className="page-report__right-column">
            <Card>
              <ReportStatus
                canApprove={canApproveReport}
                editMode={editingReport}
                onEditReport={handleEditReport}
                onCancelEdit={handleCancelReport}
                onSaveFindings={handleSaveReport}
                onApproving={handleApprove}
                onGenerate={handleGenerateREport}
                isApproving={approving}
                isGenerating={generating}
              />
            </Card>
            <Card
              title="Screenshots"
              // small padding on the right for the scroll bar
              style={{ paddingRight: '6px' }}
            >
              <Screenshots
                screenshots={screenshots}
                onDelete={handleDeleteScreenshot}
                deleting={deletingScreenshot}
              />
            </Card>
          </div>
        </div>
        {fetchingReport && !postingReport && (
          <Loader large background text="Fetching report" />
        )}
      </div>
    </>
  );
};

export default Report;
