import { History } from 'history';
import showToast from '../components/Toast/showToast';
import * as api from './api';

type LockResponse = {
  detail: string;
  lock_data: {
    id: string;
    timestamp: string;
    user: string;
  };
};

type LockResult = {
  studyId: string;
  success: boolean;
  didRelock: boolean;
};

const LOCK_ID_KEY_PREFIX = 'lockId_';

/**
 * Generates the key used to store lock ids within sessionStorage
 */
function generateLockIdKey(studyId: string): string {
  return LOCK_ID_KEY_PREFIX + studyId;
}

/**
 * Extracts the studyId from a given lockIdKey
 * Returns null if the given lockIdKey does not contain a studyId
 */
function extractStudyId(lockIdKey: string): string | null {
  if (lockIdKey.startsWith(LOCK_ID_KEY_PREFIX)) {
    return lockIdKey.replace(LOCK_ID_KEY_PREFIX, '');
  }

  return null;
}

/**
 * Obtains a lock on the given study. If ANY error occurs redirects the user to /
 * Will either call lock or relock, based on whether an existing lockId is found or not
 */
export async function lockStudy(
  studyId: string,
  history: History
): Promise<LockResult> {
  let lockId = sessionStorage.getItem(generateLockIdKey(studyId));
  let success = false;
  let didRelock = false;
  let errorMsg = 'Could not lock study';

  // include the lock_id if we have one
  let lock_payload = {};
  if (lockId) {
    lock_payload = { lock_id: lockId };
  }
  try {
    let lockResponse: LockResponse = await api.postJSON(
      `dashboard/${studyId}/locking/lock`,
      lock_payload
    );
    success = lockResponse.detail === 'success';
    if (success) {
      sessionStorage.setItem(
        generateLockIdKey(studyId),
        lockResponse.lock_data.id
      );
    } else {
      showToast.error(errorMsg);
      history.push('/');
    }
  } catch (error) {
    console.error(`Error locking study: ${error}`);
    if (error instanceof api.APIError && error.response.status === 403) {
      errorMsg = error.response_body.detail;
    }
    showToast.error(errorMsg);
    history.push('/');
  }

  return { studyId, success, didRelock };
}

/**
 * Unlocks the current study. Simply logs out any errors
 */
export async function unlockStudy(studyId: string): Promise<boolean> {
  let lockId = sessionStorage.getItem(generateLockIdKey(studyId));
  let success = false;

  if (lockId) {
    // remove the key from storage first to avoid multiple calls to unlock
    sessionStorage.removeItem(generateLockIdKey(studyId));
    try {
      let lockResponse: LockResponse = await api.postJSON(
        `dashboard/${studyId}/locking/unlock`,
        { lock_id: lockId }
      );
      success = lockResponse.detail === 'success';
    } catch (error) {
      console.error(`Error unlocking study: ${error}`);
    }
  } else {
    console.warn(`Called unlock study without a set lock_id`);
  }

  return success;
}

/**
 * Unlocks ALL locked studies, based on the presence of a lockId within sessionStorage
 */
export async function unlockAllStudies(): Promise<void> {
  let promises: Array<Promise<boolean>> = [];

  Object.keys(sessionStorage).forEach((key) => {
    let studyId = extractStudyId(key);
    if (studyId) {
      promises.push(unlockStudy(studyId));
    }
  });

  Promise.all(promises);
}
