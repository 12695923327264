import React, { Component, ErrorInfo, ReactNode } from 'react';
import Button from '../Button/Button';
import { ReactComponent as AnalysisFailed } from '../../assets/icons/analysis-failed.svg';
import * as Sentry from '@sentry/react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    Sentry.captureException(`${error}\n${errorInfo}`);
  }

  public reloadPage() {
    window.location.reload();
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <div className="error">
            <>
              <div className="error__panel">
                <div className="error__panel-icon-wrapper">
                  <AnalysisFailed
                    width="32"
                    height="32"
                    className="error__panel-icon"
                  />
                </div>
                <p>
                  <strong>An error has occurred.</strong>
                  <br />
                  Please refresh the page.
                </p>
                <Button onClick={this.reloadPage}>Refresh</Button>
              </div>
            </>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
