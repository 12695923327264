import { useEffect, useRef } from 'react';

export default function useWindowResize(
  handleResize: () => void,
  debounce: number
) {
  const debounceRef = useRef<any>();

  useEffect(() => {
    const resize = () => {
      if (debounce) {
        if (debounceRef.current) {
          clearTimeout(debounceRef.current);
        }
        debounceRef.current = setTimeout(handleResize, debounce);
      } else {
        handleResize();
      }
    };
    window.addEventListener('resize', resize);

    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = undefined;
      window.removeEventListener('resize', resize);
    };
  }, [handleResize, debounce]);
}
