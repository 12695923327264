import React, { ReactElement, useCallback, useMemo } from 'react';
import { InlineReportingActions } from '../../context/inlineReporting/actions';
import { useInlineReportingContext } from '../../context/inlineReporting/context';
import { UnsavedChangesToInlineReport } from '../../context/inlineReporting/types';
import { ActionModal } from '../ActionModal/ActionModal';

export default function InlineReportingWarning(): ReactElement {
  const { state, dispatch } = useInlineReportingContext();

  const onConfirmModal = useCallback(() => {
    dispatch(InlineReportingActions.clearInlineReportingDraftState());
    state.showWarningType?.passthroughAction();
  }, [state.showWarningType, dispatch]);

  const onCloseModal = useCallback(() => {
    dispatch(InlineReportingActions.setUnsavedChangesWarning(undefined));
  }, [dispatch]);

  const headerContent = useMemo(() => {
    switch (state.showWarningType?.type) {
      case UnsavedChangesToInlineReport.EditingReport:
        return 'Edit Report';
      case UnsavedChangesToInlineReport.ApprovingReport:
        return 'Approve Report';
      case UnsavedChangesToInlineReport.GeneratingReport:
        return 'Generate Report';

      case UnsavedChangesToInlineReport.SendingStudyForApproval:
        return 'Send report for review';

      case UnsavedChangesToInlineReport.LeavingStudy:
      default:
        return 'Leaving Report';
    }
  }, [state.showWarningType?.type]);

  const actionType = useMemo(() => {
    switch (state.showWarningType?.type) {
      case UnsavedChangesToInlineReport.EditingReport:
        return 'edit';
      case UnsavedChangesToInlineReport.ApprovingReport:
        return 'approve';
      case UnsavedChangesToInlineReport.GeneratingReport:
        return 'generate';

      case UnsavedChangesToInlineReport.SendingStudyForApproval:
        return 'send';

      case UnsavedChangesToInlineReport.LeavingStudy:
      default:
        return 'leave';
    }
  }, [state.showWarningType?.type]);

  const coronaryFieldsWithEdits = useMemo(() => {
    return [
      state.lad_coronary_findings ? 'LAD' : undefined,
      state.lcx_coronary_findings ? 'LCX' : undefined,
      state.lm_coronary_findings ? 'LM' : undefined,
      state.rca_coronary_findings ? 'RCA' : undefined,
    ].filter((field): field is string => !!field);
  }, [state]);

  const warning = useMemo(() => {
    if (coronaryFieldsWithEdits.length > 0) {
      return `Are you sure you want to ${actionType} the report? Unsaved coronary findings will be discarded on vessels ${coronaryFieldsWithEdits.join(
        ', '
      )}.${
        state.impressions
          ? ' Unsaved impression changes will also be discarded.'
          : ''
      }`;
    } else if (state.impressions) {
      return `Are you sure you want to ${actionType} the report? Unsaved impression changes will be discarded.`;
    } else {
      return undefined;
    }
  }, [coronaryFieldsWithEdits, state.impressions, actionType]);

  return (
    <ActionModal
      confirmText="Discard Changes"
      closeText="Cancel"
      onConfirm={onConfirmModal}
      onClose={onCloseModal}
      visible={state.showWarningType !== undefined}
      headerContent={<>{headerContent}</>}
    >
      <p>{warning}</p>
    </ActionModal>
  );
}
