import React, { ChangeEventHandler, ReactElement, useCallback } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useReportContext } from '../../context/report-context';
import { useStoreContext } from '../../context/store-context';
import { ActionModal } from '../ActionModal/ActionModal';

export default function ReportAmendmentModal(): ReactElement {
  const {
    draftReport,
    updateDraftReport,
    clearDraftReport,
  } = useStoreContext();
  const {
    amendmentModalPassthrough,
    setAmendmentModalPassthrough,
  } = useReportContext();

  const onClose = useCallback(() => {
    setAmendmentModalPassthrough({});
  }, [setAmendmentModalPassthrough]);

  const onMessageChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(
    (event) => {
      updateDraftReport({ update_message: event.target.value });
    },
    [updateDraftReport]
  );

  const onConfirm = useCallback(() => {
    if (amendmentModalPassthrough?.passthrough && draftReport?.update_message) {
      amendmentModalPassthrough.passthrough(draftReport?.update_message);
      clearDraftReport();
    }
  }, [
    amendmentModalPassthrough,
    draftReport?.update_message,
    clearDraftReport,
  ]);

  return (
    <>
      {amendmentModalPassthrough?.passthrough && (
        <ActionModal
          confirmText={'Continue'}
          closeText={'Cancel'}
          onClose={onClose}
          onConfirm={onConfirm}
          visible={true}
          headerContent={<>REASON FOR AMENDMENT</>}
          disabled={!draftReport?.update_message}
        >
          <div className="report-review-modal__comment findings">
            <TextareaAutosize
              maxRows={7}
              minRows={5}
              className="findings__enter"
              placeholder="Provide a reason for why it was necessary to amend the patient report."
              value={draftReport?.update_message ?? ''}
              onChange={onMessageChange}
            />
          </div>
        </ActionModal>
      )}
    </>
  );
}
