import React, { useEffect, useState, useCallback } from 'react';
import SettingsConfig from '../../settings-config.json';
import {
  KeyBindSetting,
  DragBindSetting,
  CheckboxSetting,
  SliderSetting,
} from '../../components/settings';
import { clampNumber } from '../../utils/shared';

const MENU_OPTIONS = {
  account: 'account',
  settings: 'settings',
  bindings: 'bindings',
  none: 'none',
};

const SETTING_INPUTS = {
  checkbox: 'checkbox',
  slider: 'slider',
  dragBind: 'dragBind',
  keyBind: 'keyBind',
};

const badNodeError = (key) => `Bad config node at ${key}`;

//would like to put clone in utils
const clone = (obj) => JSON.parse(JSON.stringify(obj));
const isObj = (x) => typeof x === 'object' && x !== null;

// TODO add check that leaf node contains default and
// throw error if it does not
// validate that configObject is up to date
const getConfigObject = (baseObj) => {
  if ('default' in baseObj) {
    return baseObj.default;
  }
  if ('children' in baseObj) {
    const keys = Object.keys(baseObj.children);
    const rv = {};
    for (let i = 0; i < keys.length; i++) {
      rv[keys[i]] = getConfigObject(baseObj.children[keys[i]]);
    }
    return rv;
  }
  const keys = Object.keys(baseObj);
  const rv = {};
  for (let i = 0; i < keys.length; i++) {
    rv[keys[i]] = getConfigObject(baseObj[keys[i]]);
  }
  return rv;
};

const getDefaultConfig = () => {
  return getConfigObject(SettingsConfig);
};

//TODO add stricter verification for values of fields here
const addMissingFields = (initObj, baseObj) => {
  let rv = initObj;
  if (!isObj(baseObj)) return typeof rv === typeof baseObj ? rv : baseObj;
  if (!isObj(initObj)) rv = {};
  for (const key of Object.keys(baseObj)) {
    if (key in rv) rv[key] = addMissingFields(rv[key], baseObj[key]);
    else rv[key] = clone(baseObj[key]);
  }
  return rv;
};

// below is the accessor for config that can be called from any component
export const getConfig = async () => {
  const defaultConfig = getDefaultConfig();
  let rv = localStorage.getItem('userConfig');
  if (!rv) {
    //load config json here
    //TODO implement AJAX to s3
  } else {
    try {
      rv = JSON.parse(rv);
    } catch (err) {
      console.error(err);
      rv = clone(defaultConfig);
    }
  }
  if (!rv) rv = clone(defaultConfig);
  rv = addMissingFields(rv, defaultConfig);
  return rv;
};

// wrapping accesses to config in this function
// for better error handling
export const getFromConfig = (config, key) => {
  const keys = key.split('.');
  // eslint-disable-next-line no-throw-literal
  if (!isObj(config)) throw 'invalid config';
  for (let i = 0; i < keys.length; i++) {
    config = config[keys[i]];
    // eslint-disable-next-line no-throw-literal
    if (typeof config === 'undefined') throw `${key} not found in config`;
  }
  return config;
};

class TrieNode {
  constructor(c) {
    this.c = c;
    this.children = {};
    //can't do true or false due to possibly multiple
    //elements disabling a key
    this.eow = 0;
    this.isWild = 0;
  }
}

function addWordToTrie(trie, word) {
  let node = trie;
  for (let i = 0; i < word.length; i++) {
    if (word[i] === '*') {
      node.isWild++;
      return;
    }
    if (word[i] in node.children) node = node.children[word[i]];
    else {
      const newNode = new TrieNode(word[i]);
      node.children[word[i]] = newNode;
      node = newNode;
    }
  }
  node.eow = true;
}

function delWordFromTrie(trie, word) {
  let node = trie;
  for (let i = 0; i < word.length; i++) {
    if (word[i] === '*') {
      if (node.isWild) node.isWild--;
      return;
    }
    if (word[i] in node.children) node = node.children[word[i]];
    else return;
  }
  if (node.eow) node.eow--;
}

function keyInTrie(trie, word) {
  let node = trie;
  for (let i = 0; i < word.length; i++) {
    if (node.isWild) return true;
    if (word[i] in node.children) node = node.children[word[i]];
    else return false;
  }
  return node.eow > 0;
}

export default function Settings() {
  //TODO prepend userConfig with private token
  const [config, setConfig] = useState(null);
  const [buffConfig, setBuffConfig] = useState(null);
  const [currMenu, setCurrMenu] = useState('account');
  const [rhsSettings, setRhsSettings] = useState([]);
  const [disabledTrie, setDisabledTrie] = useState(new TrieNode('^'));

  // populate state with an initial config
  // first render has no values
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    const prom = new Promise(async (res, rej) => {
      let rv = await getConfig();
      setConfig(rv);
    });
  }, []);

  useEffect(() => {
    // the only times config is updated are when buffConfig
    // must be set to the same value
    setBuffConfig(clone(config));
  }, [config]);

  // below getters and setters for parsing class
  // hierarchical strings to access the buffered config
  // e.g. settings.global.useBindings
  const setBuffSetting = useCallback(
    (key, value) => {
      const keys = key.split('.');
      if (keys.length === 0)
        return console.error('Empty key is invalid when setting config');
      let currRef = buffConfig;
      for (let i = 0; i < keys.length - 1; i++) {
        currRef = currRef[keys[i]];
      }
      currRef[keys[keys.length - 1]] = value;
      console.log('setting', key, 'to', value);
    },
    [buffConfig]
  );

  const getBuffSetting = useCallback(
    (key) => {
      const keys = key.split('.');
      let currVal = buffConfig;
      for (let i = 0; i < keys.length; i++) {
        currVal = currVal[keys[i]];
      }
      return currVal;
    },
    [buffConfig]
  );

  const applyHandler = useCallback(() => {
    setConfig(buffConfig);
    console.log('setting config to', buffConfig);
    //TODO add ajax to s3 here
    localStorage.setItem('userConfig', JSON.stringify(buffConfig));
  }, [buffConfig]);

  const resetHandler = useCallback(() => {
    setBuffConfig(clone(config));
  }, [config]);

  const resetToDefaultHandler = useCallback(() => {
    // clone likely not necessary
    setBuffConfig(clone(getDefaultConfig()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  // below is a slightly hacky fix to the issue of keys disabling when they shouldn't be
  // TODO add a more elegent fix
  useEffect(() => {
    setDisabledTrie(new TrieNode('^'));
  }, [currMenu]);

  // builds variable contet for settigs based on
  // settings-config.json
  useEffect(() => {
    if (!buffConfig) return;
    const getObjsWithKey = (obj, keyPrefix, depth) => {
      /**
       * obj describes current node
       * keyPrefix is a string describing the config class hierarchy for given node
       * depth is depth in subheadings of a node
       * known issue with empty key prefix giving subsequent prefixes prefaced
       * with a '.' character.
       * currently the function rejects the empty keyPrefix case
       */
      // eslint-disable-next-line no-throw-literal
      if (keyPrefix.length === 0) throw 'keyPrefix cannot be empty';
      let rv = [];
      //error if name and header are both fields in a node
      if ('header' in obj && 'name' in obj) throw badNodeError(keyPrefix);
      if ('header' in obj) {
        if (!('children' in obj)) throw badNodeError(keyPrefix);
        const keys = Object.keys(obj.children);
        rv.push(
          <InputHeader
            key={keyPrefix}
            disabled={keyInTrie(disabledTrie, keyPrefix)}
            value={obj.header}
            depth={depth}
          />
        );
        let isTable = false;
        let isntTable = false;
        let childElems = [];
        for (let i = 0; i < keys.length; i++) {
          if ('name' in obj.children[keys[i]]) {
            if (isntTable) throw badNodeError(keyPrefix + '.' + keys[i]);
            isTable = true;
          } else {
            if (isTable) throw badNodeError(keyPrefix + '.' + keys[i]);
            isntTable = true;
          }
          childElems = childElems.concat(
            getObjsWithKey(
              obj.children[keys[i]],
              keyPrefix + '.' + keys[i],
              depth + 1
            )
          );
        }
        if (isTable) {
          //wrap table rows in table/tbody
          rv.push(
            <table
              key={keyPrefix + 'Table'}
              style={{ width: '100%', position: 'relative' }}
            >
              <tbody>{childElems}</tbody>
            </table>
          );
        } else rv = rv.concat(childElems);
      } else if ('name' in obj) {
        if (!('settingInput' in obj)) throw badNodeError(keyPrefix);
        try {
          switch (obj.settingInput) {
            case SETTING_INPUTS.checkbox:
              let [disables, enables] = [false, false];
              if ('disables' in obj) disables = obj.disables;
              if ('enables' in obj) enables = obj.enables;
              rv.push(
                <CheckboxSetting
                  key={keyPrefix}
                  disabled={keyInTrie(disabledTrie, keyPrefix)}
                  name={obj.name}
                  enableKeys={enables}
                  disableKeys={disables}
                  /* force an update whenever a new key is added or removed from the disabledTrie */
                  /* TODO refactor such that force reender isn't necessary */
                  addKey={(val) => {
                    addWordToTrie(disabledTrie, val);
                    setDisabledTrie(clone(disabledTrie));
                  }}
                  delKey={(val) => {
                    delWordFromTrie(disabledTrie, val);
                    setDisabledTrie(clone(disabledTrie));
                  }}
                  value={getBuffSetting(keyPrefix)}
                  setValue={(val) => setBuffSetting(keyPrefix, val)}
                />
              );
              break;
            case SETTING_INPUTS.slider:
              rv.push(
                <SliderSetting
                  key={keyPrefix}
                  disabled={keyInTrie(disabledTrie, keyPrefix)}
                  name={obj.name}
                  value={getBuffSetting(keyPrefix)}
                  minValue={obj.minValue}
                  maxValue={obj.maxValue}
                  setValue={(val) => setBuffSetting(keyPrefix, val)}
                />
              );
              break;
            case SETTING_INPUTS.dragBind:
              rv.push(
                <DragBindSetting
                  key={keyPrefix}
                  disabled={keyInTrie(disabledTrie, keyPrefix)}
                  name={obj.name}
                  value={getBuffSetting(keyPrefix)}
                  setValue={(val) => setBuffSetting(keyPrefix, val)}
                />
              );
              break;
            case SETTING_INPUTS.keyBind:
              rv.push(
                <KeyBindSetting
                  key={keyPrefix}
                  disabled={keyInTrie(disabledTrie, keyPrefix)}
                  name={obj.name}
                  value={getBuffSetting(keyPrefix)}
                  setValue={(val) => setBuffSetting(keyPrefix, val)}
                />
              );
              break;
            default:
              break;
          }
        } catch (err) {
          console.error(err);
          throw badNodeError(keyPrefix);
        }
      } else {
        const keys = Object.keys(obj);
        let isTable = false;
        let isntTable = false;
        let childElems = [];
        for (let i = 0; i < keys.length; i++) {
          if ('name' in obj[keys[i]]) {
            if (isntTable) throw badNodeError(keyPrefix + '.' + keys[i]);
            isTable = true;
          } else {
            if (isTable) throw badNodeError(keyPrefix + '.' + keys[i]);
            isntTable = true;
          }
          //do not add one to depth has key had no header
          childElems = childElems.concat(
            getObjsWithKey(obj[keys[i]], keyPrefix + '.' + keys[i], depth)
          );
        }
        if (isTable) {
          //wrap table  rows in a tbody for inputs
          rv.push(
            <table
              key={keyPrefix + 'Table'}
              style={{ width: '100%', position: 'relative' }}
            >
              <tbody>{childElems}</tbody>
            </table>
          );
        } else rv = rv.concat(childElems);
      }
      //using this structure to assert return value is always an Array
      return rv;
    };
    let settings = [];
    switch (currMenu) {
      case MENU_OPTIONS.account:
        break;
      case MENU_OPTIONS.settings:
        settings = getObjsWithKey(SettingsConfig.settings, 'settings', 1);
        //guarantee order of Settings in some way
        //TODO define an order for keys not necessarily alphabetical
        break;
      case MENU_OPTIONS.bindings:
        settings = getObjsWithKey(SettingsConfig.bindings, 'bindings', 1);
        break;
      default:
        break;
    }
    setRhsSettings(settings);
    // make getBuffSetting a dependency as this must be called after it updates with
    // the correct buffConfig
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currMenu, getBuffSetting, disabledTrie]);

  return (
    <div className="settings accountContainerWrapper">
      <div className="accountContentWrapper">
        <div className="lhsWrapper">
          <div className="menuItem">
            <label className="menuLabel">
              <input
                className="menuInput"
                type="radio"
                name="menu-select"
                value={MENU_OPTIONS.account}
                checked={currMenu === MENU_OPTIONS.account}
                onChange={setCurrMenu.bind(null, MENU_OPTIONS.account)}
              />
              <div
                className={
                  'selectableItem' +
                  (currMenu === MENU_OPTIONS.account ? ' active' : '')
                }
              >
                Account
              </div>
            </label>
          </div>
          <div className="menuItem">
            <label className="menuLabel">
              <input
                className="menuInput"
                type="radio"
                name="menu-select"
                value={MENU_OPTIONS.settings}
                checked={currMenu === MENU_OPTIONS.settings}
                onChange={setCurrMenu.bind(null, MENU_OPTIONS.settings)}
              />
              <div
                className={
                  'selectableItem' +
                  (currMenu === MENU_OPTIONS.settings ? ' active' : '')
                }
              >
                Settings
              </div>
            </label>
          </div>
          <div className="menuItem">
            <label className="menuLabel">
              <input
                className="menuInput"
                type="radio"
                name="menu-select"
                value={MENU_OPTIONS.bindings}
                checked={currMenu === MENU_OPTIONS.bindings}
                onChange={setCurrMenu.bind(null, MENU_OPTIONS.bindings)}
              />
              <div
                className={
                  'selectableItem' +
                  (currMenu === MENU_OPTIONS.bindings ? ' active' : '')
                }
              >
                Bindings
              </div>
            </label>
          </div>
        </div>
        <div className="rhsWrapper">
          {/*Settings content goes here*/}
          {currMenu === MENU_OPTIONS.account && (
            //legacy placeholder buttons
            //not implemented
            <div style={{ width: '100%' }}>
              <button type="button">Update Email Address</button>
              <button type="button">Change Password</button>
            </div>
          )}
          {(currMenu === MENU_OPTIONS.settings ||
            currMenu === MENU_OPTIONS.bindings) && (
            <div style={{ width: '100%' }}>
              {rhsSettings}
              <button type="button" onClick={resetToDefaultHandler}>
                Reset To Default
              </button>
              <button type="button" onClick={resetHandler}>
                Reset
              </button>
              <button type="button" onClick={applyHandler}>
                Apply
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function InputHeader({ depth, value }) {
  const level = clampNumber(depth, 1, 6);
  switch (level) {
    case 1:
      return <h1>{value}</h1>;
    case 2:
      return <h2>{value}</h2>;
    case 3:
      return <h3>{value}</h3>;
    case 4:
      return <h4>{value}</h4>;
    case 5:
      return <h5>{value}</h5>;
    case 6:
      return <h6>{value}</h6>;
    default:
      return <h1>{value}</h1>;
  }
}
