export const Container = {};
export const FormContainer = {};
export const FormSection = {
  borderRadius: '4px',
  boxShadow: '10px 10px 60px rgba(0, 0, 0, 0.8)',
  backgroundColor: 'var(--color-shark)',
  marginBottom: '0',
};
export const FormField = {};
export const SectionHeader = {
  color: 'var(--color-white)',
};
export const SectionBody = {};
export const SectionFooter = {};
export const SectionFooterPrimaryContent = {};
export const SectionFooterSecondaryContent = {};
export const Input = {
  backgroundColor: 'var(--color-mine-shaft)',
  color: 'var(--color-dusty-gray)',
  borderColor: 'var(--color-shark)',
};
export const Button = {
  boxSizing: 'border-box',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '40px',
  padding: '0 1.6rem',
  fontSize: '1.6rem',
  lineHeight: '1em',
  letterSpacing: '0.02em',
  background: 'var(--color-dodger-blue)',
  border: '1px solid var(--color-dodger-blue)',
  borderRadius: '4px',
  color: 'var(--color-shark)',
  textTransform: 'none',
  fontWeight: '600',
};
export const PhotoPickerButton = {};
export const PhotoPlaceholder = {};
export const SignInButton = {};
export const SignInButtonIcon = {};
export const SignInButtonContent = {};
export const Strike = {};
export const StrikeContent = {};
export const ActionRow = {};
export const FormRow = {};
export const A = {
  color: 'var(--color-dodger-blue)',
};
export const Hint = {
  color: 'var(--color-white)',
  fontSize: '13px',
};
export const Radio = {};
export const Label = {};
export const InputLabel = {
  color: 'var(--color-white)',
};
export const AmazonSignInButton = {};
export const FacebookSignInButton = {};
export const GoogleSignInButton = {};
export const OAuthSignInButton = {};
export const Toast = {
  backgroundColor: 'var(--color-dove-gray)',
  borderLeft: `5px solid var(--color-torch-red)`,
};
export const NavBar = {
  backgroundColor: 'var(--color-cod-gray)',
  border: 0,
};
export const NavRight = {};
export const Nav = {};
export const NavItem = {};
export const NavButton = {
  ...Button,
  backgroundColor: 'transparent',
  color: 'var(--color-dodger-blue)',
  minWidth: 100,
  padding: '0.5rem 0',
};

const ThemeArtrya = {
  container: Container,
  formContainer: FormContainer,
  formSection: FormSection,
  formField: FormField,

  sectionHeader: SectionHeader,
  sectionBody: SectionBody,
  sectionFooter: SectionFooter,
  sectionFooterPrimaryContent: SectionFooterPrimaryContent,
  sectionFooterSecondaryContent: SectionFooterSecondaryContent,

  input: Input,
  button: Button,
  photoPickerButton: PhotoPickerButton,
  photoPlaceholder: PhotoPlaceholder,
  signInButton: SignInButton,
  signInButtonIcon: SignInButtonIcon,
  signInButtonContent: SignInButtonContent,
  amazonSignInButton: AmazonSignInButton,
  facebookSignInButton: FacebookSignInButton,
  googleSignInButton: GoogleSignInButton,
  oAuthSignInButton: OAuthSignInButton,

  formRow: FormRow,
  strike: Strike,
  strikeContent: StrikeContent,
  actionRow: ActionRow,
  a: A,

  hint: Hint,
  radio: Radio,
  label: Label,
  inputLabel: InputLabel,
  toast: Toast,

  navBar: NavBar,
  nav: Nav,
  navRight: NavRight,
  navItem: NavItem,
  navButton: NavButton,
};

export default ThemeArtrya;
