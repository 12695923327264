import { ActionType, createAction } from 'typesafe-actions';
import { Study } from '../../context/types';

export const WorkflowActions = {
  setStudyToAction: createAction(
    'Workflow/SET_STUDY_TO_ACTION',
    (study: Study | null) => study
  )(),
  setShowCancelReviewConfirm: createAction(
    'Workflow/SET_SHOW_CANCEL_REVIEW_CONFIRM',
    (show: boolean) => show
  )(),
  setAwaitingReviewList: createAction(
    'Workflow/SET_AWAITING_REVIEW_LIST',
    (list: Study[]) => list
  )(),
  setAwaitingApproveList: createAction(
    'Workflow/SET_AWAITING_APPROVE_LIST',
    (list: Study[]) => list
  )(),
  setStudyReviewCount: createAction(
    'Workflow/SET_STUDY_REVIEW_COUNT',
    (studyReviewCount: number) => studyReviewCount
  )(),
};

export type WorkflowAction = ActionType<typeof WorkflowActions>;
