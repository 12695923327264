import { useCallback } from 'react';
import { showToast } from '../components/Toast/showToast';
import { Study } from '../context/types';
import { WorkflowActions } from '../context/workflow/actions';
import { useWorkflowContext } from '../context/workflow/context';
import { useAppDispatch } from '../hooks';
import { GlobalLoaderActions } from '../reducers/global-loader';
import { fetchStudyById } from '../reducers/study';
import * as api from '../utils/api';

export function useCancelReviewRequest() {
  const {
    dispatch: dispatchWFAction,
    state: { awaitingApproveList, awaitingReviewList },
  } = useWorkflowContext();

  const dispatch = useAppDispatch();

  return useCallback(
    async ({
      study,
      shouldFetchStudy,
    }: {
      study: Study | null;
      shouldFetchStudy: boolean;
    }) => {
      if (!study) return;

      const {
        active_run: activeRun,
        study_id: studyId,
        report_active_review: review,
      } = study;

      if (!activeRun || !studyId) {
        showToast.error('Failed to get study information');
        dispatchWFAction(WorkflowActions.setStudyToAction(null));
        return;
      }

      dispatch(GlobalLoaderActions.show('Cancelling review'));
      dispatchWFAction(WorkflowActions.setShowCancelReviewConfirm(false));

      try {
        const response = await api.postJSON(
          `data/${studyId}/${activeRun}/report/review/cancel`,
          {
            review_id: review?.review_id,
          }
        );

        if (!response) throw new Error('No response');

        if (shouldFetchStudy) {
          await dispatch(fetchStudyById(study.study_id));
        }

        showToast.success('Review cancelled');
        dispatchWFAction(WorkflowActions.setShowCancelReviewConfirm(false)); // hide modal window

        // Clear study from existing awaiting lists if still exists
        dispatchWFAction(
          WorkflowActions.setAwaitingApproveList(
            awaitingApproveList.filter(
              (s) => s.report_active_review?.review_id !== response.review.id
            )
          )
        );

        dispatchWFAction(
          WorkflowActions.setAwaitingReviewList(
            awaitingReviewList.filter(
              (s) => s.report_active_review?.review_id !== response.review.id
            )
          )
        );

        dispatchWFAction(WorkflowActions.setStudyToAction(null));
      } catch (err) {
        dispatchWFAction(WorkflowActions.setStudyToAction(null));
        dispatchWFAction(WorkflowActions.setShowCancelReviewConfirm(false)); // hide modal window
        console.error(err);
        showToast.error('Failed to cancel review request');
      } finally {
        dispatch(GlobalLoaderActions.hide());
      }
    },
    [awaitingReviewList, awaitingApproveList, dispatchWFAction, dispatch]
  );
}
