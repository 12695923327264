import React, { ReactElement } from 'react';
import { StatsDataType } from '../../context/types';

interface Props {
  stats: StatsDataType;
  editingReport: boolean;
  handleUpdateReportStats: (stat: string, newValue: string) => void;
}

export default function VulnerablePlaque({
  stats,
}: Props): ReactElement<Props> {
  return (
    <div className={'stats__stat'}>
      <div className="stats__stat-value">{stats.value ? stats.value : '-'}</div>
      <div className="stats__stat-label">{stats.label}</div>
    </div>
  );
}
