import { useEffect } from 'react';
import { useStoreContext } from '../context/store-context';
import { defaultImageLoadingThrottle } from '../context/types';
import { useContrastContext } from '../context/contrast-context';
import { ContrastVolumeStatus } from '../context/contrast-types';

/**
 * Dynamically adjust the throttle applied to the loading of images in the background (specifically WebGLViewer).
 * Throttling is applied when loading a contrast CT volume as this should take priority.
 */
export function useImageLoadingThrottle() {
  const { imageLoadingThrottle, setImageLoadingThrottle } = useStoreContext();
  const { contrastVolumeMap } = useContrastContext();

  // If we are trying to load CT volume data that takes precedence over loading additional WebGLViewer slices in the background.
  useEffect(() => {
    // If we are currently loading any contrast CT volumes then throttle the background loading of non-essential images.
    let desiredImageLoadingThrottle = defaultImageLoadingThrottle;
    contrastVolumeMap.forEach((volume) => {
      if (volume.status === ContrastVolumeStatus.LOADING) {
        desiredImageLoadingThrottle = 500;
      }
    });
    // Update the image loading throttle as required.
    if (desiredImageLoadingThrottle !== imageLoadingThrottle) {
      setImageLoadingThrottle(desiredImageLoadingThrottle);
    }
  }, [imageLoadingThrottle, setImageLoadingThrottle, contrastVolumeMap]);

  // Ensure we restore the default image loading throttle on unmount.
  useEffect(() => {
    return () => {
      setImageLoadingThrottle(defaultImageLoadingThrottle);
    };
  }, [setImageLoadingThrottle]);
}
