import isEqual from 'lodash/isEqual';
import { useEffect } from 'react';
import { useContrastContext } from '../../../context/contrast-context';
import {
  ContrastVolumeActions,
  ContrastVolumeStatus,
  getStudySeriesId,
} from '../../../context/contrast-types';
import { useAppSelector } from '../../../hooks';
import { currentStudySelector } from '../../../selectors/study';
import { useVesselStateSelector } from '../../../selectors/vessels';
import { centerViewOnCrosshairs } from './Utils';

/**
 * This provides the link between the MPR and the loaded contrast views for syncing the sliceidx when
 * it is adjusted on the MPR and also for loading the MPR centerline when the selected vessel is changed.
 */
export function useMPRLink() {
  const selectedStudy = useAppSelector(currentStudySelector);

  const {
    dispatchContrastVolumeAction,
    contrastVolumeMap,
    vesselSync,
    vesselSyncInfo,
  } = useContrastContext();

  const {
    midSliceIdx: sliceidx,
    vesselData,
    selectedVesselName,
  } = useVesselStateSelector();

  // Centerlines are only ever loaded for the ai_assessed.contrast_id series.
  const seriesName = selectedStudy?.ai_assessed?.contrast_id || 'contrast';
  // Get the ai_assessed.contrast_id ContrastVolume for the selectedStudy.
  const contrastVolume = selectedStudy
    ? contrastVolumeMap.get(getStudySeriesId(selectedStudy, seriesName))
    : undefined;

  /**
   * Load and position the centerline when the selected vessel is changed.
   */
  useEffect(() => {
    // Load the currently selected centerline if it hasn't been loaded yet and we can load it now.
    // For the ContrastVolume:
    // a) Check it's loaded.
    // b) Check it has a volume loaded.
    // c) Check it needs to load the centerline for the selected vessel.
    if (
      vesselData &&
      selectedVesselName &&
      contrastVolume &&
      contrastVolume.volume &&
      !contrastVolume.centerlineMap.has(selectedVesselName)
    ) {
      const volume = contrastVolume.volume;

      // Get the centerline from the VesselData.
      const centerline = vesselData[selectedVesselName]?.centerline;
      if (centerline) {
        // Get the volume range (to get its position offset).
        const volumeXRange = volume.getXRange();
        const volumeYRange = volume.getYRange();
        const volumeZRange = volume.getZRange();

        // Move the cl_mm points to the volume's position (they are relative to the volume).
        const vesselCenterline = centerline.map((point: number[]) => {
          return [
            point[0] + volumeXRange[0],
            point[1] + volumeYRange[0],
            point[2] + volumeZRange[0],
          ];
        });

        // Update the centerline.
        dispatchContrastVolumeAction({
          study: contrastVolume.study,
          seriesName,
          vesselName: selectedVesselName,
          points: vesselCenterline,
          type: ContrastVolumeActions.SET_CENTERLINE,
        });
      }
    }
  }, [contrastVolume, selectedVesselName, vesselData]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Respond to changes in the MPR slice index and the selected vessel (ie, move the associated volume
   * crosshairs and refresh all views associated with that volume). Only do this if the vesselSync is
   * currently turned on.
   *
   * Initial CT volume load = no sync
   * Change vessel = no sync
   * Turn vesselSync on = Snap to current vessel and slice
   * Change vessel slice (when vesselSync is on) = Snap to current vessel and slice
   */
  useEffect(() => {
    if (
      selectedVesselName &&
      contrastVolume &&
      contrastVolume.volume &&
      contrastVolume.status === ContrastVolumeStatus.LOADED &&
      contrastVolume.centerlineMap.has(selectedVesselName)
    ) {
      const centerline = contrastVolume.centerlineMap.get(selectedVesselName);
      if (
        centerline &&
        centerline.points &&
        sliceidx >= 0 &&
        sliceidx < centerline.points.length
      ) {
        // Only sync the views to the desired study + vessel + slice index these were changed from
        // the last values the views were synchronized with.
        const desiredVesselSyncInfo = {
          studyId: contrastVolume.study.study_id,
          vesselName: selectedVesselName,
          // If vesselSync is currently off we should clear the desired sliceIndex because Artrya want it to pop to the
          // current vessel if vesselSync is turned back on.
          sliceIndex: vesselSync ? sliceidx : -1,
        };

        if (
          vesselSync &&
          !isEqual(vesselSyncInfo.current, desiredVesselSyncInfo)
        ) {
          // Only actually center the contrast views on the vessel position if the slice index was
          // changed on the currently synchronized study and vessel or vesselSync was just turned on.
          if (
            vesselSyncInfo.current?.studyId === desiredVesselSyncInfo.studyId &&
            vesselSyncInfo.current?.vesselName ===
              desiredVesselSyncInfo.vesselName &&
            vesselSyncInfo.current?.sliceIndex !==
              desiredVesselSyncInfo.sliceIndex
          ) {
            // Get the world position of the vessel at this slice index.
            const point = centerline.points[sliceidx];
            // Update the crosshairs position to the vessel position.
            contrastVolume.crosshairWorldPosition[0] = point[0];
            contrastVolume.crosshairWorldPosition[1] = point[1];
            contrastVolume.crosshairWorldPosition[2] = point[2];

            // Move the camera to be centered on the crosshairs position and refresh the view.
            for (let i = 0; i < 4; i++) {
              const api = contrastVolume.getApi(i);
              if (api) {
                centerViewOnCrosshairs(api, contrastVolume);
              }
            }

            // Update the crosshair values (huValue and crosshair position) for the volume.
            dispatchContrastVolumeAction({
              type: ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES_FOR_VOLUME,
              contrastVolume,
            });
          }
        }

        // Remember what we synchronized with.
        vesselSyncInfo.current = desiredVesselSyncInfo;
      }
    }
  }, [vesselSync, contrastVolume, sliceidx, selectedVesselName]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * This simple effect clears the vesselSyncInfo if we clean up the contrastVolume (normally because we have changed
   * the entire series to another contrast series without the MPR etc).
   */
  useEffect(() => {
    if (vesselSync && !contrastVolume) {
      vesselSyncInfo.current = undefined;
    }
  }, [vesselSync, contrastVolume]); // eslint-disable-line react-hooks/exhaustive-deps
}
