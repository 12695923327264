import React, { ReactElement, useCallback, useState } from 'react';
import { ReactComponent as ArrowIcon } from '../../assets/icons/arrow.svg';
import { ReactComponent as TextIcon } from '../../assets/icons/text.svg';
import Button from '../../components/Button/Button';
import { useReportContext } from '../../context/report-context';
import { useStoreContext } from '../../context/store-context';
import * as api from '../../utils/api';
import showToast from '../Toast/showToast';
import { AnnotationShapeType } from './AnnotationModal';

interface Bound {
  top: number | null;
  left: number | null;
  right: number | null;
  bottom: number | null;
}

interface Props {
  stage: any;
  onClose: () => void;
  onTakeScreenshot: any;
  vesselName: string;
  viewName: string;
  annotationType: AnnotationShapeType;
  onChangeType: (annotationType: AnnotationShapeType) => void;
  saveToAPI: any;
}

export default function AnnotationScreenshotActions({
  stage,
  onClose,
  onTakeScreenshot,
  vesselName,
  viewName,
  annotationType,
  onChangeType,
  saveToAPI,
}: Props): ReactElement<Props> {
  const { screenshots = [], setScreenshots } = useReportContext();
  const { patientID, runID } = useStoreContext();
  const [loading, setLoading] = useState(false);

  const IMAGE_PADDING = 24;

  const getTrimmedCanvas = (stage: any) => {
    return new Promise((resolve) => {
      let canvas = document.createElement('canvas');
      canvas.width = stage.current.attrs.width;
      canvas.height = stage.current.attrs.height;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = '#000000';
        ctx.fillRect(
          0,
          0,
          stage.current.attrs.width,
          stage.current.attrs.height
        );
      }
      const image = new Image();

      image.onload = () => {
        ctx?.drawImage(image, 0, 0);
        const pixels = ctx?.getImageData(
          0,
          0,
          stage.current.attrs.width,
          stage.current.attrs.height
        );
        const len = pixels?.data.length ?? 0;
        let i, x, y;
        let bound: Bound = { top: null, left: null, right: null, bottom: null };

        // Iterate over every pixel to find the highest
        // and where it ends on every axis ()
        for (i = 0; i < len; i += 4) {
          // elimiating pixel with an rgb of 0,0,0 (background fill)
          if (
            pixels?.data[i + 0] !== 0 &&
            pixels?.data[i + 1] !== 0 &&
            pixels?.data[i + 2] !== 0
          ) {
            x = (i / 4) % stage.current.attrs.width;
            y = ~~(i / 4 / stage.current.attrs.width);

            if (bound.top === null) {
              bound.top = y;
            }

            if (bound.left === null) {
              bound.left = x;
            } else if (x < bound.left) {
              bound.left = x;
            }

            if (bound.right === null) {
              bound.right = x;
            } else if (bound.right < x) {
              bound.right = x;
            }

            if (bound.bottom === null) {
              bound.bottom = y;
            } else if (bound.bottom < y) {
              bound.bottom = y;
            }
          }
        }

        // Calculate the height and width of the content
        const trimHeight =
            (bound.bottom ?? 0) - (bound.top ?? 0) + IMAGE_PADDING * 2,
          trimWidth =
            (bound.right ?? 0) - (bound.left ?? 0) + IMAGE_PADDING * 2,
          trimmed = ctx?.getImageData(
            (bound.left ?? 0) - IMAGE_PADDING,
            (bound.top ?? 0) - IMAGE_PADDING,
            trimWidth,
            trimHeight
          );

        // Clearing canvas to add trimmed image
        ctx?.clearRect(0, 0, canvas.width, canvas.height);
        if (ctx) {
          ctx.canvas.width = trimWidth;
          ctx.canvas.height = trimHeight;
        }
        if (trimmed) {
          ctx?.putImageData(trimmed, 0, 0);
        }

        // returning image url of new canvas with trimmed image
        resolve(ctx?.canvas.toDataURL());
      };

      // add snap shot of original canvas
      image.src = stage.current.toDataURL();
    });
  };

  const saveScreenshot = useCallback(() => {
    if (!stage && !stage.current) {
      return;
    }

    setLoading(true);

    const numOfScreenshots = screenshots.filter((s: any) =>
      s.title.includes(viewName)
    ).length;

    const newTitle = [viewName, vesselName, numOfScreenshots + 1]
      .filter((x) => x)
      .map((x) => String(x).toUpperCase())
      .join('_');

    try {
      getTrimmedCanvas(stage).then(async (imageData) => {
        if (!saveToAPI) {
          setLoading(false);
          onTakeScreenshot && onTakeScreenshot(imageData);
          onClose();
        } else {
          const response = await api.postJSON(
            `/data/${patientID}/${runID}/report/screenshot/add`,
            {
              img: imageData,
              title: newTitle,
            }
          );
          if (!response) throw new Error('No response from api');

          setScreenshots(response);
          showToast.success(`Added '${newTitle}' screenshot to report`);
          setLoading(false);
          onTakeScreenshot && onTakeScreenshot(imageData);
          onClose();
        }
      });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  }, [
    stage,
    screenshots,
    viewName,
    vesselName,
    saveToAPI,
    onTakeScreenshot,
    onClose,
    patientID,
    runID,
    setScreenshots,
  ]);

  const onChange = (e: any) => {
    const annotationType: string = e.target.value;
    if (annotationType === 'arrow' || annotationType === 'text') {
      onChangeType(annotationType);
    }
  };

  return (
    <div className="scanModal__title scanModal__title--full">
      <div className="scanModal__title-wrapper">
        Add Screenshot
        <div className="scanModal__actions">
          <div className="annotation-toggle">
            <input
              id="anno_arrow"
              type="radio"
              value="arrow"
              name="annotation"
              checked={annotationType === 'arrow'}
              onChange={onChange}
            ></input>
            <label htmlFor="anno_arrow">
              <ArrowIcon />
            </label>
          </div>
          <div className="annotation-toggle">
            <input
              id="anno_text"
              type="radio"
              value="text"
              name="annotation"
              checked={annotationType === 'text'}
              onChange={onChange}
            ></input>
            <label htmlFor="anno_text">
              <TextIcon />
            </label>
          </div>
        </div>
      </div>
      <div className="scanModal__actions">
        <Button theme="secondary" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          theme="primary"
          onClick={() => saveScreenshot()}
          disabled={loading}
        >
          Done
        </Button>
      </div>
    </div>
  );
}
