import cn from 'classnames';
import React, { ReactElement } from 'react';

export type HorizontalButtonProp =
  | {
      type: 'Action';
      onClick?: () => void;
      title?: string;
      icon: any;
      selected: boolean;
      disabled?: boolean;
    }
  | {
      type: 'Custom';
      element: JSX.Element;
    };

interface Props {
  buttons: HorizontalButtonProp[];
}

function renderCorrectView(button: HorizontalButtonProp) {
  switch (button.type) {
    case 'Action':
      const { onClick, title, icon, selected } = button;
      return (
        <button
          className={cn('horizontal-button__icon', {
            'horizontal-button__icon__selected': selected,
          })}
          onClick={onClick}
          title={title}
          disabled={button.disabled}
        >
          {icon}
        </button>
      );
    case 'Custom':
      return button.element;
  }
}

export default function HorizontalButtonList({
  buttons,
}: Props): ReactElement<Props> {
  return (
    <div className="horizontal-button__container">
      <div className="horizontal-button__divider" />
      {buttons.map((button, index) => {
        return (
          <div
            className="horizontal-button__item"
            key={`horizontal-button-list-${index}`}
          >
            {renderCorrectView(button)}
            {index !== buttons.length - 1 && (
              <div className="horizontal-button__divider" />
            )}
          </div>
        );
      })}
    </div>
  );
}
