import cn from 'classnames';
import { uniq } from 'lodash';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';
import Moment from 'moment';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as ReloadIcon } from '../../assets/icons/reload.svg';
import { ReactComponent as RisFailure } from '../../assets/icons/ris-error.svg';
import { ReactComponent as RisSuccess } from '../../assets/icons/ris-success.svg';
import { ReactComponent as SortIcon } from '../../assets/icons/sort.svg';
import { ReactComponent as ApprovedIcon } from '../../assets/icons/status-approved.svg';
import { ReactComponent as EditedIcon } from '../../assets/icons/status-edited.svg';
import { ReactComponent as AnalysisFailedIcon } from '../../assets/icons/status-failed.svg';
import { ReactComponent as ReadyIcon } from '../../assets/icons/status-ready.svg';
import { ReactComponent as ReviewIcon } from '../../assets/icons/status-review.svg';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { Loader } from '../../components/Loader/Loader';
import PageTitle from '../../components/PageTitle/PageTitle';
import { Pagination } from '../../components/Pagination/Pagination';
import { Search } from '../../components/Search/Search';
import {
  DASHBOARD_FORMAT_DOB,
  DASHBOARD_ITEMS_PER_PAGE,
  PERMISSION_CHECK_INTERVAL,
  RISK_LEVELS,
  STENOSIS_RISK_LEVEL_MAPPING,
  TIMEDATE_FORMAT,
} from '../../config';
import { useDashboardContext } from '../../context/dashboard-context';
import { useReportContext } from '../../context/report-context';
import { useStoreContext } from '../../context/store-context';
import { Study, WorkflowStatus } from '../../context/types';
import { useUserContext } from '../../context/user-context';
import { useWindowContext } from '../../context/window-context';
import useDashboardData, {
  decryptDatasetsResponse,
} from '../../hooks/use-dashboard-data';
import usePreviousValue from '../../hooks/use-previous-value';
import {
  useIsArtryaUserSelector,
  useIsAuditorUserSelector,
} from '../../selectors/user';
import { useClearDataSelector } from '../../selectors/vessels';
import { performPermissionCheck } from '../../utils/auth';
import * as indexedDB from '../../utils/indexedDB';
import { unlockAllStudies } from '../../utils/studyLocking';
import { WorkflowActions } from '../../views/WorkflowActions/WorkflowActions';

enum CannotBeViewedReasons {
  NOT_PROCESSED = 'NOT_PROCESSED',
  EXISTING_LOCK = 'EXISTING_LOCK',
  NOT_SUCCESSFULLY_FINISHED_PROCESSING = 'NOT_SUCCESSFULLY_FINISHED_PROCESSING',
}

const STUDY_PROCESSING_MESSAGE =
  'This study is currently being processed.  You can review it once the processing has completed.';

const STUDY_NOT_PROCESSED_MESSAGE =
  'This study is currently being analysed.  You can review it once the analysis and processing has completed.';

function formatValue(value: number) {
  if (!value && value !== 0) return '-'; // Check if value is falsy except 0
  return value;
}

function formatNumber(value: string) {
  return !Number.isNaN(Math.round(parseFloat(value)))
    ? Math.round(parseFloat(value))
    : '-';
}

function formatDate(value: string) {
  if (!value || !Moment(value).isValid()) return '-';
  return Moment(value).format(TIMEDATE_FORMAT);
}

function formatStudyDateTime(value: string) {
  // Function to handle formatting the combined Study Date and Study Time value
  if (value) {
    if (Moment(value, 'YYYYMMDDhhmmss').isValid()) {
      return Moment(value, 'YYYYMMDDhhmmss').format(TIMEDATE_FORMAT);
    } else {
      return Moment(value).format(TIMEDATE_FORMAT);
    }
  }
  return null;
}

function formatDOB(value: string) {
  if (!value || !Moment(value).isValid()) return '-';
  return Moment(value).format(DASHBOARD_FORMAT_DOB);
}

function formatWorkflowStatus(value: WorkflowStatus) {
  if (!value) return '';
  return value.short_description ? value.short_description : value.state;
}

const STATUS_ICON_MAP = {
  Approved: <ApprovedIcon />,
  Edited: <EditedIcon />,
  'Analysis Failed': <AnalysisFailedIcon />,
  Ready: <ReadyIcon />,
  Locked: <ReviewIcon />,
};

const RIS_ICON_MAP: { [key: string]: ReactElement } = {
  Success: <RisSuccess />,
  Failed: <RisFailure />,
};

const TABLE_INFO = {
  patient_name: {
    headerText: 'Patient Name',
    formatFn: formatValue,
    align: 'left',
  },
  patient_id: { headerText: 'Patient ID', align: 'left' },
  date_of_birth: {
    headerText: 'Date of Birth',
    align: 'left',
    formatFn: formatDOB,
  },
  last_scan: { headerText: 'Scan Date', formatFn: formatStudyDateTime },
  vulnerable_plaque: { headerText: 'Vulnerable Plaque', formatFn: formatValue },
  stenosis: {
    headerText: 'Stenosis',
    formatFn: formatValue,
    riskLevelMap: STENOSIS_RISK_LEVEL_MAPPING,
  },
  calcium_score: { headerText: 'Calcium Score', formatFn: formatNumber },
  cad_rads: { headerText: 'CAD RADS', formatFn: formatValue },
  workflow_status: {
    headerText: 'Status',
    iconMap: STATUS_ICON_MAP,
  },
};

// field name that can be searched ('study_id' included even though it is not included on table columns)
const SEARCH_BY = {
  patient_name: {},
  patient_id: {},
  date_of_birth: { formatFn: formatDOB },
  last_scan: { formatFn: formatDate },
  vulnerable_plaque: {},
  stenosis: {},
  calcium_score: { formatFn: formatNumber },
  cad_rads: {},
  workflow_status: { formatFn: formatWorkflowStatus },
  study_id: {},
};

export default function Dashboard() {
  useDashboardData();

  const history = useHistory();
  const {
    dashboardCurrentPage,
    setDashboardCurrentPage,
    dashboardSortKey,
    setDashboardSortKey,
    dashboardAscending,
    setDashboardAscending,
    dashboardSearchStr,
    setDashboardSearchStr,
  } = useDashboardContext();

  const {
    dashboardData,
    inactivity,
    lastStudyId,
    newDashboardData,
    studyLocked,
    studyLockedBy,
    decryptedStudies,
    setStudyLocked,
    setStudyLockedBy,
    setInactivity,
    setLastStudyId,
    setModelImage,
    setReloadDashboardData,
    setSelectedStudyClient,
    setDecryptDashboardData,
    setDecryptedStudies,
  } = useStoreContext();

  const clearData = useClearDataSelector();

  const { resetWindowLevels } = useWindowContext();
  const { clearReport } = useReportContext();
  const [processedData, setProcessedData] = useState([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [pageData, setPageData] = useState(new Map());
  const [numPages, setNumPages] = useState(1);
  const [decryptRemaining, setDecryptRemaining] = useState<
    'decrypt' | 'complete' | null
  >(null);
  const [showLockedStudy, setShowLockedStudy] = useState<string | null>(null);
  const [showDataNotAvailableReason, setShowDataNotAvailableReason] = useState(
    ''
  );
  const [pageBeforeSearch, setPageBeforeSearch] = useState<number | null>(null);
  const { user } = useUserContext();
  const [loading, setLoading] = useState<boolean>(true);

  const isArtryaUser = useIsArtryaUserSelector();

  const memoizedDashboardData = useMemo<Study[]>(
    () => Object.values(dashboardData ?? {}),
    [dashboardData]
  );

  const permissionInterval = useRef<any | undefined>(null);

  const prevDashboardString = usePreviousValue(dashboardSearchStr);

  const lockedMessage = useCallback((status: string, user: string) => {
    if (status === 'Processing' || status === 'Ingested') {
      return STUDY_PROCESSING_MESSAGE;
    } else if (status === 'Locked') {
      return `This study is currently being reviewed.  You can review it once ${user} has completed their review.`;
    } else if (status === 'Analysis Failed') {
      return `This study has failed analysis in the Artrya system, please contact support@artrya.com.`;
    }
    // Shouldn't happen as there aren't any other locked status
    return '';
  }, []);

  const handlePatientClick = useCallback(
    (params: {
      study_id: string | null | undefined;
      status: WorkflowStatus | null | undefined;
      user: string;
      client_id: string;
      can_be_viewed: {
        reason: string;
        viewable: boolean;
      };
    }) => {
      const { study_id, can_be_viewed, user, status, client_id } = params;

      if (!study_id) return;

      setSelectedStudyClient(client_id);
      setInactivity(false);
      setLastStudyId(undefined);
      setModelImage(null);

      if (
        can_be_viewed.viewable ||
        (isArtryaUser && status?.state === 'Locked')
      ) {
        history.push(`/study/${study_id}`);
      } else {
        if (can_be_viewed.reason === CannotBeViewedReasons.NOT_PROCESSED) {
          setShowDataNotAvailableReason(STUDY_NOT_PROCESSED_MESSAGE);
        } else if (
          can_be_viewed.reason ===
          CannotBeViewedReasons.NOT_SUCCESSFULLY_FINISHED_PROCESSING
        ) {
          setShowDataNotAvailableReason(STUDY_PROCESSING_MESSAGE);
        } else if (
          can_be_viewed.reason === CannotBeViewedReasons.EXISTING_LOCK
        ) {
          setShowLockedStudy(lockedMessage('Locked', user));
        }
      }
    },
    [
      setSelectedStudyClient,
      setInactivity,
      setLastStudyId,
      setModelImage,
      isArtryaUser,
      lockedMessage,
      history,
    ]
  );

  useEffect(() => {
    // Ensure the decrypted studies list is unique
    if (decryptedStudies.length > memoizedDashboardData.length) {
      setDecryptedStudies(uniq(decryptedStudies));
      return;
    }

    // Once all studies have been decrypted and if the sort field is a decrypted field
    // Sort results again
    if (
      decryptedStudies.length === memoizedDashboardData.length &&
      (dashboardSortKey === 'patient_name' ||
        dashboardSortKey === 'date_of_birth')
    ) {
      const newFilteredData = [...filteredData];
      const sortedData: any = orderBy(
        newFilteredData,
        (scan: any) => {
          let data: string;
          data = scan[dashboardSortKey]
            ? scan[dashboardSortKey].toLowerCase()
            : '';

          return data;
        },
        dashboardAscending ? 'asc' : 'desc'
      );

      setFilteredData(sortedData);
    }
  }, [decryptedStudies]);

  const handleReopenStudy = useCallback(() => {
    if (lastStudyId) {
      const lastStudy = filteredData.find(
        (s: any) => s.study_id === lastStudyId
      );

      if (lastStudy) {
        const studyParams = {
          study_id: lastStudy.study_id,
          status: lastStudy.status,
          user: lastStudy.user,
          client_id: lastStudy.client_id,
          can_be_viewed: lastStudy.can_be_viewed,
        };

        handlePatientClick(studyParams);
      } else {
        setInactivity(false);
      }
    }
  }, [filteredData, handlePatientClick, lastStudyId, setInactivity]);

  const handleHeaderClick = useCallback(
    (key: string) => {
      const asc = key === dashboardSortKey ? !dashboardAscending : false;
      // Reset to page 1 when sort column/order changes
      setDashboardCurrentPage(1);
      setDashboardSortKey(key);
      setDashboardAscending(asc);
    },
    [
      dashboardAscending,
      dashboardSortKey,
      setDashboardAscending,
      setDashboardCurrentPage,
      setDashboardSortKey,
    ]
  );

  const isAuditorUser = useIsAuditorUserSelector();

  useEffect(() => {
    // if user is an auditor then redirect to audit logs
    if (isAuditorUser) {
      return history.push('/auditlog');
    }
  }, [history, isAuditorUser]);

  useEffect(() => {
    // Catch-all to ensure studies are not stuck in a locked state
    unlockAllStudies();
    clearData();
    // as clearData will cancel all pending requests, it's required to
    // additionally call for a reload of the dashboard data
    resetWindowLevels();
    clearReport();
    (async function checkUserPermission() {
      const hasPermissions = await performPermissionCheck(user.groups);
      if (!hasPermissions) {
        indexedDB.clearDb();
        history.push('/error', { state: 'permissionError' });
      }
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (studyLocked && studyLockedBy) {
      const message = lockedMessage('Locked', studyLockedBy);
      setShowLockedStudy(message);
    }
  }, [lockedMessage, studyLocked, studyLockedBy]);

  // Filter and sort processed data
  useEffect(() => {
    if (!memoizedDashboardData || memoizedDashboardData.length === 0) return;

    const dashboardSearchStrLowerCase = String(
      dashboardSearchStr || ''
    ).toLowerCase();

    // Keeping track of where you were if there is a search string
    if (
      !pageBeforeSearch &&
      dashboardCurrentPage > 1 &&
      dashboardSearchStr !== '' &&
      !prevDashboardString
    ) {
      setPageBeforeSearch(dashboardCurrentPage);
    }

    let newFilteredData: any = memoizedDashboardData;
    // Filter by the on screen fields only
    if (dashboardSearchStrLowerCase) {
      newFilteredData = pickBy(memoizedDashboardData, function (scan: any) {
        return Object.entries(SEARCH_BY).some(([key, { formatFn }]: any[]) => {
          const scanValue = formatFn ? formatFn(scan[key]) : scan[key];
          return (
            scanValue &&
            String(scanValue)
              .toLowerCase()
              .includes(dashboardSearchStrLowerCase)
          );
        });
      });
    }

    // Sort the data by selected field
    const sortedData: any = orderBy(
      newFilteredData,
      (scan: any) => {
        let data: string | Moment.Moment | number = '';
        switch (dashboardSortKey) {
          case 'last_scan':
            // Checks if the entry has a "last_scan" field, if not fall back to the "study_date" field
            const study_date = scan.study_date ? Moment(scan.study_date) : '-';
            data = scan.last_scan
              ? Moment(scan.last_scan, 'YYYYMMDDhhmmss')
              : study_date;

            break;
          case 'calcium_score':
            data = isNaN(scan[dashboardSortKey])
              ? -1
              : Math.round(scan[dashboardSortKey]);
            break;
          case 'workflow_status':
            data =
              scan[dashboardSortKey] &&
              formatWorkflowStatus(scan[dashboardSortKey]).toLowerCase();
            break;
          case 'stenosis':
            const splitValue = scan[dashboardSortKey].split(' ')[0];
            data = parseFloat(splitValue === '-' ? '-1' : splitValue);
            break;
          case 'patient_name':
            data = scan[dashboardSortKey]
              ? scan[dashboardSortKey].toLowerCase()
              : scan['study_id']
              ? scan['study_id']
              : '';
            break;
          default:
            data = scan[dashboardSortKey]
              ? scan[dashboardSortKey].toLowerCase()
              : '';
            break;
        }

        return data;
      },
      dashboardAscending ? 'asc' : 'desc'
    );

    setFilteredData(sortedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    memoizedDashboardData,
    dashboardSearchStr,
    dashboardSortKey,
    dashboardAscending,
  ]);

  useEffect(() => {
    if (pageBeforeSearch && prevDashboardString && !dashboardSearchStr) {
      setDashboardCurrentPage(pageBeforeSearch);
      setPageBeforeSearch(null);
      return;
    }
    // Redirect to first page if user enters search string
    if (!dashboardSearchStr) {
      setPageBeforeSearch(null);
      return;
    }
    setDashboardCurrentPage(1);
  }, [
    dashboardSearchStr,
    pageBeforeSearch,
    prevDashboardString,
    setDashboardCurrentPage,
  ]);

  // Extract items for current page only
  useEffect(() => {
    if (!filteredData) return;

    const startIndex = DASHBOARD_ITEMS_PER_PAGE * (dashboardCurrentPage - 1);
    const endIndex = DASHBOARD_ITEMS_PER_PAGE * dashboardCurrentPage;
    const newPageData = Object.keys(filteredData)
      .slice(startIndex, endIndex)
      .reduce((result: any, key: any) => {
        result[key] = filteredData[key];
        return result;
      }, {});

    // If logged in with an Artrya account, we don't decrypt the current page's data to improve performance
    if (
      !isArtryaUser &&
      ((filteredData.length && decryptRemaining === null) ||
        prevDashboardString)
    ) {
      (async function decryptData() {
        const decPageData = await decryptDatasetsResponse(
          newPageData,
          dashboardData
        );
        Object.keys(decPageData).forEach((key) => {
          if (!decryptedStudies.includes(key)) {
            setDecryptedStudies((oldArray: string[]) => [...oldArray, key]);
          }
        });
        setDecryptDashboardData(decPageData);
        setPageData(newPageData);
        setDecryptRemaining('decrypt');
      })();
    } else {
      setPageData(newPageData);
    }
  }, [filteredData, dashboardCurrentPage]);

  useEffect(() => {
    if (decryptRemaining !== 'decrypt' || !filteredData.length) return;

    const numItems = Object.values(filteredData || {}).length;
    const pages = Math.ceil(numItems / DASHBOARD_ITEMS_PER_PAGE);
    let x = {};

    for (let i = 1; i <= pages; i++) {
      x = { ...x, [i]: false };
    }

    for (let i = 1; i <= pages; i++) {
      const startIndex = DASHBOARD_ITEMS_PER_PAGE * (i - 1);
      const endIndex = DASHBOARD_ITEMS_PER_PAGE * i;
      const thisPageData = Object.keys(filteredData)
        .slice(startIndex, endIndex)
        .reduce((result: any, key: any) => {
          result[key] = filteredData[key];
          return result;
        }, {});

      // If logged in with an Artrya account, we don't decrypt the remaining data to improve performance
      if (!isArtryaUser) {
        (async function decryptData() {
          const decPageData = await decryptDatasetsResponse(
            thisPageData,
            dashboardData
          );
          Object.keys(decPageData).forEach((key) => {
            if (!decryptedStudies.includes(key)) {
              setDecryptedStudies((oldArray: string[]) => [...oldArray, key]);
            }
          });
          setDecryptDashboardData(decPageData);
        })();
      }

      setDecryptRemaining('complete');
    }
  }, [decryptRemaining, filteredData]);

  // Process the fields, removing any we don't want
  useEffect(() => {
    if (!pageData || !memoizedDashboardData.length) return;

    // Only use columns displayed in dashboard (filter out others, except the study_id)
    const newProcessedData: any = Object.values(pageData).map((item = {}) => {
      let values: any = Object.entries(TABLE_INFO).reduce(
        (acc, [key, { formatFn }]: any[]) => {
          const value = item[key];
          const formattedValue = formatFn ? formatFn(value) : value;
          return { ...acc, [key]: formattedValue };
        },
        {}
      );
      // Check to assign the actual scan date to study_date if it exists for the logged in user
      if (!values.last_scan) {
        values.last_scan = item.study_date ? formatDate(item.study_date) : '-';
      }
      values.study_id = item.study_id;
      values.status = item.workflow_status;
      values.user = item.workflow_user;
      values.can_be_viewed = item.can_be_viewed;
      values.client_id = item.client_id;
      values.mesa_percentile = item.mesa_percentile;
      values.ris_status = item.ris_status;
      return values;
    });
    setProcessedData(newProcessedData);
    setLoading(false);
  }, [pageData]);

  // Calculate number of pages from filtered data
  useEffect(() => {
    if (!filteredData) return;

    const numItems = Object.values(filteredData || {}).length;
    setNumPages(Math.ceil(numItems / DASHBOARD_ITEMS_PER_PAGE));
  }, [filteredData]);

  useEffect(() => {
    permissionInterval.current = setInterval(() => {
      performPermissionCheck(user.groups);
    }, PERMISSION_CHECK_INTERVAL);

    return () => clearInterval(permissionInterval.current);
  }, [permissionInterval, user]);

  const reloadTapped = () => {
    setReloadDashboardData(true);
  };

  return (
    <>
      <PageTitle title="Dashboard" />
      {loading || !dashboardData || Object.keys(dashboardData).length === 0 ? (
        <Loader text="Loading dashboard data" large fullScreen />
      ) : (
        <div className="dashboard">
          <WorkflowActions
            user={user}
            dashboardData={memoizedDashboardData}
            onViewReport={handlePatientClick}
          />
          <div className="dashboard__data">
            <div className="dashboard__header">
              <span className="dashboard__title">Patient Dashboard</span>
              <div className="dashboard__header-actions">
                {newDashboardData ? (
                  <button
                    title="reload"
                    className="dashboard__reload"
                    type="button"
                    onClick={reloadTapped}
                  >
                    <ReloadIcon />
                    Reload
                  </button>
                ) : null}
                <Search
                  searchStr={dashboardSearchStr}
                  onChange={setDashboardSearchStr}
                />
              </div>
            </div>

            <div className="dashboard__table-wrap">
              <table className="dashboard__table">
                <thead>
                  <tr className="dashboard__table-row dashboard__table-row--header">
                    {Object.entries(TABLE_INFO).map(
                      ([key, { headerText, align }]: any[]) => (
                        <th
                          className={cn(
                            'dashboard__table-col dashboard__table-col--header',
                            {
                              'dashboard__table-col--underline':
                                key === dashboardSortKey,
                            },
                            align
                              ? `dashboard__table-col--${align}`
                              : `dashboard__table-col--center`
                          )}
                          onClick={() => handleHeaderClick(key)}
                          key={key}
                        >
                          {headerText}
                          <span
                            className={cn('dashboard__sort-icon', {
                              'dashboard__sort-icon--active':
                                dashboardSortKey === key,
                              'dashboard__sort-icon--ascending':
                                dashboardSortKey === key && dashboardAscending,
                              'dashboard__sort-icon--descending':
                                dashboardSortKey !== key || !dashboardAscending,
                            })}
                          >
                            <SortIcon />
                          </span>
                        </th>
                      )
                    )}
                  </tr>
                </thead>

                <tbody>
                  {processedData.map((data: any = {}, index) => {
                    if (!data.study_id) return null;
                    return (
                      <tr
                        className="dashboard__table-row"
                        key={index}
                        onClick={() =>
                          handlePatientClick({
                            study_id: data.study_id,
                            status: data.status,
                            user: data.user,
                            client_id: data.client_id,
                            can_be_viewed: data.can_be_viewed,
                          })
                        }
                      >
                        {Object.entries(TABLE_INFO).map(
                          ([key, { riskLevelMap, iconMap, align }]: any[]) => {
                            let value = data[key];
                            let analysisFailedDetailedDescription: string = '';
                            if (key === 'patient_name' && value === '-') {
                              value = data.study_id;
                            }

                            // Append MESA percentile to calcium score if it's available
                            // TODO: Add this back in, temporarily removed mesa percentile due to inconsistent results (SALIX-1059)
                            // if (
                            //   key === 'calcium_score' &&
                            //   data['mesa_percentile']
                            // ) {
                            //   value = `${value} (${data['mesa_percentile']}%)`;
                            // }

                            let iconKey = value;
                            let risIcon: string | undefined =
                              data.ris_status?.status;

                            // Chris B: quick fixes for 1.3
                            if (key === 'workflow_status') {
                              if (value.state === 'Analysis Failed') {
                                iconKey = 'Analysis Failed';
                                analysisFailedDetailedDescription = value.detailed_description
                                  ? value.detailed_description.trim()
                                  : '';
                                value = value.short_description
                                  ? value.short_description
                                  : value.state;
                              } else {
                                iconKey = value.state;
                                value = value.state;
                              }
                            }

                            return (
                              <td
                                title={value !== '-' ? value : null}
                                className={cn(
                                  'dashboard__table-col',
                                  riskLevelMap &&
                                    RISK_LEVELS.includes(
                                      riskLevelMap[value.split(' ')[0]]
                                    )
                                    ? `dashboard__table-col--risk-${
                                        riskLevelMap[value.split(' ')[0]]
                                      }`
                                    : '',
                                  {
                                    'dashboard__table-col--bold': !!riskLevelMap,
                                  },
                                  align
                                    ? `dashboard__table-col--${align}`
                                    : `dashboard__table-col--center`
                                )}
                                key={key}
                              >
                                {iconMap && (
                                  <span className="dashboard__status-icon">
                                    {iconMap[iconKey]}
                                  </span>
                                )}

                                {analysisFailedDetailedDescription ? (
                                  <div>
                                    <div
                                      data-tip
                                      data-for="detailedAnalysis"
                                      className="hide-overflow"
                                    >
                                      {value}
                                    </div>
                                    <ReactTooltip
                                      getContent={() => (
                                        <div>
                                          <span className="dashboard__status-icon__analysis-failed">
                                            {iconMap[iconKey]}
                                          </span>

                                          {analysisFailedDetailedDescription}
                                        </div>
                                      )}
                                      place="top"
                                      type="dark"
                                      border
                                      borderColor="#ffffff"
                                      effect="solid"
                                      id="detailedAnalysis"
                                    />
                                  </div>
                                ) : (
                                  <div className="dashboard__status">
                                    {value}

                                    {key === 'workflow_status' && risIcon && (
                                      <div className="dashboard__ris-icon">
                                        {RIS_ICON_MAP[risIcon]}
                                      </div>
                                    )}
                                  </div>
                                )}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {!processedData.length && (
              <div className="dashboard__no-results">
                {decryptedStudies.length ? (
                  'No results'
                ) : (
                  <>
                    <Loader small inline />
                    &nbsp;&nbsp;Searching
                  </>
                )}
              </div>
            )}
            <div className="dashboard__footer">
              <div className="dashboard__decrypting">
                {!!processedData.length &&
                  decryptedStudies.length < memoizedDashboardData.length &&
                  !isArtryaUser && (
                    <>
                      <Loader small inline />
                      <span>Loading dashboard data</span>
                    </>
                  )}
              </div>
              <Pagination
                numPages={numPages}
                currentPage={dashboardCurrentPage}
                onPageChange={setDashboardCurrentPage}
              />
            </div>
          </div>
        </div>
      )}

      <ActionModal
        confirmText={'OK'}
        closeText={'Open it again'}
        onConfirm={() => setInactivity(false)}
        onClose={() => handleReopenStudy()}
        visible={!!inactivity}
        headerContent={<>STUDY CLOSED</>}
      >
        <p>
          Due to inactivity, the patient study being reviewed was closed. All
          changes have been saved.
        </p>
      </ActionModal>
      <ActionModal
        confirmText={'OK'}
        onConfirm={() => {
          setShowLockedStudy(null);
          setStudyLocked(false);
          setStudyLockedBy();
        }}
        visible={showLockedStudy !== null && showLockedStudy.length > 0}
        headerContent={<>LOCKED FOR REVIEW</>}
      >
        <p>{showLockedStudy}</p>
      </ActionModal>
      <ActionModal
        confirmText={'OK'}
        onConfirm={() => setShowDataNotAvailableReason('')}
        visible={showDataNotAvailableReason.length > 0}
        headerContent={<>DATA UNAVAILABLE</>}
      >
        <p>{showDataNotAvailableReason}</p>
      </ActionModal>
    </>
  );
}
