import { InteractionOperations } from './customBase';
import { rangeToWindowLevels } from '../Utils';
import isEqual from 'lodash/isEqual';
import { vtkEvent, vtkPublicAPI, vtkModel } from '../../ReactVTKJSTypes';

/**
 * Start the window level operation.
 */
function onStart(publicAPI: vtkPublicAPI, model: vtkModel, callData: vtkEvent) {
  model.interactionOperation = InteractionOperations.WINDOW_LEVEL;
  // Set the starting mouse position.
  model.startMousePos = [callData.position.x, callData.position.y];

  // Set the starting window level.
  const property = model?.volumeActor?.getProperty();
  if (property) {
    const initialRange = property
      .getRGBTransferFunction(0)
      .getMappingRange()
      .slice();
    model.startWindowLevels = rangeToWindowLevels(initialRange);
  }
}

/**
 * Adjust the window level based on the mouse movement.
 */
function onMove(publicAPI: vtkPublicAPI, model: vtkModel, callData: vtkEvent) {
  // Calculate the multiplier that controls how quickly the window values change.
  const range = model.volumeActor
    .getMapper()
    .getInputData()
    .getPointData()
    .getScalars()
    .getRange();
  const multiplier = Math.round((range[1] - range[0]) / 1024);
  // Calculate the new window levels based on the current mouse position vs its position at the start of the adjustment.
  if (model.startWindowLevels && model.startMousePos) {
    let windowWidth =
      model.startWindowLevels.windowWidth +
      Math.round((callData.position.x - model.startMousePos[0]) * multiplier);
    let windowCenter =
      model.startWindowLevels.windowCenter -
      Math.round((callData.position.y - model.startMousePos[1]) * multiplier);
    windowWidth = Math.max(0.01, windowWidth);
    const windowLevels = { windowWidth, windowCenter };

    // Fire the onChange callback (if the windowLevels have changed from the last windowLevels).
    if (
      model.onWindowLevelsChanged &&
      !isEqual(windowLevels, model.lastWindowLevels)
    ) {
      model.lastWindowLevels = windowLevels;
      model.onWindowLevelsChanged(windowLevels);
    }
  }
}

const exports = {
  onStart,
  onMove,
};
export default exports;
