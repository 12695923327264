import React, {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAppSelector } from '../hooks';
import { currentStudySelector } from '../selectors/study';
import * as phi from '../utils/phi';
import { ReportContextValue, ReviewUser } from './types';

const ReportContext = React.createContext<ReportContextValue | undefined>(
  undefined
);
ReportContext.displayName = 'ReportContext';

interface Props {
  children: ReactNode;
}

export function ReportProvider({ children }: Props): ReactElement<Props> {
  const selectedStudy = useAppSelector(currentStudySelector);

  const [valid, setValid] = useState(false);
  const [summary, setSummary] = useState('');
  const [screenshots, setScreenshots] = useState<any[]>([]);
  const [deletingScreenshot, setDeletingScreenshot] = useState<
    string | undefined
  >(undefined);
  const [notes, setNotes] = useState('');
  const screenshotsRef = useRef([]);
  const [decryptedStudy, setDecryptedStudy] = useState<any | undefined>();
  const [approved, setApproved] = useState(false);

  const [showEmailForm, setShowEmailForm] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState('');

  const [fetchingMesaPercentile, setFetchingMesaPercentile] = useState<boolean>(
    false
  );

  const [reviewUsers, setReviewUsers] = useState<ReviewUser[] | undefined>();
  const [amendmentModalPassthrough, setAmendmentModalPassthrough] = useState<{
    passthrough?: (reason: string) => void;
  }>({});

  const clearReport = () => {
    setValid(false);
    setSummary('');
    setScreenshots([]);
    setNotes('');
    screenshotsRef.current = [];
    setShowEmailForm(false);
    setEmailAddresses('');
  };

  useEffect(() => {
    if (!selectedStudy) return;
    setApproved(!!selectedStudy['is_report_approved']);
    (async () => {
      const decrypted = await phi.decryptDashboardItem(selectedStudy);
      setDecryptedStudy(decrypted);
    })();
  }, [selectedStudy]);

  const reportContext: ReportContextValue = {
    valid,
    summary,
    notes,
    screenshots,
    showEmailForm,
    emailAddresses,
    decryptedStudy,
    approved,
    setSummary,
    setNotes,
    clearReport,
    setShowEmailForm,
    setEmailAddresses,
    setDecryptedStudy,
    setApproved,
    setScreenshots,
    deletingScreenshot,
    setDeletingScreenshot,
    fetchingMesaPercentile,
    setFetchingMesaPercentile,
    reviewUsers,
    setReviewUsers,
    amendmentModalPassthrough,
    setAmendmentModalPassthrough,
  };

  return (
    <ReportContext.Provider value={reportContext}>
      {children}
    </ReportContext.Provider>
  );
}

export function useReportContext(): ReportContextValue {
  const context = useContext(ReportContext);

  if (context === undefined) {
    throw new Error('useReportContext must be used within a ReportProvider.');
  }
  return context;
}
