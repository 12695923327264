import isEqualWith from 'lodash/isEqualWith';
import { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { API, DASHBOARD_RELOAD_INTERVAL } from '../config';
import { useStoreContext } from '../context/store-context';
import { DatasetItem, Study } from '../context/types';
import { useIsAuditorUserSelector } from '../selectors/user';
import * as api from '../utils/api';
import * as phi from '../utils/phi';

export async function decryptDatasetsResponse(
  studies: Record<string, Study>,
  currentStudies?: Record<string, Study>
): Promise<Record<string, Study>> {
  let decryptedStudies: Record<string, Study> = {};

  const decryptPromises: Promise<Study>[] = [];

  for (let studyId in studies) {
    if (
      currentStudies &&
      currentStudies[studyId] &&
      currentStudies[studyId]['patient_plaintext']
    ) {
      // Apply decrypted data from studies already loaded
      decryptedStudies[studyId] = phi.applyDecryptedData(
        currentStudies[studyId],
        studies[studyId]
      );
    } else {
      decryptPromises.push(phi.decryptDashboardItem(studies[studyId], false));
    }
  }

  const decryptedStudiesPromises = await Promise.all(decryptPromises);
  Object.values(decryptedStudiesPromises).forEach((value) => {
    decryptedStudies[value.study_id] = value;
  });

  return decryptedStudies;
}

/**
 * Converts a map of DatasetItems fetched from the API to Study objects.
 * Mutates the values in the passed map and returns them in a new object.
 * @param response A map of DatasetItem objects to be processed
 * @returns A map of Study objects
 */
async function processDatasetsResponse(
  response: Record<string, DatasetItem>,
  currentStudies?: Record<string, Study>
): Promise<Record<string, Study>> {
  const studies: Record<string, Study> = {};

  Object.keys(response).forEach((studyId) => {
    const s: Study = {
      ...response[studyId],
      workflow_user: response[studyId].workflow_status.user,
    };
    studies[studyId] = s;
  });

  await phi.checkCredentials();

  for (let studyId in studies) {
    if (
      currentStudies &&
      currentStudies[studyId] &&
      currentStudies[studyId]['patient_plaintext']
    ) {
      // Apply decrypted data from studies already loaded
      studies[studyId] = phi.applyDecryptedData(
        currentStudies[studyId],
        studies[studyId]
      );
    }
  }

  return studies;
}

/**
 * Compares two studies to determine if any changes have been made that require the dashboard item to be reloaded.
 */
export function studyIsEqual(a: Study, b: Study): boolean {
  return (
    a.patient_cipher === b.patient_cipher &&
    a.calcium_score === b.calcium_score &&
    a.status === b.status &&
    a.stenosis === b.stenosis &&
    a.study_id === b.study_id &&
    a.study_date === b.study_date &&
    a.vulnerable_plaque === b.vulnerable_plaque &&
    a.workflow_status === b.workflow_status &&
    a.workflow_user === b.workflow_user &&
    a.can_be_viewed.reason === b.can_be_viewed.reason &&
    a.can_be_viewed.viewable === b.can_be_viewed.viewable &&
    a.report_active_review?.review_id === b.report_active_review?.review_id &&
    a.report_active_review?.assignee.email ===
      b.report_active_review?.assignee.email &&
    a.report_active_review?.assigner.email ===
      b.report_active_review?.assigner.email &&
    a.client_id === b.client_id
  );
}

export default function useDashboardData() {
  const {
    dashboardData,
    setDashboardData,
    setNewDashboardData,
    setReloadDashboardData,
    setDecryptDashboardData,
    reloadDashboardData,
    decryptDashboardData,
  } = useStoreContext();

  const isAuditorUser = useIsAuditorUserSelector();

  const updateData = useCallback(
    (studies: Record<string, Study>) => {
      if (!dashboardData) {
        setDashboardData(studies);
      } else {
        let newDashboardData: Record<string, Study> = {};

        // Check for updated records
        Object.keys(studies).forEach((key) => {
          if (dashboardData[key]) {
            if (!isEqualWith(studies[key], dashboardData[key], studyIsEqual)) {
              newDashboardData[key] = studies[key];
            }
          } else {
            // We've new records, tell the reload button to appear
            setNewDashboardData(true);
          }
        });

        if (Object.keys(newDashboardData).length > 0) {
          setDashboardData({ ...dashboardData, ...newDashboardData });
        }
      }
    },
    [dashboardData, setDashboardData, setNewDashboardData]
  );

  useQuery(
    'dashboard_data',
    async () => {
      // No need to fetch dashboard data as an auditor
      if (isAuditorUser) return;
      const json = await api.getJSON(API.dashboard, true);
      const studies = await processDatasetsResponse(json, dashboardData);
      updateData(studies);
    },
    {
      // Refetch the data every x seconds
      refetchInterval: DASHBOARD_RELOAD_INTERVAL,
    }
  );

  // Update main dashboard data with the decrypted page record
  useEffect(() => {
    if (decryptDashboardData) {
      updateData(decryptDashboardData);
      setDecryptDashboardData(undefined);
    }

    return () => {
      setDecryptDashboardData(undefined);
    };
  }, [
    dashboardData,
    decryptDashboardData,
    setDecryptDashboardData,
    updateData,
  ]);

  // Reload the data if requested
  useEffect(() => {
    let isCancelled = false;
    if (reloadDashboardData) {
      (async function reloadData() {
        const json = await api.getJSON(API.dashboard, true);
        const studies = await processDatasetsResponse(json);

        if (isCancelled) {
          return;
        }
        setDashboardData(studies);
        setNewDashboardData(false);
        setReloadDashboardData(false);
      })();
    }

    return () => {
      isCancelled = true;
    };
  }, [
    reloadDashboardData,
    setDashboardData,
    setNewDashboardData,
    setReloadDashboardData,
  ]);
}
