import AWS from 'aws-sdk';
import Auth from '@aws-amplify/auth';
import isEqual from 'lodash/isEqual';

export function createUserFromCognitoData(data: {
  username: any;
  signInUserSession: { idToken: any };
}) {
  const {
    username,
    signInUserSession: { idToken },
  } = data;

  const cognitoGroups = idToken.payload['cognito:groups'] || null;
  const currentUser = {
    username: username || null,
    userId: extractAttribute(idToken.payload, 'sub'),
    email: extractAttribute(idToken.payload, 'email'),
    name: extractAttribute(idToken.payload, 'name'),
    email_verified: extractAttribute(idToken.payload, 'email_verified'),
    groups: cognitoGroups,
  };

  return currentUser;
}

function extractAttribute(obj: { [x: string]: any }, key: string) {
  if (typeof obj === 'object' && key in obj) {
    return obj[key];
  } else {
    return null;
  }
}

let pendingPromises = {} as any;

const fetchTokenKey = 'FETCH_APIToken';

async function fetchAPIToken() {
  // if calling multiple times, append this to the callback list
  if (typeof pendingPromises[fetchTokenKey] !== 'undefined') {
    return await pendingPromises[fetchTokenKey];
  }
  const funcPromise = new Promise(async (res, rej) => {
    const credentials = await Auth.currentUserCredentials();
    const currentSession = await Auth.currentSession();

    const jwtToken = {
      id_token: currentSession.getIdToken().getJwtToken(),
    };

    // configure AWS to use the users details and set the region
    AWS.config.region = process.env.REACT_APP_AWS_REGION;
    AWS.config.credentials = credentials;

    // create a KMS session and encrypt the users details
    const kms = new AWS.KMS();
    const data = await kms
      .encrypt({
        KeyId: 'alias/jwt-encrypt',
        Plaintext: JSON.stringify(jwtToken),
      })
      .promise();

    if (data.CiphertextBlob) {
      const token = data.CiphertextBlob.toString('base64');
      localStorage.setItem('apiToken', token);
      localStorage.setItem(
        'apiTokenExpiry',
        currentSession.getAccessToken().getExpiration().toString()
      );
      return res(token);
    }

    return null;
  });
  let rv;
  try {
    pendingPromises[fetchTokenKey] = funcPromise;
    rv = await funcPromise;
  } catch (err) {
    console.error('Error fetching api token');
  } finally {
    delete pendingPromises[fetchTokenKey];
  }
  return rv;
}

export async function getAPIToken() {
  const oldToken = localStorage.getItem('apiToken');
  const tokenExpiry = localStorage.getItem('apiTokenExpiry');

  // get the credentials used for login

  var currentTimeSeconds = Math.round(+new Date() / 1000);
  if (
    tokenExpiry !== null &&
    Number(tokenExpiry) >= currentTimeSeconds &&
    oldToken !== null
  ) {
    return oldToken;
  }
  return await fetchAPIToken();
}

async function refreshSession() {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();

    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(
        currentSession.getRefreshToken(),
        (error: any, session: any) => {
          if (error) {
            reject(error);
            return;
          }
          clearTokens();
          resolve(session);
        }
      );
    });
  } catch (e) {
    throw new Error(e);
  }
}

export function clearTokens() {
  localStorage.removeItem('apiTokenExpiry');
  localStorage.removeItem('apiToken');
}

export async function performPermissionCheck(userGroups: string[] | null) {
  try {
    const session = (await refreshSession()) as any;
    const cognitoGroups = session.getAccessToken().payload['cognito:groups'];

    // If the cognitoGroups changed since login (the app was mounted) OR
    // if the user has no groups assigned (`cognito:groups` property is undefined, i.e. not present in the payload)
    // log the user out.
    if (!Array.isArray(cognitoGroups) || !isEqual(userGroups, cognitoGroups)) {
      clearTokens();
      Auth.signOut();
    }
    return true;
  } catch (e) {
    // If we fail to do a permission check for any reason, log the user out.
    console.log('Error performing permission check', JSON.stringify(e));
    clearTokens();
    Auth.signOut();
  }
  return false;
}
