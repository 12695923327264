import {
  auxAnnosToXYCoordsAuxAnnos,
  fetchShape,
  fiterAuxAnnos,
  getCenterlineMapping,
  getCPRVersion,
  lineArrayToXYCoords,
  shapeV1ToShape,
} from '../components/CPRViewer/Utils';
import {
  createImageBufferFromDataset,
  ImageBuffer,
} from '../components/WebGLViewer/Utils';
import {
  AuxAnnos,
  CPRVesselData,
  Shape,
  TRange,
  VesselDataAction,
  VesselDataActions,
  WallPolygons,
} from '../reducers/vessel-data';
import { LineArray, PointArray } from '../types';
import * as api from '../utils/api';

export const VIEWER_TYPES = [
  'short-axis',
  'long-axis',
  'ct-noncontrast',
  'cpr',
];

export type ViewerTypes = typeof VIEWER_TYPES[number];

/**
 * Fetch the specified dataName CPR data for entire vessel.
 */
function fetchVesselData(
  dataName: 'cl-t-range-vessel',
  cprVersion: number,
  patientID: string,
  runID: string,
  versionHead: string,
  vesselID: string,
  key: string
): Promise<TRange> {
  return new Promise((resolve, reject) => {
    if (cprVersion === 1) {
      const cprV1Data: TRange = [0, 1];
      resolve(cprV1Data);
    }
    const idPrefix = `data/${patientID}/${runID}/vessel/${vesselID}`;
    const endpoint = `${idPrefix}${getCPRVersion(
      cprVersion
    )}/${dataName}?version=${versionHead}`;
    api
      .getJSON(endpoint, false, {
        patient_id: patientID,
        run_id: runID,
        group_type: key,
      })
      .then((data: TRange) => {
        if (data) {
          resolve(data);
        } else {
          console.error(data);
          reject(data);
        }
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
}

/**
 * Fetch the specified dataName CPR data for the specifed slice number.
 * "cl-2d" returns a LineArray
 * "aux-annos" returns AuxAnnos
 * "cl-t-vals" returns PointArray
 */
function fetchSliceData(
  patientID: string,
  runID: string,
  vesselID: string,
  cprVersion: number,
  versionHead: string,
  key: string,
  sliceIndex: number,
  dataName: 'cl-2d' | 'aux-annos' | 'cl-t-vals'
): Promise<LineArray | AuxAnnos | PointArray> {
  return new Promise((resolve, reject) => {
    const idPrefix = `data/${patientID}/${runID}/vessel/${vesselID}`;
    const endpoint = `${idPrefix}${getCPRVersion(
      cprVersion
    )}/${dataName}/${sliceIndex}?version=${versionHead}`;
    api
      .getJSON(endpoint, false, {
        patient_id: patientID,
        run_id: runID,
        group_type: key,
      })
      .then((data: LineArray | AuxAnnos | PointArray) => {
        if (data) {
          resolve(data);
        } else {
          console.error(data);
          reject(data);
        }
      })
      .catch((e) => {
        console.error(e);
        reject(e);
      });
  });
}
/**
 * Fetch the slice data from the backend for the specifed slice number.
 * @return [centerline LineArray, AuxAnnos lines, tArray number[]]
 */
function fetchCPRSliceData(
  patientID: string,
  runID: string,
  vesselID: string,
  cprVersion: number,
  versionHead: string,
  key: string,
  sliceIndex: number
): Promise<[LineArray, AuxAnnos, number[]]> {
  return Promise.all([
    fetchSliceData(
      patientID,
      runID,
      vesselID,
      cprVersion,
      versionHead,
      key,
      sliceIndex,
      'cl-2d'
    ) as Promise<LineArray>,
    fetchSliceData(
      patientID,
      runID,
      vesselID,
      cprVersion,
      versionHead,
      key,
      sliceIndex,
      'aux-annos'
    ) as Promise<AuxAnnos>,
    fetchSliceData(
      patientID,
      runID,
      vesselID,
      cprVersion,
      versionHead,
      key,
      sliceIndex,
      'cl-t-vals'
    ) as Promise<number[]>,
  ]);
}

export function fetchCPRVesselData(
  patientID: string,
  runID: string,
  vesselID: string,
  cprVersion: number,
  versionHead: string,
  viewKey: string,
  // The number of slices along the the vessel - which we need to calculate the centerlineMapping.
  sliceCount: number,
  dispatch: (action: VesselDataAction) => void
): Promise<any> {
  // Build a list of the data we must fetch (start with the first slice).
  const cprSliceData$ = fetchCPRSliceData(
    patientID,
    runID,
    vesselID,
    cprVersion,
    versionHead,
    viewKey,
    0 // Load slice 0 first: TODO: Do we always want slice 0 first?
  );

  const shape$ = fetchShape(
    `data/${patientID}/${runID}/vessel/${vesselID}${getCPRVersion(cprVersion)}`,
    cprVersion,
    versionHead,
    viewKey
  );

  // Fetch the T-range for this vessel
  const vesselData$ = fetchVesselData(
    'cl-t-range-vessel',
    cprVersion,
    patientID,
    runID,
    versionHead,
    vesselID,
    viewKey
  );

  // Fire off all required fetches at the same time.
  return Promise.all([cprSliceData$, shape$, vesselData$]).then(
    ([cprSliceData, shape, tRange]) => {
      // Set the annotation data for the vessel.
      const cprVesselData: CPRVesselData = {
        tRange,
        shape,
        sliceData: [
          {
            anno: lineArrayToXYCoords(cprSliceData[0]),
            auxAnno: fiterAuxAnnos(
              auxAnnosToXYCoordsAuxAnnos(cprSliceData[1]),
              vesselID
            ),
            tArray: cprSliceData[2],
            // Create a usable centerline mapping from the tArray.
            centerlineMapping: getCenterlineMapping(
              sliceCount,
              cprSliceData[2],
              tRange,
              cprSliceData[0].length
            ),
          },
        ],
        imageBufferData: undefined,
      };
      dispatch(
        VesselDataActions.updateCPRVesselDataForVessel(
          patientID,
          runID,
          vesselID,
          cprVesselData
        )
      );

      // Load the centerline and aux centerline data for all the other slices in the background.
      for (
        let otherSliceIndex = 1; // We have loaded slice 0, now load every other slice: TODO: Do we always want slice 0 first?
        otherSliceIndex < shape.length;
        otherSliceIndex++
      ) {
        fetchCPRSliceData(
          patientID,
          runID,
          vesselID,
          cprVersion,
          versionHead,
          viewKey,
          otherSliceIndex
        ).then((data) => {
          dispatch(
            VesselDataActions.updateCPRSliceData(
              patientID,
              runID,
              vesselID,
              otherSliceIndex,
              {
                anno: lineArrayToXYCoords(data[0]),
                auxAnno: fiterAuxAnnos(
                  auxAnnosToXYCoordsAuxAnnos(data[1]),
                  vesselID
                ),
                tArray: data[2],
                // Create a usable centerline mapping from the tArray.
                centerlineMapping: getCenterlineMapping(
                  sliceCount,
                  data[2],
                  tRange,
                  data[0].length
                ),
              }
            )
          );
        });
      }
    }
  );
}

export function getVolumeDir(
  vessel: string,
  dataType: ViewerTypes,
  cprVersion: number = 1
) {
  switch (dataType) {
    case 'ct-noncontrast':
      return 'volume-non-contrast';
    case 'short-axis':
    case 'long-axis':
      return `vessel/${vessel}/mpr/${dataType}`;
    case 'cpr':
      return `vessel/${vessel}${getCPRVersion(cprVersion)}`;
    default:
      return '';
  }
}

/**
 * Fetch the short axis annotations for the specified vessel and return the lumen and outer wall data in the results.
 */
export function fetchShortAxisAnnotations(
  versionHead: string | undefined,
  patientID: string,
  runID: string,
  vesselID: string
): Promise<{ lumen: WallPolygons; outer: WallPolygons }> {
  const idPrefix = `data/${patientID}/${runID}/vessel/${vesselID}`;
  const requests = ['mpr/polygons/lumen', 'mpr/polygons/outer'];
  const promises = requests.map((request) =>
    api.getJSON(`${idPrefix}/${request}/all?version=${versionHead}`)
  );
  return Promise.all(promises).then((results) => {
    return {
      lumen: results[0]?.view_idx || [],
      outer: results[1]?.view_idx || [],
    };
  });
}

/**
 * Fetch the shape (ie image count and size info for the specified vessel and viewerType.
 */
export function fetchViewerShape(
  versionHead: string | undefined,
  patientID: string,
  runID: string,
  vesselID: string,
  viewerType: ViewerTypes
): Promise<Shape> {
  const endPoint = `data/${patientID}/${runID}/${getVolumeDir(
    vesselID,
    viewerType
  )}`;
  return api
    .getJSON(`${endPoint}/shape?version=${versionHead}`, false, {
      group_type: viewerType,
    })
    .then((result) => {
      return shapeV1ToShape(result);
    });
}

/**
 * Request the image dataset for the specified image and convert it into an ImageBuffer.
 */
export function fetchImageBuffer(
  sliceIndex: number,
  endPoint: string,
  versionHead: string,
  viewType: string,
  transpose?: boolean
): Promise<ImageBuffer> {
  return new Promise((resolve, reject) => {
    api
      .getH5(`${endPoint}/image/${sliceIndex}?version=${versionHead}`, false, {
        group_type: viewType,
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      })
      .then((file) => {
        const sliceData = file ? file.get('data') : undefined;
        if (!sliceData) {
          const error = `Error: no slice data found in file.`;
          console.error(error);
          reject(error);
        } else {
          const imageBuffer = createImageBufferFromDataset(
            sliceData,
            transpose || false
          );
          if (!imageBuffer) return reject('No slice data available');
          resolve(imageBuffer);
        }
      });
  });
}
