import React from 'react';
import { useHistory } from 'react-router-dom';

const Error: React.FC = () => {
  const history = useHistory() as any;
  if (!history.location.state) {
    history.push('/', { state: '' });
  }

  return (
    <div className="error">
      {history.location.state &&
      history.location.state.state === 'permissionError' ? (
        <>
          Your user account does not have permission to access the patient
          database. <br />
          <br /> Please contact&nbsp;
          <a className="error__link" href="mailto:support@artrya.com">
            support@artrya.com
          </a>
          .
        </>
      ) : (
        `Sorry, we couldn't find what you're looking for`
      )}
    </div>
  );
};

export default Error;
