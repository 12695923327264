import * as PIXI from 'pixi.js-legacy';
import { uuidv4 } from '../../utils/shared';
import { HandlePosition, MeasurementGraphics } from './types';
import {
  CreateEllipseSpriteInterface,
  MouseMoveEllipseInterface,
  MouseUpEllipseInterface,
  MouseDownEllipseInterface,
} from './types';
import {
  createMarkerSprite,
  drawCrosshair,
  drawCircle,
  lineStyle,
  getDistanceBetweenPoints,
  intersectsNodes,
  MEASUREMENT_SETTINGS,
  drawDashedCrosshair,
  getAreaOfEllipse,
} from './utils';
import { XYCoords } from '../../reducers/vessel-data';
const STATES_TO_DISPLAY_PAIR_HANDLES = ['new', 'moving'];
/**
 * CreateEllipseSprite
 * @param parent {parent: MEASUREMENT_SETTINGS.PARENT.MPR | 'ShortAxis' | 'CTVolume',
 * @param sprite {MeasurementGraphics},
 * @param start {PointObject},
 * @param end {PointObject},
 * @param points {PointObject[]},
 * @param lineName {string},
 * @param state {string},
 * @param scale {number},
 * @param callback {(event: any) => void},
 * @param markerPositionsRef {[measurementId]:{x: number, y: number,width: number, height: number}}
 * @returns {PIXI.Graphics}
 **/

export const createEllipseSprite = ({
  parent,
  sprite,
  start,
  end,
  points,
  lineName = MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse,
  state = MEASUREMENT_SETTINGS.STATES.new,
  scale,
  callback,
  hitAreaEllipseToolRef = {},
  huData,
  nodeIndex = -1,
  pixelsPerMillimeter,
}: CreateEllipseSpriteInterface) => {
  if (sprite && (start || points)) {
    sprite.clear();
    sprite.removeChildren(0, sprite.children.length);
    sprite.lineName = lineName;
    let measurementId = sprite.measurementId || uuidv4();
    if (!sprite.measurementId) {
      sprite.measurementId = measurementId;
    }
    let handlePoints: XYCoords[] = points ?? [];
    let centerPoints: XYCoords[] = [];
    //editing existing ellipse
    if (handlePoints.length > 0) {
      //points to draw ellipse
      centerPoints = [
        {
          x: (handlePoints[0].x + handlePoints[1].x) / 2,
          y: (handlePoints[0].y + handlePoints[1].y) / 2,
        },
        {
          x: (handlePoints[1].x + handlePoints[2].x) / 2,
          y: (handlePoints[1].y + handlePoints[2].y) / 2,
        },
        {
          x: (handlePoints[2].x + handlePoints[3].x) / 2,
          y: (handlePoints[2].y + handlePoints[3].y) / 2,
        },
        {
          x: (handlePoints[3].x + handlePoints[0].x) / 2,
          y: (handlePoints[3].y + handlePoints[0].y) / 2,
        },
      ];
    }

    //getting points to draw a new ellipse
    if (start && end) {
      //with start and end points we can calculate the handlePoints
      // when we obtain the points of the handle we can proceed to obtain the midpoints of each side
      // of the rectangle that is formed with the handlePoints.
      handlePoints = [
        start,
        { x: end.x, y: start.y },
        end,
        { x: start.x, y: end.y },
      ];
      // to draw the ellpise we need to know the center point of each side of the rectangle 'handle points'
      centerPoints = [
        { x: (start.x + end.x) / 2, y: (start.y + start.y) / 2 },
        { x: (end.x + end.x) / 2, y: (start.y + end.y) / 2 },
        { x: (end.x + start.x) / 2, y: (end.y + end.y) / 2 },
        { x: (start.x + start.x) / 2, y: (end.y + start.y) / 2 },
      ];
    }

    if (!handlePoints.length) return null;

    // Calculate area of ellipse
    const midPoint = {
      x: (centerPoints[0].x + centerPoints[2].x) / 2,
      y: (centerPoints[0].y + centerPoints[2].y) / 2,
    };
    // major radius of the ellipse. distance from the center of
    // the ellipse to the farthest edge of the ellipse.
    const majorRadius = getDistanceBetweenPoints(centerPoints[0], midPoint);
    // minor radius of the ellipse.  distance from the center to
    // the closest point on the edge
    const minorRadius = getDistanceBetweenPoints(centerPoints[1], midPoint);
    const mmPerPixel = parseFloat(
      getAreaOfEllipse(
        majorRadius * pixelsPerMillimeter,
        minorRadius * pixelsPerMillimeter
      ).toFixed(2)
    );
    if (state === MEASUREMENT_SETTINGS.STATES.finish && mmPerPixel < 1) {
      sprite.clear();
      sprite.removeChildren(0, sprite.children.length);
      sprite = null;
      return null;
    }
    //draw handles
    //pair of handles to be displayed 0-2  --  1-3
    if (state === MEASUREMENT_SETTINGS.STATES.new) {
      nodeIndex = 0;
    }
    let handleIndex = nodeIndex;
    if (nodeIndex > 1) handleIndex -= 2;

    for (let i = 0; i < handlePoints.length; i++) {
      const point = handlePoints[i];

      //giving the handle size according to the state: active or inactive
      //if mouse is over the handle, it will be inactive state
      let handleSize = MEASUREMENT_SETTINGS.HANDLE_SIZE.active;

      if (nodeIndex === i && state === MEASUREMENT_SETTINGS.STATES.moving) {
        handleSize = MEASUREMENT_SETTINGS.HANDLE_SIZE.inactive;
      }
      if (state === MEASUREMENT_SETTINGS.STATES.new && i === 2) {
        handleSize = MEASUREMENT_SETTINGS.HANDLE_SIZE.inactive;
      }
      //if state is active or moving, all handles will be inactive
      if (nodeIndex < 0) {
        handleSize = MEASUREMENT_SETTINGS.HANDLE_SIZE.active;
      }

      const circle = drawCircle(
        HandlePosition.End,
        point,
        handleSize / scale,
        MEASUREMENT_SETTINGS.BORDER_RADIUS / scale,
        0.001
      );

      //drawing crosshair for active and inactive handle - state equal new, moving
      if (handleSize == MEASUREMENT_SETTINGS.HANDLE_SIZE.inactive) {
        // crosshair for active point
        // shadow crosshair
        let lineWidth =
          MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.shadowInactive / scale;
        let borderWidth = MEASUREMENT_SETTINGS.SHADOW_SIZE / scale;
        drawDashedCrosshair(
          circle,
          point,
          lineWidth,
          borderWidth,
          MEASUREMENT_SETTINGS.COLOR,
          MEASUREMENT_SETTINGS.SHADOW_OPACITY
        );
        // crosshair
        lineWidth = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.inactive / scale;
        borderWidth = MEASUREMENT_SETTINGS.WIDTH / scale;
        drawDashedCrosshair(
          circle,
          point,
          lineWidth,
          borderWidth,
          MEASUREMENT_SETTINGS.SHADOW_COLOR
        );
      } else {
        drawCrosshair(circle, point, scale);
      }

      circle.visible = false;
      circle.lineName = 'circle';
      circle.beginFill(0xffffff, 0.7);
      circle.lineStyle(1 / scale, 0x000000, 0.2);
      circle.nodeIndex = i;
      circle.points = handlePoints;
      circle.endFill();

      //display the pair of handles
      if (STATES_TO_DISPLAY_PAIR_HANDLES.includes(state)) {
        if ((i === 0 && handleIndex == i) || (handleIndex === i && i === 2)) {
          handleIndex = 2;
          circle.visible = true;
        }
        if ((i === 1 && handleIndex == i) || (handleIndex === i && i === 3)) {
          handleIndex = 3;
          circle.visible = true;
        }
      }
      //display handles
      if (nodeIndex < 0 && state === MEASUREMENT_SETTINGS.STATES.moving) {
        circle.visible = true;
      }
      if (state === MEASUREMENT_SETTINGS.STATES.active) {
        circle.visible = true;
        circle.interactive = true;
        callback && circle.on('mousedown', callback);
      }
      sprite.addChild(circle);
    }

    const ellipseSprite = new PIXI.Graphics() as MeasurementGraphics;
    const shadowSprite = new PIXI.Graphics() as MeasurementGraphics;
    // drew ellipse with consecutive points
    if (centerPoints.length) {
      //draw marker
      createMarkerSprite({
        type: MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse,
        sprite,
        label: `${mmPerPixel} mm²`,
        position: {
          x: Math.max(centerPoints[1].x, centerPoints[3].x),
          y: centerPoints[1].y,
        },
        scale,
        state,
        huData,
      });

      const midPoint = {
        x: (centerPoints[0].x + centerPoints[2].x) / 2,
        y: (centerPoints[0].y + centerPoints[2].y) / 2,
      };
      const hitArea = [];
      ellipseSprite.lineStyle(2, 0xff0000);
      const xradius = getDistanceBetweenPoints(midPoint, {
        x: centerPoints[0].x,
        y: centerPoints[0].y,
      });
      const yradius = getDistanceBetweenPoints(midPoint, {
        x: centerPoints[1].x,
        y: centerPoints[1].y,
      });
      const nodeNo = (yradius > xradius ? yradius : xradius) * scale;
      const nodeEvery = (2 * Math.PI) / nodeNo;
      let initialPoint: XYCoords = {
        x: 0,
        y: 0,
      };
      let j = 0;
      for (let i = 0 * Math.PI; i < 2 * Math.PI; i += nodeEvery, j++) {
        // solid line
        if (state === MEASUREMENT_SETTINGS.STATES.finish) {
          lineStyle(shadowSprite, true, scale, state);
          lineStyle(ellipseSprite, false, scale, state);
        }
        //dashed line
        MEASUREMENT_SETTINGS.DASHED_LINE.includes(state) &&
          lineStyle(ellipseSprite, (j % 2) - 1, scale, state);

        const xPos =
          midPoint.x -
          xradius * Math.sin(i) * Math.sin(0 * Math.PI) +
          yradius * Math.cos(i) * Math.cos(0 * Math.PI);
        const yPos =
          midPoint.y +
          yradius * Math.cos(i) * Math.sin(0 * Math.PI) +
          xradius * Math.sin(i) * Math.cos(0 * Math.PI);

        if (j === 0) {
          initialPoint = { x: xPos, y: yPos };
          ellipseSprite.moveTo(xPos, yPos);
          shadowSprite.moveTo(xPos, yPos);
        } else {
          ellipseSprite.lineTo(xPos, yPos);
          shadowSprite.lineTo(xPos, yPos);
        }
        hitArea.push({ x: xPos, y: yPos });
      }
      //close the ellipse
      if (hitArea.length > 0) {
        MEASUREMENT_SETTINGS.DASHED_LINE.includes(state) &&
          lineStyle(ellipseSprite, ++j % 2, scale, state);
        ellipseSprite.lineTo(initialPoint.x, initialPoint.y);
        shadowSprite.lineTo(initialPoint.x, initialPoint.y);
      }
      if (state === MEASUREMENT_SETTINGS.STATES.finish) {
        //save the curve points in hitAreaEllipseToolRef, this allows us to make the ellipse interactive
        !hitAreaEllipseToolRef[measurementId] &&
          (hitAreaEllipseToolRef[measurementId] = []);

        hitAreaEllipseToolRef[measurementId] = hitArea;
      }
    }
    ellipseSprite.interactive = true;
    ellipseSprite.hitArea = new PIXI.Rectangle(0, 0, 10, 10);
    ellipseSprite.lineName = 'InnerEllipse';
    sprite.zIndex = 5;

    sprite.points = handlePoints;
    sprite.addChild(shadowSprite);
    sprite.addChild(ellipseSprite);

    return { sprite, hitArea: hitAreaEllipseToolRef };
  }
  return null;
};

export const onMouseUpEllipse = ({
  isDraggingMeasurementToolRef,
  measurementSpriteRef,
  measurementTargetRef,
  scale,
  callbackMouseDownEllipseNode,
  hitAreaEllipseToolRef = {},
  huData,
  pixelsPerMillimeter,
}: MouseUpEllipseInterface) => {
  // change dashed line to solid line
  let objToReturn = {};
  switch (isDraggingMeasurementToolRef) {
    case MEASUREMENT_SETTINGS.ELLIPSE_STATE.New:
    case MEASUREMENT_SETTINGS.ELLIPSE_STATE.Handle:
      objToReturn = {};
      if (measurementSpriteRef || measurementTargetRef) {
        let sprite: any = measurementSpriteRef;

        let points = measurementSpriteRef?.points;
        if (!points && measurementTargetRef) {
          points = measurementTargetRef.points;
          sprite = measurementTargetRef;
        }

        if (
          measurementTargetRef &&
          measurementTargetRef.lineName !==
            MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse &&
          measurementTargetRef.parent &&
          measurementTargetRef.parent.parent
        ) {
          sprite = measurementTargetRef.parent.parent;
        }

        const hitEllipse = createEllipseSprite({
          parent: MEASUREMENT_SETTINGS.PARENT.MPR,
          sprite,
          points: points || [],
          lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse,
          state: MEASUREMENT_SETTINGS.STATES.finish,
          scale,
          callback: callbackMouseDownEllipseNode,
          hitAreaEllipseToolRef,
          huData,
          pixelsPerMillimeter,
        });

        hitEllipse &&
          hitEllipse.hitArea &&
          (objToReturn = {
            ...objToReturn,
            hitAreaEllipseToolRef: hitEllipse.hitArea,
          });
      }
      objToReturn = {
        ...objToReturn,
        measurementToolStartPoint: null,
        isDraggingMeasurementToolRef: undefined,
        measurementTargetRef: null,
        isClickDownMeasurementToolRef: false,
      };
      return objToReturn;

    case MEASUREMENT_SETTINGS.ELLIPSE_STATE.Moving:
      objToReturn = {};
      if (measurementTargetRef) {
        measurementTargetRef.position.set(0, 0);
        const hitEllipse = createEllipseSprite({
          parent: MEASUREMENT_SETTINGS.PARENT.MPR,
          sprite: measurementTargetRef,
          points: measurementTargetRef.points,
          lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse,
          state: MEASUREMENT_SETTINGS.STATES.finish,
          scale,
          callback: callbackMouseDownEllipseNode,
          hitAreaEllipseToolRef,
          huData,
          pixelsPerMillimeter,
        });
        hitEllipse &&
          hitEllipse.hitArea &&
          (objToReturn = {
            ...objToReturn,
            hitAreaEllipseToolRef: hitEllipse.hitArea,
          });
      }

      objToReturn = {
        ...objToReturn,
        isOverMeasurementToolRef: '',
        measurementToolStartPoint: null,
        isDraggingMeasurementToolRef: undefined,
        measurementTargetRef: null,
        isClickDownMeasurementToolRef: false,
      };
      return objToReturn;
  }

  return {
    isOverMeasurementToolRef: '',
    measurementToolStartPoint: null,
    isDraggingMeasurementToolRef: undefined,
    isClickDownMeasurementToolRef: false,
  };
};

export const onMouseDownEllipse = ({
  isClickDownMeasurementToolRef,
  isDraggingMeasurementToolRef,
  measurementSpriteRef,
  measurementTargetRef,
  scale,
  onMouseDownEllipseNode,
  hitAreaEllipseToolRef = {},
  huData,
  pixelsPerMillimeter,
  isOverMeasurementToolRef,
  msmToolsContainerRef,
  btnClicked,
}: MouseDownEllipseInterface) => {
  //if there is a measurement tool active, reset the state
  if (
    measurementTargetRef &&
    isDraggingMeasurementToolRef !== MEASUREMENT_SETTINGS.ELLIPSE_STATE.Handle
  ) {
    //reset the state of the current ellipse active
    createEllipseSprite({
      parent: MEASUREMENT_SETTINGS.PARENT.ShortAxis,
      sprite: measurementTargetRef,
      points: measurementTargetRef.points,
      lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse,
      state: MEASUREMENT_SETTINGS.STATES.finish,
      scale,
      callback: onMouseDownEllipseNode,
      hitAreaEllipseToolRef: hitAreaEllipseToolRef,
      huData,
      pixelsPerMillimeter,
    });
    measurementTargetRef = null;
  }
  // find the ellipse that is over the mouse and set it in targetRef
  if (!measurementTargetRef && isOverMeasurementToolRef !== '') {
    msmToolsContainerRef &&
      msmToolsContainerRef.children.forEach((child: any) => {
        if (child.measurementId === isOverMeasurementToolRef) {
          isDraggingMeasurementToolRef =
            MEASUREMENT_SETTINGS.ELLIPSE_STATE.Active;
          isClickDownMeasurementToolRef = true;

          measurementTargetRef = child;
          if (measurementTargetRef) {
            createEllipseSprite({
              parent: MEASUREMENT_SETTINGS.PARENT.ShortAxis,
              sprite: measurementTargetRef,
              points: measurementTargetRef.points,
              lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse,
              state: MEASUREMENT_SETTINGS.STATES.active,
              scale,
              callback: onMouseDownEllipseNode,
              hitAreaEllipseToolRef: hitAreaEllipseToolRef,
              huData,
              pixelsPerMillimeter,
            });
          }
        }
      });
    return {
      measurementTargetRef,
      isDraggingMeasurementToolRef,
      isClickDownMeasurementToolRef,
    };
    // if there is no measurement tool active, start a new measurement tool
  } else if (!isDraggingMeasurementToolRef && btnClicked === 1) {
    return {
      measurementTargetRef,
      isClickDownMeasurementToolRef: true,
      isDraggingMeasurementToolRef: MEASUREMENT_SETTINGS.ELLIPSE_STATE.New,
    };
  }
  return { measurementTargetRef };
};

/**
 *
 * @param isDraggingMeasurementToolRef
 * @param measurementSpriteRef new ellipse sprite
 * @param measurementTargetRef current ellipse sprite
 * @param start
 * @param mousePosition
 * @param scale
 * @param offsetX
 * @param offsetY
 * @param isOverMeasurementToolRef if mouse is over the measurement tool, this allows us to click and drag the ellipse
 * @param hitAreaEllipseToolRef {[measurementId]:{x: number, y: number,width: number, height: number}}
 * @returns
 */

export const onMouseMoveEllipse = ({
  isDraggingMeasurementToolRef,
  measurementSpriteRef,
  measurementTargetRef,
  start,
  mousePosition,
  scale,
  offsetX,
  offsetY,
  isOverMeasurementToolRef,
  hitAreaEllipseToolRef = {},
  huData,
  recalculatePixelsToMm,
}: MouseMoveEllipseInterface) => {
  let objToReturn = {};
  let hitEllipse;
  switch (isDraggingMeasurementToolRef) {
    case MEASUREMENT_SETTINGS.ELLIPSE_STATE.New:
      objToReturn = {};
      hitEllipse = createEllipseSprite({
        parent: MEASUREMENT_SETTINGS.PARENT.MPR,
        sprite: measurementSpriteRef,
        start,
        end: mousePosition,
        lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse,
        state: MEASUREMENT_SETTINGS.STATES.new,
        scale,
        hitAreaEllipseToolRef,
        huData,
        pixelsPerMillimeter: recalculatePixelsToMm(),
      });
      hitEllipse &&
        hitEllipse.hitArea &&
        (objToReturn = {
          ...objToReturn,
          hitAreaEllipseToolRef: hitEllipse.hitArea,
        });
      return objToReturn;

    case MEASUREMENT_SETTINGS.ELLIPSE_STATE.Moving:
    case MEASUREMENT_SETTINGS.ELLIPSE_STATE.Active:
      if (measurementSpriteRef) {
        //update the handles position
        measurementTargetRef.points[0].x += offsetX / scale;
        measurementTargetRef.points[0].y += offsetY / scale;
        measurementTargetRef.points[1].x += offsetX / scale;
        measurementTargetRef.points[1].y += offsetY / scale;
        measurementTargetRef.points[2].x += offsetX / scale;
        measurementTargetRef.points[2].y += offsetY / scale;
        measurementTargetRef.points[3].x += offsetX / scale;
        measurementTargetRef.points[3].y += offsetY / scale;

        objToReturn = {};
        hitEllipse = createEllipseSprite({
          parent: MEASUREMENT_SETTINGS.PARENT.MPR,
          sprite: measurementTargetRef,
          points: measurementTargetRef.points,
          lineName: measurementTargetRef.lineName,
          state: MEASUREMENT_SETTINGS.STATES.moving,
          scale,
          hitAreaEllipseToolRef,
          huData,
          pixelsPerMillimeter: recalculatePixelsToMm(),
        });

        hitEllipse &&
          hitEllipse.hitArea &&
          (objToReturn = {
            ...objToReturn,
            measurementTargetRef: hitEllipse.sprite,
            isDraggingMeasurementToolRef:
              MEASUREMENT_SETTINGS.ELLIPSE_STATE.Moving,
          });
      }
      return objToReturn;
    case MEASUREMENT_SETTINGS.ELLIPSE_STATE.Handle:
      objToReturn = {};
      let target = measurementTargetRef;
      if (
        measurementTargetRef &&
        measurementTargetRef.lineName !==
          MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse &&
        measurementTargetRef.parent
      ) {
        target = measurementTargetRef.parent;
      }

      if (target) {
        target.nodeIndex = measurementTargetRef.nodeIndex;
        const points = target.points;
        const nodeIndex = target.nodeIndex;

        points[nodeIndex] = mousePosition;
        switch (nodeIndex) {
          case 0:
            points[1].y = points[nodeIndex].y;
            points[3].x = points[nodeIndex].x;
            break;
          case 1:
            points[0].y = points[nodeIndex].y;
            points[2].x = points[nodeIndex].x;
            break;
          case 2:
            points[1].x = points[nodeIndex].x;
            points[3].y = points[nodeIndex].y;
            break;
          case 3:
            points[2].y = points[nodeIndex].y;
            points[0].x = points[nodeIndex].x;
            break;
        }
        measurementTargetRef = target;

        const hitEllipse = createEllipseSprite({
          parent: MEASUREMENT_SETTINGS.PARENT.MPR,
          sprite: target,
          points,
          lineName: measurementTargetRef.lineName,
          state: MEASUREMENT_SETTINGS.STATES.moving,
          scale,
          hitAreaEllipseToolRef,
          huData,
          nodeIndex,
          pixelsPerMillimeter: recalculatePixelsToMm(),
        });
        hitEllipse &&
          hitEllipse.hitArea &&
          (objToReturn = {
            ...objToReturn,
            hitAreaEllipseToolRef: hitEllipse.hitArea,
          });
      }
      objToReturn = {
        ...objToReturn,
        measurementTargetRef,
      };
      return objToReturn;
    default:
      //if mouse is over any ellupse se the cursor as 'move'
      //with this we can move  or clcik on any ellipse
      isOverMeasurementToolRef = '';
      if (!hitAreaEllipseToolRef) return null;
      Object.keys(hitAreaEllipseToolRef).forEach((measurementId) => {
        const mouseOver = intersectsNodes(
          hitAreaEllipseToolRef[measurementId],
          mousePosition,
          5 / scale
        );
        if (mouseOver.length) {
          isOverMeasurementToolRef = measurementId;
        }
      });
      return { isOverMeasurementToolRef };
  }
};
