import produce from 'immer';
import { isEqual } from 'lodash';
import { getType } from 'typesafe-actions';
import { ContrastViewType } from '../contrast-types';
import { ViewConfigAction, ViewConfigActions } from './actions';
import {
  DEFAULT_VIEW_CONFIG_STATE,
  MAX_RECENTLY_SELECTED_VIEWS,
} from './constants';
import { ViewConfigState } from './types';

export function viewConfigReducer(
  state: ViewConfigState = DEFAULT_VIEW_CONFIG_STATE,
  action: ViewConfigAction
): ViewConfigState {
  return produce(state, (draftState) => {
    switch (action.type) {
      case getType(ViewConfigActions.changeSeriesId): {
        draftState.seriesId = action.payload;

        break;
      }

      case getType(ViewConfigActions.setViewportsToShowBorder): {
        draftState.viewportsToShowBorder = action.payload;

        break;
      }

      case getType(ViewConfigActions.pushRecentlySelectedViews): {
        // In case we somehow push an empty view
        const newViews = action.payload.filter(
          (x) => x.viewType !== ContrastViewType.Empty
        );

        // Filter out views in current view which are contained in the new views. Unique views only please!
        const currentViews = [
          ...draftState.recentlySelected.filter(
            (recentView) =>
              newViews.find((newView) => isEqual(newView, recentView)) ===
              undefined
          ),
        ];

        // Stop this thing from growing too large, it's unlikely we'll ever need more than MAX_RECENTLY_SELECTED_VIEWS
        const mashWithPreviousViews = [...newViews, ...currentViews].slice(
          0,
          // If we've dragged in series (newViews > 1) we'll allow the list to grow to double
          newViews.length > 1
            ? MAX_RECENTLY_SELECTED_VIEWS * 2
            : MAX_RECENTLY_SELECTED_VIEWS
        );

        draftState.recentlySelected = mashWithPreviousViews;

        break;
      }

      case getType(ViewConfigActions.removeRecentlySelectedView): {
        const newViews = draftState.recentlySelected.filter(
          (x) => x !== action.payload
        );

        draftState.recentlySelected = newViews;

        break;
      }

      case getType(ViewConfigActions.clearRecentlySelectedViews): {
        draftState.recentlySelected = [];

        break;
      }

      default:
        break;
    }
  });
}
