import React, {
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DashboardContextValue } from './types';

const DashboardContext = React.createContext<DashboardContextValue | undefined>(
  undefined
);
DashboardContext.displayName = 'DashboardContext';

interface Props {
  children: ReactNode;
}

export function DashboardProvider({ children }: Props): ReactElement<Props> {
  const defaultCurrentPage = sessionStorage.getItem('dash-current-page') || 1;
  const defaultDashSortKey =
    sessionStorage.getItem('dash-sort-key') || 'last_scan';
  const defaultDashAscending = sessionStorage.getItem('dash-ascending')
    ? true
    : false;
  const defaultSearchString =
    sessionStorage.getItem('dash-search-string') || '';

  const [dashboardCurrentPage, setDashboardCurrentPage] = useState<number>(
    Number(defaultCurrentPage)
  );
  const [dashboardSortKey, setDashboardSortKey] = useState(defaultDashSortKey);
  const [dashboardAscending, setDashboardAscending] = useState(
    defaultDashAscending
  );
  const [dashboardSearchStr, setDashboardSearchStr] = useState(
    defaultSearchString
  );

  useEffect(() => {
    sessionStorage.setItem('dash-current-page', String(dashboardCurrentPage));
  }, [dashboardCurrentPage]);

  useEffect(() => {
    sessionStorage.setItem('dash-sort-key', dashboardSortKey);
  }, [dashboardSortKey]);

  useEffect(() => {
    if (dashboardAscending) {
      sessionStorage.setItem('dash-ascending', 'true');
    } else {
      sessionStorage.removeItem('dash-ascending');
    }
  }, [dashboardAscending]);

  useEffect(() => {
    sessionStorage.setItem('dash-search-string', dashboardSearchStr);
  }, [dashboardSearchStr]);

  const dashboardContext: DashboardContextValue = {
    dashboardCurrentPage,
    dashboardSortKey,
    dashboardAscending,
    dashboardSearchStr,
    setDashboardCurrentPage,
    setDashboardSortKey,
    setDashboardAscending,
    setDashboardSearchStr,
  };
  return (
    <DashboardContext.Provider value={dashboardContext}>
      {children}
    </DashboardContext.Provider>
  );
}

export function useDashboardContext(): DashboardContextValue {
  const context = useContext(DashboardContext);

  if (context === undefined) {
    throw new Error(
      'useDashboardContext must be used within an DashboardProvider.'
    );
  }
  return context;
}
