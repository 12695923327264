import { MutableRefObject } from 'react';
import { Study } from './types';
import vtkVolume from 'vtk.js/Sources/Rendering/Core/Volume';
import { vtkApi } from '../views/CTVolume/ReactVTKJS/ReactVTKJSTypes';

export interface CrosshairValues {
  // The current ct volume crosshair position in model space [X, Y, Z].
  crosshairPos?: number[];
  // The huValue of the ct volume at the current crosshair position.
  huValue?: number;
}

// The contrast volume projection methods.
export enum BlendMode {
  COMPOSITE_BLEND,
  MAXIMUM_INTENSITY_BLEND,
  MINIMUM_INTENSITY_BLEND,
  AVERAGE_INTENSITY_BLEND,
}

// Define the contrast volume view types.
export enum ContrastViewType {
  Axial = 0,
  Sagittal = 1,
  Coronal = 2,
  MPR = 3,
  Empty = 4,
}

// Define the status of the contrast volume.
export enum ContrastVolumeStatus {
  // The contrast volume is loading the volume slices.
  LOADING = 'VOLUME LOADING',
  // The contrast volume failed to load and has terminated the loading process.
  LOAD_FAILED = 'VOLUME LOAD FAILED',
  // The contrast volume has loaded the volume and initialized the initial parameters.
  // NOTE: This occurs after the 'volume' value has been set and the onViewCreated callback has set up initial state.
  LOADED = 'VOLUME LOADED',
}

export enum CTVolumeOverlay {
  INFO = 'INFO',
  REPORT = 'REPORT',
  HIDE = 'HIDE',
}

// The cyclical modes that define what overlays are shown on the contrast volume view.
export enum ContrastVolumeOverlayMode {
  CROSSHAIRS_AND_CENTERLINE,
  NONE,
  COUNT, // The number of overlay modes.
}

/**
 * This stores the information required to know when the contrast views associated with an MPR view need
 * to be synchronized to it. We can't just syncronize when the vessel is adjusted because the contrast
 * view may not have loaded yet etc.
 */
export interface VesselSyncInfo {
  // The id of the study that was last synchronized.
  studyId: string;
  // The name of the vessel that was last synchronized.
  vesselName: string;
  // The index of the slice that was last synchronized.
  sliceIndex: number;
}

// Get a paired string identifier for the study and series.
export function getStudySeriesId(study: Study, seriesName: string) {
  // The study should NEVER be undefined but some recent data seems to have it as undefined.
  return `${study?.study_id || 'unknown'}-${seriesName}`;
}

// The pairing of a study and the name of the series of interest.
export interface StudySeries {
  // The study the volume belongs to.
  study: Study;
  // The name of the series the volume belongs to.
  seriesName: string;
}
export type StudySeriesMap = Map<string, StudySeries>;

// The details of what is showing in a view on the contrast volume page.
export interface ContrastViewContent {
  // The study the volume belongs to (or undefined if the viewType is Empty).
  study?: Study;
  // The name of the series the volume belongs to (or undefined if the viewType is Empty).
  seriesName?: string;
  // The view type being shown.
  viewType: ContrastViewType;
}

// The information describing the way to render a view of a ContrastVolume.
export interface ContrastVolumeViewProps {
  // The type of projection used when casting rays on this volume (ie MIP, MinIP, or AvgIP).
  blendMode: BlendMode;
  // The thickness through which the ray will be cast when rendering the volume (using MIP, MinIP, or AvgIP).
  renderThickness: number;
  // The cyclical modes that define what overlays are shown on the contrast volume view.
  overlayMode: ContrastVolumeOverlayMode;
}

// The information describing the way to render a view of a ContrastVolume.
export interface ContrastVolumeCenterline {
  // The array of points to display in the world coordinates of the volume.
  points: number[][];
}
export type ContrastVolumeCenterlineMap = Map<string, ContrastVolumeCenterline>;

// The information describing a loaded contrast CT volume.
export interface ContrastVolume {
  // The study the volume belongs to.
  study: Study;
  // The name of the series the volume belongs to.
  seriesName: string;
  // The number of slice images to load.
  imageCount: number;
  // The number of slice images currently loaded.
  imageCountLoaded: number;
  // Flag if the image load failed and can't be continued.
  status: ContrastVolumeStatus;
  // The spacing (size) of the voxels in [X, Y, Z].
  spacing: number[];
  /* NOTE: We no longer use per-series WW/WL.
  // The default windowWidth / windowCenter to use when rendering the volume.
  defaultWindowLevels: WindowLevels;
  */
  // The loaded volume data.
  volume: vtkVolume | undefined;
  // The loaded volume data wrapped in an array to make ReactVTKJS happy (this needs to be consistent between renders).
  volumes: vtkVolume[];
  // The array of vtkApis (one for each view showing this volume) (this also needs to be consistent between renders).
  apis: vtkApi[];
  // The map from vessel name to centerline to display on this volume.
  // NOTE: Centerlines should only be added to volumes for the series that has been ai_assessed.
  centerlineMap: ContrastVolumeCenterlineMap;
  // The crosshair position and HU value.
  crosshairValues: CrosshairValues;
  // The position of the crosshairs [X, Y, Z] (this value gets mutated by vtk, it's also shared between views).
  crosshairWorldPosition: number[];
  // The directional unit vector for each of the crosshair axes [ Axial, Sagittal, Coronal ] (this value gets mutated by vtk, it's also shared between views).
  crosshairWorldAxes: number[][];
  // The information describing the way to render each view of the ContrastVolume.
  viewProps: ContrastVolumeViewProps[];
  // A helper function to get the api that is used on the specified viewIndex.
  getApi: (viewIndex: number) => vtkApi | undefined;
}

export type ContrastVolumeMap = Map<string, ContrastVolume>;
export enum ContrastVolumeActions {
  // Initialize the ContrastVolume.
  LOAD = 'LOAD',
  // Update the volume's status, if LOAD_FAILED is set then the ContrastVolume will abort loading.
  SET_STATUS = 'SET_STATUS',
  // Specify the number of images now loaded for the ContrastVolume.
  IMAGE_LOADED = 'IMAGE_LOADED',
  // Specify the number of images to load for the ContrastVolume.
  SET_IMAGE_COUNT = 'SET_IMAGE_COUNT',
  // Set the ContrastVolume volume, spacing and default window levels. This is the final step for loading.
  SET_VOLUME_SPACING_AND_WINDOW_LEVELS = 'SET_VOLUME_SPACING_AND_DEFAULT_WINDOW_LEVELS',
  // Add a centerline on the ContrastVolume. Note that only series that has been ai_assessed should have and display centerline data.
  SET_CENTERLINE = 'SET_CENTERLINE',
  // Set the ContrastVolume viewProps value for the specified ContrastViewType of the type
  // of projection used when casting rays on this volume (ie MIP, MinIP, or AvgIP).
  SET_BLEND_MODE = 'SET_BLEND_MODE',
  // Set the ContrastVolume viewProps value for the specified ContrastViewType of the thickness
  // through which the ray will be cast when rendering the volume (using MIP, MinIP, or AvgIP).
  SET_RENDER_THICKNESS = 'SET_RENDER_THICKNESS',
  // Set which overlays are currently visible on the volume.
  SET_OVERLAY_MODE = 'SET_OVERLAY_MODE',
  // Reset the renderThickness and blendMode for all views on all volumes.
  RESET_VIEWS = 'RESET_VIEWS',
  // Update the ContrastVolume crosshair values for the given viewIndex.
  UPDATE_CROSSHAIR_VALUES = 'UPDATE_CROSSHAIR_VALUES',
  // Update the ContrastVolume crosshair values for the given ContrastVolume.
  UPDATE_CROSSHAIR_VALUES_FOR_VOLUME = 'UPDATE_CROSSHAIR_VALUES_FOR_VOLUME',
  // Remove the specified ContrastVolume from the store.
  REMOVE = 'REMOVE',
}
export type ContrastVolumeBasicAction = { study: Study; seriesName: string };
export type ContrastVolumeLoadAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.LOAD;
};
export type ContrastVolumeSetStatusAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.SET_STATUS;
  status: ContrastVolumeStatus;
};
export type ContrastVolumeImageLoadedAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.IMAGE_LOADED;
  imageCountLoaded: number;
};
export type ContrastVolumeSetImageCountAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.SET_IMAGE_COUNT;
  imageCount: number;
};
export type ContrastVolumeSetVolumeSpacingWindowAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.SET_VOLUME_SPACING_AND_WINDOW_LEVELS;
  volume: vtkVolume;
  spacing: number[];
  /* NOTE: We no longer use per-series WW/WL.
  defaultWindowLevels: WindowLevels;
  */
};
export type ContrastVolumeSetCenterlineAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.SET_CENTERLINE;
  vesselName: string;
  points: number[][];
};
export type ContrastVolumeSetBlendModeAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.SET_BLEND_MODE;
  viewType: ContrastViewType;
  blendMode: BlendMode;
};
export type ContrastVolumeSetRenderThicknessAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.SET_RENDER_THICKNESS;
  viewType: ContrastViewType;
  renderThickness: number;
};
export type ContrastVolumeSetOverlayModeAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.SET_OVERLAY_MODE;
  viewType: ContrastViewType;
  overlayMode: ContrastVolumeOverlayMode;
};
export type ContrastVolumeRemoveAction = ContrastVolumeBasicAction & {
  type: ContrastVolumeActions.REMOVE;
};
export type ContrastVolumeResetViewsAction = {
  type: ContrastVolumeActions.RESET_VIEWS;
};
export type ContrastVolumeUpdateCrosshairValuesAction = {
  type: ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES;
  viewIndex: number;
};
export type ContrastVolumeUpdateCrosshairValuesForVolumeAction = {
  type: ContrastVolumeActions.UPDATE_CROSSHAIR_VALUES_FOR_VOLUME;
  contrastVolume: ContrastVolume;
};
export type ContrastVolumeAction =
  | ContrastVolumeLoadAction
  | ContrastVolumeSetStatusAction
  | ContrastVolumeImageLoadedAction
  | ContrastVolumeSetImageCountAction
  | ContrastVolumeSetVolumeSpacingWindowAction
  | ContrastVolumeSetCenterlineAction
  | ContrastVolumeSetBlendModeAction
  | ContrastVolumeSetRenderThicknessAction
  | ContrastVolumeSetOverlayModeAction
  | ContrastVolumeResetViewsAction
  | ContrastVolumeUpdateCrosshairValuesAction
  | ContrastVolumeUpdateCrosshairValuesForVolumeAction
  | ContrastVolumeRemoveAction;

export interface ContrastPendingScreenshot {
  // The index of the view that is generating a screenshot.
  viewIndex: number;
  // The function to call when the view has been set to full size (to take the screenshot).
  onTakeScreenshotCallback: (imageData: string) => void;
  // The visibleViews array before the screenshot was taken.
  lastVisibleViews: number[];
}

export interface ContrastContextValue {
  // If set we are waiting for the view to resize so we can take a screenshot.
  pendingScreenshot?: ContrastPendingScreenshot;
  // If set we are waiting for the view to resize so we can take a screenshot.
  onTakeScreenshot: (
    viewIndex: number,
    onTakeScreenshotCallback: (imageData: string) => void
  ) => void;
  // Call if the pending screenshot has now been taken.
  onCloseScreenshot: () => void;
  // Should the contrast views associated with the MPR view synchronize with the vessel slice on the MPR view?
  vesselSync: boolean;
  setVesselSync: (vesselSync: boolean) => void;
  // The last syncronized study, vessel name and slice index (as a ref).
  vesselSyncInfo: { current: VesselSyncInfo | undefined };
  // The currently visible views (this array could be 1, 2, or 4 in length and will include each view index (0, 1, 2, 3) no more than once).
  // This is updated by calling doubleClickView() or onVisibleViewCountButton().
  visibleViews: number[];
  // When a view is double clicked and multiple views are currently visible we switch to showing this single view.
  // When a view is double clicked and only that view is visible we restore the previous multi-view configuration.
  onDoubleClickView: (viewIndex: number) => void;
  // Respond to a keydown event when the mouse is over the specified viewIndex.
  onKeyDown: (viewIndex: number, event: KeyboardEvent) => void;
  // Update the visibleViews to reflect the newly requested visible view count.
  onVisibleViewCountButton: (visibleViewCount: number) => void;
  // The array of views currently being shown on the contrast volume page.
  contrastViews: ContrastViewContent[];
  // Switch to showing all (3 or 4) views of the contrast series.
  onDraggedContrastSeries: (contrastViews: ContrastViewContent[]) => void;
  // Update the single view of index viewIndex with the specified contrastViewContent.
  onDraggedContrastView: (
    contrastView: ContrastViewContent,
    viewIndex: number
  ) => void;
  // The map of studySeriesIds being used by the ContrastViewer.
  activeStudySeries: StudySeriesMap;
  // The map of studySeriesId to ContrastVolume.
  contrastVolumeMap: ContrastVolumeMap;
  // Fire off an action that changes a ContrastVolume.
  dispatchContrastVolumeAction: (action: ContrastVolumeAction) => void;
  // Maps contrast view indexes to their overlay state
  contrastOverlays: Record<number, CTVolumeOverlay>;
  // Set the overlay state for a particular contrast view
  setContrastOverlayForViewIndex: (
    overlay: CTVolumeOverlay,
    viewIndex: number
  ) => void;
  // Ref to the volume viewer div (className='volume-viewer')
  volumeViewerRef: MutableRefObject<any>;
}
