import React, { ChangeEvent, useCallback, useState } from 'react';
import Select from '../Select/Select';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { FINDINGS_LAYOUT, VesselGroupings } from '../../config';
import { CoronaryFindings, heartDominance } from '../../context/types';
import { useCoronaryFindingIsAiAssessedSelector } from '../../selectors/reporting';
import { getMajorVesselGrouping } from '../../utils/vessel';
import KeyVesselFinding from '../KeyVesselFinding';

const HEART_DOMINANCES = ['right dominant', 'left dominant', 'co dominant'];

interface KeyVesselFindingsProps {
  coronaryFindings: CoronaryFindings | undefined;
  editFindings: boolean;
  draftCoronaryFindings: CoronaryFindings | undefined;
  onUpdateKeyFindings: (newValue: string, vessel: VesselGroupings) => void;
  onUpdateHeartDominance: (newValue: string | null) => void;
  onActiveDirtyField: (isActive: boolean) => void;
  heartDominance?: heartDominance | undefined;
  draftHeartDominance?: heartDominance | undefined;
}

interface ActiveField {
  vessel: string;
  isFocused: boolean;
  isDirty: boolean;
}

const FINDINGS_LAYOUT_MAPPING: { [key: string]: string[] } = FINDINGS_LAYOUT;

export const KeyVesselFindings: React.FC<KeyVesselFindingsProps> = ({
  coronaryFindings,
  editFindings,
  onUpdateKeyFindings,
  onActiveDirtyField,
  onUpdateHeartDominance,
  draftCoronaryFindings,
  heartDominance,
  draftHeartDominance,
}) => {
  const [activeField, setActiveField] = useState<ActiveField>({
    vessel: '',
    isFocused: false,
    isDirty: false,
  });

  const coronaryFindingsAiAssessed = useCoronaryFindingIsAiAssessedSelector();

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>, vessel: VesselGroupings) => {
      const initialValue = coronaryFindings?.[vessel];
      const value = event.target.value;
      const isDirty = initialValue !== value;

      setActiveField((prev) => ({ ...prev, isDirty, vessel }));

      onUpdateKeyFindings(value, vessel);

      if (isDirty) {
        onActiveDirtyField(true);
      }
    },
    [coronaryFindings, setActiveField, onUpdateKeyFindings, onActiveDirtyField]
  );
  const handleHeartDominance = useCallback(
    (heartDominance: string | null) => {
      if (heartDominance === '') {
        heartDominance = null;
      }
      onUpdateHeartDominance(heartDominance);
    },
    [onUpdateHeartDominance]
  );
  const handleFocus = useCallback(() => {
    setActiveField((prev) => ({ ...prev, isFocused: true }));
  }, [setActiveField]);

  const handleBlur = useCallback(() => {
    setActiveField({
      vessel: '',
      isDirty: false,
      isFocused: false,
    });
    onActiveDirtyField(false);
  }, [setActiveField, onActiveDirtyField]);

  return (
    <div className="key-findings">
      <div className="vessel-finidngs__heart-dominance">
        <div className="procedure-details__data-row">
          <div className="procedure-details__data-cell">
            {heartDominance?.heart_dominance != null && (
              <dd className="procedure_details__data-name">
                The patient's heart is
              </dd>
            )}
            {editFindings && heartDominance?.heart_dominance == null && (
              <dd className="procedure_details__data-name">
                The patient's heart is
              </dd>
            )}

            {!editFindings && heartDominance?.heart_dominance == null && (
              <dd className="procedure_details__data-name indicate-heart-dominance">
                <button
                  className="procedure-details__data__info-button"
                  onClick={() => {}}
                  title="Remove"
                >
                  <span className="info-icon">
                    <Info />
                  </span>
                </button>
                Indicate heart dominance
              </dd>
            )}
          </div>

          <div className="procedure-details__data-cell">
            {editFindings && (
              <Select
                addPlaceholderAsOption={true}
                options={HEART_DOMINANCES}
                value={
                  editFindings
                    ? draftHeartDominance?.heart_dominance
                      ? draftHeartDominance?.heart_dominance + ' dominant'
                      : draftHeartDominance?.heart_dominance || ''
                    : heartDominance?.heart_dominance || ''
                }
                onChange={handleHeartDominance}
              />
            )}
            {!editFindings && heartDominance?.heart_dominance != null && (
              <dd className="procedure-details__data-value">
                {heartDominance?.heart_dominance} dominant
              </dd>
            )}
          </div>
        </div>
      </div>

      {Object.keys(FINDINGS_LAYOUT_MAPPING).map((col) => {
        return (
          <div
            className="vessel-finidngs-col"
            id={`vessel-finidngs-col__${col}`}
            key={`vessel-finidngs-col__${col}`}
          >
            {coronaryFindings &&
              FINDINGS_LAYOUT_MAPPING[col].map((v) => {
                const vesselName = getMajorVesselGrouping(v);

                if (!vesselName) {
                  return undefined;
                }

                return (
                  <KeyVesselFinding
                    key={`vessel-findings-${v}`}
                    showVesselName={true}
                    vesselGroup={vesselName}
                    editMode={editFindings}
                    coronaryFindings={coronaryFindings}
                    draftCoronaryFindings={
                      draftCoronaryFindings?.[vesselName] ?? ''
                    }
                    handleInputChange={handleInputChange}
                    handleFocus={handleFocus}
                    handleBlur={handleBlur}
                    activeField={activeField}
                    statusVersion={!coronaryFindingsAiAssessed[vesselName]}
                  />
                );
              })}
          </div>
        );
      })}
    </div>
  );
};
