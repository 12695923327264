import { WallPolygons } from '../../reducers/vessel-data';

export function processData(data: WallPolygons | undefined) {
  if (data) {
    const processed: any[] = [];
    Object.keys(data).forEach((key) => {
      processed[parseInt(key)] = data[key].map((p: any) => ({
        x: p[1],
        y: p[0],
      }));
    });
    return processed;
  }
  return [];
}
