import React, {
  ReactElement,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from 'react';
import {
  DEFAULT_VESSEL_DATA_STATE,
  VesselDataAction,
  vesselDataReducer,
  VesselDataState,
} from '../reducers/vessel-data';

export interface VesselDataContextValue {
  state: VesselDataState;
  dispatch: (action: VesselDataAction) => void;
}

const VesselDataContext = React.createContext<
  VesselDataContextValue | undefined
>(undefined);

VesselDataContext.displayName = 'VesselDataContext';

interface Props {
  children: ReactNode;
}

export function VesselDataProvider({ children }: Props): ReactElement<Props> {
  const [vesselDataState, vesselDataAction] = useReducer(
    vesselDataReducer,
    DEFAULT_VESSEL_DATA_STATE
  );

  const vesselDataContextValue: VesselDataContextValue = useMemo(
    () => ({
      state: vesselDataState,
      dispatch: vesselDataAction,
    }),
    [vesselDataState, vesselDataAction]
  );

  return (
    <VesselDataContext.Provider value={vesselDataContextValue}>
      {children}
    </VesselDataContext.Provider>
  );
}

/**
 * Hook to access the VesselDataContext
 * @returns {VesselDataContextValue} VesselDataContextValue
 */
export function useVesselDataContext(): VesselDataContextValue {
  const context = useContext(VesselDataContext);
  if (context === undefined) {
    throw new Error(
      'useVesselDataContext must be used within a VesselDataProvider.'
    );
  }
  return context;
}
