import { InlineReportingAction } from './actions';

export enum UnsavedChangesToInlineReport {
  LeavingStudy = 0,
  EditingReport,
  ApprovingReport,
  GeneratingReport,
  SendingStudyForApproval,
}

export interface InlineReportingState {
  lm_coronary_findings?: string;
  lad_coronary_findings?: string;
  lcx_coronary_findings?: string;
  rca_coronary_findings?: string;
  impressions?: string[];
  showWarningType?: {
    type: UnsavedChangesToInlineReport;
    passthroughAction: () => void;
  };
}

export interface InlineReportingContextValue {
  state: InlineReportingState;
  dispatch: (action: InlineReportingAction) => void;
}
