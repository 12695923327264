import { reshape } from 'mathjs';
import { STENOSIS_VALUES, LOG_LEVEL } from '../config';

export function clampNumber(number: number, min: number, max: number) {
  return Math.min(Math.max(number, min), max);
}

export function isCanvas(el: any) {
  return el instanceof HTMLCanvasElement;
}

export function getStenosisCategory(stenosis: number | null) {
  if (!stenosis && stenosis !== 0) return null;

  const stenosisValue = Math.round(stenosis * 100);
  if (Number.isNaN(stenosisValue)) return null;

  if (stenosisValue === 0) {
    return STENOSIS_VALUES[0];
  } else if (stenosisValue >= 1 && stenosisValue <= 24) {
    return STENOSIS_VALUES[1];
  } else if (stenosisValue >= 25 && stenosisValue <= 49) {
    return STENOSIS_VALUES[2];
  } else if (stenosisValue >= 50 && stenosisValue <= 69) {
    return STENOSIS_VALUES[3];
  } else if (stenosisValue >= 70 && stenosisValue <= 99) {
    return STENOSIS_VALUES[4];
  } else if (stenosisValue === 100) {
    return STENOSIS_VALUES[5];
  }
  return null;
}

// warning below uses a PRNG and should not be relied upon for anything
// aside from debug
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// using name attribute of Error as the way of typing an error
const ABORT_PROMISE_ERR_NAME = 'AbortPromiseError';

class AbortPromiseError extends Error {
  name: typeof ABORT_PROMISE_ERR_NAME = 'AbortPromiseError';
}

const unresolvedPromises = {} as any;
const target = window as any;
target.UNRESOLVEDPROMISES = unresolvedPromises;

const AbortPromiseWrapper = async (
  promise: Promise<unknown>,
  signal: { aborted: any },
  message = ''
) => {
  const promiseId = uuidv4();
  unresolvedPromises[promiseId] = { promise: promise, message: message };
  let result;
  try {
    result = await promise;
  } catch (err) {
    throw err;
  } finally {
    delete unresolvedPromises[promiseId];
  }
  if (signal.aborted) {
    throw new AbortPromiseError();
  }
  return result;
};

const AbortablePromise = (
  promise_func: (
    resolve: (value: unknown) => void,
    reject: (reason?: any) => void
  ) => void,
  signal: any
) => {
  return AbortPromiseWrapper(new Promise(promise_func), signal);
};

const isAbortError = (err: { name: string }) => {
  if (typeof err !== 'object') return false;
  return err.name === ABORT_PROMISE_ERR_NAME;
};

export {
  AbortPromiseError,
  AbortPromiseWrapper,
  AbortablePromise,
  isAbortError,
};

export function uid() {
  let a = new Uint32Array(3);
  window.crypto.getRandomValues(a);
  return (
    performance.now().toString(36) +
    Array.from(a)
      .map((A) => A.toString(36))
      .join('')
  ).replace(/\./g, '');
}

// Returns an empty buffer of a given shape for writing to
export function getEmptyBuffer(width: number, height = 1) {
  return new Uint8ClampedArray(width * height * 4);
}

// Places a greyscale matrix into a given buffer (which must have matching
// dimensions) after windowing into an 8 bit range [0, 255]
export function addWindowedMatrix2dToBuffer(
  buffer: number[] | Uint8ClampedArray,
  matrix: string | any[],
  wl: number,
  ww: number
) {
  // TODO check matching dimensions first

  // Prepare for windowing
  var wmin = wl - ww / 2.0;
  var wmax = wl + ww / 2.0;

  // Draw matrix into buffer in range [0, 255] depending on windowing
  let shape = [matrix.length, matrix[0].length];
  for (var r = 0; r < shape[0]; r++) {
    for (var c = 0; c < shape[1]; c++) {
      // Position in volume array based on row and col index
      var volpos = r * shape[1] + c;
      var bufpos = volpos * 4;

      // Windowed value
      var wvalue = ((matrix[r][c] - wmin) / (wmax - wmin)) * (255 - 0) + 0;

      // RGBA values respectively
      buffer[bufpos + 0] = wvalue;
      buffer[bufpos + 1] = wvalue;
      buffer[bufpos + 2] = wvalue;
      buffer[bufpos + 3] = 255;
    }
  }

  return buffer;
}

// Simple reshaper, useful for reshaping flattened h5s for example
export function reshapeArray1d(matrix: any, dimensions: number[]) {
  return reshape(matrix, dimensions);
}

export const timeFuncFactory = (
  func: (...args: any) => any,
  label: string = 'anonymous'
) => {
  const rv_func = (...args: any): any => {
    const t0 = performance.now();
    const rv = func(...args);
    const t1 = performance.now();
    // 2 is timing level
    if (LOG_LEVEL >= 2) {
      console.log(`${label} took ` + (t1 - t0) + 'ms');
    }
    return rv;
  };
  return rv_func;
};
