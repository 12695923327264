import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import Dropdown, { OptionsOrGroups, SingleValue } from 'react-select';
import usePreviousValue from '../../hooks/use-previous-value';
import Styles from './SelectStyle';
import { isEqual } from 'lodash';

interface SelectProps {
  theme?: 'default' | 'alternative';
  options: OptionsOrGroups<any, never>;
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  inline?: boolean;
  scrollable?: boolean;
  disabled?: boolean;
  hideFirstOption?: boolean;
  addPlaceholderAsOption?: boolean;
  showMenuOnTop?: boolean;
  isSearchable?: boolean;
  menuIsOpen?: boolean;
}

const Select: React.FC<SelectProps> = ({
  theme = 'default',
  options: dropdownOptions = [],
  value,
  onChange,
  placeholder = 'Please select',
  inline,
  scrollable,
  disabled,
  hideFirstOption,
  addPlaceholderAsOption = false,
  showMenuOnTop = false,
  isSearchable = false,
  menuIsOpen = undefined,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const prevDropdownOptions = usePreviousValue(dropdownOptions);
  const [options, setOptions] = useState<OptionsOrGroups<any, never>>([]);

  useEffect(() => {
    // if the dropdown options have ACTUALLY changed THEN parse the options...
    if (!isEqual(dropdownOptions, prevDropdownOptions)) {
      let parsedOptions = dropdownOptions;

      // if we need to reformat the options to match the react-select format...
      if (
        dropdownOptions.length > 0 &&
        typeof dropdownOptions[0] === 'string'
      ) {
        parsedOptions = dropdownOptions.map((option: string) => ({
          value: option,
          label: option,
        }));
      }

      // if for some reason we need the placeholder to be an actual option in the dropdown...
      const val = value === '' ? placeholder : value;
      if (addPlaceholderAsOption && val !== placeholder) {
        parsedOptions = [
          { label: placeholder, value: placeholder },
          ...parsedOptions,
        ];
      }

      setOptions(parsedOptions);
    }
  }, [
    dropdownOptions,
    prevDropdownOptions,
    options,
    placeholder,
    value,
    addPlaceholderAsOption,
  ]);

  const getValue = useCallback(() => {
    let val: SingleValue<string | number | undefined>;
    // if no value prop is passed in then use the placeholder as the value
    const defaultValue = { value: placeholder, label: placeholder };
    // as the value is passed in as a string we need to look for the value in the current
    // options array and use that matching object as the value
    if (value) {
      val = options.find((option: any) => option.value === value);
    }
    return val ? val : defaultValue;
  }, [options, value, placeholder]);

  return (
    <Dropdown
      isSearchable={isSearchable}
      className={cn('select', {
        select__alternative: theme === 'alternative',
        'select--focus': isFocused,
        'select--inline': inline,
        'select--scrollable': scrollable,
        'select--disabled': disabled,
        'select--hide-first-option': hideFirstOption,
        'select--show-menu-on-top': showMenuOnTop,
      })}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      tabSelectsValue={false}
      isDisabled={disabled}
      menuIsOpen={menuIsOpen}
      styles={Styles}
      classNamePrefix="select"
      value={getValue()}
      options={options}
      menuPlacement={showMenuOnTop ? 'top' : 'bottom'}
      onChange={(e) => {
        const value =
          e && e.value === placeholder ? '' : e && e.value ? e.value : '';
        if (onChange) onChange(value);
      }}
      placeholder={placeholder}
    />
  );
};

export default Select;
