import cn from 'classnames';
import React, { useEffect } from 'react';
import { IconButton } from '../IconButton/IconButton';
import { useMeasurementToolContext } from '../../context/measurement-tools-context';
import { useStoreContext } from '../../context/store-context';
import { NAV_TABS } from '../../config';

const MeasurementToolbar: React.FC = () => {
  const {
    isMeasurementMode,
    setIsMeasurementMode,
    isRulerActive,
    setIsRulerActive,
    isEllipseActive,
    setIsEllipseActive,
    setClearMeasurements,
  } = useMeasurementToolContext();
  const { visibleTab } = useStoreContext();

  useEffect(() => {
    setIsMeasurementMode(false);
    setIsRulerActive(false);
    setIsEllipseActive(false);
  }, [visibleTab]);

  return (
    <div
      className={cn('measurement-toolbar', {
        'measurement-toolbar__ct-volume': visibleTab == NAV_TABS.ctVolumeTab,
        'measurement-mode-active': !!isMeasurementMode,
      })}
    >
      <div
        className={cn('measurement-toolbar-selected', {})}
        onClick={() => {
          setIsMeasurementMode(!isMeasurementMode);
          if (!isRulerActive && !isEllipseActive) {
            setIsRulerActive(true);
          }
        }}
        title=""
      >
        <span className="measurement-toolbar-selected_button__icon">
          <IconButton
            selected={isMeasurementMode}
            color="toggle"
            icon={
              isRulerActive
                ? 'straightRuler'
                : isEllipseActive
                ? 'ellipseRuler'
                : 'ruler'
            }
            onClick={() => {}}
          />
        </span>
      </div>
      <div className="measurement-toolbar__popup">
        <div className="measurement-toolbar__popup-items">
          <div
            className={cn('straight-ruler-button measurement-toolbar__item', {
              'measurement-toolbar__item-selected': isRulerActive,
            })}
            onClick={() => {
              setIsMeasurementMode(true);
              setIsRulerActive(true);
              setIsEllipseActive(false);
            }}
          >
            <span className="straight-ruler-button__icon ">
              <IconButton
                selected={isRulerActive}
                color="toggle"
                icon="straightRuler"
                onClick={() => {}}
              />
            </span>
            <span className="measurement-toolbar__item-label">Ruler</span>
          </div>
          <div
            className={cn('ellipse-ruler-button measurement-toolbar__item', {
              'measurement-toolbar__item-selected': isEllipseActive,
            })}
            onClick={() => {
              setIsMeasurementMode(true);
              setIsEllipseActive(true);
              setIsRulerActive(false);
            }}
          >
            <span className="ellipse-ruler-button__icon">
              <IconButton
                selected={isEllipseActive}
                color="toggle"
                icon="ellipseRuler"
                onClick={() => {}}
              />
            </span>
            <span className="measurement-toolbar__item-label">Ellipse</span>
          </div>
          <div
            className={cn('visibility-off-button measurement-toolbar__item')}
            onClick={() => {
              setClearMeasurements((prev: number) => prev + 1);
            }}
          >
            <span className="visibility-off-button__icon">
              <IconButton
                selected={false}
                color="toggle"
                icon="visibilityOffIcon"
                onClick={() => {}}
              />
            </span>
            <span className="measurement-toolbar__item-label">Clear all</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeasurementToolbar;
