/**
 * Computed styles. Used when styling needs to be done inline rather than in CSS
 * CSS variables are defined in src/styles/settings
 */

export const Colors = {
  codGray: getComputedStyle(document.documentElement).getPropertyValue(
    '--color-cod-gray'
  ),

  doveGray: getComputedStyle(document.documentElement).getPropertyValue(
    '--color-dove-gray'
  ),

  dustyGray: getComputedStyle(document.documentElement).getPropertyValue(
    '--color-dusty-gray'
  ),

  dodgerBlue: getComputedStyle(document.documentElement).getPropertyValue(
    '--color-dodger-blue'
  ),

  completelyTransparent: getComputedStyle(
    document.documentElement
  ).getPropertyValue('--color-completely-transparent'),

  mineShaft: getComputedStyle(document.documentElement).getPropertyValue(
    '--color-mine-shaft'
  ),
};

export const ACUMIN_PRO = getComputedStyle(
  document.documentElement
).getPropertyValue('--font-acumin-pro');
