import { KonvaEventObject } from 'konva/types/Node';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Text, Transformer } from 'react-konva';
import { Annotation } from './types';

const PLACEHOLDER_TEXT = 'Double click to edit...';

interface Props {
  annotation: Annotation;
  selected: boolean;
  onDragEnd: (event: KonvaEventObject<DragEvent>) => void;
  onMouseDown: () => void;
  onEdit: () => void;
  onSave: (value: string) => void;
}

export const AnnotationText = ({
  annotation,
  selected,
  onDragEnd,
  onMouseDown,
  onEdit,
  onSave,
}: Props): ReactElement<Props> => {
  const transformerRef = useRef<any | null>(null);
  const textRef = useRef<any | null>(null);
  const [editing, setEditing] = useState(true);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [areaWidth, setAreaWidth] = useState<number | undefined>();

  const [textAnnotations, setTextAnnotations] = useState(annotation);

  const attachTransformer = useCallback(() => {
    if (selected && transformerRef.current && textRef.current) {
      transformerRef.current.nodes([textRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selected]);

  // Called when clicking outside of the text box
  const stopEditing = useCallback(() => {
    if (textRef.current && textAreaRef.current) {
      const textTrimmed = textAreaRef.current.value.trim();
      // If the user entered no text, change it back to the placeholder
      const textEntered =
        textTrimmed.length !== 0 ? textAreaRef.current.value : PLACEHOLDER_TEXT;

      onSave(textEntered);
      const layer = textRef.current.getLayer();

      // Update the text to show when not in edit mode
      textRef.current.text(textEntered);

      layer.draw();

      setEditing(false);
    }
  }, [onSave]);

  const onBlur = useCallback(() => {
    if (editing) {
      stopEditing();
    }

    textAreaRef.current?.removeEventListener('blur', onBlur);

    if (textAreaRef.current) {
      const textBlockWidth =
        textRef.current.getTextWidth() >
        parseFloat(textAreaRef.current.style.width)
          ? parseFloat(textAreaRef.current.style.width)
          : undefined;

      setAreaWidth(textBlockWidth);
      textAreaRef.current?.parentNode?.removeChild(textAreaRef.current);
      textAreaRef.current = null;
    }
  }, [editing, stopEditing]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      switch (e.code.toLowerCase()) {
        case 'enter': {
          if (!e.shiftKey) {
            onBlur();
          }
          break;
        }
        case 'escape': {
          onBlur();
        }
      }
    },
    [onBlur]
  );

  // Called when adding the text box or double clicking to edit it
  const startEditing = () => {
    const textPosition = textRef.current.getAbsolutePosition();
    const stage = textRef.current.getStage();
    const stageBox = stage.container().getBoundingClientRect();
    const areaPosition = {
      x: stageBox.left + textPosition.x,
      y: stageBox.top + textPosition.y,
    };

    textAreaRef.current = document.createElement('textarea');

    // (PART 1) If the text box is empty on edit (this happens when you start editing a text box that was
    // displaying the placeholder text), add the placeholder text temporarily just so we can get the
    // correct sizing for the box. See comment (PART 2) where we set it back to empty after the size
    // has been calculated.
    if (textRef.current.text() === '') {
      const layer = textRef.current.getLayer();
      textRef.current.text(PLACEHOLDER_TEXT);
      layer.draw();
    }

    document.body.appendChild(textAreaRef.current);

    let width = textRef.current.width();
    let height = textRef.current.height();
    const scale = textRef.current.getAbsoluteScale();
    width = width * scale.x;
    height = height * scale.y;

    textAreaRef.current.style.top = `${areaPosition.y + height / 2}px`;
    textAreaRef.current.style.left = `${areaPosition.x + width / 2}px`;
    textAreaRef.current.style.width = `${width}px`;
    textAreaRef.current.style.minHeight = `${height}px`;

    textAreaRef.current.onkeydown = onKeyDown;

    textAreaRef.current.focus();

    textAreaRef.current.addEventListener('blur', onBlur);

    // (PART 2) If the current text is still the PLACEHOLDER_TEXT, set it back to an empty string
    // so the user doesn't have to delete the text before editing (this is the main part of the fix for SALIX-1001)
    if (textRef.current.text() === PLACEHOLDER_TEXT) {
      const layer = textRef.current.getLayer();
      textRef.current.text('');
      layer.draw();
    }

    textAreaRef.current.value = textRef.current.text();
    textAreaRef.current.classList.add('annotation-textarea');

    // Hide the original text element behind during edit mode
    const layer = textRef.current.getLayer();
    textRef.current.text('');
    layer.draw();

    onEdit();
  };

  useEffect(() => {
    attachTransformer();
  }, [selected, attachTransformer]);

  useEffect(() => {
    editing && startEditing();
  }, [editing]);

  useEffect(() => {
    setTextAnnotations(annotation);
  }, [annotation]);

  return (
    <>
      <Text
        id={undefined}
        ref={textRef}
        x={textAnnotations.x}
        y={textAnnotations.y}
        width={areaWidth}
        fontSize={18}
        lineHeight={1.3}
        fill="white"
        text={
          editing
            ? textAnnotations.textVal ?? ''
            : textAnnotations.textVal ?? PLACEHOLDER_TEXT
        }
        draggable={true}
        onMouseDown={onMouseDown}
        onDragEnd={onDragEnd}
        onDblClick={() => {
          setEditing(true);
        }}
      />
      {selected && (
        <Transformer
          id={undefined}
          ref={transformerRef}
          rotateEnabled={false}
          enabledAnchors={[
            'top-left',
            'top-right',
            'bottom-left',
            'bottom-right',
          ]}
          onMouseUp={(event) => (event.cancelBubble = true)}
        />
      )}
    </>
  );
};
