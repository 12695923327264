import * as intersects from 'intersects';
import * as PIXI from 'pixi.js-legacy';
import {
  CreateMarkerSpriteInterface,
  DrawDashedLineInterface,
  ToolTypes,
  ParentType,
  RulerStates,
  EllipseStates,
  MM_TO_PX,
  HuData,
  HandlePosition,
} from './types';
import straightRulerIcon from '../../assets/icons/straight-ruler-blue.svg';
import ellipseRulerIcon from '../../assets/icons/ellipse-ruler-blue.svg';
import { PointObject } from '../../types/common';
import { MeasurementGraphics } from './types';
import { COLOR_CPR_MARKER_COLOR_INDICATOR } from '../../config';
import { XYCoords } from '../../reducers/vessel-data';
import { createEllipseSprite, createRulerSprite } from './';

export const MEASUREMENT_SETTINGS = {
  COLOR: 0x000000,
  WIDTH: 1,
  SHADOW_OPACITY: 0.7,
  SHADOW_SIZE: 3,
  SHADOW_COLOR: 0xffffff,
  BORDER_RADIUS: 3,
  CROSSHAIRS_SIZE: {
    active: 6,
    shadowActive: 7,
    inactive: 9,
    shadowInactive: 10,
  },
  HANDLE_SIZE: { active: 10, inactive: 14 },
  HIT_AREA_BUFFER_PX: 2,
  MARKER_COLOR: COLOR_CPR_MARKER_COLOR_INDICATOR,
  DELETE_KEYS: ['Backspace'],
  DASHED_LINE: ['new', 'moving', 'active'],
  RULER_STATE: RulerStates,
  ELLIPSE_STATE: EllipseStates,
  HANDLE: HandlePosition,
  STATES: {
    new: 'new',
    active: 'active',
    inactive: 'inactive',
    finish: 'finish',
    moving: 'moving',
  },
  TOOL_TYPES: ToolTypes,
  PARENT: ParentType,
};

//load the icons for the ruler
let loader = new PIXI.Loader();
loader.add('ellipse', ellipseRulerIcon).add('ruler', straightRulerIcon).load();

export const onZoomMeasurementTools = (
  parent: ParentType,
  sprites: any[],
  scale: number,
  callbackMouseDownEllipseNode: (event: any) => void,
  recalculatePixelsToMm: () => number,
  hitAreaEllipseToolRef?: any,
  huData?: HuData
) => {
  sprites.forEach((sprite: MeasurementGraphics) => {
    if (
      sprite.lineName &&
      Object.keys(MEASUREMENT_SETTINGS.TOOL_TYPES).includes(sprite.lineName)
    )
      return;
    if (sprite.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler) {
      const start = {
        x: sprite.startPoint.x,
        y: sprite.startPoint.y,
      };
      const end = {
        x: sprite.endPoint.x,
        y: sprite.endPoint.y,
      };
      createRulerSprite({
        parent,
        sprite,
        start,
        end,
        lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler,
        state: MEASUREMENT_SETTINGS.STATES.finish,
        scale,
        pixelsPerMillimeter: recalculatePixelsToMm(),
      });
    }
    sprite.lineName === MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse &&
      createEllipseSprite({
        parent,
        sprite,
        points: sprite.points || [],
        lineName: MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse,
        state: MEASUREMENT_SETTINGS.STATES.finish,
        scale,
        callback: callbackMouseDownEllipseNode,
        hitAreaEllipseToolRef,
        huData,
        pixelsPerMillimeter: recalculatePixelsToMm(),
      });
  });
};

export const drawHandle = (
  sprite: MeasurementGraphics,
  lineName: string,
  line: PointObject[],
  scale: number,
  callback?: (event: any) => void,
  isActiveHandle: boolean = false
) => {
  let size = MEASUREMENT_SETTINGS.HANDLE_SIZE.active;
  if (isActiveHandle) size = MEASUREMENT_SETTINGS.HANDLE_SIZE.active;

  const spriteHandle = drawCircle(
    lineName,
    { x: line[1].x, y: line[1].y },
    size / scale,
    MEASUREMENT_SETTINGS.BORDER_RADIUS / scale,
    0.001
  );
  spriteHandle.interactive = true;
  callback && spriteHandle.on('mousedown', callback);
  sprite.addChild(spriteHandle);
};

export const drawCircle = (
  lineName: string,
  point: PointObject,
  size: number,
  radius: number,
  alpha: number = MEASUREMENT_SETTINGS.SHADOW_OPACITY
) => {
  const sprite = new PIXI.Graphics() as MeasurementGraphics;
  sprite.beginFill(0xffffff, alpha);
  sprite.lineName = lineName;
  sprite.drawRoundedRect(
    point.x - size / 2,
    point.y - size / 2,
    size,
    size,
    radius
  );

  sprite.endFill();
  return sprite;
};

export const drawPerpendicularLine = (
  sprite: MeasurementGraphics,
  line: PointObject[]
) => {
  sprite.moveTo(line[0].x, line[0].y);
  sprite.lineTo(line[2].x, line[2].y);
};

export const getPerpendicularLine = (
  line: PointObject[],
  width = 50,
  angle: number = 90
) => {
  angle = getLineAngle(line[0], line[line.length - 1]) + angle;
  // If the line has three points we want the center to be the second point.
  const center = line[Math.round((line.length - 1) / 2)];
  const distance = width;
  const p1 = {
    x: center.x + distance * Math.cos((angle * Math.PI) / 180),
    y: center.y + distance * Math.sin((angle * Math.PI) / 180),
  };
  const p2 = {
    x: center.x - distance * Math.cos((angle * Math.PI) / 180),
    y: center.y - distance * Math.sin((angle * Math.PI) / 180),
  };
  return [p1, center, p2];
};

export const makingLineInteractive = (
  sprite: PIXI.Graphics,
  start: PointObject,
  end: PointObject,
  scale: number
) => {
  //get perpendicular line at the start and end point
  let perpLineStart = getPerpendicularLine(
    [end, start],
    MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.inactive / scale
  );
  let perpLineEnd = getPerpendicularLine(
    [start, end],
    MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.inactive / scale
  );
  //draw hit area for line
  const width = 0;
  sprite.lineStyle(width);
  sprite.interactive = true;
  sprite.beginFill(0xffffff, 0.001);
  sprite.drawPolygon([
    perpLineStart[0].x,
    perpLineStart[0].y,
    perpLineStart[2].x,
    perpLineStart[2].y,
    perpLineEnd[0].x,
    perpLineEnd[0].y,
    perpLineEnd[2].x,
    perpLineEnd[2].y,
  ]);
  sprite.endFill();
};

const getLineAngle = (point1: XYCoords, point2: XYCoords) => {
  return (Math.atan2(point2.y - point1.y, point2.x - point1.x) * 180) / Math.PI;
};

export const createMarkerSprite = ({
  type,
  sprite,
  label,
  position,
  scale,
  state,
  markerPositionsRef,
  huData,
}: CreateMarkerSpriteInterface) => {
  const style = new PIXI.TextStyle({
    fontFamily: 'Arial',
    fontSize: 12,
    fill: '#ffffff',
    align: 'center',
  });
  let text_HUMean, text_HUSD;
  const text = new PIXI.Text(label, style);
  let rectangleHeight = text.height + 8;

  if (type === MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse) {
    text_HUMean = new PIXI.Text(
      // Checking for undefined as zero is still a valid value.
      `HU Mean ${
        huData?.mean !== undefined ? Number(huData.mean).toFixed(2) : '-'
      }`,
      style
    );
    rectangleHeight += text_HUMean.height + 7;
    text_HUSD = new PIXI.Text(
      // Checking for undefined as zero is still a valid value.
      `HU SD ${
        huData?.stdDev !== undefined ? Number(huData.stdDev).toFixed(2) : '-'
      }`,
      style
    );
    rectangleHeight += 7;
  }

  // Draw the rounded grey backing rect.
  let rectangleWidth = 70;

  const texture =
    type === MEASUREMENT_SETTINGS.TOOL_TYPES.Ruler
      ? loader.resources.ruler.texture
      : loader.resources.ellipse.texture;

  const spriteIcon = new PIXI.Sprite(texture);
  spriteIcon.anchor.set(0.1);

  const rectangle = new PIXI.Graphics();
  rectangle.beginFill(
    Number(MEASUREMENT_SETTINGS.MARKER_COLOR.replace('#', '0x'))
  );
  rectangle.drawRoundedRect(0, 0, rectangleWidth, rectangleHeight, 4);
  rectangle.endFill();

  const wrapper = new PIXI.Container() as MeasurementGraphics;
  wrapper.lineName = 'marker';
  wrapper.addChild(rectangle, spriteIcon, text);
  const padding = 15;
  rectangleWidth = text.width + spriteIcon.width + padding;
  // Center the text in the middle of the rectangle.
  text.x = rectangleWidth / 2 + padding / 2;
  text.y = rectangleHeight / 2;
  text.anchor.set(0.5, 0.5);

  spriteIcon.x = 5;
  spriteIcon.y = 5;
  rectangle.width = rectangleWidth;
  if (
    type === MEASUREMENT_SETTINGS.TOOL_TYPES.Ellipse &&
    text_HUMean &&
    text_HUSD
  ) {
    text.y = (text.height + 7) / 2;
    text_HUMean.x = text.x - text.width / 2;
    text_HUMean.y = text.y + text.height / 2;
    text_HUSD.x = text.x - text.width / 2;
    text_HUSD.y = text_HUMean.y + text_HUMean.height;

    rectangle.width = text_HUMean.width + spriteIcon.width + padding;
    wrapper.addChild(text_HUMean, text_HUSD);
  }

  // distance btw tool and marker.
  // this have to be improved to avoid the overlap.
  const distance = 25;
  wrapper.scale.x = 1.0 / scale;
  wrapper.scale.y = 1.0 / scale;
  wrapper.x = position.x + distance / scale;
  wrapper.y = position.y - rectangleHeight / scale;
  //line btw marker and ruler
  const line = new PIXI.Graphics();
  line.lineStyle(2 / scale, 0xffffff);
  line.moveTo(position.x, position.y);
  if (state === MEASUREMENT_SETTINGS.STATES.finish) {
    drawDashedLine({
      line,
      start: position,
      end: {
        x: position.x + distance / scale,
        y: position.y - rectangleHeight / 2 / scale,
      },
      dashLength: 3 / scale,
      lineWidth: MEASUREMENT_SETTINGS.WIDTH / scale,
      alpha: 0.001,
    });
  }
  sprite.addChild(wrapper, line);
};

//split line into equal parts and draw it
export const drawDashedLine = ({
  line,
  start,
  end,
  dashLength,
  lineWidth,
  alpha = MEASUREMENT_SETTINGS.SHADOW_OPACITY,
}: DrawDashedLineInterface) => {
  const distance = Math.sqrt(
    Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)
  );
  const angle = getLineAngle(start, end);
  const numDashes = Math.floor(distance / dashLength);
  let x = start.x;
  let y = start.y;
  for (let i = 0; i < numDashes; i++) {
    line.lineStyle(lineWidth, 0x000000, alpha);
    if (i % 2 === 0) {
      line.lineStyle(lineWidth, 0xffffff, MEASUREMENT_SETTINGS.SHADOW_OPACITY);
    }
    line.moveTo(x, y);
    x += Math.cos((angle * Math.PI) / 180) * dashLength;
    y += Math.sin((angle * Math.PI) / 180) * dashLength;
    line.lineTo(x, y);
  }
  line.lineStyle(lineWidth, 0xffffff, alpha);
  line.moveTo(x, y);
  line.lineTo(end.x, end.y);
};

export const drawDashedCrosshair = (
  sprite: MeasurementGraphics,
  point: XYCoords,
  lineWidth: number,
  borderWidth: number,
  color: number,
  alpha: number = 1
) => {
  // horizontal line
  // get perpendicular line to the point
  let horizontalLine = getPerpendicularLine([point, point], lineWidth);
  sprite.lineStyle(borderWidth, color, alpha);

  drawDashedPerpendicularLine(sprite, horizontalLine);
  // vertical line
  // get perpendicular line to the point
  let verticalLine = getPerpendicularLine([point, point], lineWidth, 180);
  sprite.lineStyle(borderWidth, color, alpha);
  drawDashedPerpendicularLine(sprite, verticalLine);
};

const drawDashedPerpendicularLine = (
  sprite: MeasurementGraphics,
  line: XYCoords[]
) => {
  for (let i = 0; i < 2; i++) {
    let index = i === 0 ? 0 : 2;
    let from = line[index];
    let distance = getDistanceBetweenPoints(from, line[1]);
    let to = findPointsOnLine([line[index], line[1]], distance / 1.5);
    sprite.moveTo(from.x, from.y);
    sprite.lineTo(to.x, to.y);
  }
};

export const drawCrosshair = (
  sprite: MeasurementGraphics,
  point: XYCoords,
  scale: number
) => {
  //draw shadow crosshair
  let lineWidth = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.shadowActive;
  lineWidth /= scale;
  let horizontalLine = getPerpendicularLine([point, point], lineWidth);
  let verticalLine = getPerpendicularLine([point, point], lineWidth, 180);
  let borderWidth = MEASUREMENT_SETTINGS.SHADOW_SIZE / scale;
  sprite.lineStyle(
    borderWidth,
    MEASUREMENT_SETTINGS.COLOR,
    MEASUREMENT_SETTINGS.SHADOW_OPACITY
  );
  drawPerpendicularLine(sprite, horizontalLine);
  drawPerpendicularLine(sprite, verticalLine);
  //drawing crosshair
  lineWidth = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.active;
  lineWidth /= scale;
  horizontalLine = getPerpendicularLine([point, point], lineWidth);
  verticalLine = getPerpendicularLine([point, point], lineWidth, 180);
  borderWidth = MEASUREMENT_SETTINGS.WIDTH / scale;
  sprite.lineStyle(borderWidth, MEASUREMENT_SETTINGS.SHADOW_COLOR);
  drawPerpendicularLine(sprite, horizontalLine);
  drawPerpendicularLine(sprite, verticalLine);
};

export const getDistanceBetweenPoints = (
  point1: XYCoords,
  point2: XYCoords
) => {
  return Math.hypot(point2.x - point1.x, point2.y - point1.y);
};
//copied from CPRViewer
export const intersectsNodes = (
  nodeList: PointObject[],
  node: PointObject,
  tolerance: number = 1
) => {
  let indexes: number[] = [];
  nodeList.forEach((_, i) => {
    const prevPoint = nodeList[i - 1] || nodeList[0];
    const thisPoint = nodeList[i];
    const intersect = intersects.linePoint(
      prevPoint.x,
      prevPoint.y,
      thisPoint.x,
      thisPoint.y,
      node.x,
      node.y,
      tolerance
    );
    if (intersect) {
      indexes.push(i);
    }
  });
  return indexes;
};

export const lineStyle = (
  sprite: MeasurementGraphics,
  condition: number | boolean,
  scale: number,
  state?: string
) => {
  const color = condition
    ? MEASUREMENT_SETTINGS.SHADOW_COLOR
    : MEASUREMENT_SETTINGS.COLOR;
  const opacity = condition ? MEASUREMENT_SETTINGS.SHADOW_OPACITY : 1;
  let width = condition
    ? MEASUREMENT_SETTINGS.WIDTH + MEASUREMENT_SETTINGS.SHADOW_SIZE
    : MEASUREMENT_SETTINGS.WIDTH;

  state &&
    state !== MEASUREMENT_SETTINGS.STATES.finish &&
    (width = MEASUREMENT_SETTINGS.WIDTH);
  sprite.lineStyle(width / scale, color, opacity);
};

//Finding points on a line with a given distance
const findPointsOnLine = (line: PointObject[], distance: number) => {
  const angle = getLineAngle(line[0], line[1]);
  const x = line[0].x + Math.cos((angle * Math.PI) / 180) * distance;
  const y = line[0].y + Math.sin((angle * Math.PI) / 180) * distance;
  return { x, y };
};

export const getAxisMPRViewPixelPerMm = (): number => {
  return MM_TO_PX;
};

export const getNonContrastViewPixelPerMm = (): number => {
  return MM_TO_PX;
};

export const getAreaOfEllipse = (radiusX: number, radiusY: number): number => {
  return Math.PI * radiusX * radiusY;
};

export const isRulerHandled = (lineName: string | null) => {
  return lineName === HandlePosition.Start || lineName === HandlePosition.End;
};

export const drawBothCrosshairs = (
  sprite: MeasurementGraphics,
  scale: number,
  inactivePoint: PointObject,
  activePoint: PointObject
) => {
  let spriteCrosshair = new PIXI.Graphics() as MeasurementGraphics;
  // draw crosshair for inactive point
  drawCrosshair(spriteCrosshair, inactivePoint, scale);

  // crosshair for active point
  // shadow crosshair
  let lineWidth = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.shadowInactive / scale;
  let borderWidth = MEASUREMENT_SETTINGS.SHADOW_SIZE / scale;
  drawDashedCrosshair(
    spriteCrosshair,
    activePoint,
    lineWidth,
    borderWidth,
    MEASUREMENT_SETTINGS.COLOR,
    MEASUREMENT_SETTINGS.SHADOW_OPACITY
  );
  // crosshair
  lineWidth = MEASUREMENT_SETTINGS.CROSSHAIRS_SIZE.inactive / scale;
  borderWidth = MEASUREMENT_SETTINGS.WIDTH / scale;
  drawDashedCrosshair(
    spriteCrosshair,
    activePoint,
    lineWidth,
    borderWidth,
    MEASUREMENT_SETTINGS.SHADOW_COLOR
  );
  sprite.addChild(spriteCrosshair);
};
