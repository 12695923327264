import { ActionType, createAction } from 'typesafe-actions';
import { ContrastViewContent } from '../contrast-types';

export const ViewConfigActions = {
  changeSeriesId: createAction(
    'ViewConfig/CHANGE_VIEW_TYPE',
    (seriesId: string) => seriesId
  )(),
  setViewportsToShowBorder: createAction(
    'ViewConfig/SET_VIEWPORTS_TO_SHOW_BORDER',
    (viewports: number[]) => viewports
  )(),
  pushRecentlySelectedViews: createAction(
    'ViewConfig/PUSH_RECENTLY_SELECTED_VIEWS',
    (recentlySelected: ContrastViewContent[]) => recentlySelected
  )(),
  removeRecentlySelectedView: createAction(
    'ViewConfig/REMOVE_RECENTLY_SELECTED_VIEW',
    (view: ContrastViewContent) => view
  )(),
  clearRecentlySelectedViews: createAction(
    'ViewConfig/CLEAR_RECENTLY_SELECTED_VIEWS'
  )(),
};

export type ViewConfigAction = ActionType<typeof ViewConfigActions>;
