import { DragAndDropState, DraggableGroup } from './types';
import { AIAssessed } from '../types';

export function handleGroupDrag(id: string, state: DragAndDropState) {
  const draggableGroup = { ...state.draggableGroup };

  draggableGroup[id].parent = {
    ...draggableGroup[id].parent,
    isDragging: true,
  };

  return draggableGroup;
}

export function handleSingleItemDrag(
  id: string,
  childItemIndex: number,
  state: DragAndDropState
) {
  const draggableGroup = { ...state.draggableGroup };

  draggableGroup[id].children[childItemIndex] = {
    ...draggableGroup[id].children[childItemIndex],
    isDragging: true,
  };

  return draggableGroup;
}

export function onSnapBackGroupItem(id: string, state: DragAndDropState) {
  const draggableGroup = { ...state.draggableGroup };

  const draggableGroupToEdit = {
    ...draggableGroup[id].parent,
    isDragging: false,
  };

  draggableGroup[id].parent = draggableGroupToEdit;

  return draggableGroup;
}

export function onSnapBackDraggableItem(
  id: string,
  index: number,
  state: DragAndDropState
) {
  const draggableGroup = { ...state.draggableGroup };

  const draggableToEdit = {
    ...draggableGroup[id].children[index],
    isDragging: false,
  };

  draggableGroup[id].children[index] = draggableToEdit;

  return draggableGroup;
}

/**
 * Helper function to determine if a study is a Contrast Only study
 * Checks the list of parent series ids if any of them include 'noncontrast'
 */
export function isContrastOnlyStudy(series: any[]): boolean {
  const contrastView = series.filter((s) => s.id.includes('noncontrast'));
  return contrastView.length <= 0;
}

/**
 * Based on only the name; is this series a noncontrast series?
 * Currently the studies have only ever have a single noncontrast series called 'noncontrast' but this may change in the future.
 */
export function isNonContrastSeries(seriesName: string | undefined): boolean {
  return seriesName !== undefined && seriesName.includes('noncontrast');
}

/**
 * Based on only the name; is this series a contrast series?
 * Currently the studies can have multiple contrast series called 'contrast', 'contrast-s05', 'contrast-s14' ... etc.
 */
export function isContrastSeries(seriesName: string | undefined): boolean {
  return seriesName !== undefined && !isNonContrastSeries(seriesName);
}

/**
 * Get the info we need to sort the series based on its name.
 */
function getSeriesSortingInfo(
  seriesName: string,
  ai_assessed: AIAssessed
): {
  isAIAssessedContrast: boolean;
  isContrast: boolean;
  isAIAssessedNonContrast: boolean;
  isNonContrast: boolean;
  number: number;
} {
  // Is the series ai assessed?
  const isAIAssessedContrast = seriesName === ai_assessed.contrast_id;
  const isAIAssessedNonContrast = seriesName === ai_assessed.noncontrast_id;

  // Series names are in the form of:
  // "contrast" or "contrast-s[n]" or "contrastS[n]" or
  // "noncontrast" or "noncontrast-s[n]" or "noncontrastS[n]"

  // Get the series number from the series name.
  let number: number = 0;
  const index = seriesName.search(/\d/);
  if (index >= 0) {
    const int = parseInt(seriesName.substr(index, seriesName.length));
    if (!isNaN(int)) number = int;
  }

  return {
    isAIAssessedContrast,
    isAIAssessedNonContrast,
    isContrast: isAIAssessedContrast || isContrastSeries(seriesName),
    isNonContrast: isAIAssessedNonContrast || isNonContrastSeries(seriesName),
    number,
  };
}

/**
 * Function which sorts a draggable group by its parent id.
 * The order should come out:
 * ai_assessed.noncontrast_id
 * noncontrast-s[n] (or noncontrastS[n])
 * noncontrast-s[n+1] (or noncontrastS[n+1]) and so on
 * ai_assessed.contrast_id
 * contrast-s[n] (or contrastS[n])
 * contrast-s[n+1] (or contrastS[n+1]) and so on
 */
export function sortSeries(group: DraggableGroup[], ai_assessed: AIAssessed) {
  const result = group.sort((a, b) => {
    const seriesA = getSeriesSortingInfo(a.parent.id, ai_assessed);
    const seriesB = getSeriesSortingInfo(b.parent.id, ai_assessed);

    // Prioritise the ai assessed noncontrast series.
    if (seriesA.isAIAssessedNonContrast) return -1;
    if (seriesB.isAIAssessedNonContrast) return 1;
    // Prioritise noncontrast series.
    if (seriesA.isNonContrast && seriesA.isContrast) return -1;
    if (seriesA.isContrast && seriesB.isNonContrast) return 1;
    // Prioritise ai assessed contrast series over other contrast series.
    if (seriesA.isAIAssessedContrast) return -1;
    if (seriesB.isAIAssessedContrast) return 1;

    // Return the one with the lowest number first.
    return seriesA.number - seriesB.number;
  });
  return result;
}
