import cn from 'classnames';
import React, { ChangeEvent, ReactElement } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { FindingsStatus } from '../../components/FindingsStatus/FindingsStatus';
import { VesselGroupings } from '../../config';
import { CoronaryFindings } from '../../context/types';
import { getVesselColor } from '../../utils/vessel';
import Alert from '../Alert/Alert';

interface ActiveField {
  vessel: string;
  isFocused: boolean;
  isDirty: boolean;
}

interface Props {
  vesselGroup: VesselGroupings;
  coronaryFindings?: CoronaryFindings;
  draftCoronaryFindings?: string;
  editMode: boolean;
  handleFocus?: () => void;
  handleBlur?: () => void;
  handleInputChange?: (
    event: ChangeEvent<HTMLTextAreaElement>,
    vessel: VesselGroupings
  ) => void;
  activeField?: ActiveField;
  showVesselName: boolean;
  statusVersion?: boolean;
}

export default function KeyVesselFinding({
  vesselGroup,
  coronaryFindings,
  draftCoronaryFindings,
  editMode,
  handleFocus,
  handleBlur,
  handleInputChange,
  activeField,
  showVesselName,
  statusVersion,
}: Props): ReactElement<Props> {
  return (
    <div
      className={cn('key-findings__vessel', {
        'key-findings__vessel__show_vessel_name': !showVesselName,
      })}
      key={`key-findings-vessel__${vesselGroup}`}
    >
      {showVesselName && (
        <div className="key-findings__findings_container">
          <div className="key-findings__cell">
            <span
              style={{
                background: getVesselColor(vesselGroup),
              }}
              className="key-findings__legend"
            />
            <div className="key-findings__title">
              <div>
                <span className="key-findings__title-vessel-group">
                  {vesselGroup}
                </span>
                {vesselGroup !== 'LM' && ' including branches'}
              </div>
            </div>
          </div>
          <div className="key-findings__cell key-findings__cell__justify_end">
            {!editMode && statusVersion !== undefined && (
              <FindingsStatus versions={statusVersion} />
            )}
          </div>
        </div>
      )}
      <div className="key-findings__text">
        <div className="findings">
          {editMode ? (
            <>
              {activeField?.vessel === vesselGroup &&
                activeField.isDirty &&
                activeField.isFocused && (
                  <Alert>
                    Ensure that the summary information above and the Impression
                    reflects any changes.
                  </Alert>
                )}
              <TextareaAutosize
                maxRows={7}
                className="findings__enter"
                autoFocus
                value={draftCoronaryFindings}
                onChange={(ev) => {
                  handleInputChange?.(ev, vesselGroup);
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
            </>
          ) : (
            <div className="findings__enter block">
              <span>{coronaryFindings?.[vesselGroup]}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
