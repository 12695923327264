import cn from 'classnames';
import React, { ReactElement, useMemo } from 'react';
import { Vec2 } from 'three';
import { ACUMIN_PRO, Colors } from '../../styles/styles';
import { sortedVesselList } from '../../utils/vessel';
import { getVesselColor } from './helpers';

interface Props {
  mousePosHigh: boolean;
  vessels: string[];
  itemHeight: number;
  itemWidth: number;
  contextMenuPos: Vec2;
  selectedVessel: string;
  changeVessel: (e: React.MouseEvent, vessel: string) => void;
}

const PADDING_PER_ITEM = 6;
const HALF_PADDING_FOR_HORIZONTAL_SHIFT = PADDING_PER_ITEM / 2;

export function CalciumContextSubMenu({
  mousePosHigh,
  vessels,
  itemHeight,
  itemWidth,
  contextMenuPos,
  selectedVessel,
  changeVessel,
}: Props): ReactElement<Props> {
  const { x: leftPos, y: topPos }: Vec2 = useMemo(() => {
    const baseContextMenuBottomPos = itemHeight + PADDING_PER_ITEM;
    const y = mousePosHigh
      ? // Place the sub menu completely above the context menu
        // Account for 3 extra paddings for the sub context menu top, bottom and the top of the
        // context menu
        contextMenuPos.y -
        baseContextMenuBottomPos * vessels.length -
        3 * PADDING_PER_ITEM
      : // Place the sub menu below the context menu
        contextMenuPos.y + baseContextMenuBottomPos;

    const x = contextMenuPos.x - HALF_PADDING_FOR_HORIZONTAL_SHIFT;

    return { x, y };
  }, [mousePosHigh, contextMenuPos, itemHeight, vessels.length]);

  return (
    <div
      style={{
        backgroundColor: Colors.codGray,
        borderRadius: '0.3rem',
        color: Colors.doveGray,
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        position: 'fixed',
        top: topPos,
        left: leftPos,
        width: itemWidth + PADDING_PER_ITEM,
        paddingTop: '0.3rem',
        paddingBottom: '0.3rem',
      }}
    >
      {sortedVesselList(vessels).map((vessel, i) => (
        <div
          className={cn('context-menu__item', 'context-menu__item-option', {
            chosen: vessel.toLowerCase() === selectedVessel.toLowerCase(),
          })}
          key={i}
          onClick={(e) => changeVessel(e, vessel)}
        >
          <span
            style={{
              background: getVesselColor(vessel),
              width: '1.4rem',
              height: '1.4rem',
              borderRadius: '0.3rem',
              paddingRight: '1.2rem',
              marginLeft: '0.6rem',
              display: 'inline-block',
            }}
          />
          <span
            style={{
              height: itemHeight,
              fontSize: '1.4rem',
              fontWeight: 600,
              fontFamily: ACUMIN_PRO,
              width: itemWidth,
              color: Colors.dustyGray,
              textAlign: 'left',
              padding: '1.2rem',
            }}
          >
            {vessel}
          </span>
        </div>
      ))}
    </div>
  );
}
