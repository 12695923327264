import produce from 'immer';
import Konva from 'konva';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Layer, Stage } from 'react-konva';
import { Vec2 } from 'three';
import { uid } from '../../utils/shared';
import { AnnotationArrow } from './AnnotationArrow';
import { AnnotationShapeType } from './AnnotationModal';
import { AnnotationText } from './AnnotationText';
import { Annotation, AnnotationCursor, ArrowProps } from './types';

const SCREENSHOT_SHAPE = 'screenshot';

interface Props {
  stageWidth: number;
  stageHeight: number;
  seriesScreenshot: any;
  annotationType: AnnotationShapeType;
  setAnnotationType: (annotationType: AnnotationShapeType) => void;
  onReady: (stage: any) => void;
}

export default function AnnotationScreenshotStage({
  stageWidth,
  stageHeight,
  seriesScreenshot,
  annotationType,
  onReady,
  setAnnotationType,
}: Props): ReactElement<Props> {
  const [arrows, setArrows] = useState<ArrowProps[]>([]);

  // Called when dragging the entire arrow
  const updatePointsOnArrowDrag = useCallback(
    (index: number, disp: { x: number; y: number }) => {
      setArrows((prevArrows) => {
        return produce(prevArrows, (draft) => {
          // Update start/end points (they're also used for the arrow handles)
          draft[index].startPoint.x += disp.x;
          draft[index].startPoint.y += disp.y;
          draft[index].endPoint.x += disp.x;
          draft[index].endPoint.y += disp.y;
        });
      });
    },
    [setArrows]
  );

  // Called when dragging individual points
  const updateArrowPoints = useCallback(
    (index: number, startPoint: Vec2, endPoint: Vec2) => {
      setArrows((prevArrows) => {
        return produce(prevArrows, (draft) => {
          draft[index].startPoint = startPoint;
          draft[index].endPoint = endPoint;
        });
      });
    },
    [setArrows]
  );

  const [annotations, setAnnotations] = useState<Annotation[]>([]);

  const [selectedShape, setSelectedShape] = useState<string | null>(null);

  const [editingAnnotation, setEditingAnnotation] = useState<boolean | null>(
    null
  );

  const screenshotImageLayer = useRef<Konva.Layer | null>(null);

  const stageRef = useRef<Stage | null>(null);

  const userAnnotations = useRef<Konva.Layer | null>(null);

  const baseImage = useRef<Konva.Image | null>(null);

  useEffect(() => {
    if (seriesScreenshot && screenshotImageLayer && !baseImage.current) {
      const konvaImg = new Konva.Image();
      const img = new Image();
      baseImage.current = konvaImg;
      img.src = seriesScreenshot;
      img.onload = () => {
        konvaImg.image(img);
        konvaImg.name(SCREENSHOT_SHAPE);
        const stageHeight =
          stageRef.current?.getStage().attrs.height || window.innerHeight;
        const ratio = konvaImg.width() / konvaImg.height();
        const desiredHeight = stageHeight - 50;
        konvaImg.width(desiredHeight * ratio);
        konvaImg.height(desiredHeight);
        screenshotImageLayer.current?.add(konvaImg);
        if (screenshotImageLayer.current) {
          konvaImg.x(
            screenshotImageLayer.current.getWidth() / 2 - konvaImg.width() / 2
          );
          konvaImg.y(
            screenshotImageLayer.current.getHeight() / 2 - konvaImg.height() / 2
          );
          screenshotImageLayer.current.draw();
        }

        onReady && onReady(stageRef);
      };
    }
  }, [seriesScreenshot, onReady]);

  useEffect(() => {
    setSelectedShape(null);
  }, [annotationType]);

  const handleDelete = useCallback(
    (event) => {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        event.preventDefault();
        if (annotationType === 'arrow') {
          setArrows((prevArrows) => {
            return produce(prevArrows, (draft) => {
              const draftSelected = draft.find((x) => x.id === selectedShape);

              if (draftSelected) {
                // Instead of deleting, we just hide the arrow, for reasons stated in ./types.ts
                draftSelected.visible = false;
              }
            });
          });
        } else if (annotationType === 'text' && !editingAnnotation) {
          const annotationIdx = annotations.findIndex(
            (annotation) => annotation.id === selectedShape
          );
          if (annotationIdx >= 0) {
            annotations.splice(annotationIdx, 1);
            setAnnotations(annotations);
          }
        }
        setSelectedShape(null);
      } else {
        return;
      }
    },
    [annotations, selectedShape, annotationType, editingAnnotation]
  );

  useEffect(() => {
    if (selectedShape) {
      window.addEventListener('keydown', handleDelete);
    } else {
      window.removeEventListener('keydown', handleDelete);
    }
    return () => window.removeEventListener('keydown', handleDelete);
  }, [arrows, selectedShape, handleDelete]);

  const stageRefCurrent = stageRef.current;

  /**
   * @returns {Konva.Vector2d}
   */
  const getPointerPosition = useCallback(() => {
    if (stageRefCurrent) {
      return stageRefCurrent.getStage().getPointerPosition();
    }
    return { x: 0, y: 0 };
  }, [stageRefCurrent]);

  const handleMouseDown = useCallback(
    (event: Konva.KonvaEventObject<MouseEvent>) => {
      const clickedOnEmpty =
        event.target.name() === SCREENSHOT_SHAPE || !event.target.className;

      if (selectedShape && clickedOnEmpty) {
        // deselect shape when clicking on empty area
        setSelectedShape(null);
        return;
      }

      if (!selectedShape && clickedOnEmpty) {
        const pos = getPointerPosition();
        if (pos) {
          if (annotationType === 'arrow') {
            const baseX = pos.x < 120 ? pos.x + 50 : pos.x - 100;
            const baseY = pos.y < 120 ? pos.y + 50 : pos.y - 100;
            const startPoint: Vec2 = { x: baseX, y: baseY };
            const endPoint: Vec2 = { x: pos.x, y: pos.y };
            const id = `arr_${uid()}`;
            setSelectedShape(id);
            setArrows([
              ...arrows,
              {
                startPoint,
                endPoint,
                id,
                visible: true,
              },
            ]);
          } else if (annotationType === 'text' && !editingAnnotation) {
            const id = `txt_${uid()}`;
            setSelectedShape(id);
            const annotation: Annotation = {
              id,
              x: pos.x - 80,
              y: pos.y - 10,
            };
            setAnnotations([...annotations, annotation]);
          }
        }
      }
    },
    [
      annotationType,
      arrows,
      selectedShape,
      annotations,
      editingAnnotation,
      getPointerPosition,
    ]
  );

  const handleMouseLeave = useCallback(() => {
    setSelectedShape(null);
  }, [setSelectedShape]);

  const handleAnnotationDragEnd = useCallback(
    (event: any) => {
      const annotationIdx = annotations.findIndex(
        (annotation) => annotation.id === selectedShape
      );
      if (annotationIdx >= 0 && annotations[annotationIdx]) {
        const target = event.target;
        if (target && target.attrs) {
          annotations[annotationIdx].x = target.attrs.x;
          annotations[annotationIdx].y = target.attrs.y;
          setAnnotations(annotations);
          userAnnotations.current?.batchDraw();
        }
      }
    },
    [annotations, setAnnotations, selectedShape]
  );

  const onTextMouseDown = useCallback(
    (annotation: Annotation) => {
      // If we aren't already in 'text' mode,
      // set it and select the shape a render later
      if (annotationType !== 'text') {
        setAnnotationType('text');
        setTimeout(() => {
          setSelectedShape(annotation.id);
        });
      } else {
        setSelectedShape(annotation.id);
      }
    },
    [setAnnotationType, setSelectedShape, annotationType]
  );

  const annotationTexts = useMemo(() => {
    return annotations.map((annotation, i) => (
      <AnnotationText
        selected={annotation.id === selectedShape}
        annotation={annotation}
        key={`${i}`}
        onDragEnd={handleAnnotationDragEnd}
        onMouseDown={() => onTextMouseDown(annotation)}
        onEdit={() => {
          setEditingAnnotation(true);
        }}
        onSave={(value: string) => {
          annotation.textVal = value;
          setEditingAnnotation(false);
        }}
      />
    ));
  }, [selectedShape, annotations, handleAnnotationDragEnd, onTextMouseDown]);

  const onArrowMouseDown = useCallback(
    (arrow: ArrowProps) => {
      // If we aren't already in 'arrow' mode,
      // set it and select the shape a render later
      if (annotationType !== 'arrow') {
        setAnnotationType('arrow');
        setTimeout(() => {
          setSelectedShape(arrow.id);
        });
      } else {
        setSelectedShape(arrow.id);
      }
    },
    [setAnnotationType, setSelectedShape, annotationType]
  );

  const [cursorType, setCursorType] = useState<AnnotationCursor>('default');

  const annotationArrows = useMemo(() => {
    return arrows.map((arrow, i) => (
      <AnnotationArrow
        arrow={arrow}
        key={`${i}`}
        width={stageWidth}
        height={stageHeight}
        selected={arrow.id === selectedShape}
        selectArrow={() => onArrowMouseDown(arrow)}
        updatePointsOnArrowDrag={(disp: Vec2) => {
          updatePointsOnArrowDrag(i, disp);
        }}
        updateArrowPoints={(startPoint: Vec2, endPoint: Vec2) => {
          updateArrowPoints(i, startPoint, endPoint);
        }}
        updateCursorType={setCursorType}
      />
    ));
  }, [
    selectedShape,
    arrows,
    updatePointsOnArrowDrag,
    updateArrowPoints,
    onArrowMouseDown,
    stageHeight,
    stageWidth,
  ]);

  return (
    <>
      <Stage
        id={undefined}
        ref={stageRef}
        width={stageWidth}
        height={stageHeight}
        onMouseDown={handleMouseDown}
        onMouseLeave={handleMouseLeave}
        style={{ cursor: cursorType }}
      >
        <Layer id={undefined} ref={screenshotImageLayer}></Layer>
        <Layer id={undefined} ref={userAnnotations}>
          {annotationArrows}
          {annotationTexts}
        </Layer>
      </Stage>
    </>
  );
}
