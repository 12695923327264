import React, { ReactElement, useMemo } from 'react';
import {
  getNamedTypeFromContrastType,
  getViewContrastType,
} from '../../../components/DropZone/helpers';
import { ContrastViewContent } from '../../../context/contrast-types';
import { useDragAndDropContext } from '../../../context/drag-n-drop';
import { BorderType } from '../../../components/ContrastSeriesIcon/ContrastSeriesIcon';

interface Props {
  onClick: () => void;
  recent: ContrastViewContent;
}

export default function RecentlySelectedContrastSeries({
  onClick,
  recent,
}: Props): ReactElement<Props> {
  const {
    state: { draggableGroup },
  } = useDragAndDropContext();

  const renderThumbnail = useMemo(() => {
    if (!recent.seriesName) return undefined;
    return draggableGroup[recent.seriesName].children.find(
      (x) => getViewContrastType(x.customType) === recent.viewType
    )?.renderThumbnail;
  }, [draggableGroup, recent]);

  return (
    <div onClick={onClick} className="recently-selected-row">
      {renderThumbnail && renderThumbnail(BorderType.NONE)}
      <span>{getNamedTypeFromContrastType(recent.viewType)}</span>
    </div>
  );
}
