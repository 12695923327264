import Moment from 'moment';
import React, { ReactElement, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg';
import Button from '../../components/Button/Button';
import PageTitle from '../../components/PageTitle/PageTitle';
import { DASHBOARD_RELOAD_INTERVAL, DATETIME_FORMAT } from '../../config';
import * as api from '../../utils/api';

interface LogDetail {
  timestamp?: string;
  log?: string;
  actor?: string;
  detail?: string;
}

export default function AuditLog(): ReactElement {
  const [studyId, setStudyId] = useState<string | null>(null);
  const [refreshing, setRefreshing] = useState(false);
  const printableReportRef = useRef<HTMLDivElement | null>(null);
  const auditLogSearchForm = useRef<HTMLFormElement | null>(null);
  const [logs, setLogs] = useState<LogDetail[]>([]);
  const [error, setError] = useState<string | undefined>();
  const handlePrint = useReactToPrint({
    content: () => printableReportRef.current ?? null,
  });
  useQuery(
    'key',
    async () => {
      if (refreshing) {
        let response = await api.getJSON(
          '/audit-logging/study/' + studyId + '/logs'
        );
        if (response) {
          if (response.length === 0) {
            setRefreshing(false);
            setError('No logs found for ' + studyId);
          } else {
            setLogs(response);
          }
        }
      }
    },
    {
      // Query waits until first submission and then starts refreshing every X seconds
      refetchInterval: DASHBOARD_RELOAD_INTERVAL,
      enabled: refreshing,
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStudyId(event.target.value);
  };

  const queryStudy = (event?: React.FormEvent<HTMLFormElement>) => {
    setError(undefined);
    setRefreshing(true);
    event?.preventDefault();
  };

  return (
    <>
      <PageTitle title="Audit Log" />
      <div className="auditlog">
        {logs.length === 0 ? (
          <form
            className="auditlog__form"
            onSubmit={queryStudy}
            ref={auditLogSearchForm}
          >
            <div className="auditlog__title">Find an audit log</div>
            <div className="auditlog__subtitle">The Study ID is required.</div>
            <div className="auditlog__item">
              <label className="auditlog__label">Study ID</label>
              <input
                className="auditlog__input"
                type="text"
                required
                onChange={handleChange}
              />
            </div>
            <div className="auditlog__actions">
              <Button
                type="submit"
                onClick={queryStudy}
                disabled={!studyId || studyId.length < 1}
                loading={refreshing}
              >
                Find
              </Button>
            </div>
            {error !== undefined && (
              <div className="auditlog__error">
                <label>{error}</label>
              </div>
            )}
          </form>
        ) : (
          <div className="auditlog__results" ref={printableReportRef}>
            <div className="auditlog__header">
              <div className="auditlog__title">Audit Log Search Results</div>
              <button className="button" onClick={handlePrint}>
                <DownloadIcon />
                &nbsp;Export Audit
              </button>
            </div>
            <div>
              <div className="auditlog__wrapper">
                <table className="auditlog__table">
                  <thead>
                    <tr className="auditlog__table-row header">
                      <td colSpan={3}>
                        <span>Study ID</span>&nbsp;<strong>{studyId}</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.map((log: LogDetail, index) => {
                      return (
                        <tr className="auditlog__table-row" key={index}>
                          <td className="small">
                            {Moment(log.timestamp).format(DATETIME_FORMAT)}
                          </td>
                          <td className="small">{log.actor}</td>
                          <td>{log.log}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
