import Moment from 'moment';
import React, { useMemo, useState } from 'react';
import { ActionModal } from '../../components/ActionModal/ActionModal';
import { ReviewCard } from '../../components/ReviewCard/ReviewCard';
import {
  APPROVAL_WAIT_MINUTES,
  DATE_FORMAT,
  TIMEDATE_FORMAT,
  User,
} from '../../config';
import { Study } from '../../context/types';
import { WorkflowActions as Actions } from '../../context/workflow/actions';
import { useWorkflowContext } from '../../context/workflow/context';
import { useCancelReviewRequest } from '../../hooks/use-review-status-hooks';
import cn from 'classnames';

function formatDate(value: string, format: string) {
  if (!value || !Moment(value).isValid()) return '-';
  return Moment(value).format(format);
}

interface WorkflowActionsProps {
  user: User;
  dashboardData: Study[];
  onViewReport: (params: any) => void;
}

export const WorkflowActions: React.FC<WorkflowActionsProps> = ({
  dashboardData,
  onViewReport,
  user,
}) => {
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const [forReviewList, setForReviewList] = useState<Study[]>([]);
  const [recentlyApprovedList, setRecentlyApprovedList] = useState<Study[]>([]);

  const cancelReviewRequest = useCancelReviewRequest();

  const {
    state: {
      studyToAction,
      showCancelReviewConfirm,
      awaitingReviewList,
      awaitingApproveList,
    },
    dispatch,
  } = useWorkflowContext();

  useMemo(() => {
    const forReview: Study[] = [];
    const awaitingReview: Study[] = [];
    const awaitingApproval: Study[] = [];
    const recentlyApproved: Study[] = [];

    dashboardData.forEach((s) => {
      if (!!s.report_active_review) {
        if (s.report_active_review.assignee.email === user.email) {
          forReview.push(s);
          setTotalListCount(totalListCount + 1);
        } else if (s.report_active_review.assigner.email === user.email) {
          if (s.report_active_review.review_type === 'review') {
            awaitingReview.push(s);
            setTotalListCount(totalListCount + 1);
          } else {
            awaitingApproval.push(s);
            setTotalListCount(totalListCount + 1);
          }
        }
      }

      if (!!s.report_approval && s.report_approval.user.email === user.email) {
        const approvalTime = Moment(s.report_approval.timestamp);
        const current = Moment(new Date());
        const difference = current.diff(approvalTime, 'minutes');
        if (difference < APPROVAL_WAIT_MINUTES) {
          recentlyApproved.push(s);
          setTotalListCount(totalListCount + 1);
        }
      }
    });

    setForReviewList(forReview);
    dispatch(Actions.setAwaitingReviewList(awaitingReview));
    dispatch(Actions.setAwaitingApproveList(awaitingApproval));
    setRecentlyApprovedList(recentlyApproved);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData, user]);

  const openStudyReport = (study: Study) => {
    const studyParams = {
      study_id: study.study_id,
      status: study.workflow_status || study.status,
      user: study.workflow_user,
      client_id: study.client_id,
      can_be_viewed: study.can_be_viewed,
    };
    onViewReport(studyParams);
  };

  const closeCancelReview = () => {
    dispatch(Actions.setShowCancelReviewConfirm(false));
    dispatch(Actions.setStudyToAction(null));
  };

  return (
    <div
      className={cn('dashboard__actions', {
        'dashboard__actions--hidden': totalListCount <= 0,
      })}
    >
      {totalListCount > 0 && (
        <div className="workflow-actions">
          <div className="workflow-actions__list">
            {/* Studies recently approve */}
            {recentlyApprovedList.map((study, idx) => {
              return (
                study.report_approval && (
                  <ReviewCard
                    idx={idx}
                    category="recently approved"
                    key={`recently-approved__${idx}`}
                    patient={study.patient_name || ''}
                    assignee={''}
                    assigner={''}
                    assignedDate={formatDate(
                      study.report_approval.timestamp,
                      TIMEDATE_FORMAT
                    )}
                    reviewStatus={'approved'}
                    onConfirm={() => openStudyReport(study)}
                  />
                )
              );
            })}
            {/* Studies for review */}
            {forReviewList.map((study, idx) => {
              return (
                study.report_active_review && (
                  <ReviewCard
                    idx={idx}
                    category="for review"
                    key={`action_review__${idx}`}
                    comment={
                      study.report_active_review.message
                        ? {
                            message: study.report_active_review.message,
                            action: () => openStudyReport(study),
                          }
                        : undefined
                    }
                    patient={study.patient_name || ''}
                    assignee={study.report_active_review.assignee.name}
                    assigner={study.report_active_review.assigner.name}
                    assignedDate={formatDate(
                      study.report_active_review.created_at,
                      DATE_FORMAT
                    )}
                    reviewStatus={study.report_active_review.review_type}
                    onConfirm={() => openStudyReport(study)}
                  />
                )
              );
            })}
            {/* Studies awaiting review */}
            {awaitingReviewList.map((study, idx) => {
              return (
                study.report_active_review && (
                  <ReviewCard
                    idx={idx}
                    category="awaiting review"
                    key={`action_await_review__${idx}`}
                    comment={
                      study.report_active_review.message
                        ? {
                            message: study.report_active_review.message,
                            action: () => openStudyReport(study),
                          }
                        : undefined
                    }
                    patient={study.patient_name || ''}
                    assignee={study.report_active_review.assignee.name}
                    assigner={study.report_active_review.assigner.name}
                    assignedDate={formatDate(
                      study.report_active_review.created_at,
                      DATE_FORMAT
                    )}
                    reviewStatus={study.report_active_review.review_type}
                    onConfirm={() => {
                      dispatch(Actions.setStudyToAction(study));
                      dispatch(Actions.setShowCancelReviewConfirm(true));
                    }}
                  />
                )
              );
            })}
            {/* Studies awaiting approval */}
            {awaitingApproveList.map((study, idx) => {
              return (
                study.report_active_review && (
                  <ReviewCard
                    idx={idx}
                    category="awaiting approval"
                    key={`action_await__approval__${idx}`}
                    comment={
                      study.report_active_review.message
                        ? {
                            message: study.report_active_review.message,
                            action: () => openStudyReport(study),
                          }
                        : undefined
                    }
                    patient={study.patient_name || ''}
                    assignee={study.report_active_review.assignee.name}
                    assigner={study.report_active_review.assigner.name}
                    assignedDate={formatDate(
                      study.report_active_review.created_at,
                      DATE_FORMAT
                    )}
                    reviewStatus={study.report_active_review.review_type}
                    onConfirm={() => {
                      dispatch(Actions.setStudyToAction(study));
                      dispatch(Actions.setShowCancelReviewConfirm(true));
                    }}
                  />
                )
              );
            })}
          </div>
        </div>
      )}
      {
        /** Unmount when not showing so buttons aren't still clickable */ showCancelReviewConfirm && (
          <ActionModal
            closeText={'Cancel'}
            onClose={() => closeCancelReview()}
            confirmText={'Confirm'}
            onConfirm={() =>
              cancelReviewRequest({
                study: studyToAction,
                shouldFetchStudy: false,
              })
            }
            visible={showCancelReviewConfirm}
            headerContent={<>Cancel Review Request</>}
          >
            <p>Please confirm you wish to cancel this review request.</p>
          </ActionModal>
        )
      }
    </div>
  );
};

export default WorkflowActions;
