import cn from 'classnames';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useWindowContext } from '../../context/window-context';
import ScanModal from '../ScanModal/ScanModal';
import AnnotationButton from './AnnotationButton';
import AnnotationScreenshotActions from './AnnotationScreenshotActions';
import AnnotationScreenshotStage from './AnnotationScreenshotStage';

interface Props {
  screenshotRef: any; // @TODO work out type for react ref OR konva stage instance
  viewName: string;
  onTakeScreenshot?: (image: any) => void;
  saveToAPI?: boolean;
  onClose?: () => void;
  onRequestScreenshot?: any;
  screenshotDisabled?: boolean;
  vesselName?: string;
  hideText?: boolean;
  altIcon?: boolean;
  headerStyle?: boolean;
}

export type AnnotationShapeType = 'arrow' | 'text';

const DEFAULT_ANNOTATION_TYPE: AnnotationShapeType = 'arrow';

export default function AnnotationModal({
  screenshotRef,
  onRequestScreenshot,
  screenshotDisabled,
  onTakeScreenshot,
  onClose,
  saveToAPI = true,
  viewName,
  vesselName = '',
  hideText = false,
  altIcon = false,
  headerStyle = false,
}: Props): ReactElement<Props> {
  const [baseImage, setBaseImage] = useState(null);
  const [open, setOpen] = useState(false);
  const { setAnnotationModalOpen } = useWindowContext();

  const [annotationType, setAnnotationType] = useState<AnnotationShapeType>(
    DEFAULT_ANNOTATION_TYPE
  );

  const [width, setWidth] = useState(500);
  const [height, setHeight] = useState(500);
  const [stage, setStage] = useState(null);
  const contentRef = useRef<any | null>(null);

  useEffect(() => {
    if (contentRef.current) {
      setWidth(contentRef.current.clientWidth || 500);
      setHeight(contentRef.current.clientHeight || 500);
    }
  }, [open]);

  const openModal = (image: any) => {
    setBaseImage(image);
    setOpen(true);
    setAnnotationModalOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setAnnotationModalOpen(false);
    setBaseImage(null);
    onClose && onClose();
  };

  const onStageReady = (stage: any) => {
    setStage(stage);
  };

  return (
    <div
      className={cn('annotation-modal', {
        'annotation-modal__header': headerStyle,
      })}
    >
      <AnnotationButton
        onTakeScreenshot={openModal}
        screenshotRef={screenshotRef}
        onRequestScreenshot={onRequestScreenshot}
        hideText={hideText}
        altIcon={altIcon}
        headerStyle={headerStyle}
        disabled={screenshotDisabled}
      />
      {/* This ensures we don't create multiple ScanModals and only create it when needed too */}
      {open && (
        <ScanModal
          visible={open}
          onClose={closeModal}
          inScreenshotMode
          title={
            <AnnotationScreenshotActions
              annotationType={annotationType}
              stage={stage}
              onClose={closeModal}
              viewName={viewName}
              vesselName={vesselName}
              onTakeScreenshot={onTakeScreenshot}
              saveToAPI={saveToAPI}
              onChangeType={setAnnotationType}
            />
          }
        >
          <div ref={contentRef} className="annotation-stage">
            <AnnotationScreenshotStage
              annotationType={annotationType}
              stageWidth={width}
              stageHeight={height}
              seriesScreenshot={baseImage}
              onReady={onStageReady}
              setAnnotationType={setAnnotationType}
            />
          </div>
        </ScanModal>
      )}
    </div>
  );
}
